/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import CloseButton from '../mainView/CloseButton'
import Draggable from 'react-draggable'
import OpenWithIcon from '@mui/icons-material/OpenWith'

const AutomatedPlacementInfoBox = ({ setAutoPlacementInfo: setAutoPlacementInfo }) => {
  const draggableRef = useRef(null)
  return (
    <Draggable nodeRef={draggableRef} handle=".drag-icon">
      <Box
        ref={draggableRef}
        sx={{
          position: 'fixed',
          top: '48%',
          left: '10%',
          transform: 'translateX(-50%)',
          zIndex: 10000,
          backgroundColor: '#303030', // Box background color
          padding: 3,
          borderRadius: 2,
          width: '610px',
          maxWidth: '80%',
          color: '#fff',
          marginTop: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 1,
            backgroundColor: '#303030', // Matching header color with box color
            padding: '0 8px', // Reduced padding in the header
            borderRadius: '2px 2px 0 0' // Rounded corners on top only
          }}
        >
          <IconButton aria-label="info" sx={{ color: '#fff', marginRight: 1 }}>
            <InfoIcon />
          </IconButton>
          <Typography variant="subtitle1" component="span" sx={{ flexGrow: 1 }}>
            Automated Placement Explanation
          </Typography>
          <div>
            <OpenWithIcon
              className="drag-icon"
              sx={{
                color: '#FFFFFF',
                cursor: 'move',
                backgroundColor: '',
                borderRadius: '50%',
                fontSize: '25px',
                zIndex: 9999
              }}
            />
            <CloseButton setShowToolBox={setAutoPlacementInfo} />
          </div>
        </Box>
        <Typography variant="body1" sx={{ fontSize: '14px' }}>
          The Automated Placement Function simplifies implant positioning on the bone. Here is how
          to use it:
          <br />
          <br />
          1. Select the &quot;First Implant Point&quot; and &quot;First Bone Point&quot; to
          automatically move the implant to the desired position on the bone. It does not matter in
          which sequence you select the points.
          <br />
          <br />
          2. Choose the &quot;Second Implant Point&quot; and &quot;Second Bone Point&quot; to rotate
          the implant until these points, along with the &quot;First Bone Point&quot; form a
          straight line. A sphere indicates the distance between the &quot;First Bone Point&quot;
          and the second selected point. It does not matter in which sequence you select the points.
          <br />
          <br />
          3. Select the &quot;Third Implant Point&quot; and &quot;Third Bone Point&quot; to rotate
          the implant around the line between the &quot;First Bone Point&quot; and &quot;Second Bone
          Point&quot;. This aligns the &quot;Second Bone Point&quot;, &quot;Third Implant
          Point&quot;, and &quot;Third Bone Point&quot; on the same line. A circle appears to
          estimate the position of the &quot;Third Implant Point&quot;. It does not matter in which
          sequence you select the points.
          <br />
          <br />
          Once all six points are set, and the implant is moved, the Movement Type automatically
          reverts to &quot;None&quot;. You can also abort the &quot;Automated Placement&quot; method
          at any time by clicking on &quot;None&quot; or any other button in the Movement Type Menu.
        </Typography>
      </Box>
    </Draggable>
  )
}

export default AutomatedPlacementInfoBox
