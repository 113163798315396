import * as THREE from 'three'

import { addSphere } from './ScissorToolHandler3D'
import { removeAllObjects } from './SegmentToolHandler'

var pointList = {}

export function setMeasureCoordinates(dictObject, viewId) {
  // adds the positions of the meausre points to the list
  // and its viewId
  pointList = { ...dictObject }
  pointList['viewId'] = viewId
}

export function getMeasureCoordinates(scene) {
  /*
    Creates lines in the 3D view
    scene: scene
  */

  removeAllObjects(scene, 'sphere')
  removeAllObjects(scene, '3Dline')

  //Depending on the amount of points 1 or 2 lines are drawn
  //because the viewId is added to the dictonary
  //its length is amount_of_points +1

  if (Object.keys(pointList).length == 3) {
    addSphere(pointList.first, scene, 'blue')
    addSphere(pointList.second, scene, 'green')
    draw3DLine(pointList.first, pointList.second, scene)
  } else if (Object.keys(pointList).length == 4) {
    addSphere(pointList.first, scene, 'green')
    addSphere(pointList.second, scene, 'green')
    addSphere(pointList.third, scene, 'green')

    draw3DLine(pointList.first, pointList.second, scene)
    draw3DLine(pointList.second, pointList.third, scene)
  }
}

export function draw3DLine(point1, point2, scene, color = 'green', name = '3Dline', radius = 1) {
  const curve = new THREE.LineCurve3(point1, point2)
  const tubeGeometry = new THREE.TubeGeometry(curve, 1, radius, 8, false)
  const tubeMaterial = new THREE.MeshBasicMaterial({ color: color })
  const tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial)
  tubeMesh.name = name

  scene.add(tubeMesh)
}

export function drawTransparent3DLine(
  point1,
  point2,
  scene,
  color = 'green',
  name = '3Dline',
  radius = 1,
  opacity = 0.8
) {
  const curve = new THREE.LineCurve3(point1, point2)
  const tubeGeometry = new THREE.TubeGeometry(curve, 1, radius, 8, false)
  const tubeMaterial = new THREE.MeshBasicMaterial({ color: color })
  tubeMaterial.transparent = true
  tubeMaterial.opacity = opacity

  const tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial)
  tubeMesh.name = name

  scene.add(tubeMesh)
}

export function reCalcCoordinates(viewId, point, slider_value, geometry, volume) {
  /*
    recalculate the position of a 2D point in the 3D-view
    viewId: viewId,
    point: point,
    slider_value: position of the 2D-plane inside the volume,
    geometry: geometry,
    volume: volume

  */

  // the following calculations and transformations were found by brute force
  // and are the transformations from the 2D coordinates systems into the 3D view

  var x_new
  var y_new
  var z_new
  if (viewId === 'View1') {
    // Flipped coordinate system from 2D to 3D
    x_new = -point.y
    z_new = -point.x

    // Moving the 2D origin to the 3D origin
    x_new = x_new + geometry.parameters.height / 2
    z_new = z_new + geometry.parameters.width / 2

    //Change the ratio of the coordinate system
    x_new *= volume.xLength / geometry.parameters.height
    z_new *= volume.zLength / geometry.parameters.width

    //setting the new points
    point.x = x_new
    point.y = slider_value.current
    point.z = z_new
  } else if (viewId === 'View2') {
    // Flipped coordinate system from 2D to 3D
    z_new = -point.x
    y_new = -point.y

    // Moving the 2D origin to the 3D origin
    z_new = z_new + geometry.parameters.width / 2
    y_new = y_new + geometry.parameters.height / 2

    //Change the ratio of the coordinate system
    z_new *= volume.zLength / geometry.parameters.width
    y_new *= volume.yLength / geometry.parameters.height

    point.x = slider_value.current
    point.y = y_new
    point.z = z_new
  } else if (viewId === 'View3') {
    //Changed coordinate system
    x_new = point.x
    y_new = -point.y

    // Moving the 2D origin to the 3D origin
    x_new = x_new + geometry.parameters.width / 2
    y_new = y_new + geometry.parameters.height / 2

    //Change the ratio of the coordinate system
    x_new *= volume.xLength / geometry.parameters.width
    y_new *= volume.yLength / geometry.parameters.height

    //setting the new points
    point.x = x_new
    point.y = y_new
    //somehow this slider is inverted!
    point.z = slider_value.max - slider_value.current
  }
  return point
}
