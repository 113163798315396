/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import * as THREE from 'three'

import { TrackballControls } from './TrackballControlls'

import { VolumeRenderShader } from './VolumeShader'
import { LabelRenderShader } from './LabelShader'

import { useEffect, useRef } from 'react'
import Stats from 'stats.js'
import { getMeasureCoordinates } from './VariableStorage'
import { eventEmitter } from '../TwoDViews'
import { removeObject } from './SegmentToolHandler'

import {
  addBoundingBoxAndLabelsThreeScene,
  calculateNewCameraPositionThreeScene,
  toggleBoundingBoxVisibility
} from './ViewBoxUtils.jsx'

import requestAnimationFrame from 'dat.gui/src/dat/utils/requestAnimationFrame'

import { ConvexGeometry } from 'three/addons/geometries/ConvexGeometry.js'

const RenderingThreeScene = ({
  mainGUIRef: mainGUIRef,
  buttonState: buttonState,
  volume: volume,
  label: label,
  viewBoxRef4: viewBoxRef4,
  config3D: config3D,
  stats: stats,
  threeSceneInfo: threeSceneInfo,
  setThreeSceneInfo: setThreeSceneInfo,
  isSegmentationVisible: isSegmentationVisible,
  isVolumeVisible: isVolumeVisible,
  ISOThreshold: ISOThreshold,
  labelThresholdRange: labelThresholdRange,
  isoSliderInfo: isoSliderInfo,
  setIsoSliderInfo: setIsoSliderInfo,
  IsLabelChange: IsLabelChange,
  labelColorSwitch: labelColorSwitch,
  cameraResetClicked: cameraResetClicked,
  setCameraResetClicked: setCameraResetClicked,
  isRestore: isRestore,
  setIsRestore: setIsRestore,
  predictedData: predictedData,
  rotationVector: rotationVector,
  setRotationVector: setRotationVector,
  viewBoxOn: viewBoxOn,
  segmentConfig: segmentConfig,
  selectedSegment: selectedSegment,
  setIsSegmentToolAffected: setIsSegmentToolAffected,
  setIsLabelChange: setIsLabelChange,
  rotateSpeed: rotateSpeed
}) => {
  const containerRef = useRef(null)
  const guiVolFolderRef = useRef(null)
  const guiSegFolderRef = useRef(null)
  const materialRef = useRef(null)
  const materialSegRef = useRef(null)

  const renderLoopRef = useRef(null)
  const scene = useRef(new THREE.Scene())
  const cameraRef = useRef()
  const rendererRef = useRef()
  const controlsRef = useRef()

  const meshSegRef = useRef(null)
  const labelRef = useRef(null)

  const [initBool, setInitBool] = useState(false)

  const cameraPositionsRef = useRef({})
  const boundingBoxMeshRef = useRef(null)

  const [initUniforms, setInitUniforms] = useState(false)

  let labelCount = 0

  let lastTime = useRef(Date.now())
  const fps = 30
  const interval = 1000 / fps

  // we don't downsample volume right now
  // const downSamplingFactor = 2
  // const maxInsteadOfAverage = false

  function tick() {
    const now = Date.now()
    const elapsed = now - lastTime.current

    if (elapsed > interval) {
      lastTime.current = now - (elapsed % interval)

      if (stats != null) {
        stats.update()
      }

      // Ensure trackball controls are updated in the animation loop
      if (controlsRef.current != null) {
        controlsRef.current.update()
      }

      render()
    }

    requestAnimationFrame(tick)

    // commented out to avoid constant react re-rendering of the whole 2d scene as well
    // setThreeSceneInfo((prevState) => ({
    //   ...prevState,
    //   controlsTarget: controlsRef.current.target.clone(),
    //   camera: cameraRef.current.clone()
    // }))
  }

  // const tick = function () {
  //   if (stats != null) {
  //     stats.begin()
  //   }
  //   render()

  //   if (stats != null) {
  //     stats.end()
  //   }

  //   controlsRef.current.update()

  //   renderLoopRef.current = requestAnimationFrame(tick)

  // }

  const animate = function () {
    if (renderLoopRef.current === null) {
      renderLoopRef.current = requestAnimationFrame(tick)
      console.debug('render loop initiated')
    } else {
      console.debug('render loop already initiated, ignoring')
    }
  }

  function render() {
    rendererRef.current.render(scene.current, cameraRef.current)
  }

  const updateSize = () => {
    if (
      viewBoxRef4.current &&
      viewBoxRef4.current.clientWidth != null &&
      viewBoxRef4.current.clientHeight != null &&
      rendererRef.current
    ) {
      setTimeout(() => {
        rendererRef.current.setSize(
          viewBoxRef4.current.clientWidth,
          viewBoxRef4.current.clientHeight
        )
      }, 5000)
    }
  }

  function createColorMapTexture(colorTransfer) {
    const canvas = document.createElement('canvas')
    canvas.width = 256
    canvas.height = 1
    const ctx = canvas.getContext('2d')
    const imageData = ctx.createImageData(256, 1)

    for (let i = 0; i < 256; i++) {
      const t = i / 255
      let r = 0,
        g = 0,
        b = 0

      for (let j = 0; j < colorTransfer.length; j += 4) {
        if (t >= colorTransfer[j] && t <= colorTransfer[j + 4]) {
          const localT = (t - colorTransfer[j]) / (colorTransfer[j + 4] - colorTransfer[j])
          r = colorTransfer[j + 1] * (1 - localT) + colorTransfer[j + 5] * localT
          g = colorTransfer[j + 2] * (1 - localT) + colorTransfer[j + 6] * localT
          b = colorTransfer[j + 3] * (1 - localT) + colorTransfer[j + 7] * localT
          break
        }
      }

      imageData.data[i * 4 + 0] = r * 255
      imageData.data[i * 4 + 1] = g * 255
      imageData.data[i * 4 + 2] = b * 255
      imageData.data[i * 4 + 3] = 255
    }

    ctx.putImageData(imageData, 0, 0)

    const texture = new THREE.Texture(canvas)
    texture.needsUpdate = true
    return texture
  }

  const updateUniforms = function () {
    if (guiVolFolderRef) {
      const volConfig = config3D.volConfig
      // const cmTextures = new THREE.TextureLoader().load('cm_gray.png', animate)

      const cmTextures = createColorMapTexture(volConfig.colorTransfer)

      materialRef.current.uniforms['uClim'].value.set(volConfig.clim1, volConfig.clim2)
      materialRef.current.uniforms['uRenderStyle'].value = 1 // 0: MIP, 1: ISO
      materialRef.current.uniforms['uCmData'].value = cmTextures
      materialRef.current.uniforms['uOpacity'].value = volConfig.ctOpacity

      materialRef.current.uniforms['uMaxRenderThreshold'].value = 0

      if (volConfig.maxisoThreshold === 0) {
        setInitUniforms(true)
        materialRef.current.uniforms['uMaxRenderThreshold'].value = isoSliderInfo.max
      } else {
        materialRef.current.uniforms['uMaxRenderThreshold'].value = volConfig.maxisoThreshold
      }

      if (initUniforms) {
        materialRef.current.uniforms['uMaxRenderThreshold'].value = volConfig.maxisoThreshold
      }

      materialRef.current.uniforms['uRenderThreshold'].value = volConfig.isoThreshold // For ISO renderStyle
    }

    if (buttonState != 'Image' && buttonState != 'None') {
      const segConfig = config3D.segConfig
      const sgTextures = new THREE.TextureLoader().load('cm_gray.png', animate)

      materialSegRef.current.uniforms['uClim'].value.set(0.0, 0.0)
      materialSegRef.current.uniforms['uRenderStyle'].value = 1 // 0: MIP, 1: ISO
      materialSegRef.current.uniforms['uRenderThreshold'].value = segConfig.segThreshold // For ISO renderStyle
      materialSegRef.current.uniforms['uCmData'].value = sgTextures
      materialSegRef.current.uniforms['uLabelCount'].value = labelCount
      materialSegRef.current.uniforms['uLabelColorSwitch'].value = labelColorSwitch
    }

    animate()
  }

  // we don't downsample anymore so far.

  // function downsampleVolume(data, width, height, depth, factor, maxInsteadOfAverage = true) {
  //   const newWidth = Math.floor(width / factor)
  //   const newHeight = Math.floor(height / factor)
  //   const newDepth = Math.floor(depth / factor)
  //   const newData = new Float32Array(newWidth * newHeight * newDepth)

  //   for (let z = 0; z < newDepth; z++) {
  //     for (let y = 0; y < newHeight; y++) {
  //       for (let x = 0; x < newWidth; x++) {
  //         let total = 0
  //         let count = 0
  //         let localMax = -Infinity
  //         for (let dz = 0; dz < factor; dz++) {
  //           for (let dy = 0; dy < factor; dy++) {
  //             for (let dx = 0; dx < factor; dx++) {
  //               const ix =
  //                 (z * factor + dz) * width * height + (y * factor + dy) * width + (x * factor + dx)
  //               if (ix < data.length) {
  //                 total += data[ix]
  //                 if (localMax < data[ix]) {
  //                   localMax = data[ix]
  //                 }
  //                 count++
  //               }
  //             }
  //           }
  //         }
  //         if (maxInsteadOfAverage) {
  //           newData[z * newWidth * newHeight + y * newWidth + x] = localMax
  //         } else {
  //           newData[z * newWidth * newHeight + y * newWidth + x] = total / count
  //         }
  //       }
  //     }
  //   }

  //   return { data: newData, xLength: newWidth, yLength: newHeight, zLength: newDepth }
  // }

  // resample volume

  // withtou postprocessing to fill segmentation holes
  // function resampleVolumeToIsotropic(data, width, height, depth, originalSpacing) {
  //   // New target spacing is always 1x1x1
  //   const newSpacing = [1.0, 1.0, 1.0]

  //   // Calculate new dimensions based on the original spacing and target isotropic spacing
  //   const newWidth = Math.floor(width * (originalSpacing[0] / newSpacing[0]))
  //   const newHeight = Math.floor(height * (originalSpacing[1] / newSpacing[1]))
  //   const newDepth = Math.floor(depth * (originalSpacing[2] / newSpacing[2]))

  //   const newData = new Float32Array(newWidth * newHeight * newDepth)

  //   // Perform nearest-neighbor interpolation
  //   for (let z = 0; z < newDepth; z++) {
  //     for (let y = 0; y < newHeight; y++) {
  //       for (let x = 0; x < newWidth; x++) {
  //         // Calculate the nearest corresponding voxel in the original volume
  //         const origX = Math.min(Math.floor(x * (newSpacing[0] / originalSpacing[0])), width - 1)
  //         const origY = Math.min(Math.floor(y * (newSpacing[1] / originalSpacing[1])), height - 1)
  //         const origZ = Math.min(Math.floor(z * (newSpacing[2] / originalSpacing[2])), depth - 1)

  //         // Find the corresponding index in the original data
  //         const originalIndex = origZ * width * height + origY * width + origX

  //         // Assign the nearest-neighbor value to the new data array
  //         newData[z * newWidth * newHeight + y * newWidth + x] = data[originalIndex]
  //       }
  //     }
  //   }

  //   return { data: newData, xLength: newWidth, yLength: newHeight, zLength: newDepth }
  // }

  function resampleVolumeToIsotropic(data, width, height, depth, originalSpacing) {
    // New target spacing is always 1x1x1
    const newSpacing = [1.0, 1.0, 1.0]

    // Calculate new dimensions based on the original spacing and target isotropic spacing
    const newWidth = Math.floor(width * (originalSpacing[0] / newSpacing[0]))
    const newHeight = Math.floor(height * (originalSpacing[1] / newSpacing[1]))
    const newDepth = Math.floor(depth * (originalSpacing[2] / newSpacing[2]))

    const newData = new Float32Array(newWidth * newHeight * newDepth)

    // Perform nearest-neighbor interpolation with selective hole filling
    for (let z = 0; z < newDepth; z++) {
      for (let y = 0; y < newHeight; y++) {
        for (let x = 0; x < newWidth; x++) {
          // Calculate the nearest corresponding voxel in the original volume
          const origX = Math.min(Math.floor(x * (newSpacing[0] / originalSpacing[0])), width - 1)
          const origY = Math.min(Math.floor(y * (newSpacing[1] / originalSpacing[1])), height - 1)
          const origZ = Math.min(Math.floor(z * (newSpacing[2] / originalSpacing[2])), depth - 1)

          // Find the corresponding index in the original data
          const originalIndex = origZ * width * height + origY * width + origX
          let value = data[originalIndex]

          // Check if the value is a hole (zero in this case) and adjust slightly
          if (value === 0) {
            let sum = 0
            let count = 0

            // Check directly adjacent neighbors (6-connected)
            const adjacentOffsets = [
              [1, 0, 0],
              [-1, 0, 0], // X direction
              [0, 1, 0],
              [0, -1, 0], // Y direction
              [0, 0, 1],
              [0, 0, -1] // Z direction
            ]

            adjacentOffsets.forEach(([dx, dy, dz]) => {
              const neighborX = origX + dx
              const neighborY = origY + dy
              const neighborZ = origZ + dz

              // Check if the neighbor is within bounds
              if (
                neighborX >= 0 &&
                neighborX < width &&
                neighborY >= 0 &&
                neighborY < height &&
                neighborZ >= 0 &&
                neighborZ < depth
              ) {
                const neighborIndex = neighborZ * width * height + neighborY * width + neighborX
                const neighborValue = data[neighborIndex]

                // Collect non-zero neighbor values for minimal adjustment
                if (neighborValue !== 0) {
                  sum += neighborValue
                  count++
                }
              }
            })

            // Adjust the hole value slightly if valid neighbors are found
            if (count > 0) {
              value = sum / count // Partial fill: simple average of adjacent valid neighbors
            }
          }

          // Assign the adjusted or original value to the new data array
          newData[z * newWidth * newHeight + y * newWidth + x] = value
        }
      }
    }

    return { data: newData, xLength: newWidth, yLength: newHeight, zLength: newDepth }
  }

  useEffect(() => {
    function createLinesIn3D() {
      getMeasureCoordinates(scene.current)
      // animate()
    }

    if (volume === null) {
      return
    }

    if (!viewBoxRef4.current) {
      return
    }

    const width = viewBoxRef4.current.clientWidth
    const height = viewBoxRef4.current.clientHeight

    let material, materialSeg, mesh, meshSeg, cmTextures, sgTextures

    let volConfig = config3D.volConfig
    let segConfig = config3D.segConfig
    let existingController
    let ctVisibilityControl = {
      visible: config3D.visibility.ctVisibilityControl
    }
    let segVisibilityControl = {
      visible: config3D.visibility.segVisibilityControl
    }

    const guiVolFolder = mainGUIRef.current.__folders['Volume 3D']
    const guiSegFolder = mainGUIRef.current.__folders['Segmentation 3D']

    if (buttonState != 'None') {
      if (!guiVolFolder) {
        return
      }
      guiVolFolderRef.current = guiVolFolder
    }

    if (buttonState != 'Image' && buttonState != 'None') {
      if (!guiSegFolder) {
        return
      }

      if (label === null) {
        return
      }

      guiSegFolderRef.current = guiSegFolder
    }

    if (stats === null) {
      stats = new Stats()
      stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom

      stats.dom.style.position = 'fixed'
      stats.dom.style.top = '0'
      stats.dom.style.left = 'auto'
      stats.dom.style.right = '0'
      stats.dom.id = 'statsContainer' // Assign a specific name to the stats.dom element
      document.body.appendChild(stats.dom)
    }

    const groupSegment = new THREE.Group()
    const groupVolume = new THREE.Group()

    groupSegment.name = '3DSegmentGroup'
    groupVolume.name = '3DVolumesGroup'

    const renderer = new THREE.WebGLRenderer()
    renderer.sortObjects = false
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(width, height)
    containerRef.current.appendChild(renderer.domElement)

    const frustumSize = 400 //ADJUST!
    const aspect = width / height

    const camera = new THREE.OrthographicCamera(
      (frustumSize * aspect) / -2,
      (frustumSize * aspect) / 2,
      frustumSize / 2,
      frustumSize / -2,
      1,
      1000
    )

    camera.up.set(0, -1, 0) // camera vertical flip at the initialized step

    camera.position.z = -100

    const controls = new TrackballControls(camera, renderer.domElement)
    controls.rotateSpeed = 1.0
    controls.zoomSpeed = 1.0
    controls.panSpeed = 0.5

    controlsRef.current = controls
    cameraRef.current = camera
    rendererRef.current = renderer

    // initalize camera and orbit controls at the first time
    if (threeSceneInfo.initialized === false) {
      // in order to prevent clipping, we need to move the camera or volume away from eachother in 3D space.
      // camera.position.set(-1000, 0, 0) //moved far away from scan in x axis to avoid parts of the volume moving behind the camera frustrum. View gets reset by orbitcontrols block below

      controlsRef.current.target.set(0, 0, 0)
      controlsRef.current.minZoom = 0.5
      controlsRef.current.maxZoom = 4
      controlsRef.current.enablePan = false

      setThreeSceneInfo({
        ...threeSceneInfo,
        initialized: true,
        controlsTarget: controlsRef.current.target.clone(),
        camera: cameraRef.current.clone(),
        originalCamera: cameraRef.current.clone(),
        originalControls: controlsRef.current.target.clone()
      })
    }
    // update camera and orbitcontrols from latest version to prevent reset whenever rerendering
    else {
      controlsRef.current.target.copy(threeSceneInfo.controlsTarget)

      cameraRef.current.copy(threeSceneInfo.camera)

      cameraRef.current.updateProjectionMatrix()
      controlsRef.current.update()
    }

    //SEGMENTATION LOADER
    if (buttonState != 'Image' && buttonState != 'None') {
      labelCount = 0

      const downSampledLabel = resampleVolumeToIsotropic(
        label.data,
        label.xLength,
        label.yLength,
        label.zLength,
        label.spacing
      )

      label.downsampledData = downSampledLabel.data
      label.downsampledX = downSampledLabel.xLength
      label.downsampledY = downSampledLabel.yLength
      label.downsampledZ = downSampledLabel.zLength

      labelRef.current = label

      // labelCount = Object.values(label.data).filter((value) => value > 0).length
      labelCount = labelRef.current.downsampledData.reduce(
        (count, value) => (value > 0 ? count + 1 : count),
        0
      )

      const textureSeg = new THREE.Data3DTexture(
        labelRef.current.downsampledData,
        labelRef.current.downsampledX,
        labelRef.current.downsampledY,
        labelRef.current.downsampledZ
      )
      textureSeg.format = THREE.RedFormat
      textureSeg.type = THREE.FloatType
      textureSeg.minFilter = textureSeg.magFilter = THREE.LinearFilter
      textureSeg.unpackAlignment = 1
      textureSeg.needsUpdate = true
      sgTextures = new THREE.TextureLoader().load('cm_viridis.png', animate) //different to the original xyz

      const shaderSeg = LabelRenderShader
      const uniformsSeg = THREE.UniformsUtils.clone(shaderSeg.uniforms)

      uniformsSeg['uData'].value = textureSeg
      uniformsSeg['uSize'].value.set(
        labelRef.current.downsampledX,
        labelRef.current.downsampledY,
        labelRef.current.downsampledZ
      )
      // uniformsSeg['uClim'].value.set(0.0, 0.0)
      uniformsSeg['uClim'].value.set(volConfig.clim1, volConfig.clim2)
      uniformsSeg['uRenderStyle'].value = 1 // 0: MIP, 1: ISO
      uniformsSeg['uRenderThreshold'].value = segConfig.segThreshold // For ISO renderStyle
      uniformsSeg['uCmData'].value = sgTextures
      uniformsSeg['uLabelCount'].value = labelCount
      uniformsSeg['uOpacity'].value = volConfig.ctOpacity

      materialSeg = new THREE.ShaderMaterial({
        uniforms: uniformsSeg,
        vertexShader: shaderSeg.vertexShader,
        fragmentShader: shaderSeg.fragmentShader,
        side: THREE.BackSide
      })
      materialSeg.transparent = true

      materialSegRef.current = materialSeg

      // THREE.Mesh
      const geometrySeg = new THREE.BoxGeometry(
        labelRef.current.downsampledX,
        labelRef.current.downsampledY,
        labelRef.current.downsampledZ
      )
      geometrySeg.translate(
        labelRef.current.downsampledX / 2 - 0.5,
        labelRef.current.downsampledY / 2 - 0.5,
        labelRef.current.downsampledZ / 2 - 0.5
      )

      meshSeg = new THREE.Mesh(geometrySeg, materialSeg)
      meshSeg.name = 'meshSeg'
      meshSegRef.current = meshSeg

      meshSeg.visible = config3D.visibility.segVisibilityControl

      groupSegment.add(meshSeg)
      var controlTarget = new THREE.Vector3()

      meshSeg.geometry.computeBoundingBox()
      meshSeg.geometry.boundingBox.getCenter(controlTarget)

      controls.target.copy(controlTarget)
      controls.update()

      existingController = guiSegFolder.__controllers.find(
        (controller) => controller.property === 'visible'
      )
      if (existingController) {
        guiSegFolder.remove(existingController)
      }

      guiSegFolder
        .add(segVisibilityControl, 'visible')
        .name('Segmentation Visible')
        .onChange(function () {
          meshSeg.visible = segVisibilityControl.visible
          config3D.visibility.segVisibilityControl = segVisibilityControl.visible
          animate()
        })

      scene.current.add(groupSegment)
    }

    const downSampled = resampleVolumeToIsotropic(
      volume.data,
      volume.xLength,
      volume.yLength,
      volume.zLength,
      volume.spacing
    )

    volume.downsampledData = downSampled.data
    volume.downsampledX = downSampled.xLength
    volume.downsampledY = downSampled.yLength
    volume.downsampledZ = downSampled.zLength

    //VOLUME LOADER
    const texture = new THREE.Data3DTexture(
      volume.downsampledData,
      volume.downsampledX,
      volume.downsampledY,
      volume.downsampledZ
    )

    // //VOLUME LOADER
    // const texture = new THREE.Data3DTexture(
    //   volume.data,
    //   volume.xLength,
    //   volume.yLength,
    //   volume.zLength
    // )
    texture.format = THREE.RedFormat
    texture.type = THREE.FloatType
    texture.minFilter = texture.magFilter = THREE.LinearFilter
    texture.unpackAlignment = 1
    texture.needsUpdate = true

    // cmTextures = new THREE.TextureLoader().load('cm_gray.png', animate)
    cmTextures = createColorMapTexture(volConfig.colorTransfer)

    const shader = VolumeRenderShader

    const uniforms = THREE.UniformsUtils.clone(shader.uniforms)

    uniforms['uData'].value = texture
    uniforms['uSize'].value.set(volume.downsampledX, volume.downsampledY, volume.downsampledZ)
    uniforms['uClim'].value.set(volConfig.clim1, volConfig.clim2)
    uniforms['uRenderStyle'].value = 1 // 0: MIP, 1: ISO
    uniforms['uCmData'].value = cmTextures
    uniforms['uOpacity'].value = volConfig.ctOpacity

    uniforms['uRenderThreshold'].value = isoSliderInfo.min
    uniforms['uMaxRenderThreshold'].value = isoSliderInfo.max

    material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader,
      side: THREE.BackSide, // The volume shader uses the backface as its "reference point"
      transparent: true
    })

    materialRef.current = material

    // THREE.Mesh
    const geometry = new THREE.BoxGeometry(
      volume.downsampledX,
      volume.downsampledY,
      volume.downsampledZ
    )

    geometry.translate(
      volume.downsampledX / 2 - 0.5,
      volume.downsampledY / 2 - 0.5,
      volume.downsampledZ / 2 - 0.5
    )

    geometry.computeVertexNormals()

    mesh = new THREE.Mesh(geometry, material)
    mesh.visible = config3D.visibility.ctVisibilityControl
    mesh.castShadow = true

    mesh.name = 'volumeMesh'

    groupVolume.add(mesh)

    //set orbit control center
    controls.target.set(volume.downsampledX / 2, volume.downsampledY / 2, volume.downsampledZ / 2)
    controls.update()
    scene.current.add(groupVolume)

    // remove old properties
    existingController = guiVolFolder.__controllers.find(
      (controller) => controller.property === 'isoThreshold'
    )
    if (existingController) {
      guiVolFolder.remove(existingController)
    }
    existingController = guiVolFolder.__controllers.find(
      (controller) => controller.property === 'maxisoThreshold'
    )
    if (existingController) {
      guiVolFolder.remove(existingController)
    }
    existingController = guiVolFolder.__controllers.find(
      (controller) => controller.property === 'ctOpacity'
    )
    if (existingController) {
      guiVolFolder.remove(existingController)
    }
    existingController = guiVolFolder.__controllers.find(
      (controller) => controller.property === 'visible'
    )
    if (existingController) {
      guiVolFolder.remove(existingController)
    }

    guiVolFolder.add(volConfig, 'isoThreshold', 0, 5000, 1).onChange(updateUniforms)
    guiVolFolder.add(volConfig, 'ctOpacity', 0, 1, 0.01).onChange(updateUniforms)
    guiVolFolder
      .add(ctVisibilityControl, 'visible')
      .name('Model Visible')
      .onChange(function () {
        mesh.visible = ctVisibilityControl.visible
        config3D.visibility.ctVisibilityControl = ctVisibilityControl.visible
        animate()
      })
    guiVolFolder.add(volConfig, 'maxisoThreshold', 0, 10000, 1).onChange(updateUniforms)

    containerRef.current.appendChild(renderer.domElement)
    eventEmitter.on('measureFucntionCalled', createLinesIn3D) //this function is called inside the measure3D function and then it calls a function to create 3D lines
    const minMax = volume.computeMinMax()
    const min = minMax[0]
    const max = minMax[1]
    // // attach the scalar range to the volume
    volume.windowLow = min
    volume.windowHigh = max
    animate()

    setInitBool(true)

    return () => {
      // Clean up the animation loop when component unmounts
      if (containerRef.current) {
        containerRef.current.removeChild(renderer.domElement)
      }

      renderer.dispose()

      removeObject(scene.current, '3DVolumesGroup')
      removeObject(scene.current, '3DSegmentGroup')
    }
  }, [mainGUIRef, volume, label, buttonState])

  useEffect(() => {
    if (!viewBoxRef4.current) {
      return
    }

    if (rendererRef.current) {
      updateSize()
    }

    if (containerRef.current) {
      window.addEventListener('resize', updateSize)
    }

    return () => {
      // Clean up the animation loop when component unmounts
      if (containerRef.current) {
        window.removeEventListener('resize', updateSize)
      }
    }
  }, [viewBoxRef4])

  // create useEffect when position is out of value, then return camera position
  // but it is not ideal solution, we need to find better way to solve this issue
  useEffect(() => {
    if (rendererRef.current && scene.current) {
      if (
        isNaN(cameraRef.current.position.x) ||
        isNaN(cameraRef.current.position.y) ||
        isNaN(cameraRef.current.position.z)
      ) {
        cameraRef.current.position.x = 0
        cameraRef.current.position.y = 0
        cameraRef.current.position.z = -100

        cameraRef.current.updateProjectionMatrix()

        animate()
      }
    }
  }, []) // Empty dependency array to ensure it runs only once

  useEffect(() => {
    if (guiVolFolderRef.current !== null) {
      const existingController = guiVolFolderRef.current.__controllers.find(
        (controller) => controller.property === 'visible'
      )

      if (existingController !== undefined) {
        existingController.setValue(isVolumeVisible)
      } else {
        console.warn('Controller with property "visible" not found')
      }
    } else {
      console.warn('guiVolFolderRef.current is null')
    }
  }, [isVolumeVisible])

  useEffect(() => {
    if (guiSegFolderRef.current !== null) {
      const existingController = guiSegFolderRef.current.__controllers.find(
        (controller) => controller.property === 'visible'
      )

      if (existingController !== undefined) {
        existingController.setValue(isSegmentationVisible)
      } else {
        console.warn('Controller with property "visible" not found')
      }
      if (guiVolFolderRef) {
        if (isSegmentationVisible) {
          materialRef.current.uniforms['uOpacity'].value = 0.5
        } else {
          materialRef.current.uniforms['uOpacity'].value = 1.0
        }

        animate()
      }
    } else {
      console.warn('guiSegFolderRef.current is null')
    }
  }, [isSegmentationVisible])

  useEffect(() => {
    if (guiVolFolderRef.current !== null) {
      const isoThresholControllerMIN = guiVolFolderRef.current.__controllers.find(
        (controller) => controller.property === 'isoThreshold'
      )

      if (isoThresholControllerMIN !== undefined) {
        isoThresholControllerMIN.setValue(ISOThreshold.min)

        if (setIsoSliderInfo.initialized) {
          setIsoSliderInfo((prevState) => ({
            ...prevState,
            min: ISOThreshold.min
          }))
        }
      } else {
        console.warn('Controller with property "isoThreshold" not found')
      }
    } else {
      console.warn('guiVolFolderRef.current is null')
    }
  }, [ISOThreshold.min, labelThresholdRange])

  useEffect(() => {
    if (guiVolFolderRef.current !== null) {
      const isoThresholControllerMAX = guiVolFolderRef.current.__controllers.find(
        (controller) => controller.property === 'maxisoThreshold'
      )

      if (isoThresholControllerMAX !== undefined) {
        isoThresholControllerMAX.setValue(ISOThreshold.max)

        if (setIsoSliderInfo.initialized) {
          setIsoSliderInfo((prevState) => ({
            ...prevState,
            max: ISOThreshold.max
          }))
        }
      } else {
        console.warn('Controller with property "maxisoThreshold" not found')
      }
    } else {
      console.warn('guiVolFolderRef.current is null')
    }
  }, [ISOThreshold.max, labelThresholdRange])

  useEffect(() => {
    if (IsLabelChange && labelRef.current && meshSegRef.current && materialSegRef.current) {
      // const maxInsteadOfAverage = true

      const downSampledLabel = resampleVolumeToIsotropic(
        labelRef.current.data,
        label.xLength,
        label.yLength,
        label.zLength,
        label.spacing
      )

      labelRef.current.downsampledData = downSampledLabel.data
      labelRef.current.downsampledX = downSampledLabel.xLength
      labelRef.current.downsampledY = downSampledLabel.yLength
      labelRef.current.downsampledZ = downSampledLabel.zLength

      // Create a new texture with the updated label data
      const updatedTextureSeg = new THREE.Data3DTexture(
        labelRef.current.downsampledData,
        labelRef.current.downsampledX,
        labelRef.current.downsampledY,
        labelRef.current.downsampledZ
      )
      updatedTextureSeg.format = THREE.RedFormat
      updatedTextureSeg.type = THREE.FloatType
      updatedTextureSeg.minFilter = updatedTextureSeg.magFilter = THREE.LinearFilter
      updatedTextureSeg.unpackAlignment = 1
      updatedTextureSeg.needsUpdate = true

      // Update the material's uniforms-
      materialSegRef.current.uniforms['uData'].value = updatedTextureSeg

      // Trigger a re-render
      animate()
    }
  }, [IsLabelChange])

  useEffect(() => {
    if (labelRef.current && meshSegRef.current && materialSegRef.current) {
      // const maxInsteadOfAverage = true

      const downSampledLabel = resampleVolumeToIsotropic(
        labelRef.current.data,
        label.xLength,
        label.yLength,
        label.zLength,
        label.spacing
      )

      labelRef.current.downsampledData = downSampledLabel.data
      labelRef.current.downsampledX = downSampledLabel.xLength
      labelRef.current.downsampledY = downSampledLabel.yLength
      labelRef.current.downsampledZ = downSampledLabel.zLength

      // Create a new texture with the updated label data
      const updatedTextureSeg = new THREE.Data3DTexture(
        labelRef.current.downsampledData,
        labelRef.current.downsampledX,
        labelRef.current.downsampledY,
        labelRef.current.downsampledZ
      )
      updatedTextureSeg.format = THREE.RedFormat
      updatedTextureSeg.type = THREE.FloatType
      updatedTextureSeg.minFilter = updatedTextureSeg.magFilter = THREE.LinearFilter
      updatedTextureSeg.unpackAlignment = 1
      updatedTextureSeg.needsUpdate = true

      // Update the material's uniforms-
      materialSegRef.current.uniforms['uData'].value = updatedTextureSeg
      materialSegRef.current.uniforms['uLabelColorSwitch'].value = labelColorSwitch

      // Trigger a re-render
      animate()
    }
  }, [labelColorSwitch])

  useEffect(() => {
    if (cameraResetClicked) {
      if (cameraRef.current && controlsRef.current) {
        setCameraResetClicked(false)

        cameraRef.current.copy(threeSceneInfo.originalCamera)

        cameraRef.current.updateProjectionMatrix()

        // Reset controls
        controlsRef.current.target.copy(threeSceneInfo.originalControls)

        setThreeSceneInfo((prevState) => ({
          ...prevState,
          controlsTarget: threeSceneInfo.originalControls,
          camera: threeSceneInfo.originalCamera
        }))

        animate()
      }
    }
  }, [cameraResetClicked])

  useEffect(() => {
    if (isRestore && labelRef.current && meshSegRef.current && materialSegRef.current) {
      // const maxInsteadOfAverage = true

      labelRef.current.data = new Float32Array(predictedData)

      const downSampledLabel = resampleVolumeToIsotropic(
        labelRef.current.data,
        label.xLength,
        label.yLength,
        label.zLength,
        label.spacing
      )

      labelRef.current.downsampledData = downSampledLabel.data
      labelRef.current.downsampledX = downSampledLabel.xLength
      labelRef.current.downsampledY = downSampledLabel.yLength
      labelRef.current.downsampledZ = downSampledLabel.zLength

      // Create a new texture with the updated label data
      const updatedTextureSeg = new THREE.Data3DTexture(
        labelRef.current.downsampledData,
        labelRef.current.downsampledX,
        labelRef.current.downsampledY,
        labelRef.current.downsampledZ
      )
      updatedTextureSeg.format = THREE.RedFormat
      updatedTextureSeg.type = THREE.FloatType
      updatedTextureSeg.minFilter = updatedTextureSeg.magFilter = THREE.LinearFilter
      updatedTextureSeg.unpackAlignment = 1
      updatedTextureSeg.needsUpdate = true

      // Update the material's uniforms-
      materialSegRef.current.uniforms['uData'].value = updatedTextureSeg

      setIsRestore(false)

      // Trigger a re-render
      animate()
    }
  }, [isRestore])

  useEffect(() => {
    if (initBool) {
      scene.current.children = scene.current.children.filter(
        (child) => child.name !== 'BoundingBox'
      )
      boundingBoxMeshRef.current = addBoundingBoxAndLabelsThreeScene(scene.current)

      if (!threeSceneInfo.set && cameraRef.current && controlsRef.current) {
        setThreeSceneInfo((prevState) => ({
          ...prevState,
          set: true,
          originalCamera: cameraRef.current.clone(),
          originalControls: controlsRef.current.target.clone()
        }))
      }

      if (threeSceneInfo.set) {
        cameraPositionsRef.current = {
          camera: threeSceneInfo.originalCamera,
          controlsTarget: threeSceneInfo.originalControls
        }
      } else {
        cameraPositionsRef.current = {
          camera: cameraRef.current.clone(),
          controlsTarget: controlsRef.current.target.clone()
        }
      }
    }
  }, [initBool])

  useEffect(() => {
    if (rotationVector !== null) {
      const zoomValue = cameraRef.current.zoom

      // Copy the initial camera and control targets
      cameraRef.current.copy(cameraPositionsRef.current.camera)
      controlsRef.current.target.copy(cameraPositionsRef.current.controlsTarget)

      // Calculate and set the new camera position based on rotationVector
      const newPosition = calculateNewCameraPositionThreeScene(
        cameraPositionsRef.current.camera.position,
        cameraPositionsRef.current.controlsTarget,
        rotationVector
      )
      cameraRef.current.position.copy(newPosition)

      // Special handling for 'S' and 'I' to adjust the camera's up vector and lookAt
      if (rotationVector === 'S') {
        cameraRef.current.up.set(0, 0, -1) // For looking down
      } else if (rotationVector === 'I') {
        cameraRef.current.up.set(0, 0, 1) // For looking up
      }

      if (rotationVector === 'S' || rotationVector === 'I') {
        cameraRef.current.lookAt(controlsRef.current.target) // Ensure correct orientation
      }
      cameraRef.current.zoom = zoomValue
      cameraRef.current.updateProjectionMatrix()
      animate() // Update the scene
      setRotationVector(null) // Reset rotationVector to avoid re-triggering
    }
  }, [rotationVector])

  useEffect(() => {
    if (viewBoxOn === true) {
      toggleBoundingBoxVisibility(boundingBoxMeshRef.current, true) // Show
    } else {
      toggleBoundingBoxVisibility(boundingBoxMeshRef.current, false) // Hide
    }
  }, [viewBoxOn])

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.rotateSpeed = rotateSpeed
    }
  }, [rotateSpeed])

  // 3D scissors
  // without outside of dragline

  let markedSurfacePoints = [] // Store marked surface points
  let convexHulls = [] // Store convex hulls
  let lastMouseMoveTime = 0 // Track the last time handleMouseMove was called
  let isDragging = false
  let points = []
  let dragLine = null

  useEffect(() => {
    if (segmentConfig.type.indexOf('Scissor') !== -1) {
      if (containerRef.current) {
        containerRef.current.addEventListener('mousedown', handleMouseDown)
        containerRef.current.addEventListener('mouseup', handleMouseUp)
        containerRef.current.addEventListener('mousemove', handleMouseMove)

        if (controlsRef.current) {
          controlsRef.current.noPan = true
          controlsRef.current.noRotate = true
        }
      }
    } else {
      if (controlsRef.current) {
        controlsRef.current.noPan = false
        controlsRef.current.noRotate = false
      }
    }

    return () => {
      // Clean up the animation loop when component unmounts
      if (containerRef.current) {
        containerRef.current.removeEventListener('mousedown', handleMouseDown)
        containerRef.current.removeEventListener('mouseup', handleMouseUp)
        containerRef.current.removeEventListener('mousemove', handleMouseMove)
      }
    }
  }, [segmentConfig, selectedSegment, labelThresholdRange])

  const handleMouseDown = (event) => {
    if (segmentConfig.type.indexOf('Scissor') != -1) {
      const rect = viewBoxRef4.current.getBoundingClientRect()
      const x = event.clientX
      const y = event.clientY

      if (x < rect.left || x > rect.right || y < rect.top || y > rect.bottom) {
        return // Return if the mouse point is outside of viewBoxRef4
      }

      if (event.button !== 0) return // Only proceed if left mouse button is clicked

      // Reset points at the beginning of a new drag
      isDragging = true
      points = []

      // Remove the drag line
      if (dragLine) {
        scene.current.remove(dragLine)
        dragLine.geometry.dispose()
        dragLine.material.dispose()
        dragLine = null
      }
    }
  }

  const handleMouseMove = (event) => {
    if (segmentConfig.type.indexOf('Scissor') == -1) return

    const currentTime = Date.now()
    const throttleTime = 10 // Reduced throttle time in milliseconds

    if (!isDragging || currentTime - lastMouseMoveTime < throttleTime) return

    lastMouseMoveTime = currentTime
    const startPoint = getMousePointIn3D(event)
    if (startPoint) {
      points.push(startPoint.clone())
      // Add more points between the previous point and the new point for higher density
      if (points.length > 1) {
        const prevPoint = points[points.length - 2]
        const numIntermediatePoints = 10 // Number of points to add between each pair of points
        for (let i = 1; i < numIntermediatePoints; i++) {
          const intermediatePoint = prevPoint.clone().lerp(startPoint, i / numIntermediatePoints)
          points.push(intermediatePoint)
        }
      }

      // Update the drag line
      updateDragLine(points)
    }
  }

  const updateDragLine = (points) => {
    if (!dragLine) {
      const geometry = new THREE.BufferGeometry().setFromPoints(points)
      const material = new THREE.LineBasicMaterial({ color: 'yellow' })
      dragLine = new THREE.Line(geometry, material)
      scene.current.add(dragLine)
    } else {
      const geometry = new THREE.BufferGeometry().setFromPoints(points)
      dragLine.geometry.dispose()
      dragLine.geometry = geometry
    }
  }

  const handleMouseUp = (event) => {
    if (segmentConfig.type.indexOf('Scissor') == -1) return
    if (event.button !== 0 || !isDragging) return

    isDragging = false
    if (points.length > 0) {
      // Ensure the first and last points are connected
      if (!points[0].equals(points[points.length - 1])) {
        const firstPoint = points[0]
        const lastPoint = points[points.length - 1]
        const distance = firstPoint.distanceTo(lastPoint)
        const pointDensity = 0.1 // Distance between intermediate points
        const numIntermediatePoints = Math.ceil(distance / pointDensity) // Calculate number of intermediate points based on distance

        for (let i = 1; i <= numIntermediatePoints; i++) {
          const intermediatePoint = lastPoint.clone().lerp(firstPoint, i / numIntermediatePoints)
          points.push(intermediatePoint)
        }
      }
      // console.log('Number of points collected:', points.length)

      processDragPoints(points)
    }
    points = [] // Reset points for the next drag

    // Remove the drag line
    if (dragLine) {
      scene.current.remove(dragLine)
      dragLine.geometry.dispose()
      dragLine.material.dispose()
      dragLine = null
    }
  }

  const getMousePointIn3D = (event) => {
    const rect = rendererRef.current.domElement.getBoundingClientRect()
    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1
    const y = -((event.clientY - rect.top) / rect.height) * 2 + 1
    const mouse = new THREE.Vector2(x, y)

    const raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(mouse, cameraRef.current)

    const intersects = raycaster.intersectObjects(scene.current.children, true)
    if (intersects.length > 0) {
      return intersects[0].point
    } else {
      // If no intersection, create a point on the camera's near plane
      const vector = new THREE.Vector3(x, y, -1).unproject(cameraRef.current)
      const dir = vector.sub(cameraRef.current.position).normalize()
      const distance = -cameraRef.current.position.z / dir.z
      const point = cameraRef.current.position.clone().add(dir.multiplyScalar(distance))
      return point
    }
  }

  const processDragPoints = (points) => {
    let sceneBoundingBox = new THREE.Box3().setFromObject(scene.current)
    let sceneSize = sceneBoundingBox.getSize(new THREE.Vector3())
    let lineLength = sceneSize.length() // Use the length of the diagonal of the bounding box

    points.forEach((startPoint) => {
      const direction = cameraRef.current.getWorldDirection(new THREE.Vector3()).normalize()
      const endPoint = startPoint.clone().add(direction.multiplyScalar(-lineLength)) // Create a line based on the scene size

      markLineIntersections(startPoint, endPoint)
    })

    createConvexGeometry([...markedSurfacePoints])
    ScissorMain()
  }

  const markLineIntersections = (start, end) => {
    const dir = new THREE.Vector3().subVectors(end, start).normalize()
    const distance = start.distanceTo(end)
    const steps = Math.ceil(distance * 10) // Increase steps for more granular checking

    for (let i = 0; i <= steps; i++) {
      const point = new THREE.Vector3().copy(start).add(dir.clone().multiplyScalar(i / 10)) // Move in small increments
      const value = getVolumeValueAtPoint(point)
      if (value > 0 && isSurfacePoint(point)) {
        markedSurfacePoints.push(point) // Add the marked point to the array
      }
    }
  }

  const isSurfacePoint = (point) => {
    const neighbors = [
      new THREE.Vector3(1, 0, 0),
      new THREE.Vector3(-1, 0, 0),
      new THREE.Vector3(0, 1, 0),
      new THREE.Vector3(0, -1, 0),
      new THREE.Vector3(0, 0, 1),
      new THREE.Vector3(0, 0, -1)
    ]

    for (let i = 0; i < neighbors.length; i++) {
      const neighborPoint = point.clone().add(neighbors[i])
      const value = getVolumeValueAtPoint(neighborPoint)
      if (value === 0) {
        return true
      }
    }
    return false
  }

  const getVolumeValueAtPoint = (point) => {
    const { x, y, z } = point
    const i = Math.floor(x)
    const j = Math.floor(y)
    const k = Math.floor(z)

    // Check if the indices are within the bounds
    if (
      i < 0 ||
      i >= volume.downsampledX ||
      j < 0 ||
      j >= volume.downsampledY ||
      k < 0 ||
      k >= volume.downsampledZ
    ) {
      return 0 // Return zero if out of bounds
    }

    const index = i + j * volume.downsampledX + k * volume.downsampledX * volume.downsampledY
    return volume.downsampledData[index]
  }

  const createConvexGeometry = (points) => {
    if (points.length < 4) {
      // console.log('Not enough points to create a convex geometry')
      return
    }

    const geometry = new ConvexGeometry(points)
    const material = new THREE.MeshBasicMaterial({
      color: 'green',
      opacity: 0.5,
      transparent: true
    })
    const mesh = new THREE.Mesh(geometry, material)
    convexHulls.push(mesh) // Add the convex hull to the array
  }

  const ScissorMain = () => {
    if (convexHulls.length === 0) {
      // console.log('No convex hulls to fill.')
      return
    }

    const hullMesh = convexHulls[0]
    const box = new THREE.Box3().setFromObject(hullMesh)
    const min = box.min
    const max = box.max
    let indexIsChanged = false
    let isChanged = false

    // Get vertices and faces from the hull geometry
    const hullGeometry = hullMesh.geometry.toNonIndexed()
    const positions = hullGeometry.attributes.position.array

    let vertices = []
    for (let i = 0; i < positions.length; i += 3) {
      vertices.push(new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]))
    }

    const faces = []
    for (let i = 0; i < vertices.length; i += 3) {
      faces.push([i, i + 1, i + 2])
    }

    for (let x = Math.floor(min.x); x <= Math.ceil(max.x); x++) {
      for (let y = Math.floor(min.y); y <= Math.ceil(max.y); y++) {
        for (let z = Math.floor(min.z); z <= Math.ceil(max.z); z++) {
          const point = new THREE.Vector3(x, y, z)
          if (isPointInsideHull(point, vertices, faces)) {
            if (segmentConfig.type === 'Scissor Erase') {
              indexIsChanged = eraseLabelValue(point) // Set label volume to zero
            } else if (
              segmentConfig.type === 'Scissor Seg_' ||
              segmentConfig.type === 'Scissor Seg'
            ) {
              indexIsChanged = setLabelValueAtPoint(point)
            }

            if (isChanged === false && indexIsChanged === true) {
              isChanged = indexIsChanged
            }
          }
        }
      }
    }

    clearMarksAndHulls()

    if (isChanged) {
      setIsSegmentToolAffected(true)
      setIsLabelChange(true)
    }
  }

  const isPointInsideHull = (point, vertices, faces) => {
    let inside = true

    faces.forEach((face) => {
      const a = vertices[face[0]]
      const b = vertices[face[1]]
      const c = vertices[face[2]]

      const normal = new THREE.Vector3()
        .subVectors(b, a)
        .cross(new THREE.Vector3().subVectors(c, a))
        .normalize()

      const distance = normal.dot(point.clone().sub(a))

      if (distance > 0) {
        inside = false
      }
    })

    return inside
  }

  // it is from downsampling
  // const setOriginalLabelData = (i, j, k, value) => {
  //   for (let dz = 0; dz < downSamplingFactor; dz++) {
  //     for (let dy = 0; dy < downSamplingFactor; dy++) {
  //       for (let dx = 0; dx < downSamplingFactor; dx++) {
  //         const originalX = i * downSamplingFactor + dx
  //         const originalY = j * downSamplingFactor + dy
  //         const originalZ = k * downSamplingFactor + dz

  //         // Check if the indices are within the bounds of the original data
  //         if (
  //           originalX >= 0 &&
  //           originalX < label.xLength &&
  //           originalY >= 0 &&
  //           originalY < label.yLength &&
  //           originalZ >= 0 &&
  //           originalZ < label.zLength
  //         ) {
  //           const originalIndex =
  //             originalX + originalY * label.xLength + originalZ * label.xLength * label.yLength
  //           label.data[originalIndex] = value // Set the original data to zero
  //         }
  //       }
  //     }
  //   }
  // }

  const setOriginalLabelDataFromIsotropic = (i, j, k, value) => {
    // The target spacing is isotropic (1x1x1)
    const newSpacing = [1.0, 1.0, 1.0]
    const originalSpacing = label.spacing

    // Calculate the corresponding original voxel coordinates based on the spacing ratio
    const originalX = Math.min(
      Math.floor(i * (newSpacing[0] / originalSpacing[0])),
      label.xLength - 1
    )
    const originalY = Math.min(
      Math.floor(j * (newSpacing[1] / originalSpacing[1])),
      label.yLength - 1
    )
    const originalZ = Math.min(
      Math.floor(k * (newSpacing[2] / originalSpacing[2])),
      label.zLength - 1
    )

    // Check if the indices are within the bounds of the original data
    if (
      originalX >= 0 &&
      originalX < label.xLength &&
      originalY >= 0 &&
      originalY < label.yLength &&
      originalZ >= 0 &&
      originalZ < label.zLength
    ) {
      // Calculate the original index using the original dimensions
      const originalIndex =
        originalX + originalY * label.xLength + originalZ * label.xLength * label.yLength

      // Set the original data value at the computed original index
      label.data[originalIndex] = value
    }
  }

  const eraseLabelValue = (point) => {
    let isChanged = false
    const { x, y, z } = point
    const i = Math.floor(x)
    const j = Math.floor(y)
    const k = Math.floor(z)

    // Check if the indices are within the bounds
    if (
      i < 0 ||
      i >= labelRef.current.downsampledX ||
      j < 0 ||
      j >= labelRef.current.downsampledY ||
      k < 0 ||
      k >= labelRef.current.downsampledZ
    ) {
      return // Return if out of bounds
    }

    const index =
      i +
      j * labelRef.current.downsampledX +
      k * labelRef.current.downsampledX * labelRef.current.downsampledY

    if (labelRef.current.downsampledData[index] === selectedSegment) {
      // labelRef.current.downsampledData[index] = 0

      // set corresponding values in the original volume
      setOriginalLabelDataFromIsotropic(i, j, k, 0)
      isChanged = true
    }

    return isChanged
  }

  const setLabelValueAtPoint = (point) => {
    let isChanged = false
    const { x, y, z } = point
    const i = Math.floor(x)
    const j = Math.floor(y)
    const k = Math.floor(z)

    // Check if the indices are within the bounds
    if (
      i < 0 ||
      i >= labelRef.current.downsampledX ||
      j < 0 ||
      j >= labelRef.current.downsampledY ||
      k < 0 ||
      k >= labelRef.current.downsampledZ
    ) {
      return // Return if out of bounds
    }

    const index =
      i +
      j * labelRef.current.downsampledX +
      k * labelRef.current.downsampledX * labelRef.current.downsampledY

    if (labelRef.current.downsampledData[index] === 0) {
      if (
        volume.downsampledData[index] <= labelThresholdRange.max &&
        volume.downsampledData[index] >= labelThresholdRange.min
      ) {
        // labelRef.current.downsampledData[index] = selectedSegment

        // set corresponding values in the original volume
        setOriginalLabelDataFromIsotropic(i, j, k, selectedSegment)
        isChanged = true
      }
    }

    return isChanged
  }
  const clearMarksAndHulls = () => {
    markedSurfacePoints = []

    // Remove convex hulls
    convexHulls.forEach((hull) => {
      scene.current.remove(hull)
    })
    convexHulls = []
  }

  return <div name="3DBox" ref={containerRef} />
}

export default RenderingThreeScene
