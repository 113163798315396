import React from 'react'
import PropTypes from 'prop-types'

const CameraCompass = ({ setRotationVector }) => {
  const handleRotateClick = (rotation) => {
    setRotationVector(rotation)
  }

  return (
    <div style={{ position: 'fixed', width: '10%', height: '20%', bottom: '1%' }}>
      <span
        onClick={() => handleRotateClick('P')}
        style={{
          top: '30%',
          left: '28%',
          transform: 'translateX(-50%) translateY(-50%)',
          position: 'absolute',
          cursor: 'pointer'
        }}
      >
        P
      </span>
      <span
        onClick={() => handleRotateClick('S')}
        style={{
          top: '15%',
          left: '50%',
          transform: 'translateX(-50%)',
          position: 'absolute',
          cursor: 'pointer'
        }}
      >
        S
      </span>
      <span
        onClick={() => handleRotateClick('A')}
        style={{
          bottom: '20%',
          left: '72%',
          transform: 'translateX(-50%)',
          position: 'absolute',
          cursor: 'pointer'
        }}
      >
        A
      </span>
      <span
        onClick={() => handleRotateClick('R')}
        style={{
          top: '50%',
          left: '15%',
          transform: 'translateX(-50%) translateY(-50%)',
          position: 'absolute',
          cursor: 'pointer'
        }}
      >
        R
      </span>
      <span
        onClick={() => handleRotateClick('L')}
        style={{
          top: '50%',
          right: '15%',
          transform: 'translateX(50%) translateY(-50%)',
          position: 'absolute',
          cursor: 'pointer'
        }}
      >
        L
      </span>
      <span
        onClick={() => handleRotateClick('I')}
        style={{
          bottom: '15%',
          left: '50%',
          transform: 'translateX(-50%)',
          position: 'absolute',
          cursor: 'pointer'
        }}
      >
        I
      </span>
      <div
        style={{
          top: '50%',
          left: '22%',
          height: '2px',
          backgroundColor: 'gray',
          position: 'absolute',
          width: '55%'
        }}
      ></div>
      <div
        style={{
          top: '35%',
          left: '49%',
          height: '32%',
          backgroundColor: 'gray',
          position: 'absolute',
          width: '2px'
        }}
      ></div>
      <div
        style={{
          top: '31%',
          left: '49%',
          height: '39%',
          backgroundColor: 'gray',
          position: 'absolute',
          width: '2px',
          transform: 'rotate(120deg)'
        }}
      ></div>
    </div>
  )
}

CameraCompass.propTypes = {
  setRotationVector: PropTypes.func,
  volume: PropTypes.any
}
export default CameraCompass
