/* eslint-disable react/prop-types */
import { demoDocumentLoader } from '../demo/DemoDocumentLoader'
import { targetAnatomyLoader } from './TargetAnatomyLoader'

function sortObjectKeys(obj) {
  if (Object.keys(obj).length === 0) {
    return {}
  }

  const sortedKeys = Object.keys(obj).sort((a, b) => {
    return parseInt(a.replace('label', ''), 10) - parseInt(b.replace('label', ''), 10)
  })

  const sortedObj = {}
  sortedKeys.forEach((key) => {
    sortedObj[key] = obj[key]
  })

  return sortedObj
}

export const targetAnatomyButtonHandler = async (
  sdk,
  isDemo,
  setIsLoading,
  filePath,
  setFilePath,
  setButtonState,
  backInfo,
  setBackInfo,
  userInfo,
  setLoadingValue
) => {
  if (isDemo) {
    setIsLoading(true)

    let fileName = filePath['filename']

    const isolationObjects = await demoDocumentLoader(sdk, fileName, 'isolationObjects')
    const targetObjects = await demoDocumentLoader(sdk, fileName, 'targetObjects')
    const repositionTransformMatrix = await demoDocumentLoader(
      sdk,
      fileName,
      'repositionTransformMatrix'
    )
    const repositionObjects = await demoDocumentLoader(sdk, fileName, 'repositionObjects')

    const sortedTargetObjects = sortObjectKeys(targetObjects)
    const sortedIsolationObjects = sortObjectKeys(isolationObjects)
    const sortedRepositionTransformMatrix = sortObjectKeys(repositionTransformMatrix)
    const sortedRepositionObjects = sortObjectKeys(repositionObjects)

    setFilePath({
      ...filePath,
      isolationObjects: sortedIsolationObjects,
      repositionTransformMatrix: sortedRepositionTransformMatrix,
      repositionObjects: sortedRepositionObjects,
      manualRepositionTransformMatrix: {},
      targetObjects: sortedTargetObjects
    })

    setButtonState('Target')
    setLoadingValue(50)

    return
  } else {
    setIsLoading(true)

    let fileName = filePath['filename']

    const [
      targetObjects,
      isolationObjects,
      repositionTransformMatrix,
      manualRepositionTransformMatrix
    ] = await targetAnatomyLoader(sdk, backInfo, fileName, userInfo, setBackInfo, setLoadingValue)

    if (targetObjects !== 'stop') {
      const sortedTargetObjects = sortObjectKeys(targetObjects)
      const sortedIsolationObjects = sortObjectKeys(isolationObjects)
      const sortedRepositionTransformMatrix = sortObjectKeys(repositionTransformMatrix)

      let sortedManualRepositionTransformMatrix = {}

      if (manualRepositionTransformMatrix !== undefined) {
        sortedManualRepositionTransformMatrix = sortObjectKeys(manualRepositionTransformMatrix)
      }

      setFilePath({
        ...filePath,
        isolationObjects: sortedIsolationObjects,
        repositionTransformMatrix: sortedRepositionTransformMatrix,
        manualRepositionTransformMatrix: sortedManualRepositionTransformMatrix,
        targetObjects: sortedTargetObjects
      })

      setButtonState('Target')
      setLoadingValue(100)
    }
  }

  return
}
