/* eslint-disable react/prop-types */
// anonymize dicom files
import dicomParser from 'dicom-parser'
import JSZip from 'jszip'

const getDicomElementValue = (dataSet, tag) => {
  const element = dataSet.elements[tag]
  if (!element) return null

  // Check if the element's VR is undefined (implicit VR)
  if (element.vr === undefined) {
    // Read the data as a raw string for implicit VRs
    // Note: This approach might not be accurate for all VR types
    let str = ''
    for (let i = element.dataOffset; i < element.dataOffset + element.length; i++) {
      str += String.fromCharCode(dataSet.byteArray[i])
    }
    return str
  } else {
    // Handle explicit VRs
    try {
      return dicomParser.explicitElementToString(dataSet, element)
    } catch (e) {
      console.error(`Error converting element to string: ${e}`)
      return null
    }
  }
}

const dicomExtractor = async (byteArray) => {
  const dataSet = dicomParser.parseDicom(byteArray)

  /// Extract SeriesInstanceUID
  const seriesInstanceUID = getDicomElementValue(dataSet, 'x0020000e')

  // Extract SeriesDescription
  const seriesDescription = getDicomElementValue(dataSet, 'x0008103e')

  // Extract SeriesNumber
  const seriesNumber = getDicomElementValue(dataSet, 'x00200011')

  // Extract Modality
  const modality = getDicomElementValue(dataSet, 'x00080060')

  // Extract Rows and Columns
  const rows = getDicomElementValue(dataSet, 'x00280010')
  const columns = getDicomElementValue(dataSet, 'x00280011')

  // Calculate size
  const size = rows && columns ? `${columns} x ${rows}` : null
  return {
    seriesInstanceUID, // the extracted SeriesInstanceUID
    seriesDescription, // the extracted SeriesDescription
    seriesNumber, // the extracted SeriesNumber
    modality, // the extracted Modality
    size // the calculated image size
  }
}

export const dicomFolderLoader = async (event, SetdicomSeriesInfo, setIsDicomSelected) => {
  const files = event.target.files

  const filePath = files[0].webkitRelativePath
  const folderPath = filePath.split('/').slice(-2, -1)[0]
  const encodedFolderPath = encodeURIComponent(folderPath)
  const fileNameWoExtension = encodedFolderPath

  const seriesInfo = {}

  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const reader = new FileReader()

    reader.onload = async (e) => {
      const arrayBuffer = e.target.result
      let byteArray = new Uint8Array(arrayBuffer)

      const dataSet = await dicomExtractor(byteArray)

      // Extract seriesInstanceUID and other relevant data
      const { seriesInstanceUID, seriesNumber, seriesDescription, modality, size } = dataSet

      if (seriesInstanceUID) {
        if (!seriesInfo[seriesInstanceUID] && modality.toLowerCase() === 'ct') {
          seriesInfo[seriesInstanceUID] = {
            count: 0,
            seriesNumber,
            seriesDescription,
            modality,
            size,
            fileNameWoExtension
          }
        }
        if (seriesInfo[seriesInstanceUID]) {
          seriesInfo[seriesInstanceUID].count++
        }
        // seriesInfo[seriesInstanceUID].count++
      }

      if (i === files.length - 1) {
        SetdicomSeriesInfo(seriesInfo)
        setIsDicomSelected(true)
      }
    }

    reader.readAsArrayBuffer(file)
  }

  return
}

export const dicomZipLoader = async (file, SetdicomSeriesInfo, setIsDicomSelected) => {
  const zip = new JSZip()

  const fileNameWoExtension = file['name'].split('.')[0]
  // Load the contents of the file
  const data = await zip.loadAsync(file)

  let files = []
  let fileNames = []

  // Loop through each file in the zip archive
  for (const [filename, fileData] of Object.entries(data.files)) {
    fileNames.push(filename)

    // Extract the file data and add it to the files array
    files.push(await fileData.async('uint8array'))
  }

  const seriesInfo = {}

  for (let index = 0; index < files.length; index++) {
    let byteArray = files[index]
    const dataSet = await dicomExtractor(byteArray)

    const { seriesInstanceUID, seriesNumber, seriesDescription, modality, size } = dataSet

    if (seriesInstanceUID) {
      if (!seriesInfo[seriesInstanceUID] && modality.toLowerCase() === 'ct') {
        seriesInfo[seriesInstanceUID] = {
          count: 0,
          seriesNumber,
          seriesDescription,
          modality,
          size,
          fileNameWoExtension
        }
      }
      seriesInfo[seriesInstanceUID].count++
    }

    if (index === files.length - 1) {
      SetdicomSeriesInfo(seriesInfo)
      setIsDicomSelected(true)
    }
  }

  return
}
