/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'
import { Grid, Box, styled } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import BusinessIcon from '@mui/icons-material/Business'
// import HistoryIcon from '@mui/icons-material/History'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
// import SettingsIcon from '@mui/icons-material/Settings'
import logo from '../../assets/logo512.png'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
// import LogoutDialog from '../authentication/Logout'
// import ChangeCasesDialog from '../authentication/ChangeCases'

import { closedCaseButtonHandler } from '../caseOverview/closedCases/ClosedCaseButtonHandler'
import { CurrentCaseButtonHandler } from '../maintool/CurrentCaseButtonHandler'
import { activeCaseButtonHandler } from '../caseOverview/activeCases/ActiveCaseButtonHandler'
import { createCaseButtonHandler } from '../caseOverview/createCase/CreateCaseButtonHandler'

import { useTheme } from '@mui/material'
import { UserContext } from '../../App'

function LeftBar({
  currentView: currentView,
  setCurrentView: setCurrentView,
  setIsRenderingThreeDisabled: setIsRenderingThreeDisabled,
  on3DRender: on3DRender,
  setOn3DRender: setOn3DRender,
  activeButton: activeButton,
  setActiveButton: setActiveButton,
  setRedirectTo: setRedirectTo,
  setDialogOpen: setDialogOpen
}) {
  const leftBarTheme = useTheme()

  const {
    isLoading,
    loadingValue,
    handleWarnDialog,
    setWarnMessage,
    setIsClickedCase,
    isClickedCase,
    setLoadingValue,
    setIsLoading,
    isFirstfileSelected
  } = useContext(UserContext)

  const [isHovered, setIsHovered] = useState(false)

  const defaultWidth = '52px'
  const ExtendedWidth = '215px'
  const [isExpand, setIsExpand] = useState(false)
  // const [open, setOpen] = useState('closed')

  useEffect(() => {
    if (currentView === 'activeCase') setActiveButton('activeCase')
    if (currentView === 'createCase') setActiveButton('createCase')
    if (currentView === 'closedCase') setActiveButton('closedCase')
    if (currentView === 'main') setActiveButton('main')
    setOn3DRender(() => ({
      on: false
    }))
  }, [currentView])

  const handleClickOpen = () => {
    if (isLoading === true && loadingValue !== 0) {
      setWarnMessage('Please wait for the ongoing process to complete!')
      handleWarnDialog(true)
    } else {
      setDialogOpen('logout')
    }
  }
  // const handleClose = () => {
  //   setDialogOpen('closed')
  // }

  useEffect(() => {
    if (isClickedCase === true) {
      setIsClickedCase(false)
    }
  }, [isClickedCase])

  const MainGrid = styled(Grid)(() => ({
    width: isExpand ? ExtendedWidth : defaultWidth, // Adjust these values as needed
    height: '100vh',
    paddingTop: '16px',
    paddingBottom: '16px'
  }))

  const LeftBarBox = styled(Box)(({ theme }) => ({
    position: 'fixed',
    backgroundColor: theme.palette.background.light,
    left: 0,
    top: 0,
    zIndex: 10,
    height: '100%',
    width: isExpand ? ExtendedWidth : defaultWidth, // Adjust these values as needed
    boxShadow: '3px 0 3px 0px #505050',
    padding: '6px 0px 6px 14px'
  }))

  const LeftBarButtonBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '0.2em'
  }))

  const LeftBarButton = styled(IconButton)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: '#fff',
    width: isExpand ? ExtendedWidth : defaultWidth,
    height: '60%',
    padding: isExpand ? '0.5em' : 'calc((100% - 30px) / 2)', // centered the icon
    borderRadius: '10px 0 0 10px',
    borderRight: 'solid',
    boxShadow: '0 0 0px 0px',
    borderColor: 'transparent',
    borderWidth: '5px',
    '&:hover': {
      backgroundColor: theme.palette.background.highlight,
      borderColor: ' #fff'
    },
    justifyContent: isExpand ? 'flex-start' : 'center', // align content to the left when expanded
    fontWeight: isExpand ? '700' : '400'
  }))

  function handleToggle() {
    setIsExpand(!isExpand)
  }

  function onClickChange(redirectTo) {
    setRedirectTo(redirectTo)
    if (isLoading === true && loadingValue !== 0) {
      setWarnMessage('Please wait for the ongoing process to complete!')
      handleWarnDialog(true)
    } else if (currentView !== 'main') {
      if (redirectTo === 'activeCase') {
        activeCaseButtonHandler(setCurrentView)
        setActiveButton(redirectTo)
        setCurrentView(redirectTo)
      } else if (redirectTo === 'createCase') {
        createCaseButtonHandler(setCurrentView)
        setCurrentView(redirectTo)
        setActiveButton(redirectTo)
      } else if (redirectTo === 'currentCase') {
        setActiveButton('main')
        CurrentCaseButtonHandler(
          setCurrentView,
          isClickedCase,
          setIsRenderingThreeDisabled,
          setLoadingValue,
          setIsLoading,
          on3DRender,
          setOn3DRender
        )
      } else if (redirectTo === 'closedCase') {
        //should be checked once we enable closed Cases button
        closedCaseButtonHandler(setCurrentView)
        setActiveButton(redirectTo)
        setCurrentView(redirectTo)
      }
    } else {
      setDialogOpen('changeCases')
    }
  }

  return (
    <LeftBarBox>
      <MainGrid>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            alignItems: 'center', // centers the content when the sidebar is collapsed
            height: '100%', // occupy the full height
            display: 'flex', // enable flexbox
            flexDirection: 'column' // stack items vertically
          }}
        >
          {/* {currentView === 'main' && redirectTo !== 'currentCase' && (
            <ChangeCasesDialog
              open={open}
              onClose={handleClose}
              setActiveButton={setActiveButton}
              setRedirectTo={setRedirectTo}
              redirectTo={redirectTo}
              setCurrentView={setCurrentView}
            />
          )} */}
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ ...logoContentStyle(isExpand), marginBottom: '2em' }}>
              <Box sx={logoDetailsStyle}>
                <Box component="img" alt="Mango Medical" src={logo} sx={mainLogoIconStyle} />
                {isExpand && <Box sx={mainLogoNameStyle}>Mango Medical</Box>}

                <IconButton onClick={handleToggle} sx={{ padding: '0' }}>
                  {isExpand ? (
                    <ArrowBackIosIcon
                      sx={{ ...arrowLogoIconStyle, marginBottom: '1.2em', paddingLeft: '1.5rem' }}
                    />
                  ) : (
                    <ArrowForwardIosIcon
                      sx={{ ...arrowLogoIconStyle, marginBottom: '1em', paddingRight: '1rem' }}
                    />
                  )}
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ ...logoContentStyle(isExpand) }}>
              <LeftBarButtonBox>
                <LeftBarButton
                  size="large"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() => {
                    onClickChange('activeCase')
                    setIsExpand(false)
                  }}
                  style={{
                    backgroundColor:
                      activeButton === 'activeCase'
                        ? leftBarTheme.palette.background.highlight
                        : isHovered
                        ? leftBarTheme.palette.background.hover
                        : 'transparent',
                    borderColor: activeButton === 'activeCase' ? ' #fff' : 'transparent'
                  }}
                >
                  <BusinessIcon sx={logoIconStyle} />
                  {isExpand && (
                    <Box
                      sx={{
                        ...logoNameStyle,
                        fontWeight: activeButton === 'activeCase' ? 600 : 200
                      }}
                    >
                      Active Cases
                    </Box>
                  )}
                </LeftBarButton>
              </LeftBarButtonBox>
            </Box>

            <Box sx={logoContentStyle(isExpand)}>
              <LeftBarButtonBox>
                <LeftBarButton
                  size="large"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() => {
                    onClickChange('createCase')
                    setIsExpand(false)
                  }}
                  disabled={currentView === 'main'}
                  style={{
                    backgroundColor:
                      activeButton === 'createCase'
                        ? leftBarTheme.palette.background.highlight
                        : isHovered
                        ? leftBarTheme.palette.background.hover
                        : 'transparent',
                    borderColor: activeButton === 'createCase' ? ' #fff' : 'transparent',
                    filter: currentView !== 'main' ? 'none' : 'blur(3px)'
                  }}
                >
                  <PersonAddIcon sx={logoIconStyle} />
                  {isExpand && (
                    <Box
                      sx={{
                        ...logoNameStyle,
                        fontWeight: activeButton === 'createCase' ? 600 : 200
                      }}
                    >
                      New Patient Case
                    </Box>
                  )}
                </LeftBarButton>
              </LeftBarButtonBox>
            </Box>

            <Box sx={logoContentStyle(isExpand)}>
              <LeftBarButtonBox>
                <LeftBarButton
                  size="large"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() => {
                    onClickChange('currentCase')
                    setIsExpand(false)
                  }}
                  disabled={!isFirstfileSelected || activeButton === 'activeCase'}
                  style={{
                    backgroundColor:
                      activeButton === 'main'
                        ? leftBarTheme.palette.background.highlight
                        : isHovered
                        ? leftBarTheme.palette.background.hover
                        : 'transparent',
                    borderColor: activeButton === 'main' ? ' #fff' : 'transparent',
                    filter:
                      isFirstfileSelected && activeButton !== 'activeCase' ? 'none' : 'blur(3px)'
                  }}
                >
                  <ContentPasteIcon sx={logoIconStyle} />
                  {isExpand && (
                    <Box sx={{ ...logoNameStyle, fontWeight: activeButton === 'main' ? 600 : 200 }}>
                      Current Case
                    </Box>
                  )}
                </LeftBarButton>
              </LeftBarButtonBox>
            </Box>

            {/* <Box sx={logoContentStyle(isExpand)}>
              <LeftBarButtonBox>
                <LeftBarButton
                  size="large"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() => onClickChange('closedCase')}
                  style={{
                    backgroundColor:
                      activeButton === 'closedCase'
                        ? leftBarTheme.palette.background.highlight
                        : isHovered
                        ? leftBarTheme.palette.background.hover
                        : 'transparent',
                    borderColor: activeButton === 'closedCase' ? ' #fff' : 'transparent'
                  }}
                >
                  <HistoryIcon sx={logoIconStyle} />
                  {isExpand && (
                    <Box
                      sx={{
                        ...logoNameStyle,
                        fontWeight: activeButton === 'closedCase' ? 600 : 200
                      }}
                    >
                      Closed Cases
                    </Box>
                  )}
                </LeftBarButton>
              </LeftBarButtonBox>
            </Box> */}

            <Box sx={{ marginTop: 'auto' }}>
              {/* <Box sx={logoContentStyle(isExpand)}>
                <LeftBarButtonBox>
                  <LeftBarButton size="large">
                    <SettingsIcon sx={logoIconStyle} />
                    {isExpand && <Box sx={logoNameStyle}>Settings</Box>}
                  </LeftBarButton>
                </LeftBarButtonBox>
              </Box>

              <Box sx={logoContentStyle(isExpand)}>
                <LeftBarButtonBox>
                  <LeftBarButton size="large">
                    <HelpOutlineIcon sx={logoIconStyle} />
                    {isExpand && <Box sx={logoNameStyle}>Help</Box>}
                  </LeftBarButton>
                </LeftBarButtonBox>
              </Box> */}

              <Box sx={logoContentStyle(isExpand)}>
                <LeftBarButtonBox>
                  <LeftBarButton onClick={handleClickOpen} size="large">
                    <ExitToAppIcon sx={logoIconStyle} />
                    {isExpand && <Box sx={logoNameStyle}>Log Out</Box>}
                  </LeftBarButton>
                </LeftBarButtonBox>
              </Box>
            </Box>
          </Box>

          {/* <LogoutDialog open={open} onClose={handleClose} /> */}
        </Stack>
      </MainGrid>
    </LeftBarBox>
  )
}

export default LeftBar

// style
const logoIconStyle = {
  color: 'rgb(255, 255, 255)',
  display: 'inline-block',
  height: 30,
  width: 30
}

const mainLogoIconStyle = {
  color: 'rgb(255, 255, 255)',
  display: 'inline-block',
  paddingBottom: '30px',
  height: 45,
  width: 45
}

const arrowLogoIconStyle = {
  color: 'rgb(255, 255, 255)',
  display: 'inline-block',
  height: 20,
  width: 18
}

const logoNameStyle = {
  fontSize: 12,
  fontWeight: 200,
  color: 'white',
  paddingLeft: 2
}

const mainLogoNameStyle = {
  fontSize: 15,
  fontWeight: 400,
  color: 'white',
  paddingLeft: 1,
  marginBottom: '2em'
}

const logoContentStyle = (isExpand) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '4rem',
  marginBottom: '1em',
  logoNameStyle: {
    display: isExpand ? 'block' : 'none'
  },
  mainLogoNameStyle: {
    display: isExpand ? 'none' : 'block'
  }
})

const logoDetailsStyle = {
  display: 'flex',
  alignItems: 'center'
}
