/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { getImplants } from '../others/GetImplant'
import { Box, Button, Typography } from '@mui/material'

const SelectImplant = ({
  sdk: sdk,
  handleImplantSelect: handleImplantSelect,
  isImplantExpanded: isImplantExpanded,
  indication: indication
}) => {
  const [implantsArray, setImplantsArray] = useState([])

  useEffect(() => {
    const setImplants = async () => {
      const implantsData = await getImplants(sdk)
      if (implantsData !== undefined && implantsData.length !== 0) {
        setImplantsArray(implantsData)
      }
    }
    if (implantsArray.length === 0) {
      setImplants()
    }
  }, [])

  const implants = implantsArray
    .filter(
      (implant) =>
        implant.data.description !== 'Wrist Plate A' &&
        implant.data.description !== 'Wrist Plate B' &&
        implant.data.description !== 'self made implant'
    )
    .filter((implant) => implant.data.indication.toLowerCase() === indication.toLowerCase())
    .map((implant) => (
      <Button
        sx={{
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            background: 'rgba(217, 217, 217, 0.16)',
            borderRadius: '4px'
          }
        }}
        onClick={() => handleImplantSelect(implant.data)}
        key={implant.id}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: '#FFFFFF',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '20px',
            flex: 'none',
            order: 2,
            flexGrow: 0,
            marginRight: '10px',
            marginTop: '5px',
            textTransform: 'none'
          }}
        >
          {implant.data.description}
        </Typography>
      </Button>
    ))

  return (
    <>
      {isImplantExpanded && (
        <Box sx={{ marginTop: '2%', zIndex: 9998, position: 'fixed' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '16px 0px',
              gap: '16px',
              width: '150px',
              background: '#232323',
              boxShadow: '0px 4px 20px rgba(255, 255, 255, 0.25)',
              borderRadius: '8px'
            }}
          >
            {implants}
          </Box>
        </Box>
      )}
    </>
  )
}

export default SelectImplant
