/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import React from 'react'

import { MainViewSmallButton } from '../../../../Theme'
import { mainViewDownloadButtonHandler } from './MainViewDownloadButtonHandler'
import { mainviewSaveButtonHandler } from './MainViewSaveButtonHandler'

// save button component in header component
const MainViewDownloadButton = ({
  sdk,
  backInfo,
  buttonState,
  label,
  setIsSaving,
  filePath,
  setFilePath,
  repoScene
}) => {
  const handleClick = async () => {
    let newToken = ''
    if (buttonState !== 'Image') {
      newToken = await mainviewSaveButtonHandler(
        sdk,
        backInfo,
        buttonState,
        label,
        setIsSaving,
        filePath,
        setFilePath,
        repoScene
      )

      if (newToken !== undefined) {
        mainViewDownloadButtonHandler(sdk, backInfo, buttonState, newToken)
      }
    } else {
      mainViewDownloadButtonHandler(sdk, backInfo, buttonState, newToken)
    }
  }

  return (
    <MainViewSmallButton
      id="download"
      sx={{
        color: 'white',
        background: '#232323',
        zIndex: 9999,
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '20px',
        width: '100px'
      }}
      onClick={handleClick}
    >
      download
    </MainViewSmallButton>
  )
}

export default MainViewDownloadButton
