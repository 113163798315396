import * as THREE from 'three'

function addLights(scene) {
  // Create a group for lights
  let intensity = 0.5
  const groupLights = new THREE.Group()
  groupLights.name = 'groupLights'

  // // Create directional lights and add them to the group
  // const directionalNegZ = new THREE.DirectionalLight(0xffffff, intensity)
  // directionalNegZ.name = 'directionalNegZ'
  // directionalNegZ.position.set(0, 0, -1000)
  // directionalNegZ.castShadow = true
  // groupLights.add(directionalNegZ)

  const directionalPosZ = new THREE.DirectionalLight(0xffffff, intensity)
  directionalPosZ.name = 'directionalPosZ'
  directionalPosZ.position.set(0, 0, 1000)
  directionalPosZ.castShadow = true
  groupLights.add(directionalPosZ)

  // const directionalPosY = new THREE.DirectionalLight(0xffffff, intensity)
  // directionalPosY.name = 'directionalPosY'
  // directionalPosY.position.set(0, 1000, 0)
  // directionalPosY.castShadow = true
  // groupLights.add(directionalPosY)

  const directionalNegY = new THREE.DirectionalLight(0xffffff, intensity)
  directionalNegY.name = 'directionalNegY'
  directionalNegY.position.set(0, -1000, 0)
  directionalNegY.castShadow = true
  groupLights.add(directionalNegY)

  const directionalNegX = new THREE.DirectionalLight(0xffffff, intensity)
  directionalNegX.name = 'directionalNegX'
  directionalNegX.position.set(-1000, 0, 0)
  directionalNegX.castShadow = true
  groupLights.add(directionalNegX)

  const directionalPosX = new THREE.DirectionalLight(0xffffff, intensity)
  directionalPosX.name = 'directionalPosX'
  directionalPosX.position.set(1000, 0, 0)
  directionalPosX.castShadow = true
  groupLights.add(directionalPosX)

  // Create an ambient light and add it to the group
  const ambiLight = new THREE.AmbientLight(0xffffff, intensity)
  ambiLight.name = 'ambiLight'
  groupLights.add(ambiLight)

  // Add the group of lights to the scene
  scene.add(groupLights)
}

export { addLights }
