/* eslint-disable react/prop-types */
import { demoDocumentLoader } from '../demo/DemoDocumentLoader'
import { repositionLoader } from './RepositionLoader'

export const repositionButtonHandler = async (
  sdk,
  isDemo,
  setIsLoading,
  filePath,
  setFilePath,
  setButtonState,
  backInfo,
  setBackInfo,
  userInfo,
  setLoadingValue
) => {
  if (isDemo) {
    setIsLoading(true)

    let fileName = filePath['filename']

    const fileUrl = await demoDocumentLoader(sdk, fileName, 'regVolToken')
    const segFileUrl = await demoDocumentLoader(sdk, fileName, 'regToken')
    const repositionObjects = await demoDocumentLoader(sdk, fileName, 'repositionObjects')

    const sortedKeys = Object.keys(repositionObjects).sort((a, b) => {
      return parseInt(a.replace('label', ''), 10) - parseInt(b.replace('label', ''), 10)
    })

    const sortedRepositionObjects = {}
    sortedKeys.forEach((key) => {
      sortedRepositionObjects[key] = repositionObjects[key]
    })

    setFilePath({
      ...filePath,
      reg: segFileUrl,
      reg_image: fileUrl,
      repositionObjects: sortedRepositionObjects,
      model: {
        ...filePath.model,
        repositionObjects: sortedRepositionObjects
      }
    })

    setButtonState('Rep')
    setLoadingValue(50)

    return
  } else {
    setIsLoading(true)

    let fileName = filePath['filename']

    const [fileUrl, segFileUrl, repositionObjects, predictedRepositionObjects] =
      await repositionLoader(sdk, backInfo, fileName, userInfo, setBackInfo, setLoadingValue)

    if (fileUrl !== 'stop') {
      const sortedKeys = Object.keys(repositionObjects).sort((a, b) => {
        return parseInt(a.replace('label', ''), 10) - parseInt(b.replace('label', ''), 10)
      })

      const sortedRepositionObjects = {}
      sortedKeys.forEach((key) => {
        sortedRepositionObjects[key] = repositionObjects[key]
      })

      const predictedSortedKeys = Object.keys(predictedRepositionObjects).sort((a, b) => {
        return parseInt(a.replace('label', ''), 10) - parseInt(b.replace('label', ''), 10)
      })

      const predictedSortedRepositionObjects = {}
      predictedSortedKeys.forEach((key) => {
        predictedSortedRepositionObjects[key] = predictedRepositionObjects[key]
      })

      setFilePath({
        ...filePath,
        reg: segFileUrl,
        reg_image: fileUrl,
        repositionObjects: sortedRepositionObjects,
        model: {
          ...filePath.model,
          repositionObjects: predictedSortedRepositionObjects
        }
      })

      setButtonState('Rep')
    }

    return
  }
}
