/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  HeaderButtonContainer,
  RectanglePart,
  LeftTrianglePart,
  RightTrianglePart,
  TextContainer
} from '../../../../Theme'

import { targetAnatomyButtonHandler } from './TargetAnatomyButtonHandler'

import { useContext } from 'react'
import { UserContext } from '../../../../App'

const TargetAnatomyButton = ({
  sdk,
  isDemo,
  setIsLoading,
  filePath,
  setFilePath,
  setButtonState,
  backInfo,
  setBackInfo,
  userInfo,
  buttonStates,
  buttonState
}) => {
  const [hover, setHover] = useState(false)
  const isActive = buttonState === 'Target'
  const isDone = buttonStates.indexOf(buttonState) > buttonStates.indexOf('Target')

  const {
    handleClickOpen,
    setLoadingValue,
    isLoading,
    loadingValue,
    handleWarnDialog,
    setWarnMessage
  } = useContext(UserContext)

  // Click handler
  const handleClick = () => {
    if (isLoading === true && loadingValue !== 0) {
      setWarnMessage('Please wait for the ongoing process to complete!')
      handleWarnDialog(true)
    } else if (!isDemo && (filePath.reg === '' || filePath.reg_image === '')) {
      setWarnMessage('Please wait for the previous process to complete!')
      handleWarnDialog(true)
    } else {
      isDemo
        ? targetAnatomyButtonHandler(
            sdk,
            isDemo,
            setIsLoading,
            filePath,
            setFilePath,
            setButtonState,
            backInfo,
            setBackInfo,
            userInfo,
            setLoadingValue
          )
        : handleClickOpen('Target')
    }
  }

  return (
    <HeaderButtonContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={isActive ? undefined : handleClick}
    >
      <LeftTrianglePart hover={hover} active={isActive} done={isDone} />
      <RectanglePart
        sx={{
          width: '125px'
        }}
        hover={hover}
        active={isActive}
        done={isDone}
      />
      <RightTrianglePart hover={hover} active={isActive} done={isDone} />
      <TextContainer>Target Anatomy</TextContainer>
    </HeaderButtonContainer>
  )
}

export default TargetAnatomyButton
