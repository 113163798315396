/* eslint-disable react/prop-types */
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import Login from './components/authentication/Login'
import Register from './components/authentication/Register'
import ResetPassword from './components/authentication/ResetPassword'
import SetNewPassword from './components/authentication/SetNewPassword'
import Activate from './components/authentication/Activate'
import ErrorPage from './pages/ErrorPage'
import reportWebVitals from './ReportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { authenticatedSDKRefresh } from './components/authentication/AuthenticationSDK'

// this function to authenticate and save user information
function ProtectedRoute({
  setSdk,
  isAuthenticated,
  setAuthenticated,
  setUserInfo,
  redirectTo,
  children
}) {
  const navigate = useNavigate()
  let isCalled = false

  useEffect(() => {
    // console.log('is authenticated')
    if (isAuthenticated) {
      return
    }
    if (isCalled) {
      return
    }

    const refreshToken = localStorage.getItem('refreshToken')

    if (refreshToken) {
      const checkAuthentication = async () => {
        const authenticated = await authenticatedSDKRefresh(refreshToken)

        if (authenticated.result) {
          setSdk(authenticated.sdk)
          setAuthenticated(true)
          setUserInfo(await authenticated.sdk.users.me())
        } else {
          console.log('RefreshToken is not working')
          localStorage.removeItem('refreshToken')
          navigate('/', { replace: true })
        }
      }

      checkAuthentication()

      isCalled = true
    } else {
      if (!isAuthenticated) {
        navigate(redirectTo, { replace: true })
        isCalled = false
      }
    }
  }, [isAuthenticated])

  return isAuthenticated ? children : null
}

function AppWrapper() {
  const [sdk, setSdk] = useState(null)
  const [authenticated, setAuthenticated] = useState(false)
  const [userInfo, setUserInfo] = useState(false)

  useEffect(() => {
    document.body.style.margin = '0'
    document.body.style.fontFamily =
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
    document.body.style.webkitFontSmoothing = 'antialiased'
    document.body.style.mozOsxFontSmoothing = 'grayscale'
    document.body.style.backgroundColor = '#191919'

    const allCodeElements = document.getElementsByTagName('code')
    for (let i = 0; i < allCodeElements.length; i++) {
      allCodeElements[i].style.fontFamily =
        "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace"
    }
  }, [])

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Login
          setSdk={setSdk}
          isAuthenticated={authenticated}
          setAuthenticated={setAuthenticated}
          setUserInfo={setUserInfo}
        />
      ),
      errorElement: <ErrorPage />
    },
    {
      path: '/resetpassword',
      element: <ResetPassword />,
      errorElement: <ErrorPage />
    },
    {
      path: '/setnewpassword',
      element: <SetNewPassword />,
      errorElement: <ErrorPage />
    },
    {
      path: '/register',
      element: <Register />,
      errorElement: <ErrorPage />
    },
    {
      path: '/activate',
      element: <Activate />,
      errorElement: <ErrorPage />
    },
    {
      path: '/home',
      element: (
        <ProtectedRoute
          sdk={sdk}
          setSdk={setSdk}
          isAuthenticated={authenticated}
          setAuthenticated={setAuthenticated}
          setUserInfo={setUserInfo}
          redirectTo="/"
        >
          <App sdk={sdk} userInfo={userInfo} />
        </ProtectedRoute>
      ),
      errorElement: <ErrorPage />
    }
  ])

  return <RouterProvider router={router} />
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
