import { colorMapArray } from './ColorMap_2D'
import * as THREE from 'three'

export function update2DTexture(label, sliceSeg, meshSeg, labelColorSwitch) {
  const width = sliceSeg.iLength
  const height = sliceSeg.jLength

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d')

  const imgData = context.createImageData(width, height)
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      let labelValue = label.data[sliceSeg.sliceAccess(x, y)]
      let i = 4 * (y * width + x)

      if (labelValue * 3 < colorMapArray.length) {
        if (labelColorSwitch) {
          imgData.data[i] = colorMapArray[labelValue * 3] // Red component
          imgData.data[i + 1] = colorMapArray[labelValue * 3 + 1] // Green component
          imgData.data[i + 2] = colorMapArray[labelValue * 3 + 2] // Blue component
        } else {
          // Check if labelValue is zero
          if (labelValue === 0) {
            imgData.data[i] = 0 // Red component for black
            imgData.data[i + 1] = 0 // Green component for black
            imgData.data[i + 2] = 0 // Blue component for black
          } else {
            imgData.data[i] = 255 // Red component
            imgData.data[i + 1] = 255 // Green component
            imgData.data[i + 2] = 255 // Blue component
          }
        }
      }
      imgData.data[i + 3] = 255 // Alpha (transparency)
    }
  }

  context.putImageData(imgData, 0, 0)

  const texture = new THREE.Texture(canvas)
  texture.needsUpdate = true // This needs to be set when the texture is updated after being applied to a material

  texture.wrapS = THREE.RepeatWrapping

  if (sliceSeg.axis === 'x' || sliceSeg.axis === 'z') {
    texture.flipY = false
  }

  meshSeg.material.map = texture
  meshSeg.material.needsUpdate = true

  if (sliceSeg.axis === 'x') {
    meshSeg.scale.x = -1 // flip left to right
  }
}

export function assign2DTexture(label, axis, index) {
  const sliceSeg = label.extractSlice(axis, index)

  const width = sliceSeg.iLength
  const height = sliceSeg.jLength

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d')

  const imgData = context.createImageData(width, height)
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      let labelValue = label.data[sliceSeg.sliceAccess(x, y)]
      let i = 4 * (y * width + x)

      if (labelValue * 3 < colorMapArray.length) {
        imgData.data[i] = colorMapArray[labelValue * 3] // Red component
        imgData.data[i + 1] = colorMapArray[labelValue * 3 + 1] // Green component
        imgData.data[i + 2] = colorMapArray[labelValue * 3 + 2] // Blue component
        imgData.data[i + 3] = 255 // Alpha (transparency)
      } else {
        // Handle the case when label value is not in the color map
        // For example, assign a random color
        imgData.data[i] = Math.floor(Math.random() * 256)
        imgData.data[i + 1] = Math.floor(Math.random() * 256)
        imgData.data[i + 2] = Math.floor(Math.random() * 256)
        imgData.data[i + 3] = 255 // Alpha (transparency)
      }
    }
  }

  context.putImageData(imgData, 0, 0)

  const texture = new THREE.Texture(canvas)
  texture.needsUpdate = true // This needs to be set when the texture is updated after being applied to a material

  texture.wrapS = THREE.RepeatWrapping
  // texture.repeat.y = -1;

  const material = new THREE.MeshBasicMaterial({ map: texture })

  if (axis === 'x' || axis === 'z') {
    material.map.flipY = false
  }

  return { sliceSeg, material }
}
