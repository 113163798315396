export const colorMapArray = [
  // label 0: Black
  0, 0, 0,

  // label 1: Light Red (Soft Pink)
  255, 182, 193,

  // label 2: Light Green (Mint Green)
  152, 255, 152,

  // label 3: Light Blue (Sky Blue)
  135, 206, 235,

  // label 4: Light Yellow (Lemon Chiffon)
  255, 250, 205,

  // label 5: Light Cyan (Pale Turquoise)
  175, 238, 238,

  // label 6: Light Magenta (Lavender Blush)
  255, 240, 245,

  // label 7: Light Orange (Peach Puff)
  255, 218, 185,

  // label 8: Light Lime (Honeydew)
  240, 255, 240,

  // label 9: Light Blue (Powder Blue)
  176, 224, 230,

  // label 10: Light Purple (Thistle)
  216, 191, 216,

  // label 11: Light Pink (Pink)
  255, 192, 203,

  // label 12: Light Peach (Misty Rose)
  255, 228, 225,

  // label 13: Light Gold (Pale Goldenrod)
  238, 232, 170,

  // label 14: Light Mint Green (Mint Cream)
  245, 255, 250,

  // label 15: Light Turquoise (Aquamarine)
  127, 255, 212,

  // label 16: Light Sky Blue (Light Sky Blue)
  135, 206, 250,

  // label 17: Light Purple (Plum)
  221, 160, 221,

  // label 18: Light Lavender (Lavender)
  230, 230, 250,

  // label 19: Light Rose (Light Pink)
  255, 182, 193,

  // label 20: Cream
  255, 253, 208,

  // label 21: Baby Blue
  137, 207, 240,

  // label 22: Pale Green
  152, 251, 152,

  // label 23: Light Salmon
  255, 160, 122,

  // label 24: Light Coral
  240, 128, 128,

  // label 25: Alice Blue
  240, 248, 255,

  // label 26: Azure
  240, 255, 255,

  // label 27: Beige
  245, 245, 220,

  // label 28: Lavender Blush
  255, 240, 245,

  // label 29: Floral White
  255, 250, 240,

  // label 30: Gainsboro
  220, 220, 220,

  // label 31: Ghost White
  248, 248, 255,

  // label 32: Honeydew
  240, 255, 240,

  // label 33: Ivory
  255, 255, 240,

  // label 34: Linen
  250, 240, 230,

  // label 35: Old Lace
  253, 245, 230,

  // label 36: Pale Goldenrod
  238, 232, 170,

  // label 37: Papaya Whip
  255, 239, 213,

  // label 38: Sea Shell
  255, 245, 238,

  // label 39: Snow
  255, 250, 250,

  // label 40: White Smoke
  245, 245, 245,

  // label 41: Light Goldenrod Yellow
  250, 250, 210,

  // label 42: Mint Cream
  245, 255, 250,

  // label 43: Pale Turquoise
  175, 238, 238,

  // label 44: Peach Puff
  255, 218, 185,

  // label 45: Bisque
  255, 228, 196,

  // label 46: Blanched Almond
  255, 235, 205,

  // label 47: Cornsilk
  255, 248, 220,

  // label 48: Lemon Chiffon
  255, 250, 205,

  // label 49: Light Cyan
  224, 255, 255,

  // label 50: Light Lavender
  230, 230, 250,

  // label 51: Light Indigo
  173, 216, 230,

  // label 52: Light Orchid
  230, 168, 215,

  // label 53: Light Slate Blue
  132, 112, 255,

  // label 54: Light Golden Yellow
  255, 255, 224,

  // label 55: Light Beige
  245, 245, 220,

  // label 56: Light Peach
  255, 218, 185,

  // label 57: Light Lavender Gray
  196, 195, 208,

  // label 58: Light Slate Gray
  119, 136, 153,

  // label 59: Light Pastel Purple
  177, 156, 217,

  // label 60: Light Coral Pink
  255, 182, 193,

  // label 61: Light Blush Pink
  255, 182, 193,

  // label 62: Light Peach Pink
  255, 182, 193,

  // label 63: Light Tangerine
  255, 204, 153,

  // label 64: Light Apricot
  255, 204, 153,

  // label 65: Light Peach Orange
  255, 204, 153,

  // label 66: Light Pastel Orange
  255, 204, 153,

  // label 67: Light Pastel Brown
  255, 204, 153,

  // label 68: Light Pastel Beige
  255, 204, 153,

  // label 69: Light Pastel Pink
  255, 204, 204,

  // label 70: Light Rose Pink
  255, 204, 204,

  // label 71: Light Dusty Rose
  255, 204, 204,

  // label 72: Light Ballet Pink
  255, 204, 204,

  // label 73: Light Baby Pink
  255, 204, 204,

  // label 74: Light Pastel Pink
  255, 204, 204,

  // label 75: Light Pastel Blue
  204, 229, 255,

  // label 76: Light Ice Blue
  204, 229, 255,

  // label 77: Light Powder Blue
  204, 229, 255,

  // label 78: Light Baby Blue
  204, 229, 255,

  // label 79: Light Pastel Green
  204, 255, 204,

  // label 80: Light Mint Green
  204, 255, 204,

  // label 81: Light Lime Green
  204, 255, 204,

  // label 82: Light Seafoam Green
  204, 255, 204,

  // label 83: Light Spring Green
  204, 255, 204,

  // label 84: Light Pastel Green
  204, 255, 204,

  // label 85: Light Pastel Purple
  204, 204, 255,

  // label 86: Light Orchid Purple
  204, 204, 255,

  // label 87: Light Lilac Purple
  204, 204, 255,

  // label 88: Light Lavender Purple
  204, 204, 255,

  // label 89: Light Pastel Purple
  204, 204, 255,

  // label 90: Light Pastel Gray
  204, 204, 204,

  // label 91: Light Silver Gray
  204, 204, 204,

  // label 92: Light Grayish Blue
  204, 204, 204,

  // label 93: Light Steel Blue
  204, 204, 204,

  // label 94: Light Goldenrod Yellow
  250, 250, 210,

  // label 95: Alice Blue
  240, 248, 255,

  // label 96: Light Steel Blue
  176, 196, 222,

  // label 97: Sea Shell
  255, 245, 238,

  // label 98: Lavender
  230, 230, 250,

  // label 99: Light Blue
  173, 216, 230,

  // label 100: Light Sunflower Yellow
  255, 222, 117,

  // label 101: Light Ocean Blue
  173, 216, 230,

  // label 102: Light Cotton Candy
  255, 204, 255,

  // label 103: Light Pistachio
  190, 245, 116,

  // label 104: Light Morning Dew
  230, 255, 255,

  // label 105: Light Periwinkle
  204, 204, 255,

  // label 106: Light Caramel
  255, 204, 153,

  // label 107: Light Cherry Blossom
  255, 204, 204,

  // label 108: Light Grapefruit
  255, 102, 102,

  // label 109: Light Pomegranate
  255, 77, 77,

  // label 110: Light Lilac Haze
  204, 153, 204,

  // label 111: Light Vanilla Cream
  255, 244, 204,

  // label 112: Light Marshmallow
  255, 250, 240,

  // label 113: Light Bubblegum
  255, 182, 193,

  // label 114: Light Lemonade
  255, 250, 205,

  // label 115: Light Blue Lagoon
  173, 216, 230,

  // label 116: Light Creamsicle
  255, 204, 153,

  // label 117: Light Lavender Mist
  230, 230, 250,

  // label 118: Light Mint Sorbet
  184, 252, 233,

  // label 119: Light Rose Quartz
  255, 223, 186,

  // label 120: Light Sunflower Petal
  255, 222, 117,

  // label 121: Light Ocean Breeze
  173, 216, 230,

  // label 122: Light Cotton Candy
  255, 204, 255,

  // label 123: Light Pistachio Ice Cream
  190, 245, 116,

  // label 124: Light Morning Mist
  230, 255, 255,

  // label 125: Light Periwinkle Blue
  204, 204, 255,

  // label 126: Light Caramel Cream
  255, 204, 153,

  // label 127: Light Cherry Blossom Pink
  255, 204, 204,

  // label 128: Light Grapefruit Splash
  255, 102, 102,

  // label 129: Light Pomegranate Punch
  255, 77, 77,

  // label 130: Light Lilac Haze
  204, 153, 204,

  // label 131: Light Vanilla Cream
  255, 244, 204,

  // label 132: Light Marshmallow Fluff
  255, 250, 240,

  // label 133: Light Bubblegum Pink
  255, 182, 193,

  // label 134: Light Lemonade Sorbet
  255, 250, 205,

  // label 135: Light Blue Lagoon
  173, 216, 230,

  // label 136: Light Creamsicle
  255, 204, 153,

  // label 137: Light Lavender Mist
  230, 230, 250,

  // label 138: Light Mint Sorbet
  184, 252, 233,

  // label 139: Light Rose Quartz
  255, 223, 186,

  // label 140: Light Sunflower Petal
  255, 222, 117,

  // label 141: Light Ocean Breeze
  173, 216, 230,

  // label 142: Light Cotton Candy
  255, 204, 255,

  // label 143: Light Pistachio Ice Cream
  190, 245, 116,

  // label 144: Light Morning Mist
  230, 255, 255,

  // label 145: Light Periwinkle Blue
  204, 204, 255
]

// export const colorMapArray = [
//   // label 0: black
//   0, 0, 0,

//   // label 1: red
//   255, 0, 0,

//   // label 2: green
//   0, 255, 0,

//   // label 3: blue
//   0, 0, 255,

//   // label 4: yellow
//   255, 255, 0,

//   // label 5: cyan (light blue-green)
//   0, 255, 255,

//   // label 6: magenta (purplish-red)
//   255, 0, 255,

//   // label 7: orange
//   255, 128, 0,

//   // label 8: lime (bright green)
//   128, 255, 0,

//   // label 9: light blue
//   0, 128, 255,

//   // label 10: purple
//   127, 0, 255,

//   // label 11: pink
//   255, 153, 204,

//   // label 12: peach
//   255, 204, 153,

//   // label 13: gold
//   255, 255, 153,

//   // label 14: mint green
//   204, 255, 153,

//   // label 15: turquoise
//   153, 255, 153,

//   // label 16: sky blue
//   153, 255, 204,

//   // label 17: light purple
//   204, 153, 255,

//   // label 18: lavender
//   255, 153, 255,

//   // label 19: rose
//   255, 0, 127,

//   // label 20: olive green
//   153, 204, 255,

//   // label 21: indigo
//   153, 153, 255,

//   // label 22: sea green
//   153, 255, 255,

//   // label 23: teal
//   0, 255, 128,

//   // label 24: Coral
//   255, 100, 100,

//   // label 25: Orchid
//   186, 85, 211,

//   // label 26: Slate Blue
//   106, 90, 205,

//   // label 27: Dark Olive Green
//   85, 107, 47,

//   // label 28: Saddle Brown
//   160, 82, 45,

//   // label 29: Dark Slate Gray
//   47, 79, 79,

//   // label 30: Fire Brick
//   178, 34, 34,

//   // label 31: Dark Khaki
//   189, 183, 107,

//   // label 32: Dark Orchid
//   153, 50, 204,

//   // label 33: Steel Blue
//   70, 130, 180,

//   // label 34: Medium Purple
//   147, 112, 219,

//   // label 35: Olive Drab
//   107, 142, 35,

//   // label 36: Dark Salmon
//   233, 150, 122,

//   // label 37: Sienna
//   160, 82, 45,

//   // label 38: Dark Turquoise
//   0, 206, 209,

//   // label 39: Chocolate
//   210, 105, 30,

//   // label 40: Dark Slate Blue
//   72, 61, 139,

//   // label 41: Forest Green
//   34, 139, 34,

//   // label 42: Maroon
//   128, 0, 0,

//   // label 43: Dark Olive Green
//   85, 107, 47,

//   // label 44: Midnight Blue
//   25, 25, 112,

//   // label 45: Dark Cyan
//   0, 139, 139,

//   // label 46: Dark Orchid
//   153, 50, 204,

//   // label 47: Dark Red
//   139, 0, 0,

//   // label 48: Dark Orange
//   255, 140, 0,

//   // label 49: Dark Magenta
//   139, 0, 139,

//   // label 50: Dark Goldenrod
//   184, 134, 11,

//   // label 51: Dark Sea Green
//   143, 188, 143,

//   // label 52: Dark Violet
//   148, 0, 211,

//   // label 53: Dark Gray
//   169, 169, 169,

//   // label 54: Dark Slate Gray
//   47, 79, 79,

//   // label 55: Dark Turquoise
//   0, 206, 209,

//   // label 56: Dark Olive Green
//   85, 107, 47,

//   // label 57: Midnight Blue
//   25, 25, 112,

//   // label 58: Dark Cyan
//   0, 139, 139,

//   // label 59: Dark Orchid
//   153, 50, 204,

//   // label 60: Dark Red
//   139, 0, 0,

//   // label 61: Dark Orange
//   255, 140, 0,

//   // label 62: Dark Magenta
//   139, 0, 139,

//   // label 63: Dark Goldenrod
//   184, 134, 11,

//   // label 64: Dark Sea Green
//   143, 188, 143,

//   // label 65: Dark Violet
//   148, 0, 211,

//   // label 66: Dark Gray
//   169, 169, 169,

//   // label 67: Dark Slate Gray
//   47, 79, 79,

//   // label 68: Dark Turquoise
//   0, 206, 209,

//   // label 69: Dark Olive Green
//   85, 107, 47,

//   // label 70: Midnight Blue
//   25, 25, 112,

//   // label 71: Dark Cyan
//   0, 139, 139,

//   // label 72: Dark Orchid
//   153, 50, 204,

//   // label 73: Dark Red
//   139, 0, 0,

//   // label 74: Dark Orange
//   255, 140, 0,

//   // label 75: Dark Magenta
//   139, 0, 139,

//   // label 76: Dark Goldenrod
//   184, 134, 11,

//   // label 77: Dark Sea Green
//   143, 188, 143,

//   // label 78: Dark Violet
//   148, 0, 211,

//   // label 79: Dark Gray
//   169, 169, 169,

//   // label 80: Dark Slate Gray
//   47, 79, 79,

//   // label 81: Dark Turquoise
//   0, 206, 209,

//   // label 82: Dark Olive Green
//   85, 107, 47,

//   // label 83: Midnight Blue
//   25, 25, 112,

//   // label 84: Dark Cyan
//   0, 139, 139,

//   // label 85: Dark Orchid
//   153, 50, 204,

//   // label 86: Dark Red
//   139, 0, 0,

//   // label 87: Dark Orange
//   255, 140, 0,

//   // label 88: Dark Magenta
//   139, 0, 139,

//   // label 89: Dark Goldenrod
//   184, 134, 11,

//   // label 90: Dark Sea Green
//   143, 188, 143,

//   // label 91: Dark Violet
//   148, 0, 211,

//   // label 92: Dark Gray
//   169, 169, 169,

//   // label 93: Dark Slate Gray
//   47, 79, 79,

//   // label 94: Dark Turquoise
//   0, 206, 209,

//   // label 95: Dark Olive Green
//   85, 107, 47,

//   // label 96: Midnight Blue
//   25, 25, 112,

//   // label 97: Dark Cyan
//   0, 139, 139,

//   // label 98: Dark Orchid
//   153, 50, 204,

//   // label 99: Dark Red
//   139, 0, 0,

//   // label 100: Dark Green
//   0, 139, 0,

//   // label 101: Dark Blue
//   0, 0, 139,

//   // label 102: Dark Yellow
//   139, 139, 0,

//   // label 103: Dark Cyan
//   0, 139, 139,

//   // label 104: Dark Magenta
//   139, 0, 139,

//   // label 105: Dark Orange
//   255, 69, 0,

//   // label 106: Dark Pink
//   139, 0, 69,

//   // label 107: Dark Lavender
//   69, 0, 139,

//   // label 108: Dark Teal
//   0, 69, 69,

//   // label 109: Dark Beige
//   139, 107, 69,

//   // label 110: Dark Brown
//   69, 34, 0,

//   // label 111: Dark Gold
//   139, 111, 0,

//   // label 112: Dark Silver
//   111, 111, 111,

//   // label 113: Dark Indigo
//   75, 0, 139,

//   // label 114: Dark Plum
//   139, 0, 75,

//   // label 115: Dark Olive
//   69, 69, 0,

//   // label 116: Dark Peach
//   139, 75, 34,

//   // label 117: Dark Coral
//   139, 50, 50,

//   // label 118: Dark Turquoise
//   0, 139, 139,

//   // label 119: Dark Rust
//   139, 34, 0,

//   // label 120: Dark Mint
//   0, 139, 75,

//   // label 121: Dark Lilac
//   75, 0, 139,

//   // label 122: Dark Rose
//   139, 0, 50,

//   // label 123: Dark Lemon
//   139, 139, 34,

//   // label 124: Dark Sky
//   0, 50, 139,

//   // label 125: Dark Peach
//   139, 75, 34,

//   // label 126: Dark Aqua
//   0, 139, 75,

//   // label 127: Dark Lavender
//   75, 0, 139,

//   // label 128: Dark Chocolate
//   139, 69, 0,

//   // label 129: Dark Ruby
//   139, 0, 50,

//   // label 130: Dark Lemon
//   139, 139, 34,

//   // label 131: Dark Emerald
//   0, 69, 34,

//   // label 132: Dark Amber
//   139, 69, 0,

//   // label 133: Dark Violet
//   69, 0, 139,

//   // label 134: Dark Topaz
//   139, 69, 34,

//   // label 135: Dark Orchid
//   69, 34, 139,

//   // label 136: Dark Ruby
//   139, 0, 50,

//   // label 137: Dark Teal
//   0, 69, 69,

//   // label 138: Dark Olive
//   69, 69, 0,

//   // label 139: Dark Aqua
//   0, 139, 75,

//   // label 140: Dark Sky
//   0, 50, 139,

//   // label 141: Dark Beige
//   139, 107, 69,

//   // label 142: Dark Brown
//   69, 34, 0,

//   // label 143: Dark Gold
//   139, 111, 0,

//   // label 144: Dark Silver
//   111, 111, 111,

//   // label 145: Dark Indigo
//   75, 0, 139,

//   // label 146: Dark Plum
//   139, 0, 75,

//   // label 147: Dark Olive
//   69, 69, 0,

//   // label 148: Dark Peach
//   139, 75, 34,

//   // label 149: Dark Coral
//   139, 50, 50,

//   // label 150: Dark Turquoise
//   0, 139, 139,

//   // label 151: Dark Rust
//   139, 34, 0,

//   // label 152: Dark Mint
//   0, 139, 75,

//   // label 153: Dark Lilac
//   75, 0, 139,

//   // label 154: Dark Rose
//   139, 0, 50,

//   // label 155: Dark Lemon
//   139, 139, 34,

//   // label 156: Dark Sky
//   0, 50, 139,

//   // label 157: Dark Peach
//   139, 75, 34,

//   // label 158: Dark Aqua
//   0, 139, 75,

//   // label 159: Dark Lavender
//   75, 0, 139,

//   // label 160: Dark Chocolate
//   139, 69, 0,

//   // label 161: Dark Ruby
//   139, 0, 50,

//   // label 162: Dark Lemon
//   139, 139, 34,

//   // label 163: Dark Emerald
//   0, 69, 34,

//   // label 164: Dark Amber
//   139, 69, 0,

//   // label 165: Dark Violet
//   69, 0, 139,

//   // label 166: Dark Topaz
//   139, 69, 34,

//   // label 167: Dark Orchid
//   69, 34, 139,

//   // label 168: Dark Ruby
//   139, 0, 50,

//   // label 169: Dark Teal
//   0, 69, 69,

//   // label 170: Dark Olive
//   69, 69, 0,

//   // label 171: Dark Aqua
//   0, 139, 75,

//   // label 172: Dark Sky
//   0, 50, 139,

//   // label 173: Dark Beige
//   139, 107, 69,

//   // label 174: Dark Brown
//   69, 34, 0,

//   // label 175: Dark Gold
//   139, 111, 0,

//   // label 176: Dark Silver
//   111, 111, 111,

//   // label 177: Dark Indigo
//   75, 0, 139,

//   // label 178: Dark Plum
//   139, 0, 75,

//   // label 179: Dark Olive
//   69, 69, 0,

//   // label 180: Dark Peach
//   139, 75, 34,

//   // label 181: Dark Coral
//   139, 50, 50,

//   // label 182: Dark Turquoise
//   0, 139, 139,

//   // label 183: Dark Rust
//   139, 34, 0,

//   // label 184: Dark Mint
//   0, 139, 75,

//   // label 185: Dark Lilac
//   75, 0, 139,

//   // label 186: Dark Rose
//   139, 0, 50,

//   // label 187: Dark Lemon
//   139, 139, 34,

//   // label 188: Dark Sky
//   0, 50, 139,

//   // label 189: Dark Peach
//   139, 75, 34,

//   // label 190: Dark Aqua
//   0, 139, 75,

//   // label 191: Dark Lavender
//   75, 0, 139,

//   // label 192: Dark Chocolate
//   139, 69, 0,

//   // label 193: Dark Ruby
//   139, 0, 50,

//   // label 194: Dark Lemon
//   139, 139, 34,

//   // label 195: Dark Emerald
//   0, 69, 34,

//   // label 196: Dark Amber
//   139, 69, 0,

//   // label 197: Dark Violet
//   69, 0, 139,

//   // label 198: Dark Topaz
//   139, 69, 34,

//   // label 199: Dark Orchid
//   69, 34, 139,

//   // label 200: Dark Ruby
//   139, 0, 50,

//   // label 201: Dark Teal
//   0, 69, 69,

//   // label 202: Dark Olive
//   69, 69, 0,

//   // label 203: Dark Aqua
//   0, 139, 75,

//   // label 204: Dark Sky
//   0, 50, 139,

//   // label 205: Dark Beige
//   139, 107, 69,

//   // label 206: Dark Brown
//   69, 34, 0,

//   // label 207: Dark Gold
//   139, 111, 0,

//   // label 208: Dark Silver
//   111, 111, 111,

//   // label 209: Dark Indigo
//   75, 0, 139,

//   // label 210: Dark Plum
//   139, 0, 75,

//   // label 211: Dark Olive
//   69, 69, 0,

//   // label 212: Dark Peach
//   139, 75, 34,

//   // label 213: Dark Coral
//   139, 50, 50,

//   // label 214: Dark Turquoise
//   0, 139, 139,

//   // label 215: Dark Rust
//   139, 34, 0,

//   // label 216: Dark Mint
//   0, 139, 75,

//   // label 217: Dark Lilac
//   75, 0, 139,

//   // label 218: Dark Rose
//   139, 0, 50,

//   // label 219: Dark Lemon
//   139, 139, 34,

//   // label 220: Dark Sky
//   0, 50, 139,

//   // label 221: Dark Peach
//   139, 75, 34,

//   // label 222: Dark Aqua
//   0, 139, 75,

//   // label 223: Dark Lavender
//   75, 0, 139,

//   // label 224: Dark Chocolate
//   139, 69, 0,

//   // label 225: Dark Ruby
//   139, 0, 50,

//   // label 226: Dark Lemon
//   139, 139, 34,

//   // label 227: Dark Emerald
//   0, 69, 34,

//   // label 228: Dark Amber
//   139, 69, 0,

//   // label 229: Dark Violet
//   69, 0, 139,

//   // label 230: Dark Topaz
//   139, 69, 34,

//   // label 231: Dark Orchid
//   69, 34, 139,

//   // label 232: Dark Ruby
//   139, 0, 50,

//   // label 233: Dark Teal
//   0, 69, 69,

//   // label 234: Dark Olive
//   69, 69, 0,

//   // label 235: Dark Aqua
//   0, 139, 75,

//   // label 236: Dark Sky
//   0, 50, 139,

//   // label 237: Dark Beige
//   139, 107, 69,

//   // label 238: Dark Brown
//   69, 34, 0,

//   // label 239: Dark Gold
//   139, 111, 0,

//   // label 240: Dark Silver
//   111, 111, 111
// ]
