/* eslint-disable react/prop-types */
import { mainViewNextButtonHandler } from '../mainView/MainViewNextButtonHandler'
import { mainViewBackButtonHandler } from '../mainView/MainViewBackButtonHandler'
import { segmentationButtonHandler } from '../segmentation/SegmentationButtonHandler'
import { isolationButtonHandler } from '../isolation/IsolationButtonHandler'
import { repositionButtonHandler } from '../reposition/RepositionButtonHandler'
import { targetAnatomyButtonHandler } from '../targetAnatomy/TargetAnatomyButtonHandler'
import { implantPositioningButtonHandler } from '../implantPositioning/ImplantPositioningButtonHandler'

// function to handle when user click one of active case cards
export const confirmDialogHandler = async (
  setOpen,
  nextButtonType,
  indication,
  buttonState,
  setButtonState,
  buttonStates,
  sdk,
  isDemo,
  setIsLoading,
  filePath,
  setFilePath,
  backInfo,
  setBackInfo,
  userInfo,
  setLoadingValue
) => {
  setOpen(false)

  if (nextButtonType === 'Next') {
    mainViewNextButtonHandler(
      indication,
      buttonState,
      setButtonState,
      buttonStates,
      sdk,
      isDemo,
      setIsLoading,
      filePath,
      setFilePath,
      backInfo,
      setBackInfo,
      userInfo,
      setLoadingValue
    )
  } else if (nextButtonType === 'Back') {
    mainViewBackButtonHandler(
      indication,
      buttonState,
      setButtonState,
      buttonStates,
      sdk,
      isDemo,
      setIsLoading,
      filePath,
      setFilePath,
      backInfo,
      setBackInfo,
      userInfo,
      setLoadingValue
    )
  } else if (nextButtonType === 'Seg') {
    segmentationButtonHandler(
      sdk,
      isDemo,
      setIsLoading,
      filePath,
      setFilePath,
      setButtonState,
      backInfo,
      setBackInfo,
      userInfo,
      setLoadingValue,
      indication
    )
  } else if (nextButtonType === 'Iso') {
    isolationButtonHandler(
      sdk,
      isDemo,
      setIsLoading,
      filePath,
      setFilePath,
      setButtonState,
      backInfo,
      setBackInfo,
      userInfo,
      setLoadingValue
    )
  } else if (nextButtonType === 'Rep') {
    repositionButtonHandler(
      sdk,
      isDemo,
      setIsLoading,
      filePath,
      setFilePath,
      setButtonState,
      backInfo,
      setBackInfo,
      userInfo,
      setLoadingValue
    )
  } else if (nextButtonType === 'Target') {
    targetAnatomyButtonHandler(
      sdk,
      isDemo,
      setIsLoading,
      filePath,
      setFilePath,
      setButtonState,
      backInfo,
      setBackInfo,
      userInfo,
      setLoadingValue
    )
  } else {
    // nextButtonType === 'Implant

    implantPositioningButtonHandler(
      sdk,
      isDemo,
      setIsLoading,
      filePath,
      setFilePath,
      setButtonState,
      backInfo,
      setLoadingValue
    )
  }
}
