/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  HeaderButtonContainer,
  RectanglePart,
  LeftTrianglePart,
  RightTrianglePart,
  TextContainer
} from '../../../../Theme'
import { implantSimulationButtonHandler } from './ImplantSimulationButtnHandler'

const ImplantSimulationButton = ({ setButtonState, buttonStates, buttonState }) => {
  const [hover, setHover] = useState(false)
  const isActive = buttonState === 'Sim'
  const isDone = buttonStates.indexOf(buttonState) > buttonStates.indexOf('Sim')

  return (
    <HeaderButtonContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => implantSimulationButtonHandler(setButtonState)}
    >
      <LeftTrianglePart hover={hover} active={isActive} done={isDone} />
      <RectanglePart
        sx={{
          width: '155px'
        }}
        hover={hover}
        active={isActive}
        done={isDone}
      />
      <RightTrianglePart hover={hover} active={isActive} done={isDone} />
      <TextContainer>Implant Simulation</TextContainer>
    </HeaderButtonContainer>
  )
}

export default ImplantSimulationButton
