/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { fileHandler } from '../../maintool/functions/file/FileHandler'
import { folderHandler } from '../../maintool/functions/file/FolderHandler'
import { dicomFolderLoader, dicomZipLoader } from '../../maintool/functions/file/DicomLoader'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import { Box, FormControlLabel, Checkbox, Typography, IconButton } from '@mui/material'
import DicomSeriesRows from './DicomSeriesRows'

// dropzon component to drag and drop file or upload folder and file.
const DropZone = ({
  isDemo,
  setDemo,
  isRenderingThreeDisabled,
  setIsRenderingThreeDisabled,
  backInfo,
  setBackInfo,
  setButtonState,
  userInfo,
  filePath,
  setFilePath,
  sdk,
  setFileLoadingStatus,
  setDocumentUpdateStatus,
  setFileName
}) => {
  const [enableFolderSelection, setEnableFolderSelection] = useState(true) // check file select or folder select
  const [dicomSeriesInfo, SetdicomSeriesInfo] = useState({})
  const [currentFile, setCurrentFile] = useState(null)
  const [chosenDicom, setChosenDicom] = useState(null)
  const [isDicomSelected, setIsDicomSelected] = useState(false)

  useEffect(() => {
    if (isDicomSelected === false && currentFile !== null && chosenDicom !== null) {
      if (enableFolderSelection) {
        folderHandler(
          currentFile,
          isDemo,
          setDemo,
          backInfo,
          setBackInfo,
          setButtonState,
          userInfo,
          isRenderingThreeDisabled,
          setIsRenderingThreeDisabled,
          setFilePath,
          sdk,
          setFileLoadingStatus,
          setDocumentUpdateStatus,
          setFileName,
          chosenDicom
        )
      } else {
        fileHandler(
          currentFile,
          isDemo,
          setDemo,
          backInfo,
          setBackInfo,
          setButtonState,
          userInfo,
          isRenderingThreeDisabled,
          setIsRenderingThreeDisabled,
          filePath,
          setFilePath,
          sdk,
          setFileLoadingStatus,
          setDocumentUpdateStatus,
          setFileName,
          chosenDicom
        )
      }
    }
  }, [chosenDicom, currentFile, isDicomSelected])

  const uploadFolder = (folderEntry) => {
    setCurrentFile(folderEntry)
    dicomFolderLoader(folderEntry, SetdicomSeriesInfo, setIsDicomSelected)
  }

  const uploadFile = (fileList) => {
    // const event = {target:{files:[file]}}

    setCurrentFile(fileList[0])

    const file = fileList[0]
    const fileExtension = file['name'].split('.').pop()

    if (fileExtension == 'zip' || fileExtension == '7z') {
      dicomZipLoader(file, SetdicomSeriesInfo, setIsDicomSelected)
    } else {
      fileHandler(
        file,
        isDemo,
        setDemo,
        backInfo,
        setBackInfo,
        setButtonState,
        userInfo,
        isRenderingThreeDisabled,
        setIsRenderingThreeDisabled,
        filePath,
        setFilePath,
        sdk,
        setFileLoadingStatus,
        setDocumentUpdateStatus,
        setFileName,
        chosenDicom
      )
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()

    if (enableFolderSelection) {
      e.dataTransfer.effectAllowed = 'none'
      e.dataTransfer.dropEffect = 'none'
    } else {
      e.dataTransfer.dropEffect = 'copy'
    }
  }

  const handleEnableFolderSelectionChange = (e) => {
    setEnableFolderSelection(e.target.checked)
  }

  const handleInput = (event) => {
    event.preventDefault() // Prevent the browser from attempting to load the dropped file
    if (enableFolderSelection) {
      uploadFolder(event)
    } else {
      const files = event.type === 'drop' ? event.dataTransfer.files : event.target.files
      uploadFile(files)
      if (event.target.type === 'file') {
        // Check if the event came from a file input
        event.target.value = null // Reset the file input value
      }
    }
  }

  return (
    <>
      {isDicomSelected && (
        <DicomSeriesRows
          dicomSeriesInfo={dicomSeriesInfo}
          setChosenDicom={setChosenDicom}
          setIsDicomSelected={setIsDicomSelected}
        />
      )}
      <Box sx={{ marginTop: '10px', color: '#fff' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={enableFolderSelection}
              sx={{ color: '#fff' }}
              onChange={(e) => handleEnableFolderSelectionChange(e)}
            />
          }
          label={<Typography sx={{ fontSize: '14px' }}>Enable Dicom Folder Selection</Typography>}
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        />
      </Box>
      <Box
        onDrop={(e) => handleInput(e)}
        onDragOver={(e) => handleDragOver(e)}
        sx={{
          border: '2px dashed #2C622C',
          p: '10px',
          minHeight: '200px',
          backgroundColor: '#303030',
          position: 'relative',
          width: '30em',
          color: '#fff'
        }}
      >
        <IconButton
          sx={{
            marginTop: '2em',
            width: '20em',
            height: '3em'
          }}
        >
          {enableFolderSelection ? (
            <Box sx={{ color: 'white' }}>
              <DriveFolderUploadIcon fontSize="large" sx={{ fontSize: '5rem' }} />
            </Box>
          ) : (
            <Box sx={{ color: 'white' }}>
              <UploadFileIcon fontSize="large" sx={{ fontSize: '5rem' }} />
            </Box>
          )}
        </IconButton>

        <Typography
          variant="body1"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1em',
            fontSize: '1.0rem'
          }}
        >
          {enableFolderSelection
            ? 'Click to upload a DICOM folder'
            : 'Click to upload or drag NIFTI(.nii.gz) or DICOM zip file here.'}
        </Typography>
        <label
          htmlFor="fileInput"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
        ></label>
        <input
          type="file"
          id="fileInput"
          onChange={(e) => handleInput(e)}
          webkitdirectory={enableFolderSelection ? 'true' : undefined}
          style={{ display: 'none' }}
        />
      </Box>
    </>
  )
}

export default DropZone
