/* eslint-disable react/prop-types */
import React from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Card, Button } from '@mui/material'
import { addNewCaseButtonHandler } from './AddNewCaseButtonHandler'

// new button component to change create button view
function AddNewCaseButton({ setCurrentView }) {
  return (
    <Button
      component={Card}
      variant="outlined"
      sx={{
        backgroundColor: '#303030',
        color: '#FFF',
        padding: '16px',
        position: 'relative',
        borderRadius: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '32.33%',

        borderColor: 'transparent', // Set border color to transparent
        '&:hover': {
          borderColor: '#FFF' // Set border color to white on hover
        }
      }}
      onClick={() => addNewCaseButtonHandler(setCurrentView)}
    >
      <AddCircleIcon sx={{ fontSize: '149px', color: 'rgba(255, 255, 255, 0)' }} />
      <AddCircleIcon sx={{ fontSize: '80px', color: 'rgba(255, 255, 255, 0.6)' }} />
      <AddCircleIcon sx={{ fontSize: '149px', color: 'rgba(255, 255, 255, 0)' }} />
    </Button>
  )
}

export default AddNewCaseButton
