/* eslint-disable react/prop-types */
import React from 'react'
import { Box, Typography } from '@mui/material'

function ClosedCase() {
  return (
    <Box
      sx={{
        backgroundColor: '#191919',
        height: '100vh',
        padding: '16px'
      }}
    >
      {/* Heading */}
      <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>
        Closed Patient Cases
      </Typography>
    </Box>
  )
}

export default ClosedCase
