/* eslint-disable react/prop-types */
import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { Grid, Box, Button } from '@mui/material'
// import { activeCaseButtonHandler } from '../caseOverview/activeCases/ActiveCaseButtonHandler'
import { createCaseButtonHandler } from '../caseOverview/createCase/CreateCaseButtonHandler'

const ChangeCasesDialog = (props) => {
  const { onClose, open, setRedirectTo, redirectTo, setActiveButton, setCurrentView } = props
  const handleClose = () => {
    onClose()
  }

  const handleRedirect = () => {
    if (redirectTo === 'activeCase') {
      window.location.reload()
    } else if (redirectTo === 'createCase') {
      createCaseButtonHandler(setCurrentView)
      setActiveButton(redirectTo)
    }
    setRedirectTo('main')
  }

  if (open === 'changeCases' && redirectTo !== 'main') {
    return (
      <Dialog onClose={handleClose} open={true}>
        <Box
          sx={{
            padding: '16px'
          }}
        >
          <DialogTitle>
            Are you sure you want to leave? Any unsaved changes will be lost.
          </DialogTitle>

          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Button onClick={() => handleClose()} variant="contained">
                No, stay.
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => handleRedirect()} variant="contained">
                Yes, leave.
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    )
  }
}

export default ChangeCasesDialog
