/* eslint-disable react/prop-types */
import React, { useContext, useState, useRef } from 'react'
import {
  Box,
  useTheme,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  FormControlLabel
} from '@mui/material'
import Draggable from 'react-draggable'
import InfoIcon from '@mui/icons-material/Info'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import OpenWithIcon from '@mui/icons-material/OpenWith'
import TransformIcon from '@mui/icons-material/Transform'
import CancelIcon from '@mui/icons-material/Cancel'
import CloseButton from './CloseButton'
import AdsClickIcon from '@mui/icons-material/AdsClick'
import FlipIcon from '@mui/icons-material/Flip'
import Switch from '@mui/material/Switch'
import { UserContext } from '../../../../App'

// measurement tool function
const MainViewMovementBox = ({
  setMovementConfig: setMovementConfig,
  setMovementActivation: setMovementActivation,
  setShowMovementBox: setShowMovementBox,
  labelBoxMargin: labelBoxMargin,
  currentMovementMenu: currentMovementMenu,
  setCurrentMovementMenu: setCurrentMovementMenu,
  centerPoint: centerPoint,
  setCenterPoint: setCenterPoint,
  autoPlacementInfo: autoPlacementInfo,
  setAutoPlacementInfo: setAutoPlacementInfo
}) => {
  const theme = useTheme()

  const draggableRef = useRef(null)

  const { buttonState, setTranslationInfo, translationInfo, setFlip } = useContext(UserContext)

  const [movementBool, setMovementBool] = useState(false)

  const toggleVal = false

  const [showFlipOptions, setShowFlipOptions] = useState(false)

  // Function to calculate dynamic height based on flip options visibility
  const calculateHeight = () => {
    let baseHeight = 200 // Base height of the box without flip options
    if (buttonState === 'Implant') {
      baseHeight += 112 // Additional height for 'Implant' state
    } else if (buttonState === 'Rep') {
      baseHeight += 10 // Additional height for 'Rep' state
    }
    if (showFlipOptions) {
      baseHeight += 158 // Additional height for flip options
    }
    return `${baseHeight}px`
  }

  const handleSwitch = () => {
    setCenterPoint(!centerPoint)
    setMovementConfig({ type: 'none' })
    setCurrentMovementMenu('none')
    setAutoPlacementInfo(false)
  }

  const handleRotateClick = () => {
    setMovementConfig({ type: 'rotate' })
    // setCurrentMovementMenu('rotate')
    setCurrentMovementMenu('none')
    setAutoPlacementInfo(false)
  }

  const handleTranslateClick = () => {
    setMovementConfig({ type: 'translate' })
    // setCurrentMovementMenu('translate')
    setCurrentMovementMenu('none')
    setAutoPlacementInfo(false)
  }

  const handleNoneClick = () => {
    setMovementConfig({ type: 'none' })
    setCurrentMovementMenu('none')
    setAutoPlacementInfo(false)
  }

  const handleMovementClick = () => {
    setMovementConfig({ type: 'none' })
    setCurrentMovementMenu('none')
    setMovementActivation((preMovementActivation) => !preMovementActivation)
    setMovementBool((prevmovementBool) => !prevmovementBool)
    setAutoPlacementInfo(false)
  }

  const handleAutomatedPlacement = () => {
    setMovementConfig({ type: 'auto' })
    setCurrentMovementMenu('auto')
  }

  const handleAutoInfoClick = (e) => {
    e.stopPropagation()
    setAutoPlacementInfo(!autoPlacementInfo)
    setMovementConfig({ type: 'none' })
    setCurrentMovementMenu('none')
  }

  const handleTranslateInfoClick = (e) => {
    e.stopPropagation()
    if (buttonState == 'Rep' || buttonState === 'Implant') {
      setTranslationInfo(!translationInfo)
      setMovementConfig({ type: 'none' })
      setCurrentMovementMenu('none')
    }
  }
  const handleP90 = (event) => {
    setFlip({ type: 'P90' })
    event.stopPropagation()
  }
  const handleM90 = (event) => {
    setFlip({ type: 'M90' })
    event.stopPropagation()
  }
  const handle180 = (event) => {
    setFlip({ type: '180' })
    event.stopPropagation()
  }

  const handleInnerGroupChange = () => {
    //PlaceHolder Line
  }

  // Function to handle click on the new toggle button
  const handleToggleFlipVisibility = () => {
    setShowFlipOptions(!showFlipOptions)
    setCurrentMovementMenu('flip')
  }

  return (
    // dragging is working on any location in box
    <Draggable nodeRef={draggableRef} handle=".drag-icon">
      <Box
        ref={draggableRef}
        sx={{
          position: 'absolute',
          top: `calc(${labelBoxMargin}% + ${theme.sizes.nav.top} + 100px)`,
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '16px 0px',
          gap: '16px',
          width: '240px',
          height: calculateHeight(),
          background: '#232323',
          boxShadow: '0px 4px 20px rgba(255, 255, 255, 0.25)',
          borderRadius: '8px',
          zIndex: 9990,
          right: '10px'
        }}
      >
        <Box
          value={movementBool}
          onClick={handleMovementClick}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Open Sans',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
              flex: 'none',
              order: 0,
              flexGrow: 0
            }}
          >
            Movement Type
          </Typography>

          <div>
            <OpenWithIcon
              className="drag-icon"
              sx={{
                color: '#FFFFFF',
                cursor: 'move',
                backgroundColor: '',
                borderRadius: '50%',
                fontSize: '25px',
                zIndex: 9999
              }}
            />
            <CloseButton setShowToolBox={setShowMovementBox} />
          </div>
        </Box>
        {buttonState == 'Rep' && (
          <Box display="flex" alignItems="center">
            <span
              style={{
                color: '#FFFFFF',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '20px',
                flex: 'none',
                marginLeft: '5px',
                marginRight: '20px',
                order: 0,
                flexGrow: 0
              }}
            >
              OBJECT POINT
            </span>
            <FormControlLabel
              control={<Switch checked={centerPoint} onChange={handleSwitch} />}
              label=""
            />
            <Box flexGrow={1} textAlign="right">
              <span
                style={{
                  color: '#FFFFFF',
                  marginLeft: '-100px',
                  fontSize: '16px',
                  fontWeight: 300,
                  lineHeight: '20px',
                  // flex: 'none',
                  order: 2,
                  flexGrow: 0
                }}
              >
                CENTER
              </span>
            </Box>
          </Box>
        )}
        {buttonState == 'Implant' && (
          <Box display="flex" alignItems="center">
            <span
              style={{
                color: '#FFFFFF',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '20px',
                flex: 'none',
                marginLeft: '5px',
                marginRight: '20px',
                order: 0,
                flexGrow: 0
              }}
            >
              OBJECT POINT
            </span>
            <FormControlLabel
              control={<Switch checked={centerPoint} onChange={handleSwitch} />}
              label=""
            />
            <Box flexGrow={1} textAlign="right">
              <span
                style={{
                  color: '#FFFFFF',
                  marginLeft: '-100px',
                  fontSize: '16px',
                  fontWeight: 300,
                  lineHeight: '20px',
                  // flex: 'none',
                  order: 2,
                  flexGrow: 0
                }}
              >
                CENTER
              </span>
            </Box>
          </Box>
        )}
        <ToggleButtonGroup
          value={currentMovementMenu}
          exclusive
          onChange={(event, currentValue) => setCurrentMovementMenu(currentValue)}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          {buttonState == 'Implant' && (
            <ToggleButton
              value="auto"
              onClick={handleAutomatedPlacement}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: '#91C58E'
                }
              }}
            >
              <AdsClickIcon //alternativ: AdsClickIcon
                sx={{
                  order: 1,
                  color: '#FFFFFF',
                  marginRight: '10px',
                  transform: 'scaleX(-1)'
                }}
              />
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  color: '#FFFFFF',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '20px',
                  flex: 'none',
                  order: 2,
                  flexGrow: 0,
                  marginRight: '10px',
                  marginTop: '5px'
                }}
              >
                Automated Placement
              </Typography>
              <InfoIcon
                sx={{ order: 3, color: '#FFFFFF', fontSize: '20px' }}
                onClick={handleAutoInfoClick}
              />
            </ToggleButton>
          )}
          <ToggleButton
            value="rotate"
            onClick={handleRotateClick}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#91C58E'
              }
            }}
          >
            <AutorenewIcon
              sx={{
                order: 1,
                color: '#FFFFFF',
                marginRight: '10px',
                transform: 'scaleX(-1)'
              }}
            />
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: '#FFFFFF',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '20px',
                flex: 'none',
                order: 2,
                flexGrow: 0,
                marginRight: '10px',
                marginTop: '5px'
              }}
            >
              Rotate
            </Typography>
            <InfoIcon sx={{ order: 3, color: '#FFFFFF', fontSize: '20px' }} />
          </ToggleButton>
          <ToggleButton
            value="translate"
            onClick={handleTranslateClick}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#91C58E'
              }
            }}
          >
            <TransformIcon
              sx={{
                order: 1,
                color: '#FFFFFF',
                marginRight: '10px',
                transform: 'scaleX(-1)'
              }}
            />
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: '#FFFFFF',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '20px',
                flex: 'none',
                order: 2,
                flexGrow: 0,
                marginRight: '10px',
                marginTop: '5px'
              }}
            >
              Translate
            </Typography>
            <InfoIcon
              sx={{ order: 3, color: '#FFFFFF', fontSize: '20px' }}
              onClick={handleTranslateInfoClick}
            />
          </ToggleButton>
        </ToggleButtonGroup>
        {buttonState == 'Implant' && (
          <Box
            onClick={handleToggleFlipVisibility}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              cursor: 'pointer',
              backgroundColor: showFlipOptions ? '#1C1C1C' : 'transparent', // Darker black when clicked
              borderBottom: '1px solid #1C1C1C',
              padding: '10px 0',
              marginTop: '0' // Remove top margin
            }}
          >
            <FlipIcon
              sx={{
                color: '#FFFFFF',
                marginRight: '10px'
              }}
            />
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: '#FFFFFF',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '20px',
                flex: 'none',
                flexGrow: 0,
                marginRight: '10px',
                marginTop: '5px'
              }}
            >
              FLIP OPTIONS
            </Typography>
            <InfoIcon sx={{ color: '#FFFFFF', fontSize: '20px' }} />
          </Box>
        )}
        {showFlipOptions && buttonState == 'Implant' && centerPoint && (
          <ToggleButtonGroup
            value={toggleVal}
            exclusive
            onChange={handleInnerGroupChange}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <ToggleButton
              // value = currentMovementMenu
              onClick={handleP90}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: '#91C58E'
                }
              }}
            >
              <AutorenewIcon //alternativ: AdsClickIcon
                sx={{
                  order: 1,
                  color: '#FFFFFF',
                  marginRight: '10px',
                  transform: 'scaleX(-1)'
                }}
              />
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  color: '#FFFFFF',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '20px',
                  flex: 'none',
                  order: 2,
                  flexGrow: 0,
                  marginRight: '10px',
                  marginTop: '5px'
                }}
              >
                Rotate +90 degrees
              </Typography>
              <InfoIcon sx={{ order: 3, color: '#FFFFFF', fontSize: '20px' }} />
            </ToggleButton>

            <ToggleButton
              onClick={handle180}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: '#91C58E'
                }
              }}
            >
              <AutorenewIcon //alternativ: AdsClickIcon
                sx={{
                  order: 1,
                  color: '#FFFFFF',
                  marginRight: '10px',
                  transform: 'scaleX(-1)'
                }}
              />
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  color: '#FFFFFF',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '20px',
                  flex: 'none',
                  order: 2,
                  flexGrow: 0,
                  marginRight: '10px',
                  marginTop: '5px'
                }}
              >
                Rotate 180 degrees
              </Typography>
              <InfoIcon sx={{ order: 3, color: '#FFFFFF', fontSize: '20px' }} />
            </ToggleButton>

            <ToggleButton
              onClick={handleM90}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: '#91C58E'
                }
              }}
            >
              <AutorenewIcon //alternativ: AdsClickIcon
                sx={{
                  order: 1,
                  color: '#FFFFFF',
                  marginRight: '10px',
                  transform: 'scaleX(-1)'
                }}
              />
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  color: '#FFFFFF',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '20px',
                  flex: 'none',
                  order: 2,
                  flexGrow: 0,
                  marginRight: '10px',
                  marginTop: '5px'
                }}
              >
                Rotate -90 degrees
              </Typography>
              <InfoIcon sx={{ order: 3, color: '#FFFFFF', fontSize: '20px' }} />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <Box
          onClick={handleNoneClick}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            cursor: 'pointer',
            backgroundColor: currentMovementMenu === 'none' ? '#91C58E' : 'transparent',
            borderBottom: '1px solid #1C1C1C',
            padding: '10px 0',
            marginTop: '0' // Remove top margin
          }}
        >
          <CancelIcon
            sx={{
              color: '#FFFFFF',
              marginRight: '10px',
              transform: 'scaleX(-1)'
            }}
          />
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: '#FFFFFF',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
              marginRight: '10px',
              marginTop: '5px'
            }}
          >
            None
          </Typography>
          <InfoIcon sx={{ color: '#FFFFFF', fontSize: '20px' }} />
        </Box>
      </Box>
    </Draggable>
  )
}

export default MainViewMovementBox
