/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'
import { Grid, Box, ThemeProvider, Typography, Button } from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useTheme } from '@mui/material/styles'

import SegmentationButton from '../maintool/functions/segmentation/SegmentationButton'
import IsolationButton from '../maintool/functions/isolation/IsolationButton'
import RepositionButton from '../maintool/functions/reposition/RepositionButton'
import TargetAnatomyButton from '../maintool/functions/targetAnatomy/TargetAnatomyButton'
import ScrewPositioningButton from '../maintool/functions/screwPositioning/ScrewPositioningButton'
import ImplantPositioningButton from '../maintool/functions/implantPositioning/ImplantPositioningButton'
import ImplantSimulationButton from '../maintool/functions/implantSimulation/ImplantSimulationButton'
import ImplantOptimizationButton from '../maintool/functions/implantOptimization/ImplantOptimizationButton'

import { BackNextButton, MainViewSmallButton } from '../../Theme'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import TurnLeftIcon from '@mui/icons-material/TurnLeft'
import TurnRightIcon from '@mui/icons-material/TurnRight'
import GridViewIcon from '@mui/icons-material/GridView'
import BrushIcon from '@mui/icons-material/Brush'
import VisibilityIcon from '@mui/icons-material/Visibility'
import NearMeIcon from '@mui/icons-material/NearMe'
import InfoIcon from '@mui/icons-material/Info'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import RestoreIcon from '@mui/icons-material/Restore'
import PreviewIcon from '@mui/icons-material/Preview'
// import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest' //not used right now
import StraightenIcon from '@mui/icons-material/Straighten'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import SplitscreenIcon from '@mui/icons-material/Splitscreen'

import { mainViewNextButtonHandler } from '../maintool/functions/mainView/MainViewNextButtonHandler'
import { mainViewBackButtonHandler } from '../maintool/functions/mainView/MainViewBackButtonHandler'
import MainViewSaveButton from '../maintool/functions/mainView/MainViewSaveButton'
import { mainViewChooseViewButtonHandler } from '../maintool/functions/mainView/MainViewChooseViewButtnHandler'
import { mainView3DButtonHandler } from '../maintool/functions/mainView/MainView3DButtonHandler'
import MainViewMeasureToolBox from '../maintool/functions/mainView/MainViewMeasureToolBox'
import MainViewBrushToolBox from '../maintool/functions/mainView/MainViewBrushToolBox'
import MainViewEraserToolBox from '../maintool/functions/mainView/MainViewEraserToolBox'
import MainViewScissorsToolBox from '../maintool/functions/mainView/MainViewScissorsToolBox'
import MainViewBrightContrast from '../maintool/functions/mainView/mainViewBrightContrast'
import { VerticalIconButton } from '../../Theme'
import MainViewMovementBox from '../maintool/functions/mainView/MainViewMovementBox'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import SelectImplant from '../maintool/functions/implantPositioning/SelectImplant'
import { mainViewSegmentationButtonHandler } from '../maintool/functions/mainView/MainViewSegmentationButtonHandler'
import ConfirmDialog from '../maintool/functions/others/ConfirmDialog'
import WarnDialog from '../maintool/functions/others/WarnDialog'
import MainViewLabelBox from '../maintool/functions/mainView/MainViewLabelBox'

import ISOSlider from '../maintool/functions/mainView/MainViewISOThresHoldBarHandler'

import { UserContext } from '../../App'
import { mainViewLabelBoxButtonHandler } from '../maintool/functions/mainView/mainViewLabelBoxButtonHandler'
import { mainViewVolumeButtonHandler } from '../maintool/functions/mainView/MainViewVolumeButtonHandler'
import MainViewDownloadButton from '../maintool/functions/mainView/MainViewDownloadButton'
import MainViewCameraResetButton from '../maintool/functions/mainView/MainViewCameraResetButton'

import { debounce } from 'lodash'

import MainView3DViewBox from '../maintool/functions/mainView/MainView3DViewBox'
import MainViewAnimationSwitchButton from '../maintool/functions/mainView/MainViewAnimationSwitchButton'
import MainViewCrossHairs from '../maintool/functions/mainView/MainViewCrossHairs'
import MainViewIntensityToolBox from '../maintool/functions/mainView/MainViewIntensityToolBox'
import MainViewRotateSpeed from '../maintool/functions/mainView/MainViewRotateSpeed'

function Header({
  filePath,
  setFilePath,
  setButtonState,
  sdk,
  userInfo,
  isDemo,
  backInfo,
  setBackInfo,
  setIsLoading,
  buttonStates,
  buttonState,
  indication,
  label,
  mainView: currentView,
  setMainView: setCurrentView,
  on3DRender,
  setOn3DRender,
  setIsSaving,
  setMeasureConfig,
  Radius,
  setRadius,
  setSegmentConfig,
  setSelectedSegment,
  showToolBox,
  setShowToolBox,
  brightness,
  setBrightness,
  contrast,
  setContrast,
  volumeRange,
  SetLabelThresholdRange,
  setMovementConfig,
  setMovementActivation,
  setImplantToken,
  isSegmentationVisible,
  setIsSegmentationVisible,
  isVolumeVisible,
  setIsVolumeVisible,
  ISOThreshold,
  setISOThreshold,
  isoSliderInfo,
  setIsoSliderInfo,
  currentMovementMenu,
  setCurrentMovementMenu,
  centerPoint,
  setCenterPoint,
  autoPlacementInfo,
  setAutoPlacementInfo,
  isMovementExpanded,
  setMovementsExpanded,
  isoThresholdMinimumDefault,
  setSelectedView
}) {
  const theme = useTheme()
  const currentVersion = 'first'

  const [isToolsExpanded, setToolsIsExpanded] = useState(false) // useState for toolbox extension status
  const [isViewSettingExpanded, setViewSettingExpanded] = useState(false) //useState for view setting extension status
  const [isImplantExpanded, setImplantIsExpanded] = useState(false) // useState for implant extension status
  const [isLabelTool, setisLabelTool] = useState(false) // useState forlabel tool status
  const [isRotationExpanded, setRotationsExpanded] = useState(false) // useState for rotation extension status
  const [isISOThresholdSettingExpanded, setIsISOThresholdSettingExpanded] = useState(false) //useState for view setting extension status
  const [isViewsExpended, setViewsExpended] = useState(false)
  const [isChooseViewExpended, setChooseViewExpended] = useState(false)
  const [chooseView, setChooseView] = useState('Axial')

  const [isLabelBoxVisible, setIsLabelBoxVisible] = useState(false)
  const [originalLabelID, setOriginalLabelID] = useState(null)
  const {
    openConfirmDialog,
    setOpenConfirmDialog,
    nextButtonType,
    handleClickOpen,
    setLoadingValue,
    setIsUndo,
    setIsRedo,
    undoStack,
    redoStack,
    openWarnDialog,
    setOpenWarnDialog,
    isLoading,
    loadingValue,
    handleWarnDialog,
    warnMessage,
    setWarnMessage,
    repoScene,
    currentButton,
    setCurrentView: setCurrentPage,
    setLabelColorSwitch,
    labelColorSwitch,
    setCameraResetClicked,
    setIsRestore,
    viewBoxOn,
    setViewBoxOn,
    animationOn,
    setAnimationOn,
    crosshairsOn,
    setCrosshairsOn,
    allViewLoaded,
    setUndoStack,
    setRedoStack,
    implantToken,
    rotateSpeed,
    setRotateSpeed
  } = useContext(UserContext)

  // function to expand toolbox

  const handleToolsExpandClick = () => {
    setToolsIsExpanded(!isToolsExpanded)

    if (isViewSettingExpanded) {
      handleViewSettingExpandClick()
    }
    if (isISOThresholdSettingExpanded) {
      handleISOThresholdSettingExpandClick()
    }
    if (isViewsExpended) {
      handleViewsExpandClick()
    }
    if (isChooseViewExpended) {
      handleChooseViewExpandClick()
    }
  }

  const handleChooseViewExpandClick = () => {
    setChooseViewExpended(!isChooseViewExpended)

    if (isViewSettingExpanded) {
      handleViewSettingExpandClick()
    }
    if (isISOThresholdSettingExpanded) {
      handleISOThresholdSettingExpandClick()
    }
    if (isViewsExpended) {
      handleViewsExpandClick()
    }
    if (isToolsExpanded) {
      handleToolsExpandClick()
    }
  }

  const handleViewsExpandClick = () => {
    setViewsExpended(!isViewsExpended)

    if (isViewSettingExpanded) {
      handleViewSettingExpandClick()
    }
    if (isISOThresholdSettingExpanded) {
      handleISOThresholdSettingExpandClick()
    }

    if (isToolsExpanded) {
      handleToolsExpandClick()
    }

    if (isChooseViewExpended) {
      handleChooseViewExpandClick()
    }
  }
  // function to expand implants
  const handleImplantExpandClick = () => {
    setImplantIsExpanded(!isImplantExpanded)

    if (isViewSettingExpanded) {
      handleViewSettingExpandClick()
    }
  }

  // hanlde selcet implant
  const handleImplantSelect = (implant) => {
    setImplantToken({ token: implant.file.token, description: implant.description })
    setImplantIsExpanded(false)
  }
  // function to expand view setting
  const handleViewSettingExpandClick = () => {
    setViewSettingExpanded(!isViewSettingExpanded)

    if (isToolsExpanded) {
      handleToolsExpandClick() // close tool box
    }
    if (isISOThresholdSettingExpanded) {
      handleISOThresholdSettingExpandClick()
    }
    if (isViewsExpended) {
      handleViewsExpandClick()
    }
  }

  // function to expand view setting
  const handleISOThresholdSettingExpandClick = () => {
    setIsISOThresholdSettingExpanded(!isISOThresholdSettingExpanded)

    if (isToolsExpanded) {
      handleToolsExpandClick() // close tool box
    }
    if (isViewSettingExpanded) {
      handleViewSettingExpandClick()
    }
    if (isViewsExpended) {
      handleViewsExpandClick()
    }
  }

  const onMainViewClick = (currentView, setCurrentView) => {
    if (currentView === 'view4' || currentView.includes('dual')) {
      setCurrentView('view4 whole')
    } else {
      mainViewChooseViewButtonHandler(currentView, setCurrentView)
    }
    handleToolBoxClick('None')
  }

  const onChooseViewHandle = (chosenView) => {
    setChooseView(chosenView)
    setCurrentView('dual ' + chosenView)
    setChooseViewExpended(!isChooseViewExpended)
  }

  const onPanelViewClick = () => {
    if (currentView === 'whole') {
      setCurrentView('dual ' + chooseView)
    }
    handleToolBoxClick('None')
  }

  // function to choose tools in tool box
  const handleToolBoxClick = (toolState) => {
    setShowToolBox(toolState)

    if (isToolsExpanded) {
      handleToolsExpandClick() // close tool box
    }

    if (toolState === 'None') {
      setSegmentConfig({ type: 'None' }) // reset segmentation tool
      setSelectedSegment(1) // reset segmentation label
      setMeasureConfig({ type: 'None' }) // reset measure tool
    }

    if (toolState !== 'Measure') {
      setMeasureConfig({ type: 'None' }) // reset measure tool

      if (toolState === 'Brush') {
        setSegmentConfig({ type: 'Segmentation_' })
      } else if (toolState === 'Eraser') {
        setSegmentConfig({ type: 'Erase_' })
      } else if (toolState === 'Scissors') {
        setSegmentConfig({ type: 'Scissor Seg_' })
      }
    } else {
      setMeasureConfig({ type: 'Measure' }) // reset measure tool
    }

    if (toolState !== 'Brush' && toolState !== 'Eraser' && !toolState?.includes('Scissors')) {
      setSegmentConfig({ type: 'None' }) // reset segmentation tool
      setSelectedSegment(1) // reset segmentation label
    }
  }

  const handleViewBoxClick = (view) => {
    setSelectedView(view)

    if (isViewsExpended) {
      handleViewsExpandClick() // close tool box
    }
  }

  // remove additional tool box when view setting is expanded
  useEffect(() => {
    if (isViewSettingExpanded) {
      if (showToolBox !== 'None') {
        setShowToolBox('None')
        setSegmentConfig({ type: 'None' }) // reset segmentation tool
        setSelectedSegment(1) // reset segmentation label
        setMeasureConfig({ type: 'None' }) // reset measure tool
      }
    }
  }, [isViewSettingExpanded])

  // useEffect to enable or disable label tools in tool box
  useEffect(() => {
    const currentIndex = buttonStates.indexOf(buttonState)
    const segIndex = buttonStates.indexOf('Seg')

    setUndoStack([])
    setRedoStack([])
    setIsRestore(false)

    setMovementsExpanded(false)

    if (currentIndex < segIndex) {
      setisLabelTool(false)
    } else {
      setisLabelTool(true)
    }
    if (buttonState === 'Implant' && !on3DRender.on) {
      mainView3DButtonHandler(on3DRender, setOn3DRender)
    }

    if (buttonState === 'Rep' || buttonState === 'Target') {
      setIsLabelBoxVisible(true)
    }

    if (buttonState === 'Seg' || buttonState === 'Iso') {
      setIsLabelBoxVisible(false)

      if (isVolumeVisible) {
        setIsVolumeVisible(!isVolumeVisible)
      }
    }

    if (buttonState === 'Image') {
      if (!isVolumeVisible) {
        setIsVolumeVisible(!isVolumeVisible)
      }
    }

    if (isViewSettingExpanded) {
      setViewSettingExpanded(!isViewSettingExpanded)
    }

    if (isToolsExpanded) {
      setToolsIsExpanded(!isToolsExpanded)
    }

    if (showToolBox !== 'None') {
      setShowToolBox('None')
      setSegmentConfig({ type: 'None' }) // reset segmentation tool
      setSelectedSegment(1) // reset segmentation label
      setMeasureConfig({ type: 'None' }) // reset measure tool
    }

    // reset implant token
    if (buttonState !== 'Implant') {
      setImplantToken({ token: '', description: '' })
    }

    // reset iso threshold value
    setIsoSliderInfo((prevState) => ({
      ...prevState,
      initialized: false
    }))

    setISOThreshold({
      min: volumeRange.min < 200 ? isoThresholdMinimumDefault : volumeRange.min,
      max: volumeRange.max
    })

    setLabelColorSwitch(true)
    setViewBoxOn(false)

    if (buttonState !== 'Target') {
      setAnimationOn(false)
    } else {
      setAnimationOn(true)
    }
  }, [buttonState])

  useEffect(() => {
    if (animationOn) {
      setIsLabelBoxVisible(false)
    }
  }, [animationOn])

  const handleUndoButton = debounce(() => {
    if (undoStack.length !== 0) {
      setIsUndo(true)
    }
  }, 300)

  const handleRedoButton = debounce(() => {
    if (redoStack.length !== 0) {
      setIsRedo(true)
    }
  }, 300)

  const handleRestoreButton = debounce(() => {
    if (undoStack.length !== 0 || redoStack.length !== 0) {
      setIsRestore(true)
    }
  }, 300)

  return (
    <>
      <Box
        sx={{
          left: theme.sizes.nav.left,
          right: '0px'
        }}
        id="Header"
      >
        {openConfirmDialog && (
          <ConfirmDialog
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            nextButtonType={nextButtonType}
            indication={indication}
            buttonState={buttonState}
            setButtonState={setButtonState}
            buttonStates={buttonStates}
            sdk={sdk}
            isDemo={isDemo}
            setIsLoading={setIsLoading}
            filePath={filePath}
            setFilePath={setFilePath}
            backInfo={backInfo}
            setBackInfo={setBackInfo}
            userInfo={userInfo}
          ></ConfirmDialog>
        )}

        {WarnDialog && (
          <WarnDialog
            open={openWarnDialog}
            setOpen={setOpenWarnDialog}
            warnMessage={warnMessage}
            currentButton={currentButton}
            setButtonState={setButtonState}
            setIsLoading={setIsLoading}
            setLoadingValue={setLoadingValue}
            setCurrentPage={setCurrentPage}
            backInfo={backInfo}
          ></WarnDialog>
        )}

        <Grid
          container
          justifyContent="flex-start"
          spacing={1}
          alignItems="center"
          sx={{
            backgroundColor: theme.palette.background.main,
            padding: '16px'
          }}
        >
          {/* first line of header */}
          <Grid container item>
            <ThemeProvider theme={theme}>
              <SegmentationButton
                filePath={filePath}
                setFilePath={setFilePath}
                setIsLoading={setIsLoading}
                isDemo={isDemo}
                setButtonState={setButtonState}
                backInfo={backInfo}
                setBackInfo={setBackInfo}
                userInfo={userInfo}
                sdk={sdk}
                buttonStates={buttonStates}
                buttonState={buttonState}
                indication={indication}
              />
              {indication !== 'Face' && indication !== 'Shoulder' && (
                <IsolationButton
                  filePath={filePath}
                  setFilePath={setFilePath}
                  setIsLoading={setIsLoading}
                  isDemo={isDemo}
                  setButtonState={setButtonState}
                  backInfo={backInfo}
                  setBackInfo={setBackInfo}
                  userInfo={userInfo}
                  sdk={sdk}
                  buttonStates={buttonStates}
                  buttonState={buttonState}
                />
              )}
              {indication !== 'Shoulder' && (
                <RepositionButton
                  filePath={filePath}
                  setFilePath={setFilePath}
                  setIsLoading={setIsLoading}
                  isDemo={isDemo}
                  setButtonState={setButtonState}
                  backInfo={backInfo}
                  setBackInfo={setBackInfo}
                  userInfo={userInfo}
                  sdk={sdk}
                  buttonStates={buttonStates}
                  buttonState={buttonState}
                />
              )}
              {indication !== 'Shoulder' && (
                <TargetAnatomyButton
                  filePath={filePath}
                  setFilePath={setFilePath}
                  setIsLoading={setIsLoading}
                  isDemo={isDemo}
                  setButtonState={setButtonState}
                  backInfo={backInfo}
                  setBackInfo={setBackInfo}
                  userInfo={userInfo}
                  sdk={sdk}
                  buttonStates={buttonStates}
                  buttonState={buttonState}
                />
              )}
              {currentVersion !== 'first' && (
                <ScrewPositioningButton
                  setButtonState={setButtonState}
                  buttonStates={buttonStates}
                  buttonState={buttonState}
                />
              )}
              <ImplantPositioningButton
                filePath={filePath}
                setFilePath={setFilePath}
                setIsLoading={setIsLoading}
                isDemo={isDemo}
                setButtonState={setButtonState}
                backInfo={backInfo}
                sdk={sdk}
                buttonStates={buttonStates}
                buttonState={buttonState}
              />
              {currentVersion !== 'first' && (
                <ImplantSimulationButton
                  setButtonState={setButtonState}
                  buttonStates={buttonStates}
                  buttonState={buttonState}
                />
              )}
              {currentVersion !== 'first' && (
                <ImplantOptimizationButton
                  setButtonState={setButtonState}
                  buttonStates={buttonStates}
                  buttonState={buttonState}
                />
              )}
            </ThemeProvider>
          </Grid>
          {/* second line of header */}
          <Grid container item sx={{ marginTop: '10px' }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: 'white', marginRight: '0.4em', paddingTop: '0.25rem' }}
            >
              <span style={{ fontWeight: 'bold' }}>Case : </span> {filePath.filename}
            </Typography>
            <Box sx={{ display: 'flex', gap: '10px', marginRight: '1em', marginTop: '5px' }}>
              <BackNextButton
                startIcon={<ArrowBackIosIcon />}
                sx={{ justifyContent: 'space-between', height: '2em', zIndex: 9999 }}
                onClick={() => {
                  if (indication === 'Shoulder') return
                  if (isLoading === true && loadingValue !== 0) {
                    setWarnMessage('Please wait for the ongoing process to complete!')
                    handleWarnDialog(true)
                  } else {
                    isDemo
                      ? mainViewBackButtonHandler(
                          indication,
                          buttonState,
                          setButtonState,
                          buttonStates,
                          sdk,
                          isDemo,
                          setIsLoading,
                          filePath,
                          setFilePath,
                          backInfo,
                          setBackInfo,
                          userInfo,
                          setLoadingValue
                        )
                      : handleClickOpen('Back')
                  }
                }}
              >
                Back
              </BackNextButton>
              <BackNextButton
                endIcon={<ArrowForwardIosIcon />}
                sx={{ justifyContent: 'space-between', height: '2em', zIndex: 9999 }}
                onClick={() => {
                  if (indication === 'Shoulder') return
                  if (isLoading === true && loadingValue !== 0) {
                    setWarnMessage('Please wait for the ongoing process to complete!')
                    handleWarnDialog(true)
                  } else {
                    isDemo
                      ? mainViewNextButtonHandler(
                          indication,
                          buttonState,
                          setButtonState,
                          buttonStates,
                          sdk,
                          isDemo,
                          setIsLoading,
                          filePath,
                          setFilePath,
                          backInfo,
                          setBackInfo,
                          userInfo,
                          setLoadingValue
                        )
                      : handleClickOpen('Next')
                  }
                }}
              >
                Next
              </BackNextButton>
            </Box>

            {buttonState !== 'Target' && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '1em',
                  marginRight: '1em',
                  marginTop: '5px',
                  zIndex: 9999
                }}
              >
                <MainViewSmallButton
                  id="undo"
                  onClick={handleUndoButton}
                  disabled={buttonState === 'Implant' && implantToken.token === ''} // Disable when conditions are met
                  startIcon={
                    <TurnLeftIcon
                      sx={{
                        color: '#FFFFFF',
                        marginLeft: '10px',
                        // Apply blur if buttonState is 'Implant' and implantToken.token is empty or undoStack is empty
                        ...((buttonState === 'Implant' && implantToken.token === '') ||
                        undoStack.length === 0
                          ? { filter: 'blur(3px)' }
                          : {})
                      }}
                    />
                  }
                />

                <MainViewSmallButton
                  id="redo"
                  onClick={handleRedoButton}
                  disabled={buttonState === 'Implant' && implantToken.token === ''} // Disable when conditions are met
                  startIcon={
                    <TurnRightIcon
                      sx={{
                        color: '#FFFFFF',
                        marginLeft: '10px',
                        // Apply blur if buttonState is 'Implant' and implantToken.token is empty or redoStack is empty
                        ...((buttonState === 'Implant' && implantToken.token === '') ||
                        redoStack.length === 0
                          ? { filter: 'blur(3px)' }
                          : {})
                      }}
                    />
                  }
                />

                <MainViewSmallButton
                  id="restore"
                  onClick={handleRestoreButton}
                  disabled={buttonState === 'Implant' && implantToken.token === ''} // Disable when conditions are met
                  startIcon={
                    <RestoreIcon
                      sx={{
                        color: '#FFFFFF',
                        marginLeft: '5px',
                        // Apply blur if buttonState is 'Implant' and implantToken.token is empty, and if both stacks are empty
                        ...((buttonState === 'Implant' && implantToken.token === '') ||
                        (undoStack.length === 0 && redoStack.length === 0)
                          ? { filter: 'blur(3px)' }
                          : {})
                      }}
                    />
                  }
                />
              </Box>
            )}

            {buttonState !== 'Rep' && buttonState !== 'Target' && buttonState !== 'Implant' && (
              <Box sx={{ display: 'flex', marginRight: '1em', marginTop: '5px', zIndex: 9999 }}>
                <MainViewSmallButton
                  id="chooseView"
                  startIcon={
                    <GridViewIcon
                      sx={{
                        color: '#FFFFFF',
                        marginLeft: '5px',
                        filter: currentView === 'whole' ? 'blur(4px)' : 'none'
                      }}
                    />
                  }
                  onClick={() => onMainViewClick(currentView, setCurrentView)}
                ></MainViewSmallButton>
              </Box>
            )}

            {buttonState !== 'Rep' && buttonState !== 'Target' && buttonState !== 'Implant' && (
              <Box sx={{ display: 'flex', marginRight: '1em', marginTop: '5px', zIndex: 9999 }}>
                <MainViewSmallButton
                  id="panelView"
                  startIcon={
                    <SplitscreenIcon
                      sx={{
                        color: '#FFFFFF',
                        marginLeft: '5px',
                        transform: 'rotate(90deg)',
                        filter: currentView === 'whole' ? 'none' : 'blur(4px)'
                      }}
                    />
                  }
                  onClick={() => onPanelViewClick()}
                ></MainViewSmallButton>
              </Box>
            )}

            <Box sx={{ display: 'flex', marginTop: '5px', gap: '1em' }}>
              {false &&
                buttonState !== 'Rep' &&
                buttonState !== 'Target' &&
                buttonState !== 'Implant' && (
                  <MainViewSmallButton
                    id="2DView"
                    sx={{
                      color: 'white',
                      zIndex: 9999,
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '20px'
                    }}
                  >
                    2D
                  </MainViewSmallButton>
                )}
              {buttonState !== 'Rep' &&
                buttonState !== 'Target' &&
                buttonState !== 'Implant' &&
                currentView !== 'view1' &&
                currentView !== 'view2' &&
                currentView !== 'view3' && (
                  <MainViewSmallButton
                    id="3DView"
                    sx={{
                      color: 'white',
                      background: on3DRender.on ? '#2C622C' : '#232323',
                      zIndex: 9999,
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '20px'
                    }}
                    onClick={() => mainView3DButtonHandler(on3DRender, setOn3DRender)}
                  >
                    3D
                  </MainViewSmallButton>
                )}
              {!isDemo && buttonState !== 'Target' && buttonState !== 'Implant' && (
                <MainViewDownloadButton
                  sdk={sdk}
                  backInfo={backInfo}
                  buttonState={buttonState}
                  label={label}
                  setIsSaving={setIsSaving}
                  filePath={filePath}
                  setFilePath={setFilePath}
                  repoScene={repoScene}
                />
              )}
              {buttonState !== 'Image' &&
                buttonState !== 'Rep' &&
                buttonState !== 'Target' &&
                buttonState !== 'Implant' && (
                  <MainViewSmallButton
                    id="segmentation"
                    sx={{
                      color: 'white',
                      background: isSegmentationVisible ? '#2C622C' : '#232323',
                      zIndex: 9999,
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '20px',
                      width: '100px'
                    }}
                    onClick={() =>
                      mainViewSegmentationButtonHandler(
                        isSegmentationVisible,
                        setIsSegmentationVisible
                      )
                    }
                  >
                    segmentation
                  </MainViewSmallButton>
                )}
              {buttonState !== 'Image' &&
                buttonState !== 'Rep' &&
                buttonState !== 'Target' &&
                buttonState !== 'Implant' &&
                on3DRender.on && (
                  <MainViewSmallButton
                    id="volume"
                    sx={{
                      color: 'white',
                      background: isVolumeVisible ? '#2C622C' : '#232323',
                      zIndex: 9999,
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '20px',
                      width: '100px'
                    }}
                    onClick={() => mainViewVolumeButtonHandler(isVolumeVisible, setIsVolumeVisible)}
                  >
                    volume
                  </MainViewSmallButton>
                )}

              {/* label box button */}
              {buttonState !== 'Image' && (
                <MainViewSmallButton
                  id="labelBox"
                  sx={{
                    color: 'white',
                    background: isLabelBoxVisible ? '#2C622C' : '#232323',
                    zIndex: 9999,
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '20px',
                    width: '100px'
                  }}
                  onClick={() =>
                    mainViewLabelBoxButtonHandler(isLabelBoxVisible, setIsLabelBoxVisible)
                  }
                >
                  label box
                </MainViewSmallButton>
              )}
              {buttonState !== 'Image' && isLabelBoxVisible === true && (
                <MainViewLabelBox
                  label={label}
                  setSelectedSegment={setSelectedSegment}
                  originalLabelID={originalLabelID}
                  setOriginalLabelID={setOriginalLabelID}
                  buttonState={buttonState}
                  filePath={filePath}
                  setShowLabelBox={setIsLabelBoxVisible}
                  indication={indication}
                  labelColorSwitch={labelColorSwitch}
                  setLabelColorSwitch={setLabelColorSwitch}
                />
              )}
            </Box>

            {/* additional gui box for each tool */}
            {!isToolsExpanded && showToolBox === 'Measure' && (
              <MainViewMeasureToolBox
                setMeasureConfig={setMeasureConfig}
                labelBoxMargin={isLabelBoxVisible ? 35 : 0}
                setShowToolBox={setShowToolBox}
              />
            )}
            {!isToolsExpanded && showToolBox === 'Brush' && (
              <MainViewBrushToolBox
                Radius={Radius}
                setRadius={setRadius}
                volumeRange={volumeRange}
                SetLabelThresholdRange={SetLabelThresholdRange}
                labelBoxMargin={isLabelBoxVisible ? 35 : 0}
                setShowToolBox={setShowToolBox}
              />
            )}

            {!isToolsExpanded && showToolBox === 'Eraser' && (
              <MainViewEraserToolBox
                Radius={Radius}
                setRadius={setRadius}
                labelBoxMargin={isLabelBoxVisible ? 35 : 0}
                setShowToolBox={setShowToolBox}
              />
            )}

            {!isToolsExpanded && showToolBox === 'Scissors' && (
              <MainViewScissorsToolBox
                setSegmentConfig={setSegmentConfig}
                volumeRange={volumeRange}
                SetLabelThresholdRange={SetLabelThresholdRange}
                labelBoxMargin={isLabelBoxVisible ? 35 : 0}
                setShowToolBox={setShowToolBox}
              />
            )}

            {!isToolsExpanded && showToolBox === 'intensity' && (
              <MainViewIntensityToolBox
                handleViewBoxClick={handleViewBoxClick}
                setShowToolBox={setShowToolBox}
                handleViewsExpandClick={handleViewsExpandClick}
              />
            )}

            {isMovementExpanded && (buttonState === 'Implant' || buttonState === 'Rep') && (
              <MainViewMovementBox
                setMovementConfig={setMovementConfig}
                labelBoxMargin={isLabelBoxVisible ? 35 : 0}
                setShowMovementBox={setMovementsExpanded}
                setMovementActivation={setMovementActivation}
                currentMovementMenu={currentMovementMenu}
                setCurrentMovementMenu={setCurrentMovementMenu}
                centerPoint={centerPoint}
                setCenterPoint={setCenterPoint}
                autoPlacementInfo={autoPlacementInfo}
                setAutoPlacementInfo={setAutoPlacementInfo}
              />
            )}

            {currentView.includes('dual') && (
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'column',
                  marginRight: '20px',
                  zIndex: 9999
                }}
              >
                <VerticalIconButton
                  onClick={handleChooseViewExpandClick}
                  expanded={isChooseViewExpended.toString()}
                  sx={{ width: '10em' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '20px'
                    }}
                  >
                    Choose View
                    <ExpandMoreIcon sx={{ marginLeft: '10px' }} />
                  </Box>
                </VerticalIconButton>
                {isChooseViewExpended && (
                  <Box sx={{ zIndex: 9998 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '16px 0px',
                        gap: '16px',
                        width: '240px',
                        background: '#232323',
                        boxShadow: '0px 4px 20px rgba(255, 255, 255, 0.25)',
                        borderRadius: '8px',
                        position: 'fixed',
                        marginTop: '10px'
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                          color: '#FFFFFF',
                          fontFamily: 'Open Sans',
                          fontWeight: 400,
                          fontSize: '12px',
                          lineHeight: '20px',
                          flex: 'none',
                          order: 0,
                          flexGrow: 0
                        }}
                      >
                        Choose View
                      </Typography>

                      {['Axial', 'Coronal', 'Sagittal'].map((view) => (
                        <Box
                          key={view}
                          onClick={() => onChooseViewHandle(view)}
                          sx={{
                            cursor: 'pointer',
                            padding: '10px',
                            borderRadius: '4px',
                            width: '90%', // Ensuring the clickable area is full width
                            background: chooseView === view ? '#91C58E' : 'transparent',
                            '&:hover': {
                              background: '#91C58E' // Full highlight color on hover
                            }
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              color: '#FFFFFF',
                              fontWeight: 400,
                              fontSize: '12px',
                              lineHeight: '20px',
                              textAlign: 'center', // Centering text within each clickable box
                              width: '100%' // Full width to center text properly
                            }}
                          >
                            {view} View
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {/* tool box */}
            {buttonState != 'Rep' && buttonState != 'Target' && buttonState != 'Implant' && (
              <Box
                sx={{ display: 'flex', marginTop: '5px', flexDirection: 'column', zIndex: 9999 }}
              >
                <VerticalIconButton
                  onClick={handleToolsExpandClick}
                  expanded={isToolsExpanded.toString()}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '20px'
                    }}
                  >
                    <BrushIcon sx={{ marginRight: '10px' }} />
                    Tools
                    <ExpandMoreIcon sx={{ marginLeft: '10px' }} />
                  </Box>
                </VerticalIconButton>
                {isToolsExpanded && (
                  <Box sx={{ zIndex: 9998 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '16px 0px',
                        gap: '16px',
                        width: '240px',
                        height: '380px',
                        background: '#232323',
                        boxShadow: '0px 4px 20px rgba(255, 255, 255, 0.25)',
                        borderRadius: '8px',
                        position: 'fixed',
                        marginTop: '10px'
                      }}
                    >
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{
                          color: '#FFFFFF',
                          fontFamily: 'Open Sans',
                          fontWeight: 400,
                          fontSize: '12px',
                          lineHeight: '20px',
                          flex: 'none',
                          order: 0,
                          flexGrow: 0
                        }}
                      >
                        Tools
                      </Typography>

                      <Button
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            background: 'rgba(217, 217, 217, 0.16)',
                            borderRadius: '4px'
                          }
                        }}
                        onClick={() => handleToolBoxClick('None')}
                      >
                        <NearMeIcon
                          sx={{
                            order: 1,
                            color: '#FFFFFF',
                            marginRight: '10px',
                            transform: 'scaleX(-1)'
                          }}
                        />
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{
                            color: '#FFFFFF',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '20px',
                            flex: 'none',
                            order: 2,
                            flexGrow: 0,
                            marginRight: '10px',
                            marginTop: '5px'
                          }}
                        >
                          Pointer
                        </Typography>
                        <InfoIcon sx={{ order: 3, color: '#FFFFFF', fontSize: '12px' }} />
                      </Button>

                      <Button
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            background: 'rgba(217, 217, 217, 0.16)',
                            borderRadius: '4px'
                          }
                        }}
                        onClick={() => handleToolBoxClick('Measure')}
                      >
                        <StraightenIcon
                          sx={{
                            order: 1,
                            color: '#FFFFFF',
                            marginRight: '10px',
                            transform: 'scaleX(-1)'
                          }}
                        />
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{
                            color: '#FFFFFF',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '20px',
                            flex: 'none',
                            order: 2,
                            flexGrow: 0,
                            marginRight: '10px',
                            marginTop: '5px'
                          }}
                        >
                          Measurement
                        </Typography>
                        <InfoIcon sx={{ order: 3, color: '#FFFFFF', fontSize: '12px' }} />
                      </Button>

                      {allViewLoaded && (
                        <>
                          <Button
                            disabled={!isLabelTool} // Disable the button when isLabel is false
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              '&:hover': {
                                background: 'rgba(217, 217, 217, 0.16)',
                                borderRadius: '4px'
                              }
                            }}
                            onClick={() => handleToolBoxClick('Brush')}
                          >
                            <BrushIcon
                              sx={{
                                order: 1,
                                color: '#FFFFFF',
                                marginRight: '10px',
                                filter: isLabelTool ? 'none' : 'blur(2px)' // Apply blur when isLabel is false
                              }}
                            />
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{
                                color: '#FFFFFF',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '20px',
                                flex: 'none',
                                order: 2,
                                flexGrow: 0,
                                marginRight: '10px',
                                marginTop: '5px',
                                filter: isLabelTool ? 'none' : 'blur(2px)' // Apply blur when isLabel is false
                              }}
                            >
                              Brush
                            </Typography>
                            <InfoIcon
                              sx={{
                                order: 3,
                                color: '#FFFFFF',
                                fontSize: '12px',
                                filter: isLabelTool ? 'none' : 'blur(2px)' // Apply blur when isLabel is false
                              }}
                            />
                          </Button>

                          <Button
                            disabled={!isLabelTool} // Disable the button when isLabel is false
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              '&:hover': {
                                background: 'rgba(217, 217, 217, 0.16)',
                                borderRadius: '4px'
                              }
                            }}
                            onClick={() => handleToolBoxClick('Scissors')}
                          >
                            <ContentCutIcon
                              sx={{
                                order: 1,
                                color: '#FFFFFF',
                                marginRight: '10px',
                                filter: isLabelTool ? 'none' : 'blur(2px)' // Apply blur when isLabel is false
                              }}
                            />
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{
                                color: '#FFFFFF',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '20px',
                                flex: 'none',
                                order: 2,
                                flexGrow: 0,
                                marginRight: '10px',
                                marginTop: '5px',
                                filter: isLabelTool ? 'none' : 'blur(2px)' // Apply blur when isLabel is false
                              }}
                            >
                              Scissors
                            </Typography>
                            <InfoIcon
                              sx={{
                                order: 3,
                                color: '#FFFFFF',
                                fontSize: '12px',
                                filter: isLabelTool ? 'none' : 'blur(2px)' // Apply blur when isLabel is false
                              }}
                            />
                          </Button>

                          <Button
                            disabled={!isLabelTool} // Disable the button when isLabel is false
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              '&:hover': {
                                background: 'rgba(217, 217, 217, 0.16)',
                                borderRadius: '4px'
                              }
                            }}
                            onClick={() => handleToolBoxClick('Eraser')}
                          >
                            <AutoFixHighIcon
                              sx={{
                                order: 1,
                                color: '#FFFFFF',
                                marginRight: '10px',
                                filter: isLabelTool ? 'none' : 'blur(2px)' // Apply blur when isLabel is false
                              }}
                            />
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{
                                color: '#FFFFFF',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '20px',
                                flex: 'none',
                                order: 2,
                                flexGrow: 0,
                                marginRight: '10px',
                                marginTop: '5px',
                                filter: isLabelTool ? 'none' : 'blur(2px)' // Apply blur when isLabel is false
                              }}
                            >
                              Eraser
                            </Typography>
                            <InfoIcon
                              sx={{
                                order: 3,
                                color: '#FFFFFF',
                                fontSize: '12px',
                                filter: isLabelTool ? 'none' : 'blur(2px)' // Apply blur when isLabel is false
                              }}
                            />
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {(buttonState == 'Implant' || buttonState === 'Rep') && (
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'column',
                  zIndex: 9999,
                  width: '11rem'
                }}
              >
                <VerticalIconButton
                  id="Implant Movement"
                  sx={{
                    color: 'white',
                    background: isMovementExpanded ? '#2C622C' : '#232323',
                    zIndex: 9999,
                    width: '100%',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '20px',
                    // Apply blur effect if buttonState is 'Implant' and implantToken.token is empty
                    ...(buttonState === 'Implant' && implantToken.token === ''
                      ? { filter: 'blur(3px)' }
                      : {})
                  }}
                  onClick={() => setMovementsExpanded(!isMovementExpanded)}
                  // Disable button if buttonState is 'Implant' and implantToken.token is empty
                  disabled={buttonState === 'Implant' && implantToken.token === ''}
                >
                  Movement Type
                </VerticalIconButton>
                {false && (
                  <VerticalIconButton
                    id="Implant Rotation"
                    sx={{
                      color: 'white',
                      background: isRotationExpanded ? '#2C622C' : '#232323',
                      zIndex: 9999,
                      width: '100%',
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '20px'
                    }}
                    onClick={() => setRotationsExpanded(!isRotationExpanded)}
                  >
                    Implant Rotation
                  </VerticalIconButton>
                )}
              </Box>
            )}
            {/* implant tool */}
            {buttonState === 'Implant' && (
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '5px',
                  marginLeft: '1.5rem',
                  flexDirection: 'column',
                  zIndex: 9999
                }}
              >
                <VerticalIconButton
                  onClick={handleImplantExpandClick}
                  expanded={isToolsExpanded.toString()}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontWeight: 400,
                      fontSize: '12px',
                      lineHeight: '20px'
                    }}
                  >
                    <ChangeCircleIcon sx={{ marginRight: '10px' }} />
                    Implant
                    <ExpandMoreIcon sx={{ marginLeft: '10px' }} />
                  </Box>
                </VerticalIconButton>
                <SelectImplant
                  sdk={sdk}
                  handleImplantSelect={handleImplantSelect}
                  isImplantExpanded={isImplantExpanded}
                  indication={indication}
                />
              </Box>
            )}

            <Box sx={{ display: 'flex', marginTop: '5px', flexDirection: 'column', zIndex: 9999 }}>
              <VerticalIconButton
                onClick={handleViewSettingExpandClick}
                expanded={isViewSettingExpanded.toString()}
                sx={{ width: '12em' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '20px'
                  }}
                >
                  <VisibilityIcon sx={{ marginRight: '10px' }} />
                  View Settings
                  <ExpandMoreIcon sx={{ marginLeft: '10px' }} />
                </Box>
              </VerticalIconButton>
              {isViewSettingExpanded && (
                <Box sx={{ zIndex: 9998 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      padding: '16px 0px',
                      gap: '16px',
                      width: '289px',
                      background: '#232323',
                      boxShadow: '0px 4px 20px rgba(255, 255, 255, 0.25)',
                      borderRadius: '8px',
                      position: 'fixed',
                      marginTop: '10px'
                    }}
                  >
                    {buttonState !== 'Rep' &&
                      buttonState !== 'Target' &&
                      buttonState !== 'Implant' && (
                        <MainViewBrightContrast
                          brightness={brightness}
                          setBrightness={setBrightness}
                          contrast={contrast}
                          setContrast={setContrast}
                        />
                      )}
                    {buttonState !== 'Rep' &&
                      buttonState !== 'Target' &&
                      buttonState !== 'Implant' &&
                      currentView !== 'view1' &&
                      currentView !== 'view2' &&
                      currentView !== 'view3' &&
                      on3DRender.on && (
                        <ISOSlider
                          setISOThreshold={setISOThreshold}
                          ISOThreshold={ISOThreshold}
                          volumeRange={volumeRange}
                          isoSliderInfo={isoSliderInfo}
                          setIsoSliderInfo={setIsoSliderInfo}
                        />
                      )}
                    {currentView !== 'view1' &&
                      currentView !== 'view2' &&
                      currentView !== 'view3' &&
                      on3DRender.on && (
                        <MainView3DViewBox viewBoxOn={viewBoxOn} setViewBoxOn={setViewBoxOn} />
                      )}
                    {currentView !== 'view1' &&
                      currentView !== 'view2' &&
                      currentView !== 'view3' &&
                      on3DRender.on && (
                        <MainViewRotateSpeed
                          rotateSpeed={rotateSpeed}
                          setRotateSpeed={setRotateSpeed}
                        />
                      )}

                    {buttonState !== 'Rep' &&
                      buttonState !== 'Target' &&
                      buttonState !== 'Implant' &&
                      currentView === 'whole' && (
                        <MainViewCrossHairs
                          crosshairsOn={crosshairsOn}
                          setCrosshairsOn={setCrosshairsOn}
                        ></MainViewCrossHairs>
                      )}
                    {buttonState !== 'Rep' &&
                      buttonState !== 'Target' &&
                      buttonState !== 'Implant' &&
                      allViewLoaded && (
                        <Button
                          sx={{
                            display: 'flex',
                            alignItems: 'center', // Ensures vertical center alignment
                            justifyContent: 'center', // Ensures horizontal center alignment
                            width: '100%', // Ensures the button uses the full width of its container
                            '&:hover': {
                              background: 'rgba(217, 217, 217, 0.16)',
                              borderRadius: '4px'
                            }
                          }}
                          onClick={() => handleToolBoxClick('intensity')}
                        >
                          <PreviewIcon
                            sx={{
                              order: 1,
                              color: '#FFFFFF',
                              marginRight: '10px', // Adjust or remove as needed to fine-tune spacing
                              transform: 'scaleX(-1)'
                            }}
                          />
                          <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                              color: '#FFFFFF',
                              fontWeight: 400,
                              fontSize: '12px',
                              lineHeight: '20px',
                              flex: 'none',
                              order: 2,
                              flexGrow: 0,
                              marginRight: '10px', // Adjust or remove as needed to fine-tune spacing
                              marginTop: '5px'
                            }}
                          >
                            Intensity Windowing
                          </Typography>
                          <InfoIcon
                            sx={{
                              order: 3,
                              color: '#FFFFFF',
                              fontSize: '12px'
                            }}
                          />
                        </Button>
                      )}
                  </Box>
                </Box>
              )}
            </Box>

            {buttonState === 'Target' && (
              <MainViewAnimationSwitchButton
                animationOn={animationOn}
                setAnimationOn={setAnimationOn}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      {/* save button to save modified label data into backend server */}
      {!isDemo && buttonState != 'Image' && buttonState != 'Target' && buttonState != 'Implant' && (
        <MainViewSaveButton
          sdk={sdk}
          backInfo={backInfo}
          buttonState={buttonState}
          label={label}
          setIsSaving={setIsSaving}
          filePath={filePath}
          setFilePath={setFilePath}
          repoScene={repoScene}
        ></MainViewSaveButton>
      )}

      {
        <MainViewCameraResetButton
          isDemo={isDemo}
          buttonState={buttonState}
          setCameraResetClicked={setCameraResetClicked}
        ></MainViewCameraResetButton>
      }
    </>
  )
}

export default Header
