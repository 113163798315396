/* eslint-disable react/prop-types */
import React, { useCallback } from 'react'
import * as THREE from 'three'
import { TrackballControls } from './TrackballControlls'

import { useEffect, useRef, useState } from 'react'
import Stats from 'stats.js'
import { removeObject } from './SegmentToolHandler'
// import { loadLabels } from '../functions/labelPositioning/LoadLabels'
import { addLights } from '../functions/labelPositioning/AddLights'

import { TransformControls } from 'three/addons/controls/TransformControls.js'
import { addSphere } from './ScissorToolHandler3D'
// import { colorMapArray } from './ColorMap'

import { colorMapArray } from './ColorMap.jsx'
import { moveObject } from '../functions/implantPositioning/MoveImplant'
import { useContext } from 'react'
import { UserContext } from '../../../App'

import {
  addBoundingBoxAndLabels,
  toggleBoundingBoxVisibility,
  calculateNewCameraPosition
} from './ViewBoxUtils.jsx'

import requestAnimationFrame from 'dat.gui/src/dat/utils/requestAnimationFrame'

const RenderingThreeSceneReposition = ({
  mainGUIRef: mainGUIRef,
  filePath: filePath,
  buttonState: buttonState,
  movementConfig: movementConfig,
  viewBoxRef4: viewBoxRef4,
  stats: stats,
  checkedValues: checkedValues,
  selectedValue: selectedValue,
  setSelectedValue: setSelectedValue,
  setRepoScene: setRepoScene,
  isRedo: isRedo,
  setIsRedo: setIsRedo,
  isUndo: isUndo,
  setIsUndo: setIsUndo,
  redoStack: redoStack,
  setRedoStack: setRedoStack,
  undoStack: undoStack,
  setUndoStack: setUndoStack,
  setMovementConfig: setMovementConfig,
  setCurrentMovementMenu: setCurrentMovementMenu,
  centerPoint: centerPoint,
  labelColorSwitch: labelColorSwitch,
  cameraResetClicked: cameraResetClicked,
  setCameraResetClicked: setCameraResetClicked,
  isRestore: isRestore,
  setIsRestore: setIsRestore,
  rotationVector: rotationVector,
  setRotationVector: setRotationVector,
  viewBoxOn: viewBoxOn,
  setIsLoading: setIsLoading,
  repLoadedObjects: repLoadedObjects,
  rotateSpeed: rotateSpeed
}) => {
  const containerRef = useRef(null)
  const scene = useRef(new THREE.Scene())
  const cameraRef = useRef()
  const rendererRef = useRef()
  const controlsRef = useRef()
  const initFinished = useRef(false)
  const [initFinishedState, setInitFinishedState] = useState(false)
  const transformer_init = useRef(false)
  const transformer = useRef(null)
  const transformDict = useRef(null)
  const [checkingTrigger, setCheckingTrigger] = useState(false)
  const [currentState, setCurrentState] = useState(null)
  const [initBool, setInitBool] = useState(false)
  const [controllerPoint, setControllerPoint] = useState({
    point: null,
    state: null,
    active: false,
    init: false,
    label: null,
    mode: null
  })

  const [initialCameraState, setInitialCameraState] = useState({
    set: false,
    camera: null
  })

  var pivot = new THREE.Group()
  pivot.name = 'PivotGroupObject'

  const { setObjectPositionInfo, objectPositionInfo, setManualRepositionMatrix } =
    useContext(UserContext)
  const [keyDown, setKeyDown] = useState(false)

  const [objectsLoaded, setObjectsLoaded] = useState({})
  const cameraPositionsRef = useRef({})
  const boundingBoxMeshRef = useRef(null)

  let lastTime = useRef(Date.now())
  const renderLoopRef = useRef(null)
  const fps = 30
  const interval = 1000 / fps

  function tick() {
    const now = Date.now()
    const elapsed = now - lastTime.current

    if (elapsed > interval) {
      lastTime.current = now - (elapsed % interval)

      if (stats != null) {
        stats.update()
      }

      // Ensure trackball controls are updated in the animation loop
      if (controlsRef.current != null) {
        controlsRef.current.update()
      }

      render()
    }

    requestAnimationFrame(tick)
  }

  function animate() {
    if (renderLoopRef.current === null) {
      renderLoopRef.current = requestAnimationFrame(tick)
      console.debug('render loop initiated')
    } else {
      console.debug('render loop already initiated, ignoring')
    }
  }

  function render() {
    if (rendererRef && scene && cameraRef) {
      rendererRef.current.render(scene.current, cameraRef.current)
    }
  }

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj))
  }

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false
      }
    }
    return true
  }

  function areObjectsEqual(obj1, obj2) {
    if (obj1 == null || obj2 == null) {
      console.log('objs are null')
      return false
    }
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) {
      console.log('keylenghts are not equal')
      return false
    }

    for (const key of keys1) {
      const value1 = obj1[key]
      const value2 = obj2[key]

      if (Array.isArray(value1) && Array.isArray(value2)) {
        // Compare two arrays
        if (!arraysAreEqual(value1, value2)) {
          return false
        }
      } else {
        // Compare non-array values
        if (value1 !== value2) {
          console.log('values are diff', value1, value2)
          return false
        }
      }
    }

    return true
  }

  function getObjectByName(scene, name) {
    let desiredObject = null

    // Traverse the entire scene
    scene.traverse(function (object) {
      if (object.name === name) {
        desiredObject = object
      }
    })
    return desiredObject
  }

  function calcTransformationMatrix(object) {
    // Make sure the matrix is updated

    object.updateWorldMatrix()

    /* because we attach the object to a groupObject in
    case of "object Point" we need to use the world Matrix
    because the matrix is constant inside the group object*/

    const matrix = object.matrixWorld

    //let matrix4x4 = convertArray16ToArray4x4(matrix.elements)
    return matrix.elements
  }

  function calculateTransformMatrices() {
    let transformDict = {}
    Object.keys(filePath.repositionObjects).forEach((objectName) => {
      var object = getObjectByName(scene.current, objectName)

      if (object === null) {
        console.log('something wrong for name', objectName)
        console.log(scene)
      }
      transformDict[objectName] = calcTransformationMatrix(object)
    })
    return transformDict
  }

  function applyTransformMatrices(trDict) {
    // console.log("trDict: ", trDict)
    Object.keys(trDict).forEach((objectName) => {
      var object = getObjectByName(scene.current, objectName)

      if (object === null) {
        console.log('something wrong for name', objectName)
        console.log(scene)
      }
      let new_matrix_elements = trDict[objectName]

      let position = new THREE.Vector3()
      let quaternion = new THREE.Quaternion()
      let scale = new THREE.Vector3()
      new THREE.Matrix4().fromArray(new_matrix_elements).decompose(position, quaternion, scale)
      object.position.copy(position)
      object.quaternion.copy(quaternion)
      object.scale.copy(scale)
      object.updateMatrix()
    })
    animate()
  }

  function getCenterOfObject(object) {
    var box = new THREE.Box3().setFromObject(object)
    var worldCenter = new THREE.Vector3()
    box.getCenter(worldCenter)
    var localCenter = new THREE.Vector3()
    localCenter.copy(worldCenter)
    object.worldToLocal(localCenter)

    return localCenter
  }

  // function repositionObjectPositionToGeoCenter(filePath, scene, setObjectsLoaded) {
  //   Object.keys(filePath.repositionObjects).forEach((objectName) => {
  //     let object = getObjectByName(scene.current, objectName)
  //     var box = new THREE.Box3().setFromObject(object)
  //     var worldCenter = new THREE.Vector3()
  //     box.getCenter(worldCenter)
  //     // Calculate the offset between the mesh's position and the center of the bounding box
  //     const offSet = new THREE.Vector3()
  //     offSet.subVectors(worldCenter, object.position)
  //     const geometry = object.geometry
  //     geometry.translate(-offSet.x, -offSet.y, -offSet.z)
  //     // Move only the mesh to align its position with the center of its geometry
  //     object.position.copy(worldCenter)

  //     setObjectsLoaded(objectName)
  //   })
  // }

  function extractNumberFromString(str) {
    const matches = str.match(/\d+/)
    return matches ? parseInt(matches[0], 10) : null
  }

  function logFirstIntersectedObjectName(event) {
    const raycaster = new THREE.Raycaster()
    const mouse = new THREE.Vector2()
    let bbox = event.currentTarget.getBoundingClientRect()
    let objectNum = null
    let element = null
    mouse.x = ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1
    mouse.y = -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1
    raycaster.setFromCamera(mouse, cameraRef.current)

    // Calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(scene.current.children, true)

    // Log the name of the first intersected object, if any
    if (intersects.length > 0) {
      for (let i = 0; i < intersects.length; i++) {
        element = intersects[i]
        if (element.object.name.indexOf('label') !== -1) {
          objectNum = extractNumberFromString(element.object.name)
          break
        }
      }
    } else {
      console.log('No objects intersected')
    }
    return objectNum
  }

  function onPointerDown(event) {
    if (transformer.current === null || movementConfig.type == 'none' || event.ctrlKey) {
      let intersectedObject = logFirstIntersectedObjectName(event)
      if (intersectedObject != null) {
        setSelectedValue(intersectedObject)
        if (selectedValue != null) {
          let newLabel = 'label' + selectedValue.toString()
          setControllerPoint((prevState) => ({
            ...prevState,
            label: newLabel
          }))
        }
      }
    }
  }

  // function createWireFrame(object, scene, color = 'green') {
  //   const boxHelper = new THREE.BoxHelper(object, new THREE.Color(color))
  //   scene.current.add(boxHelper)
  // }
  // createWireFrame

  const [attachmentBool, setAttachmentBool] = useState(false)

  function definePointOnObject(event) {
    if (!centerPoint) {
      const raycaster = new THREE.Raycaster()
      const mouse = new THREE.Vector2()
      let bbox = event.currentTarget.getBoundingClientRect()

      mouse.x = ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1
      mouse.y = -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1
      raycaster.setFromCamera(mouse, cameraRef.current)

      const intersectsObjects = raycaster.intersectObjects(scene.current.children)
      if (intersectsObjects.length != 0 && !controllerPoint.active) {
        var interSectedObject = intersectsObjects[0]
        removeObject(scene.current, 'pointSphere')
        addSphere(interSectedObject.point, scene.current, 'white', 'pointSphere', 1)
        setControllerPoint((prevState) => ({
          ...prevState,
          point: interSectedObject.point.clone()
        }))
        if (initFinished.current && selectedValue !== null && movementConfig.type !== 'none') {
          if (transformer.current !== null) {
            transformer.current.detach()
            removeObject(scene.current, '3DLabelController')
          }
          if (!transformer_init.current) {
            console.log('called')
            removeObject(scene.current, '3DLabelController')
            transformer.current = new TransformControls(
              cameraRef.current,
              rendererRef.current.domElement
            )
            let objName = 'label' + selectedValue.toString()
            let object = getObjectByName(scene.current, objName)

            if (object !== null) {
              var transformerPosition = new THREE.Vector3()
              transformerPosition = interSectedObject.point.clone()

              pivot.position.copy(transformerPosition)
              scene.current.add(pivot)

              var newObjectPositionPivot = new THREE.Vector3()
              newObjectPositionPivot.subVectors(transformerPosition, object.position)

              pivot.add(object)
              object.position.copy(newObjectPositionPivot)
              object.position.multiplyScalar(-1)

              setAttachmentBool(true)

              transformer.current.setSpace('local')
              transformer.current.mode = movementConfig.type || 'translate'
              transformer.current.size = 0.75
              transformer.current.name = '3DLabelController'
              transformer.current.attach(pivot)

              var sphereObject = scene.current.getObjectByName('pointSphere')

              transformer.current.addEventListener('change', function () {
                // Manually check and adjust object's position, rotation, or scale.
                sphereObject.position.copy(pivot.position)
                animate()
              })

              transformer.current.addEventListener('mouseDown', function () {
                transformDict.current = deepCopy(calculateTransformMatrices())
                controlsRef.current.enabled = false
              })

              transformer.current.addEventListener('mouseUp', function () {
                controlsRef.current.enabled = true

                if (!areObjectsEqual(transformDict.current, calculateTransformMatrices())) {
                  if (undoStack.length === 0) {
                    setCurrentState(deepCopy(transformDict.current))
                  }
                  setCheckingTrigger(true)
                }
              })

              scene.current.add(transformer.current)

              setControllerPoint((prevState) => ({
                ...prevState,
                active: true,
                label: objName,
                mode: movementConfig.type
              }))
            }
            transformer_init.current = false
          } else {
            transformer_init.current = true
          }
          animate()
        }
        //remove Pivot and Controller
      }
    }
  }

  const updateSize = () => {
    setTimeout(() => {
      if (viewBoxRef4.current && rendererRef.current) {
        rendererRef.current.setSize(
          viewBoxRef4.current.clientWidth,
          viewBoxRef4.current.clientHeight
        )
        rendererRef.current.setPixelRatio(window.devicePixelRatio)
        render()
      }
    }, 5000)
  }

  function updateObjectVisibility(scene, checkedValues) {
    if (Object.keys(checkedValues).length !== 0) {
      if (scene.current !== null) {
        scene.current.children.forEach((child) => {
          // Assume the child's name or some other property maps to the keys in checkedValues
          const childId = parseInt(child.name.replace('label', '')) // This is an assumption based on your structure

          // If childId is not NaN and we have a corresponding checkedValue...
          if (!isNaN(childId) && Object.prototype.hasOwnProperty.call(checkedValues, childId)) {
            // Set the visibility based on the checkedValue
            child.visible = checkedValues[childId]
          }
        })
        animate()
      }
    }
  }

  useEffect(() => {
    if (controllerPoint.init == false) {
      setControllerPoint((prevState) => ({
        ...prevState,
        state: centerPoint,
        init: true
      }))
    }
  }, [controllerPoint])

  useEffect(() => {
    if (!viewBoxRef4.current) {
      return
    }
    mainGUIRef.current.hide()

    const width = viewBoxRef4.current.clientWidth
    const height = viewBoxRef4.current.clientHeight

    // save repo scene to load object files to save later
    setRepoScene(scene.current)

    // const scene = new THREE.Scene()
    // const group = new THREE.Group()

    // group.name = '3DVolumesGroup'

    const renderer = new THREE.WebGLRenderer()
    renderer.sortObjects = false
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(width, height)
    containerRef.current.appendChild(renderer.domElement)

    const camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 20000)
    camera.position.z = 250

    const controls = new TrackballControls(camera, renderer.domElement)
    controls.rotateSpeed = rotateSpeed
    controls.zoomSpeed = 1.0
    controls.panSpeed = 0.5
    controls.enabled = false
    addLights(scene.current)

    window.addEventListener('resize', updateSize)
    // containerRef.current.addEventListener('pointerdown', onPointerDown)

    rendererRef.current = renderer
    cameraRef.current = camera
    controlsRef.current = controls

    updateSize()
    setInitBool(true)

    return () => {
      // Clean up the animation loop when component unmounts
      if (containerRef.current) {
        containerRef.current.removeChild(renderer.domElement)
        rendererRef.current.dispose()
        // containerRef.current.removeEventListener('pointerdown', onPointerDown)

        window.removeEventListener('resize', updateSize)
        if (!stats) {
          document.body.removeChild(stats.dom)
        }
      }
      removeObject(scene.current, 'groupLights')
    }
  }, [mainGUIRef, viewBoxRef4, buttonState])

  useEffect(() => {
    stats = new Stats()
    stats.showPanel(2) // 0: fps, 1: ms, 2: mb, 3+: custom

    stats.dom.style.position = 'fixed'
    stats.dom.style.top = '0'
    stats.dom.style.left = 'auto'
    stats.dom.style.right = '0'
    stats.dom.id = 'statsContainer' // Assign a specific name to the stats.dom element

    document.body.appendChild(stats.dom)
    animate()
  }, [])

  // Loading Segments
  useEffect(() => {
    const allKeys = Object.keys(filePath.repositionObjects) // Keys that should be loaded
    const allLoaded = allKeys.every((key) =>
      Object.prototype.hasOwnProperty.call(repLoadedObjects, key)
    ) // Check if all keys are loaded

    if (!allLoaded) {
      // If not all objects are loaded, exit early
      return
    }

    if (initBool && initFinished.current === false) {
      Object.entries(repLoadedObjects).forEach(([objectName, { object }]) => {
        const clonedObject = object.clone()
        clonedObject.name = objectName
        scene.current.add(clonedObject)

        // Render the scene immediately after adding the object
        rendererRef.current.render(scene.current, cameraRef.current)

        // Update the loading state for this object
        setObjectsLoaded((prev) => ({ ...prev, [objectName]: true }))
      })

      initFinished.current = true
      setInitFinishedState(true)

      updateSize()
      animate()
    }
  }, [initBool, repLoadedObjects])

  const keyPressed = useCallback(
    (event) => {
      if (movementConfig.type === 'translate') {
        if (keyDown == false) {
          transformDict.current = deepCopy(calculateTransformMatrices())
          setKeyDown(true)
        }

        if (centerPoint) {
          let objName = 'label' + selectedValue.toString()
          let object = getObjectByName(scene.current, objName)
          moveObject(object, event, 1)
        } else {
          let pivotObject = getObjectByName(scene.current, 'PivotGroupObject')
          moveObject(pivotObject, event, 1)
          var sphereObject = getObjectByName(scene.current, 'pointSphere')
          if (sphereObject != null) {
            sphereObject.position.copy(pivotObject.position)
          }
        }
      }
    },
    [selectedValue, transformer, movementConfig, keyDown]
  )

  //Transformer_init is always false!
  useEffect(() => {
    function handleMouseClick(event) {
      onPointerDown(event)
      definePointOnObject(event)
    }

    function keyupHandler() {
      if (!areObjectsEqual(transformDict.current, calculateTransformMatrices())) {
        if (undoStack.length === 0) {
          setCurrentState(deepCopy(transformDict.current))
        }

        setCheckingTrigger(true)
      }
      // setCheckingTrigger(true);
      setKeyDown(false)
    }

    if (selectedValue != null) {
      let newLabel = 'label' + selectedValue.toString()
      if (controllerPoint.label != newLabel) {
        setMovementConfig({ type: 'none' })
        setCurrentMovementMenu('none')
        setControllerPoint((prevState) => ({
          ...prevState,
          label: newLabel
        }))
      }
    }

    if (movementConfig.type == 'none') {
      removeObject(scene.current, 'pointSphere')
    }

    if (centerPoint) {
      removeObject(scene.current, 'pointSphere')

      if (initFinished.current && selectedValue !== null && movementConfig.type !== 'none') {
        if (transformer.current !== null) {
          transformer.current.detach()
          removeObject(scene.current, '3DLabelController')
        }
        if (!transformer_init.current) {
          removeObject(scene.current, '3DLabelController')
          transformer.current = new TransformControls(
            cameraRef.current,
            rendererRef.current.domElement
          )

          let objName = 'label' + selectedValue.toString()
          let object = getObjectByName(scene.current, objName)
          if (object !== null) {
            var transformerPosition = new THREE.Vector3()
            transformerPosition = getCenterOfObject(object)
            // console.log('label, center : ', objName, transformerPosition)
            removeObject(scene.current, 'pointSphere')
            addSphere(transformerPosition, scene.current, 'white', 'pointSphere', 1)
            transformer.current.position.copy(transformerPosition)
            transformer.current.setSpace('local')
            transformer.current.mode = movementConfig.type || 'translate'
            transformer.current.size = 0.75
            transformer.current.name = '3DLabelController'
            transformer.current.rotationSnap = THREE.MathUtils.degToRad(0.01)
            transformer.current.attach(object)

            var sphereObject = getObjectByName(scene.current, 'pointSphere')

            transformer.current.addEventListener('change', function () {
              // Manually check and adjust object's position, rotation, or scale.
              sphereObject.position.copy(transformer.current.position)
              animate()
            })
            transformer.current.addEventListener('mouseDown', function () {
              transformDict.current = deepCopy(calculateTransformMatrices())
              controlsRef.current.enabled = false
            })
            transformer.current.addEventListener('mouseUp', function () {
              controlsRef.current.enabled = true

              if (!areObjectsEqual(transformDict.current, calculateTransformMatrices())) {
                if (undoStack.length === 0) {
                  setCurrentState(deepCopy(transformDict.current))
                }

                setCheckingTrigger(true)
              }
            })
            scene.current.add(transformer.current)
          }
          transformer_init.current = false
        } else {
          transformer_init.current = true
        }

        removeObject(scene.current, 'pointSphere')

        animate()
      }

      if (initFinished.current && movementConfig.type === 'none') {
        if (transformer.current !== null) {
          transformer.current.detach()
          removeObject(scene.current, '3DLabelController')
        }

        animate()
      }
    } else if (!centerPoint) {
      let labelName = 'label' + selectedValue.toString()
      var objectWorldMatrix

      if (initFinished.current && movementConfig.type === 'none') {
        removeObject(scene.current, 'pointSphere')
        if (transformer.current !== null) {
          transformer.current.detach()
          removeObject(scene.current, '3DLabelController')
          let objName = controllerPoint.label
          let object = getObjectByName(scene.current, objName)
          if (object !== null) {
            objectWorldMatrix = new THREE.Matrix4()
            objectWorldMatrix.copy(object.matrixWorld)

            const position1 = new THREE.Vector3()
            const quaternion1 = new THREE.Quaternion()
            const scale1 = new THREE.Vector3()
            object.matrixWorld.decompose(position1, quaternion1, scale1)

            pivot.remove(object)
            scene.current.add(object)
            object.position.copy(position1)
            object.rotation.setFromQuaternion(quaternion1)
            setAttachmentBool(false)
            pivot = new THREE.Group()
            pivot.name = 'PivotGroupObject'
          }
          if (controllerPoint.active) {
            setControllerPoint((prevState) => ({
              ...prevState,
              active: false
            }))
          }
        }
      }

      if (initFinished.current && labelName != controllerPoint.label) {
        removeObject(scene.current, 'pointSphere')
        if (transformer.current !== null) {
          transformer.current.detach()
          removeObject(scene.current, '3DLabelController')
          let objName = controllerPoint.label
          let object = getObjectByName(scene.current, objName)
          if (object !== null) {
            objectWorldMatrix = new THREE.Matrix4()
            objectWorldMatrix.copy(object.matrixWorld)

            const position1 = new THREE.Vector3()
            const quaternion1 = new THREE.Quaternion()
            const scale1 = new THREE.Vector3()
            object.matrixWorld.decompose(position1, quaternion1, scale1)

            pivot.remove(object)
            scene.current.add(object)
            object.position.copy(position1)
            object.rotation.setFromQuaternion(quaternion1)
            setAttachmentBool(false)
            pivot = new THREE.Group()
            pivot.name = 'PivotGroupObject'
          }
          setControllerPoint((prevState) => ({
            ...prevState,
            active: false,
            label: labelName
          }))
        }
        animate()
      }
      if (
        controllerPoint.mode != movementConfig.type &&
        controllerPoint.active == true &&
        transformer.current != null
      ) {
        transformer.current.setMode(movementConfig.type)
        setControllerPoint((prevState) => ({
          ...prevState,
          mode: movementConfig.type
        }))
      }
    }

    containerRef.current.addEventListener('click', handleMouseClick)
    document.addEventListener('keydown', keyPressed)
    document.addEventListener('keyup', keyupHandler)
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('click', handleMouseClick)
        document.removeEventListener('keydown', keyPressed)
        document.removeEventListener('keyup', keyupHandler)
      }
    }
  }, [selectedValue, movementConfig, transformer, centerPoint, controllerPoint, keyPressed])

  useEffect(() => {
    updateObjectVisibility(scene, checkedValues)
  }, [checkedValues])

  useEffect(() => {
    if (initFinished.current && checkingTrigger) {
      setCheckingTrigger(false)

      if (currentState !== null) {
        setUndoStack([...undoStack, currentState])
        // console.log('currentState : ', currentState)
      }

      setRedoStack([])

      setCurrentState(deepCopy(calculateTransformMatrices()))
    }
  }, [checkingTrigger])

  useEffect(() => {
    if (isUndo === true) {
      setMovementConfig({ type: 'none' })
      setCurrentMovementMenu('none')
      setControllerPoint((prevState) => ({
        ...prevState,
        active: false
      }))

      if (!attachmentBool) {
        if (undoStack.length === 0) {
          window.alert('there are no data for undo')
          setIsUndo(false)
          return
        } else if (undoStack.length === 1) {
          setRedoStack([...redoStack, deepCopy(calculateTransformMatrices())])

          const lastState = undoStack.pop()

          applyTransformMatrices(deepCopy(lastState))

          setCurrentState(deepCopy(calculateTransformMatrices()))
          setIsUndo(false)
        } else {
          const lastState = undoStack.pop()

          setRedoStack([...redoStack, deepCopy(calculateTransformMatrices())])
          applyTransformMatrices(deepCopy(lastState))

          // setCurrentState(new Float32Array(lastState))
          setCurrentState(deepCopy(calculateTransformMatrices()))

          setIsUndo(false)
        }
      }
    }
  }, [isUndo, movementConfig, attachmentBool])

  // useEffect(() => {
  //   console.log('undoStack: ', undoStack, '\nredoStack: ', redoStack)
  // }, [undoStack, redoStack])

  useEffect(() => {
    if (isRedo === true) {
      setIsRedo(false)

      if (redoStack.length === 0) {
        window.alert('there are no more redo')
        return
      } else {
        const nextState = redoStack.pop()
        setUndoStack([...undoStack, deepCopy(calculateTransformMatrices())])
        applyTransformMatrices(deepCopy(nextState))
        setCurrentState(deepCopy(nextState))
      }
    }
  }, [isRedo])

  useEffect(() => {
    if (isRestore) {
      setMovementConfig({ type: 'none' })
      setCurrentMovementMenu('none')
      setControllerPoint((prevState) => ({
        ...prevState,
        active: false
      }))

      setUndoStack([])
      setRedoStack([])

      // Remove 'TransformControls' object from the scene
      const transformControls = scene.current.children.find(
        (child) => child instanceof TransformControls // Replace with the correct condition to identify your TransformControls
      )
      if (transformControls) {
        scene.current.remove(transformControls)
      }

      // Remove child objects with names matching "label{number}"
      const removableObjects = scene.current.children.filter((child) =>
        child.name.match(/^label\d+$/)
      )

      removableObjects.forEach((object) => {
        scene.current.remove(object)
      })

      const executeFunctions = async () => {
        // await loadLabels(scene.current, cameraRef.current, rendererRef.current, filePath, 'restore') // Wait for to finish
        // repositionObjectPositionToGeoCenter(filePath, scene) // Then execute

        Object.entries(repLoadedObjects).forEach(([objectName, { object }]) => {
          const clonedObject = object.clone()
          clonedObject.name = objectName
          // clonedObject.visible = false

          scene.current.add(clonedObject)

          // Render the scene immediately after adding the object
          rendererRef.current.render(scene.current, cameraRef.current)

          // // Update the loading state for this object
          setObjectsLoaded((prev) => ({ ...prev, [objectName]: true }))
        })
      }

      executeFunctions()

      animate()

      setIsRestore(false)
    }
  }, [isRestore])

  useEffect(() => {
    // Define the neutral color (e.g., light gray RGB)
    const neutralColor = new THREE.Color(1.0, 1.0, 1.0) // Corresponds to 211/255

    const colors = colorMapArray.map((c) => c / 255)

    scene.current.children.forEach((child) => {
      if (child.name.startsWith('label')) {
        // Extract label number from child name
        const labelNumber = parseInt(child.name.replace('label', ''))

        // Calculate the index for the color in the 'colors' array
        const colorIndex = labelNumber * 3

        // Get color for the label or use neutral color based on the switch state
        const color = labelColorSwitch
          ? new THREE.Color(colors[colorIndex], colors[colorIndex + 1], colors[colorIndex + 2])
          : neutralColor

        // Apply color to the geometry of the child object
        child.traverse((subChild) => {
          if (subChild.isMesh && subChild.geometry && subChild.geometry.attributes.color) {
            const colorsArray = subChild.geometry.attributes.color.array
            for (let i = 0, n = colorsArray.length; i < n; i += 3) {
              colorsArray[i] = color.r
              colorsArray[i + 1] = color.g
              colorsArray[i + 2] = color.b
            }
            subChild.geometry.attributes.color.needsUpdate = true
          }
        })
      }
    })

    // Trigger a render update if necessary
    animate()
  }, [labelColorSwitch])

  useEffect(() => {
    if (cameraResetClicked) {
      if (initialCameraState.set && cameraRef.current && controlsRef.current) {
        // Reset camera
        cameraRef.current.copy(initialCameraState.camera)

        // Reset controls
        controlsRef.current.target.copy(initialCameraState.controlsTarget)

        setCameraResetClicked(false)
        animate()
      }
    }
  }, [cameraResetClicked])

  // Set the initial Position for the display in the info box
  useEffect(() => {
    if (initFinishedState && objectPositionInfo.init == false) {
      const newManualRepositionMatrix = {}

      Object.keys(filePath.repositionObjects).forEach((objectName) => {
        // Generate an identity matrix for this object
        const identityMatrix = new THREE.Matrix4().identity()

        // Convert the Three.js Matrix4 to an array for easier storage in state
        const identityMatrixArray = identityMatrix.toArray()

        // Initialize this object's entry in manualRepositionMatrix with the identity matrix
        newManualRepositionMatrix[objectName] = identityMatrixArray

        let obj = getObjectByName(scene.current, objectName)

        let matrix = obj.matrixWorld

        let trans = new THREE.Vector3()
        let rotation = new THREE.Quaternion()
        let scale = new THREE.Vector3()

        matrix.decompose(trans, rotation, scale)
        var positionString = objectName + 'Position'
        let teststring = objectName + 'initMatrix'

        setObjectPositionInfo((prevState) => ({
          ...prevState,
          [positionString]: trans,
          [teststring]: matrix,
          init: true
        }))
      })

      // Update manualRepositionMatrix state once with all the identity matrices
      setManualRepositionMatrix(newManualRepositionMatrix)
    }
  }, [initFinishedState])

  useEffect(() => {
    if (
      (selectedValue != null && selectedValue != undefined && objectPositionInfo.init) ||
      checkingTrigger
    ) {
      let labelName = 'label' + selectedValue.toString()
      let segment = getObjectByName(scene.current, labelName)

      if (segment != null && segment != undefined) {
        segment.updateWorldMatrix()
        let matrix = segment.matrixWorld

        let trans = new THREE.Vector3()
        let rotation = new THREE.Quaternion()
        let scale = new THREE.Vector3()

        matrix.decompose(trans, rotation, scale)

        // some of the segments are translated in the beginning
        // the initial translation is deducted for the representation
        // Currently they are not rotated initially. If so this rotation needs to be subtracted aswell
        var positionString = labelName + 'Position'
        trans.sub(objectPositionInfo[positionString])

        // Assuming trans, rotation, and scale have been updated
        let manualRepositionMatrix = new THREE.Matrix4()
        manualRepositionMatrix.compose(trans, rotation, scale)

        // console.log('label, secondMatrix : ', labelName, manualRepositionMatrix.elements)

        setObjectPositionInfo((prevState) => ({
          ...prevState,
          trans: trans,
          rotation: rotation,
          scale: scale
        }))

        setManualRepositionMatrix((prevState) => ({
          ...prevState,
          [labelName]: manualRepositionMatrix.elements
        }))
      }
    }

    if (isRestore) {
      setObjectPositionInfo((prevState) => ({
        ...prevState,
        trans: new THREE.Vector3(),
        rotation: new THREE.Quaternion(),
        scale: new THREE.Vector3()
      }))
    }
  }, [selectedValue, checkingTrigger, isUndo, isRedo, isRestore])

  useEffect(() => {
    if (rotationVector !== null) {
      let z = cameraRef.current.position.z
      let y = cameraRef.current.position.y
      let x = cameraRef.current.position.x
      const zoomValue = Math.max(Math.abs(z), Math.abs(x), Math.abs(y))
      // Copy the initial camera and control targets
      cameraRef.current.copy(cameraPositionsRef.current.camera)
      controlsRef.current.target.copy(cameraPositionsRef.current.controlsTarget)

      // Calculate and set the new camera position based on rotationVector
      const newPosition = calculateNewCameraPosition(
        cameraPositionsRef.current.camera.position,
        cameraPositionsRef.current.controlsTarget,
        rotationVector
      )
      cameraRef.current.position.copy(newPosition)

      // Special handling for 'S' and 'I' to adjust the camera's up vector and lookAt
      if (rotationVector === 'S') {
        cameraRef.current.up.set(0, 0, -1) // For looking down
      } else if (rotationVector === 'I') {
        cameraRef.current.up.set(0, 0, 1) // For looking up
      }

      if (rotationVector === 'S' || rotationVector === 'I') {
        cameraRef.current.lookAt(controlsRef.current.target) // Ensure correct orientation
        cameraRef.current.position.y = rotationVector === 'I' ? -zoomValue : zoomValue
      } else if (rotationVector === 'A' || rotationVector === 'P') {
        cameraRef.current.position.z = rotationVector === 'P' ? -zoomValue : zoomValue
      } else {
        cameraRef.current.position.x = rotationVector === 'L' ? zoomValue : -zoomValue
      }

      animate() // Update the scene
      setRotationVector(null) // Reset rotationVector to avoid re-triggering
    }
  }, [rotationVector])

  useEffect(() => {
    // Check if all objects are loaded based on filePath.isolationObjects keys
    const allKeys = Object.keys(filePath.repositionObjects) // Keys that should be loaded
    const allLoaded = allKeys.every((key) => objectsLoaded[key] === true) // Check if all keys are loaded

    // console.log('allKeys : ', allKeys)
    // console.log('objectsLoaded : ', objectsLoaded)

    if (initFinished.current && allLoaded) {
      // Perform actions after all objects have been loaded
      controlsRef.current.enabled = true

      // Remove all existing 'BoundingBox' objects from the scene
      scene.current.children = scene.current.children.filter(
        (child) => child.name !== 'BoundingBox'
      )
      boundingBoxMeshRef.current = addBoundingBoxAndLabels(scene.current)

      if (cameraRef.current && controlsRef.current) {
        // Set the camera position and orientation for rotationVector = "I" (Inferior view)
        const zoomValue = Math.max(
          Math.abs(cameraRef.current.position.z),
          Math.abs(cameraRef.current.position.x),
          Math.abs(cameraRef.current.position.y)
        )

        // Position the camera below the target for an Inferior view
        cameraRef.current.position.set(0, -zoomValue, 0) // Below the object
        cameraRef.current.up.set(0, 0, 1) // Camera's up vector pointing along the Z-axis
        cameraRef.current.lookAt(controlsRef.current.target) // Ensure the camera looks at the target
      }

      // Now, save this as the initial camera state
      if (!initialCameraState.set && cameraRef.current && controlsRef.current) {
        setInitialCameraState({
          set: true,
          camera: cameraRef.current.clone(), // Save the cloned state of the camera
          controlsTarget: controlsRef.current.target.clone() // Save the cloned state of the controls' target
        })
      }

      // Save these initial positions for possible resets
      cameraPositionsRef.current = {
        camera: cameraRef.current.clone(),
        controlsTarget: controlsRef.current.target.clone()
      }

      updateObjectVisibility(scene, checkedValues)

      setIsLoading(false)
      animate()
    }
  }, [initFinished, objectsLoaded])

  useEffect(() => {
    if (viewBoxOn === true) {
      toggleBoundingBoxVisibility(boundingBoxMeshRef.current, true) // Show
    } else {
      toggleBoundingBoxVisibility(boundingBoxMeshRef.current, false) // Hide
    }
  }, [viewBoxOn])

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.rotateSpeed = rotateSpeed
    }
  }, [rotateSpeed])

  return <div name="3DBox" ref={containerRef} />
}

export default RenderingThreeSceneReposition
