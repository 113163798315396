/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import JSZip from 'jszip'
import { dicomHandler } from './DicomHandler'
import { fileLoader } from './FileLoader'

const sanitizeFileName = (name) => {
  // Replace spaces with underscores and remove non-alphanumeric characters (except underscores)
  return name.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '')
}

const formatPatientInfo = (info) => {
  // Format gender
  if (info.gender === 'M') {
    info.gender = 'Male'
  } else if (info.gender === 'F') {
    info.gender = 'Female'
  } else if (info.gender === 'O') {
    info.gender = 'Other'
  }
  // Do not change if it's already in the desired format

  // Format birthday
  if (info.birthday && info.birthday.length === 8 && info.birthday.indexOf('/') === -1) {
    info.birthday = `${info.birthday.slice(0, 4)}/${info.birthday.slice(
      4,
      6
    )}/${info.birthday.slice(6)}`
  }
  // Do not change if it's already in the desired format or not in 'YYYYMMDD' format
}

export const fileHandler = async (
  fileList,
  isDemo,
  setDemo,
  backInfo,
  setBackInfo,
  setButtonState,
  userInfo,
  isRenderingThreeDisabled,
  setIsRenderingThreeDisabled,
  filePath,
  setFilePath,
  sdk,
  setFileLoadingStatus,
  setDocumentUpdateStatus,
  setFileName,
  chosenDicom
) => {
  setFileLoadingStatus('progress')

  if (isDemo) setDemo(!isDemo)

  let finalFileName
  const file = fileList
  const fileExtension = file['name'].split('.').pop()
  const fileNameWoExtension = file['name'].split('.')[0]

  const patientInfo = {
    gender: 'Other',
    birthday: '1900/01/01',
    updated: false
  }

  if (fileExtension == 'zip' || fileExtension == '7z') {
    const zip = new JSZip()
    const fileDatas = {}

    // Load the contents of the file
    const data = await zip.loadAsync(file)

    // Loop through each file in the zip archive
    for (const [filename, fileData] of Object.entries(data.files)) {
      if (!fileData.dir) {
        // Check if it's not a directory
        fileDatas[filename] = fileData.async('uint8array')
      }
    }

    // Wait for all file data to be loaded
    for (const filename in fileDatas) {
      fileDatas[filename] = await fileDatas[filename]
    }

    const folderPath = fileNameWoExtension
    encodeURIComponent(folderPath)

    finalFileName = sanitizeFileName(folderPath + '_' + chosenDicom[0].seriesDescription)

    setFileName(finalFileName)

    const seriesMap = new Map()

    // Group files by seriesInstanceUID
    for (const Dicom of chosenDicom) {
      seriesMap.set(Dicom.seriesInstanceUID, {
        seriesDescription: Dicom.seriesDescription,
        files: []
      })
    }

    // Process each file
    for (const [fileName, fileData] of Object.entries(fileDatas)) {
      const arrayBuffer = await fileData
      const byteArray = new Uint8Array(arrayBuffer)
      const data = await dicomHandler(byteArray)

      if (seriesMap.has(data.seriesInstanceUID)) {
        seriesMap.get(data.seriesInstanceUID).files.push({ fileName, data })
      }

      if (patientInfo.updated === false) {
        patientInfo.updated = true
        if (data.originalGender !== null && data.originalGender !== '') {
          patientInfo.gender = data.originalGender
        }
        if (data.originalBirthDate !== null && data.originalBirthDate !== '') {
          patientInfo.birthday = data.originalBirthDate
        }

        formatPatientInfo(patientInfo)
      }
    }

    // Now call processSeriesMap with the populated seriesMap
    await processSeriesMap(seriesMap)
  } else {
    setFileName(file['name'])

    const fileData = file

    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(fileData)

    fileReader.onload = async () => {
      const myBuffer = new Blob([fileReader.result])

      const fileUrl = await fileLoader(
        file['name'],
        myBuffer,
        sdk,
        userInfo['id'],
        fileNameWoExtension,
        setBackInfo,
        backInfo,
        'file_upload',
        setFileLoadingStatus,
        setDocumentUpdateStatus,
        patientInfo
      )

      setFilePath({
        ...filePath,
        filename: fileNameWoExtension,
        image: fileUrl
      })

      setButtonState('Image')
    }

    if (isRenderingThreeDisabled) setIsRenderingThreeDisabled(!isRenderingThreeDisabled)
  }

  async function processSeriesMap(seriesMap) {
    const finalZip = new JSZip()

    for (const [seriesUID, seriesData] of seriesMap.entries()) {
      console.log('Processing series: ', seriesUID)
      const seriesZip = new JSZip()
      for (const { fileName, data } of seriesData.files) {
        const myBuffer = new Blob([data.dataSet.byteArray.buffer])
        seriesZip.file(fileName, myBuffer)
      }

      const seriesZipBuffer = await seriesZip.generateAsync({ type: 'arraybuffer' })
      finalZip.file(seriesData.seriesDescription + '.zip', seriesZipBuffer)
    }

    finalZip.generateAsync({ type: 'arraybuffer' }).then(async function (dicomBuffer) {
      const zipFileName = finalFileName + '.zip'

      // Create a Blob object from the ArrayBuffer:
      const zipBlob = new Blob([dicomBuffer], {
        type: 'application/x-gzip'
      })

      const fileUrl = await fileLoader(
        zipFileName,
        zipBlob,
        sdk,
        userInfo['id'],
        finalFileName,
        setBackInfo,
        backInfo,
        'dicom_upload',
        setFileLoadingStatus,
        setDocumentUpdateStatus,
        patientInfo
      )

      setFilePath({
        ...filePath,
        filename: finalFileName,
        image: fileUrl
      })

      setButtonState('Image')

      if (isRenderingThreeDisabled) setIsRenderingThreeDisabled(!isRenderingThreeDisabled)
    })
  }

  return
}
