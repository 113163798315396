/* eslint-disable react/prop-types */
import React from 'react'
import { Typography, Button, Grid, Box, TextField } from '@mui/material'
import render from '../../assets/login_render_img.png'
import { authenticationSDK } from './AuthenticationSDK'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

function ResetPassword() {
  const navigate = useNavigate()
  const textFieldStyle = {
    backgroundColor: '#727272',
    borderRadius: '4px',

    input: {
      color: '#fff',

      borderColor: '#fff'
    },
    label: { color: '#fff' },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#fff'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#fff'
      }
    },
    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff'
    }
  }
  const [emailInput, setEmailInput] = useState('')

  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value)
  }

  const checkEmailAvailability = async (sdk, email) => {
    let available = await sdk.users.isEmailAvailable(email)
    return available
  }

  const initiateReset = async (sdk, email) => {
    try {
      await sdk.users.requestPasswordReset(email)
      alert('Password reset initiated! Please check your email for instructions.')
      navigate('/login', { replace: true })
    } catch (error) {
      await sdk.users.requestEmailActivation(email)
      alert(
        'Entered email is not yet verified. Please verify the email first! Activation link is sent again.'
      )
    }
  }

  const handlePassReset = async () => {
    let authentication = await authenticationSDK(
      process.env.REACT_APP_EXTRAHORIZON_EMAIL,
      process.env.REACT_APP_EXTRAHORIZON_PASSWORD
    )
    if (!authentication.result) {
      alert('Unable to initiate reset. Please try again later!')
    } else {
      const result = await checkEmailAvailability(authentication.sdk, emailInput)
      if (result.emailAvailable) {
        alert('Entered email is not registered! Please check the input or register.')
      } else {
        initiateReset(authentication.sdk, emailInput)
      }
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: '#191919',
        width: '100%',
        height: '100vh'
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          padding: '16px',
          height: '100vh'
        }}
      >
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={5}
          sx={{
            height: '100vh'
          }}
        >
          <Grid item>
            <Box
              sx={{
                backgroundColor: '#383838',
                borderRadius: '20px',
                padding: '32px',
                color: '#fff',
                maxWidth: '70%',
                minWidth: '40ch'
              }}
            >
              <Typography variant="h4">Reset Password</Typography>
              <Typography variant="h6">
                Remember your password?{' '}
                <a style={{ color: '#1976d2' }} href="/">
                  Login
                </a>
                .
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1, color: '#fff' }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={emailInput}
                  onChange={handleEmailInputChange}
                  autoFocus
                  sx={textFieldStyle}
                />

                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handlePassReset}
                >
                  Reset password
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={7}
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '100vh'
          }}
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              height: '100%'
            }}
          >
            <Box
              component="img"
              src={render}
              alt="Mango Rendering"
              sx={{ maxWidth: '70vh', margin: '16px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ResetPassword
