import * as THREE from 'three'
import { addCircle } from './SegmentToolHandler'
import { removeAllObjects } from './SegmentToolHandler'

import pointInPolygon from 'point-in-polygon'

/*
  This is the ScissorToolHandler that creates a closed shape and erases or labels everything inside the closed shape.
*/

export function savePoints(event, camera, scene, curvePointList) {
  //here the array of the points that are used for the closed curve are saved

  /*
    adds a circle to the scene and save the position of the circle to a List of Points
    event: event from an event Listener, the event could be a pointerdown or mousemove,
    camera: a camera object,
    scene: the scene in which the event happened,
    curvePointList: the point List in which the points are going to be saved in,
  */

  let pointer = new THREE.Vector3()
  let raycaster = new THREE.Raycaster()
  let bbox = event.currentTarget.getBoundingClientRect()

  // Sets the x & y values of the pointes to the x & y coordinates of the 2D-view
  // Since it is only 2D the z-value is set 0 by default
  pointer.set(
    ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1,
    -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1,
    0
  )

  // Creating an intersection point of the position where we clicked and the scene
  raycaster.setFromCamera(pointer, camera)
  const intersects = raycaster.intersectObjects(scene.children)

  // If the point is inside the scene, a circle is added at that point, and the coordinates are added to the curvePointList
  if (intersects.length > 0) {
    addCircle(scene, 6, intersects[0].point.x, intersects[0].point.y, 0, 32, 'yellow', 'curvePoint')
    curvePointList.push(intersects[0].point)
  }
}

export function createLine(p1, p2, scene) {
  /*
    This function creates a 3D-line between 2 points
    p1: THREE.Vector3(),
    p2: THREE.Vector3(),
    scene: scene object in which this function is applied,
  */

  // Create a curve from point1 to point2
  const curve = new THREE.LineCurve3(p1, p2)

  // Create a tube geometry along the curve
  const tubeGeometry = new THREE.TubeGeometry(curve, 1, 1, 8, false)

  // Create a material for the tube
  const tubeMaterial = new THREE.MeshBasicMaterial({ color: 'yellow' })

  // Create a mesh from the tube geometry and material
  const tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial)

  //Adding the name of the line which can be used to find it
  tubeMesh.name = 'closeCurveLine'
  //adding it to the respective scene
  scene.add(tubeMesh)
}

export function createClosedShape(
  curvePointList,
  scene,
  camera,
  label,
  sliceSeg,
  setLabel,
  viewId,
  volume,
  geometry,
  slice,
  selectedSegment,
  segmentConfig,
  deltaX,
  labelThresholdRange,
  setIsSegmentToolAffected
) {
  /*
    Creates a closed shape in the scene of a 2D image from a list of points.
    Afterward segments that are inside the closed shape are getting erased
    or overwritten with a new label
    
    curvePointList: the list of points that are used to create the closed shape
    camera: camers object,
    label: label,
    sliceSeg: the location of the slice in the 3D volume,
    setLabel: setLabel,
    viewId: the scene in which the shape is create,
    volume: volume,
    geometry: geometry,
    slice: slice,
    selectedSegment: selected Segment,
    segmentConfig: segment Configuration,
    deltaX: stepsize to search for points inside the closed shape,
    labelThresholdRange: label Threshold to be overwritten
  */

  camera
  var shape = new THREE.Shape()

  //Iterating over the point list and creating a line between point i and point i+1
  for (let i = 0; i < curvePointList.length; i++) {
    if (i === 0) {
      let p1 = curvePointList[i]
      shape.moveTo(p1.x, p1.y)
    } else {
      let p1 = curvePointList[i]
      shape.lineTo(p1.x, p1.y)
    }
  }

  // Create a geometry based on the shape
  var geo = new THREE.ShapeGeometry(shape)

  // Create a mesh using the geometry and material
  var material = new THREE.MeshBasicMaterial({ color: 'yellow' })
  var closedShape = new THREE.Mesh(geo, material)

  closedShape.name = 'closedShape'

  // Add the mesh to the scene
  scene.add(closedShape)

  // Search for all points inside the clodes Shape
  var pointsInside = findPointsInsideShape(closedShape, deltaX)

  // Depending on the Scissor type, the values inside the closed Shape are getting overwritten or erased
  if (segmentConfig.type === 'Scissor Erase') {
    eraseSegmentWithClosedShape(
      pointsInside,
      label,
      sliceSeg,
      selectedSegment,
      setLabel,
      viewId,
      volume,
      geometry,
      setIsSegmentToolAffected
    )
  } else if (segmentConfig.type === 'Scissor Seg') {
    addSegmentWithClosedShape(
      pointsInside,
      slice,
      sliceSeg,
      volume,
      label,
      selectedSegment,
      setLabel,
      viewId,
      geometry,
      labelThresholdRange,
      setIsSegmentToolAffected
    )
  }
}

//removing the closed form
export function eraseClosedForm(scene) {
  removeAllObjects(scene, 'closeCurveLine')
  //removeAllObjects(scene, 'curvePoint')
}

//remove the closed shape
export function eraseClosedShape(scene) {
  removeAllObjects(scene, 'closedShape')
  //removeAllObjects(scene, 'curvePoint')
}

export function savePointsOnMouseMove(event, camera, scene, curvePointList, segmenting) {
  /*
    save the points where the mouse has been moved
    event: mouse event - in this case the movement,
    camera: camera,
    scene: scene,
    curvePointList: the list where the points are saved,
    segementing: boolean to enable the saving of the points
  */

  let pointer = new THREE.Vector3()
  let raycaster = new THREE.Raycaster()
  let bbox = event.currentTarget.getBoundingClientRect()

  // remove of all curvePoints
  if (segmenting === false) {
    removeAllObjects(scene, 'curvePoint')
  }

  // Calculate the coordinates of the mouse in the scene
  pointer.set(
    ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1,
    -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1,
    0
  )
  raycaster.setFromCamera(pointer, camera)
  const intersects = raycaster.intersectObjects(scene.children)

  // if the segmenting is true and the mouse is in the scene
  // the position of the mouse is added to the list and a line
  // between the points is created
  if (intersects.length > 0) {
    //addCircle(scene,4, intersects[ 0 ].point.x, intersects[ 0 ].point.y, 0, 32, 'yellow', 'curvePoint');

    if (segmenting) {
      curvePointList.push(intersects[0].point)
      if (curvePointList.length % 2 === 0 && curvePointList.length > 2) {
        createLine(curvePointList[curvePointList.length - 3], intersects[0].point, scene)
      }
    }
  }
}

export function findPointsInsideShape(closedShape, deltaX) {
  /* Find points inside a closed shape
    closedShape: the closed shape,
    deltaX: Step size,
  */

  var pointsInside = [] //List of points of inside the shape
  var boundingBox = new THREE.Box3().setFromObject(closedShape)
  var positionAttribute = closedShape.geometry.attributes.position //postion of the vertics or faces
  var shapePoints = []

  //iterating of the the positions and push the them to the shapePoints list
  for (let i = 0; i < positionAttribute.count; i++) {
    let x = positionAttribute.getX(i)
    let y = positionAttribute.getY(i)
    shapePoints.push([x, y])
  }
  // console.log('Stepsize in iteration: ', deltaX)

  // This value needs to be further adjusted in the optimization process
  // if it is too small it causes the website to crash
  // if it is too big, not all pixels are overwritten
  deltaX = 0.1

  // iterating through the bounding box and check if a point is inside the closed shape
  // if yes it is added to poinsInside List
  for (let x = Math.floor(boundingBox.min.x); x < Math.ceil(boundingBox.max.x); x += deltaX) {
    for (let y = Math.floor(boundingBox.min.y); y < Math.ceil(boundingBox.max.y); y += deltaX) {
      if (pointInPolygon([x, y], shapePoints)) {
        pointsInside.push(new THREE.Vector2(x, y))
      }
    }
  }

  // console.log("Points inside the shape: ", pointsInside);
  return pointsInside
}

export function eraseSegmentWithClosedShape(
  pointsInside,
  label,
  sliceSeg,
  selectedSegment,
  setLabel,
  viewId,
  volume,
  geometry,
  setIsSegmentToolAffected
) {
  /*
    Erase all segment inside the closed shape
    pointsInside: List of points that are going to be erased
    label: label,
    sliceSeg: sliceSeg:
    viewId: viewId,
    volume: volume,
    geometry: geometry
  */

  const currentLabel = label
  let isChanged = false
  var x
  var y

  // Iterating over all poiunts and set the the label to 0
  pointsInside.forEach((point) => {
    // console.log("Point before: ", point);
    point = convertCoordinates(point, viewId, volume, geometry)
    // console.log("Point after: ", point);

    if (currentLabel !== null) {
      // Clear the point
      x = point.x
      y = point.y

      let value = currentLabel.data[sliceSeg.sliceAccess(x, y)]
      if (value === selectedSegment) {
        currentLabel.data[sliceSeg.sliceAccess(x, y)] = 0
        isChanged = true
        // setLabel(currentLabel)
      }
    }
  })

  if (isChanged === true) {
    setIsSegmentToolAffected(true)
    setLabel(currentLabel)
  }
}

export function addSegmentWithClosedShape(
  pointsInside,
  slice,
  sliceSeg,
  volume,
  label,
  selectedSegment,
  setLabel,
  viewId,
  geometry,
  labelThresholdRange,
  setIsSegmentToolAffected
) {
  /*
    Adding a new label to the points inside a list
    pointsInside: List of points that are going to get a new label
    slice: slice,
    sliceSeg: sliceSeg,
    volume: volume,
    label: label,
    selectedSegment: slectedSegment,
    setLabel: setLabel,
    viewId: viewId,
    geometry: geometry,
    labelThresholdRange: label Threshold to be overwritten
  */
  const currentLabel = label
  let isChanged = false
  var x
  var y

  // Iterting over all points and convert their coordinates
  // depending on the plane they are
  // The old label of the points will be overwritten with the new label
  pointsInside.forEach((point) => {
    point = convertCoordinates(point, viewId, volume, geometry)

    if (currentLabel !== null) {
      x = point.x
      y = point.y

      let value = volume.data[slice.sliceAccess(x, y)]

      // The value below needs to be overwritten to maybe -10000 or something
      // because it is creating empty pixels in the segmentation of the webapp
      // for more clarification please reach out to Dario or Sören
      // if (value !== 0){
      if (value >= labelThresholdRange.min && value <= labelThresholdRange.max) {
        currentLabel.data[sliceSeg.sliceAccess(x, y)] = selectedSegment
        // setLabel(currentLabel)
        isChanged = true
      }
      // }
    }
  })

  if (isChanged === true) {
    setIsSegmentToolAffected(true)
    setLabel(currentLabel)
  }
}

export function convertCoordinates(point, viewId, volume, geometry) {
  let aspectX, aspectY

  /*
    Transoforms the coordinates from the scene into the coordinates inside the volume
    point: the point that is going to be transformed,
    viewId: viewId, 
    volume: volume,
    geometry: geometry
  */

  // Each view represents a different plane inside the volume
  // depending on the plane you see only two axis
  // while the third is oriented into or out of the display
  if (viewId === 'View1') {
    aspectX = volume.RASDimensions[0] / geometry.parameters.width
    aspectY = volume.RASDimensions[2] / geometry.parameters.height

    point.x = Math.floor((point.x + geometry.parameters.width / 2) * aspectX)
    point.y = Math.floor((-point.y + geometry.parameters.height / 2) * aspectY)
  } else if (viewId === 'View2') {
    aspectX = volume.RASDimensions[0] / geometry.parameters.width
    aspectY = volume.RASDimensions[1] / geometry.parameters.height
    ;(point.x = Math.floor((point.x + geometry.parameters.width / 2) * aspectX)),
      (point.y = Math.floor((point.y + geometry.parameters.height / 2) * aspectY))
  } else if (viewId === 'View3') {
    aspectX = volume.RASDimensions[2] / geometry.parameters.width
    aspectY = volume.RASDimensions[1] / geometry.parameters.height

    point.x = Math.floor((-point.x + geometry.parameters.width / 2) * aspectX)
    point.y = Math.floor((point.y + geometry.parameters.height / 2) * aspectY)
  }

  return point
}

export function calculateStepsize(event) {
  // calculate the the stepsize between two pixels
  var deltaX = 2 / event.currentTarget.clientWidth
  return deltaX
}
