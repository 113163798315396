/* eslint-disable react/prop-types */

import React from 'react'
import {
  Box,
  Typography,
  Popover,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid
} from '@mui/material'

function CaseFilters({
  isDemoChecked,
  setIsDemoChecked,
  genderFilter,
  setGenderFilter,
  indicationFilter,
  setIndicationFilter,
  statusFilter,
  setStatusFilter,
  isFilterPopoverOpen,
  handleFilterPopoverToggle,
  anchorEl
}) {
  const handleDemoChange = (event) => {
    setIsDemoChecked(event.target.checked)
  }

  const handleGenderChange = (event) => {
    setGenderFilter({ ...genderFilter, [event.target.name]: event.target.checked })
  }

  const handleIndicationChange = (event) => {
    setIndicationFilter({ ...indicationFilter, [event.target.name]: event.target.checked })
  }

  const handleStatusChange = (event) => {
    setStatusFilter({ ...statusFilter, [event.target.name]: event.target.checked })
  }

  return (
    <Popover
      open={isFilterPopoverOpen}
      anchorEl={anchorEl}
      onClose={handleFilterPopoverToggle}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          gap: '16px',
          backgroundColor: '#232323'
        }}
      >
        <Typography id="filter-popover-title" variant="h6" sx={{ color: 'white' }}>
          Filter
        </Typography>

        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="subtitle1" sx={{ color: 'white' }}>
              Include Demo :
            </Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset" sx={{ color: 'white' }}>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={isDemoChecked} onChange={handleDemoChange} />}
                  label="Demo"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ color: 'white', width: '100px' }}>
            Gender :
          </Typography>
          <FormControl component="fieldset" sx={{ color: 'white' }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox checked={genderFilter.male} onChange={handleGenderChange} name="male" />
                }
                label="Male"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={genderFilter.female}
                    onChange={handleGenderChange}
                    name="female"
                  />
                }
                label="Female"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={genderFilter.other}
                    onChange={handleGenderChange}
                    name="other"
                  />
                }
                label="Other"
              />
            </FormGroup>
          </FormControl>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ color: 'white', width: '100px' }}>
            Indication :
          </Typography>
          <FormControl component="fieldset" sx={{ color: 'white' }}>
            <FormGroup row>
              {Object.keys(indicationFilter).map((indication) => (
                <FormControlLabel
                  key={indication}
                  control={
                    <Checkbox
                      checked={indicationFilter[indication]}
                      onChange={handleIndicationChange}
                      name={indication}
                    />
                  }
                  label={indication}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ color: 'white', width: '100px' }}>
            Status :
          </Typography>
          <FormControl component="fieldset" sx={{ color: 'white' }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={statusFilter.complete}
                    onChange={handleStatusChange}
                    name="complete"
                  />
                }
                label="Complete"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={statusFilter.notComplete}
                    onChange={handleStatusChange}
                    name="notComplete"
                  />
                }
                label="Not Complete"
              />
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
    </Popover>
  )
}

export default CaseFilters
