import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Link from '@mui/material/Link'
import { authenticatedSDKRefresh } from '../../../authentication/AuthenticationSDK'

const SessionCounter = () => {
  let [minutes, setMinutes] = useState(0)
  let [seconds, setSeconds] = useState(0)
  let [progress, setProgress] = useState(0)
  let [saved, setSaved] = useState(false)
  const statsContainer = document.getElementById('statsContainer')
  const extendSession = async () => {
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      const authenticated = await authenticatedSDKRefresh(refreshToken)
      console.log(authenticated)
      if (!authenticated.result) {
        localStorage.removeItem('refreshToken')
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    const sessionExpire = new Date(localStorage.getItem('sessionExpire'))
    let timeDifference = sessionExpire - new Date()
    const timer =
      timeDifference > 0 &&
      setInterval(() => {
        timeDifference = sessionExpire - new Date()
        const saveButton = document.querySelector('#save-button')
        if (minutes < 5 && saveButton && !saved) {
          document.querySelector('#save-button').click()
          setSaved(true)
        }
        setMinutes(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)))
        setSeconds(Math.floor((timeDifference % (1000 * 60)) / 1000))
        setProgress((minutes / 60) * 100)
      }, 1000)
    return () => clearInterval(timer)
  }, [minutes, seconds])

  return (
    <>
      {(seconds > 0 || minutes > 0) && (
        <Box
          sx={{
            position: 'absolute',
            top: '1%',
            right: statsContainer ? 'calc(2% + 60px)' : '2%',
            color: 'red',
            width: '9rem'
          }}
        >
          <CircularProgress variant="determinate" value={progress} />
          <Box
            display="flex"
            sx={{
              float: 'right',
              marginTop: '0.5rem'
            }}
          >
            <span>
              {minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}
            </span>
            <Link
              sx={{
                marginLeft: '0.5rem'
              }}
              component="button"
              underline="none"
              onClick={() => extendSession()}
            >
              extend
            </Link>
          </Box>
        </Box>
      )}
    </>
  )
}

export default SessionCounter
