/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
import { rqlBuilder } from '@extrahorizon/javascript-sdk'

export const segmentationLoader = async (
  sdk,
  backInfo,
  fileName,
  userInfo,
  setBackInfo,
  setLoadingValue
) => {
  setLoadingValue(0)

  const delay = (ms) => new Promise((res) => setTimeout(res, ms))
  let resultDocument

  const schema = await sdk.data.schemas.findByName('Pipeline', {
    rql: rqlBuilder().select(['id', 'name', 'transitions']).build()
  })

  const document = await sdk.data.documents.findById(backInfo['schemaID'], backInfo['documentID'])

  const originFileToken = document.data.originFileToken
  const fileToken = document.data.fileToken
  const fractureLocation = document.data.side
  let segToken = document.data.segToken

  await delay(1000)

  if (segToken === '' && fileToken != '') {
    if (process.env.REMOTE_SERVER == false) {
      await sdk.data.documents.transition(backInfo['schemaID'], document.id, {
        id: schema.findTransitionIdByName('segmentation'),
        data: {
          filename: fileName,
          user: userInfo['id'],
          fileToken: fileToken,
          broken_location: fractureLocation,
          originFileToken: originFileToken
        }
      })
    } else {
      let backendInfo = {}

      if (document.data.indication == 'Clavicle') {
        // load second backend
        backendInfo = {
          schemaID: schema.id,
          documentID: document.id,
          indication: document.data.indication
        }
      } else {
        // Face

        backendInfo = {
          schemaID: schema.id,
          documentID: document.id,
          indication: document.data.indication,
          architecture: '3d_lowres'
        }
      }

      if (
        document.data.indication == 'Wrist' ||
        document.data.indication == 'Clavicle' ||
        document.data.indication == 'Face'
      ) {
        if (
          document.data.status === 'uploaded_file' ||
          (document.data.status !== 'uploaded_file' &&
            document.data.status !== 'seg_preprocessed' &&
            document.data.status !== 'segmented' &&
            document.data.status !== 'seg_postprocessed' &&
            document.data.status !== 'segmented_file')
        ) {
          let startTimestamp = performance.now()

          fetch('/segmentation', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(backendInfo)
          })
            .then((response) => {
              let endTimestamp = performance.now()
              let timeElapsed = endTimestamp - startTimestamp

              // console.log(`Fetch request took ${timeElapsed} milliseconds.`)

              if (timeElapsed > 10000 && response.status === 504) {
                console.log('Ignoring gateway timeout after 10 seconds.')
              } else {
                if (!response.ok) {
                  throw new Error(`Error ${response.status}: ${response.statusText}`)
                }
              }
            })
            .catch((error) => {
              console.error('Fetch error: ', error.message)

              window.alert(error.message)
              setLoadingValue(-1)
            })
        }
      } else {
        let startTimestamp = performance.now()

        fetch('/segmentation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(backendInfo)
        })
          .then((response) => {
            let endTimestamp = performance.now()
            let timeElapsed = endTimestamp - startTimestamp

            // console.log(`Fetch request took ${timeElapsed} milliseconds.`)

            if (timeElapsed > 10000 && response.status === 504) {
              console.log('Ignoring gateway timeout after 10 seconds.')
            } else {
              if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`)
              }
            }
          })
          .catch((error) => {
            console.error('Fetch error: ', error.message)

            window.alert(error.message)
            setLoadingValue(-1)
          })
      }
    }

    setBackInfo({
      ...backInfo,
      documentID: document.id
    })

    while (true) {
      resultDocument = await sdk.data.documents.findById(backInfo['schemaID'], document.id)

      if (resultDocument.data.status === 'stop') {
        setLoadingValue(0)
        return 'stop'
      }

      if (
        document.data.indication == 'Wrist' ||
        document.data.indication == 'Clavicle' ||
        document.data.indication == 'Face'
      ) {
        if (resultDocument.data.status === 'seg_preprocessed') {
          setLoadingValue(20)
        }

        if (resultDocument.data.status === 'segmented') {
          setLoadingValue(40)
        }

        if (resultDocument.data.status === 'seg_postprocessed') {
          setLoadingValue(60)
        }

        if (resultDocument.data.status === 'segmented_file') {
          setLoadingValue(80)
        }

        if (resultDocument.data.segToken !== '') {
          break
        }
      } else {
        if (resultDocument.data.segToken !== '') {
          break
        }
      }

      // wait for a short period of time before trying again
      await delay(1000)
    }

    await delay(1000)

    segToken = resultDocument.data.segToken
  }

  return `${
    process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
  }/files/v1/${segToken}/file`
}
