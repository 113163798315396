/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react'
import {
  Card,
  Typography,
  Chip,
  styled,
  Grid,
  Button,
  Avatar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { activeCaseCardHandler } from './ActiveCaseCardHandler'
import { activeCaseCardDeleteHandler } from './ActiveCaseCardDeleteHandler'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import { UserContext } from '../../../App'

const UserChip = styled(Chip)({
  color: '#fff',
  borderRadius: 8,
  margin: 8
})

function ActiveCaseCard({
  sdk,
  cases,
  setCurrentView,
  setIsLoading,
  filePath,
  setFilePath,
  setButtonState,
  setIsRenderingThreeDisabled,
  setDemo,
  setIndication,
  setBackInfo,
  backInfo
}) {
  const {
    setLoadingValue,
    setsliderSagittal,
    setsliderAxial,
    setsliderCoronal,
    setIsClickedCase,
    setIsoSliderInfo,
    setISOThreshold,
    volumeRange,
    setMainView,
    setImplantToken,
    setIsFirstfileSelected,
    setCurrentButton
  } = useContext(UserContext)

  const [open, setOpen] = useState(false)
  const [imageDialogOpen, setImageDialogOpen] = useState(false) // State to manage the image dialog

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleAvatarClick = () => {
    setImageDialogOpen(true) // Open the image dialog when avatar is clicked
  }

  const handleImageDialogClose = () => {
    setImageDialogOpen(false) // Close the image dialog
  }

  // Construct the profile image URL using the token
  const profileImageUrl = `/files/v1/${cases.data.profil_image_url}/file`

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: '#303030',
        color: '#FFF',
        padding: '10px',
        position: 'relative',
        borderRadius: '10px',
        width: '31%'
      }}
    >
      {cases.data.user === 'demo' && (
        <Chip
          label="Demo"
          sx={{ position: 'absolute', right: 16, backgroundColor: '#FFEEB0' }}
        ></Chip>
      )}
      {cases.data.user !== 'demo' && (
        <Chip
          label={cases.data.status !== 'complete' ? 'Planning Not Completed' : 'Planned'}
          sx={{
            position: 'absolute',
            right: 16,
            backgroundColor: cases.data.status !== 'complete' ? '#F29DA0' : '#FFCC9C'
          }}
        ></Chip>
      )}
      <Grid container direction="column">
        <Grid item container direction="row" alignItems="center">
          <Avatar
            src={profileImageUrl} // Use the constructed profile image URL
            sx={{ width: 70, height: 70, marginRight: 2, marginBottom: 2, cursor: 'pointer' }} // Add cursor pointer for clickability
            onClick={handleAvatarClick} // Add onClick handler to avatar
          />
          <Typography>
            <b>Case ID {cases.data.filename} </b>
          </Typography>
        </Grid>

        <Grid item container direction="row" alignItems="flex-end" xs={12}>
          <Grid item container direction="row" alignItems="flex-start" xs={6}>
            <Grid item>
              <UserChip
                label={cases.data.indication}
                variant="outlined"
                sx={{ marginLeft: '0px' }}
              />
            </Grid>
            <Grid item container direction="row">
              <UserChip
                label={`${new Date(cases.creationTimestamp).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit'
                })} ${new Date(cases.creationTimestamp).toLocaleTimeString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false
                })}`}
                variant="outlined"
                sx={{ marginLeft: '0px', marginBottom: '0px' }} // Adjust the styling as needed
              />
              <UserChip
                label={
                  cases.data.patientInfo !== undefined ? cases.data.patientInfo.gender : 'Other'
                }
                variant="outlined"
                sx={{ marginLeft: '0px', marginBottom: '0px' }}
              />
              <UserChip
                label={
                  cases.data.patientInfo !== undefined
                    ? cases.data.patientInfo.birthday
                    : '1900/01/01'
                }
                variant="outlined"
                sx={{ marginLeft: '0px', marginBottom: '0px' }}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row-reverse" alignItems="flex-end" spacing={2} xs={6}>
            <Grid item>
              {cases.data.user === 'demo' && (
                <Button
                  variant="contained"
                  startIcon={<VisibilityIcon />}
                  onClick={() =>
                    activeCaseCardHandler(
                      sdk,
                      cases,
                      setCurrentView,
                      setIsLoading,
                      filePath,
                      setFilePath,
                      setButtonState,
                      setIsRenderingThreeDisabled,
                      setDemo,
                      setIndication,
                      setBackInfo,
                      backInfo,
                      setLoadingValue,
                      setsliderSagittal,
                      setsliderAxial,
                      setsliderCoronal,
                      setIsClickedCase,
                      setIsoSliderInfo,
                      setISOThreshold,
                      volumeRange,
                      setMainView,
                      setImplantToken,
                      setIsFirstfileSelected,
                      setCurrentButton
                    )
                  }
                  sx={{
                    backgroundColor: '#036019',
                    paddingBottom: '10px'
                  }}
                >
                  {'Review Case'}
                </Button>
              )}
              {cases.data.user !== 'demo' && (
                <Button
                  variant="contained"
                  startIcon={cases.data.status === 'complete' ? <VisibilityIcon /> : <EditIcon />}
                  onClick={() =>
                    activeCaseCardHandler(
                      sdk,
                      cases,
                      setCurrentView,
                      setIsLoading,
                      filePath,
                      setFilePath,
                      setButtonState,
                      setIsRenderingThreeDisabled,
                      setDemo,
                      setIndication,
                      setBackInfo,
                      backInfo,
                      setLoadingValue,
                      setsliderSagittal,
                      setsliderAxial,
                      setsliderCoronal,
                      setIsClickedCase,
                      setIsoSliderInfo,
                      setISOThreshold,
                      volumeRange,
                      setMainView,
                      setImplantToken,
                      setIsFirstfileSelected,
                      setCurrentButton
                    )
                  }
                  sx={{
                    backgroundColor: '#036019',
                    paddingBottom: '10px'
                  }}
                >
                  {cases.data.status === 'complete' ? 'Review Case' : 'Edit Case'}
                </Button>
              )}
            </Grid>
            <Grid item>
              {cases.data.user !== 'demo' && (
                <Button
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  onClick={handleClickOpen}
                  sx={{
                    backgroundColor: '#D32F2F',
                    paddingBottom: '10px'
                  }}
                >
                  {'DELETE CASE'}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* Image Dialog for Enlarged Avatar */}
        <Dialog
          open={imageDialogOpen}
          onClose={handleImageDialogClose}
          maxWidth="lg"
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: '#303030', // Matching the active card's background color
              color: '#FFFFFF', // Set the default text color to white
              margin: 0, // Remove default margins
              overflow: 'hidden' // Ensure no overflow
            }
          }}
        >
          <DialogTitle sx={{ position: 'relative', paddingRight: '40px' }}>
            <Typography variant="h6" component="div">
              Case ID: {cases.data.filename}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleImageDialogClose}
              sx={{ position: 'absolute', right: 8, top: 8, color: '#FFFFFF' }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px'
            }}
          >
            <Avatar
              src={profileImageUrl} // Use the constructed profile image URL
              sx={{
                width: '50vw',
                height: '50vh',
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain' // Ensure the image is fully contained within its bounds
              }}
            />
          </DialogContent>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
          PaperProps={{
            style: {
              backgroundColor: '#232323',
              color: '#FFFFFF' // Sets default text color to white
            }
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: '#FFFFFF' }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            id="delete-dialog-title"
            sx={{ color: '#FF5733', fontWeight: 'bold', fontSize: '25px' }}
          >
            {'DELETE CASE'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="delete-dialog-description"
              sx={{ color: '#FFFFFF', fontSize: '18px' }}
            >
              Are you sure you want to remove this case?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => activeCaseCardDeleteHandler(sdk, cases)}
              sx={{
                backgroundColor: '#036019',
                color: '#FFFFFF',
                paddingBottom: '10px',
                '&:hover': {
                  backgroundColor: '#024712'
                }
              }}
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: '#D32F2F',
                color: '#FFFFFF',
                paddingBottom: '10px',
                '&:hover': {
                  backgroundColor: '#b22b2b'
                }
              }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Card>
  )
}

export default ActiveCaseCard
