/* eslint-disable react/prop-types */
import { demoDocumentLoader } from '../../maintool/functions/demo/DemoDocumentLoader'
import { getSchema } from '../../maintool/functions/others/GetSchema'

function sortObjectKeys(obj) {
  if (Object.keys(obj).length === 0) {
    return {}
  }

  const sortedKeys = Object.keys(obj).sort((a, b) => {
    return parseInt(a.replace('label', ''), 10) - parseInt(b.replace('label', ''), 10)
  })

  const sortedObj = {}
  sortedKeys.forEach((key) => {
    sortedObj[key] = obj[key]
  })

  return sortedObj
}

// function to handle when user click one of active case cards
export const activeCaseCardHandler = async (
  sdk,
  cases,
  setCurrentView,
  setIsLoading,
  filePath,
  setFilePath,
  setButtonState,
  setIsRenderingThreeDisabled,
  setDemo,
  setIndication,
  setBackInfo,
  backInfo,
  setLoadingValue,
  setsliderSagittal,
  setsliderAxial,
  setsliderCoronal,
  setIsClickedCase,
  setIsoSliderInfo,
  setISOThreshold,
  volumeRange,
  setMainView,
  setImplantToken,
  setIsFirstfileSelected,
  setCurrentButton
) => {
  setMainView('whole')
  setImplantToken({ token: '', description: '' })

  setCurrentButton('None')

  setIsoSliderInfo({
    initialized: false,
    min: null,
    max: null
  })

  setISOThreshold({
    min: volumeRange.min,
    max: volumeRange.max
  })

  setsliderSagittal({
    max: 0,
    current: -1
  })

  setsliderCoronal({
    max: 0,
    current: -1
  })

  setsliderAxial({
    max: 0,
    current: -1
  })

  setFilePath({
    filename: '',
    image: '',
    seg: '',
    iso: '',
    reg: '',
    reg_image: '',
    regObj: '',
    target_image: '',
    target: '',
    isolationObjects: {},
    repositionObjects: {},
    repositionTransformMatrix: {},
    manualRepositionTransformMatrix: {},
    targetObjects: {},
    side: '',
    model: {
      image: '',
      seg: '',
      iso: '',
      regObj: '',
      isolationObjects: {},
      repositionObjects: {},
      repositionTransformMatrix: {},
      targetObjects: {}
    }
  })

  if (cases.data.user === 'demo') {
    const schema = await getSchema(sdk)

    const fileName = cases.data.filename
    const fileUrl = await demoDocumentLoader(sdk, fileName, 'fileToken')
    const side = await demoDocumentLoader(sdk, fileName, 'side')

    setBackInfo({
      schemaID: schema.id,
      documentID: cases.id
    })

    setIndication(cases.data.indication)

    if (cases.data.indication !== 'Shoulder') {
      setFilePath({
        filename: fileName,
        image: fileUrl,
        side: side,
        model: {
          image: fileUrl
        }
      })
      setButtonState('Image')
    } else {
      const targetObjects = await demoDocumentLoader(sdk, fileName, 'targetObjects')
      const repositionObjects = await demoDocumentLoader(sdk, fileName, 'repositionObjects')
      const objFileUrl = await demoDocumentLoader(sdk, fileName, 'regObjToken')
      const sortedTargetObjects = sortObjectKeys(targetObjects)
      const sortedRepositionObjects = sortObjectKeys(repositionObjects)

      setFilePath({
        filename: fileName,
        image: fileUrl,
        side: side,
        regObj: objFileUrl,
        targetObjects: sortedTargetObjects,
        repositionObjects: sortedRepositionObjects,
        repositionTransformMatrix: {},
        manualRepositionTransformMatrix: {},
        model: {
          image: fileUrl
        }
      })

      setButtonState('Image')
    }

    setIsRenderingThreeDisabled(false)

    setDemo(true)

    setIsLoading(true)
    setLoadingValue(50)
  } else {
    // when user click patient active case
    const schema = await getSchema(sdk)

    if (cases.data.status === 'complete') {
      const repositionObjects = cases.data.repositionObjects
      const targetObjects = cases.data.targetObjects
      const isolationObjects = cases.data.isolationObjects
      const repositionTransformMatrix = cases.data.repositionTransformMatrix

      const side = cases.data.side

      let sortedRepositionTransformMatrix = {}

      const sortedRepositionObjects = sortObjectKeys(repositionObjects)
      const sortedTargetObjects = sortObjectKeys(targetObjects)
      const sortedIsolationObjects = sortObjectKeys(isolationObjects)

      if (repositionTransformMatrix !== undefined) {
        sortedRepositionTransformMatrix = sortObjectKeys(repositionTransformMatrix)
      }

      let sortedManualRepositionTransformMatrix = {}

      if (cases.data.manualRepositionTransformMatrix !== undefined) {
        sortedManualRepositionTransformMatrix = sortObjectKeys(
          cases.data.manualRepositionTransformMatrix
        )
      }

      setFilePath({
        ...filePath,
        filename: cases.data.filename,
        image: `${
          process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
        }/files/v1/${cases.data.fileToken}/file`,
        seg: `${
          process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
        }/files/v1/${cases.data.segToken}/file`,
        iso: `${
          process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
        }/files/v1/${cases.data.isoToken}/file`,
        reg: `${
          process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
        }/files/v1/${cases.data.regToken}/file`,
        reg_image: `${
          process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
        }/files/v1/${cases.data.regVolToken}/file`,
        regObj: `${
          process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
        }/files/v1/${cases.data.regObjToken}/file`,
        target_image: `${
          process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
        }/files/v1/${cases.data.targetVolToken}/file`,
        target: `${
          process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
        }/files/v1/${cases.data.targetToken}/file`,
        repositionObjects: sortedRepositionObjects,
        repositionTransformMatrix: sortedRepositionTransformMatrix,
        sortedManualRepositionTransformMatrix: sortedManualRepositionTransformMatrix,
        targetObjects: sortedTargetObjects,
        isolationObjects: sortedIsolationObjects,
        side: side,
        model: {
          ...filePath.model,
          image: `${
            process.env.REACT_APP_ENVIRONMENT === 'production'
              ? process.env.REACT_APP_HOST_NAME
              : ''
          }/files/v1/${cases.data.fileToken}/file`
        }
      })
    } else {
      let sortedRepositionObjects = {}
      let sortedTargetObjects = {}
      let sortedRepositionTransformMatrix = {}
      let sortedManualRepositionTransformMatrix = {}
      let sortedIsolationObjects = {}

      const side = cases.data.side

      if (cases.data.repositionObjects !== undefined) {
        sortedRepositionObjects = sortObjectKeys(cases.data.repositionObjects)
      }

      if (cases.data.targetObjects !== undefined) {
        sortedTargetObjects = sortObjectKeys(cases.data.targetObjects)
      }

      if (cases.data.repositionTransformMatrix !== undefined) {
        sortedRepositionTransformMatrix = sortObjectKeys(cases.data.repositionTransformMatrix)
      }

      if (cases.data.manualRepositionTransformMatrix !== undefined) {
        sortedManualRepositionTransformMatrix = sortObjectKeys(
          cases.data.manualRepositionTransformMatrix
        )
      }

      if (cases.data.isolationObjects !== undefined) {
        sortedIsolationObjects = sortObjectKeys(cases.data.isolationObjects)
      }

      setFilePath({
        ...filePath,
        filename: cases.data.filename,
        image: `${
          process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
        }/files/v1/${cases.data.fileToken}/file`,
        seg:
          cases.data.segToken !== ''
            ? `${
                process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? process.env.REACT_APP_HOST_NAME
                  : ''
              }/files/v1/${cases.data.segToken}/file`
            : '',
        iso:
          cases.data.isoToken !== ''
            ? `${
                process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? process.env.REACT_APP_HOST_NAME
                  : ''
              }/files/v1/${cases.data.isoToken}/file`
            : '',
        reg:
          cases.data.regToken !== ''
            ? `${
                process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? process.env.REACT_APP_HOST_NAME
                  : ''
              }/files/v1/${cases.data.regToken}/file`
            : '',
        reg_image:
          cases.data.regVolToken !== ''
            ? `${
                process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? process.env.REACT_APP_HOST_NAME
                  : ''
              }/files/v1/${cases.data.regVolToken}/file`
            : '',
        regObj:
          cases.data.regObjToken !== ''
            ? `${
                process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? process.env.REACT_APP_HOST_NAME
                  : ''
              }/files/v1/${cases.data.regObjToken}/file`
            : '',
        target_image:
          cases.data.targetVolToken !== ''
            ? `${
                process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? process.env.REACT_APP_HOST_NAME
                  : ''
              }/files/v1/${cases.data.targetVolToken}/file`
            : '',
        target:
          cases.data.targetToken !== ''
            ? `${
                process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? process.env.REACT_APP_HOST_NAME
                  : ''
              }/files/v1/${cases.data.targetToken}/file`
            : '',
        repositionObjects: sortedRepositionObjects,
        repositionTransformMatrix: sortedRepositionTransformMatrix,
        manualRepositionTransformMatrix: sortedManualRepositionTransformMatrix,
        targetObjects: sortedTargetObjects,
        isolationObjects: sortedIsolationObjects,
        side: side,
        model: {
          ...filePath.model,
          image: `${
            process.env.REACT_APP_ENVIRONMENT === 'production'
              ? process.env.REACT_APP_HOST_NAME
              : ''
          }/files/v1/${cases.data.fileToken}/file`
        }
      })
    }

    setBackInfo({
      ...backInfo,
      schemaID: schema.id,
      documentID: cases.id
    })

    setIndication(cases.data.indication)

    setButtonState('Image')

    setIsRenderingThreeDisabled(false)

    setDemo(false)

    setIsLoading(true)
    setLoadingValue(50)
  }

  // check activecard is clicked to go to current patient case
  setIsClickedCase(true)

  setIsFirstfileSelected(true)

  setCurrentView('main') // go to main view
}
