/* eslint-disable react/prop-types */
import React from 'react'
import Slider from '@mui/material/Slider'

const AxialSlider = ({ sliderAxial: sliderAxial, setsliderAxial: setsliderAxial }) => {
  const axialSliderHandler = (event, value) => {
    setsliderAxial({
      ...sliderAxial,
      current: value
    })
  }

  return (
    <Slider
      id="axialSlider"
      defaultValue={0}
      value={sliderAxial.current}
      aria-label="Small"
      valueLabelDisplay="auto"
      max={sliderAxial.max}
      sx={{
        width: '95%',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        '& .MuiSlider-rail': {
          height: 10,
          backgroundColor: '#EFEFEF'
        },
        '& .MuiSlider-track': {
          height: 10,
          backgroundColor: '#EFEFEF'
        },
        '& .MuiSlider-thumb': {
          backgroundColor: '#2C622C' // Green color
        }
      }}
      onChange={axialSliderHandler} // Add the onChange event handler
    ></Slider>
  )
}

const CoronalSlider = ({ sliderCoronal: sliderCoronal, setsliderCoronal: setsliderCoronal }) => {
  const coronalSliderHandler = (event, value) => {
    setsliderCoronal({
      ...sliderCoronal,
      current: value
    })
  }

  return (
    <Slider
      id="coronalSlider"
      defaultValue={70}
      value={sliderCoronal.current}
      aria-label="Small"
      valueLabelDisplay="auto"
      max={sliderCoronal.max}
      sx={{
        width: '95%',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        '& .MuiSlider-rail': {
          height: 10,
          backgroundColor: '#EFEFEF'
        },
        '& .MuiSlider-track': {
          height: 10,
          backgroundColor: '#EFEFEF'
        },
        '& .MuiSlider-thumb': {
          backgroundColor: '#2C622C' // Green color
        }
      }}
      onChange={coronalSliderHandler} // Add the onChange event handler
    ></Slider>
  )
}

const SagittalSlider = ({
  sliderSagittal: sliderSagittal,
  setsliderSagittal: setsliderSagittal
}) => {
  const sagittalSliderHandler = (event, value) => {
    setsliderSagittal({
      ...sliderSagittal,
      current: value
    })
  }

  return (
    <Slider
      id="sagittalSlider"
      defaultValue={0}
      value={sliderSagittal.current}
      aria-label="Small"
      valueLabelDisplay="auto"
      max={sliderSagittal.max}
      sx={{
        width: '95%',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        '& .MuiSlider-rail': {
          height: 10,
          backgroundColor: '#EFEFEF'
        },
        '& .MuiSlider-track': {
          height: 10,
          backgroundColor: '#EFEFEF'
        },
        '& .MuiSlider-thumb': {
          backgroundColor: '#2C622C' // Green color
        }
      }}
      onChange={sagittalSliderHandler} // Add the onChange event handler
    ></Slider>
  )
}

export { AxialSlider, CoronalSlider, SagittalSlider }
