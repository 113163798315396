/* eslint-disable react/prop-types */
import React from 'react'
import { Box, Typography, LinearProgress } from '@mui/material'

// progress bar component to show current status of title
function ProgressBar({ title, status }) {
  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        marginTop: '10px', // Add your desired margin value
        width: '50%',
        background: 'rgba(217, 217, 217, 0.12)',
        border: '1px solid #D9D9D9',
        borderRadius: '10px'
      }}
    >
      <Box sx={{ marginBottom: '10px', marginTop: '10px', marginLeft: '5px' }}>
        <Typography variant="h10" gutterBottom sx={{ color: 'white' }}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: '10px', marginLeft: '5px' }}>
        {status === 'progress' && <LinearProgress sx={{ width: '95%' }} />}
        {status === 'done' && (
          <LinearProgress variant="determinate" value={100} sx={{ width: '95%' }} />
        )}
      </Box>
    </Box>
  )
}

export default ProgressBar
