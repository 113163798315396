import * as THREE from 'three'

export function removeAllObjects(scene, targetObjectName) {
  // Start the loop
  let objectFound = true

  while (objectFound) {
    // Check if the object exists in the scene
    const object = scene.getObjectByName(targetObjectName)
    // If the object is found, set the flag to true and exit the loop
    if (object == undefined) {
      objectFound = false
    } else {
      removeObject(scene, targetObjectName)
    }
  }
}

export function removeObject(scene, name) {
  var selectedObject = scene.getObjectByName(name)
  scene.remove(selectedObject)
}

export function removeObjectSceneRef(scene, name) {
  var selectedObject = scene.current.getObjectByName(name)
  scene.current.remove(selectedObject)
}

// Function to add a circle to the scene
export function addCircle(
  scene,
  radius,
  centerX,
  centerY,
  centerZ = 0,
  segments = 32,
  color = 0x00ff00,
  name = 'circle'
) {
  // Create the geometry for circle
  const circleGeometry = new THREE.CircleGeometry(radius, segments)

  // Create the material for circle
  const circleMaterial = new THREE.MeshBasicMaterial({ color: color })

  // Create the circle mesh
  const circle = new THREE.Mesh(circleGeometry, circleMaterial)

  // Set the center of the circle
  circle.position.set(centerX, centerY, centerZ)
  circle.name = name
  // Add the circle to the scene
  scene.add(circle)
}

export function calculateAndAdjustMousePoints(
  event,
  viewId,
  volume,
  geometry,
  scene,
  camera,
  Radius,
  addedCircle = true
) {
  let adjustedMousePoints = []
  let pointer = new THREE.Vector3()
  let raycaster = new THREE.Raycaster()
  let bbox = event.currentTarget.getBoundingClientRect()

  pointer.set(
    ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1,
    -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1,
    0
  )
  raycaster.setFromCamera(pointer, camera)
  const intersects = raycaster.intersectObjects(scene.children)

  if (intersects.length > 0) {
    let aspectX, aspectY
    let adjustedX, adjustedY

    if (addedCircle) {
      // Visualize the point with a circle (if needed)
      addCircle(
        scene,
        Radius.radius,
        intersects[0].point.x,
        intersects[0].point.y,
        0,
        32,
        'white',
        'segmentCircle'
      )
    }

    switch (viewId) {
      case 'View1':
        aspectX = volume.RASDimensions[0] / geometry.parameters.width
        aspectY = volume.RASDimensions[2] / geometry.parameters.height

        adjustedX = (intersects[0].point.x + geometry.parameters.width / 2) * aspectX
        adjustedY = (-intersects[0].point.y + geometry.parameters.height / 2) * aspectY
        break
      case 'View2':
        aspectX = volume.RASDimensions[0] / geometry.parameters.width
        aspectY = volume.RASDimensions[1] / geometry.parameters.height

        adjustedX = (intersects[0].point.x + geometry.parameters.width / 2) * aspectX
        adjustedY = (intersects[0].point.y + geometry.parameters.height / 2) * aspectY
        break
      case 'View3':
        aspectX = volume.RASDimensions[2] / geometry.parameters.width
        aspectY = volume.RASDimensions[1] / geometry.parameters.height
        adjustedX = (-intersects[0].point.x + geometry.parameters.width / 2) * aspectX
        adjustedY = (intersects[0].point.y + geometry.parameters.height / 2) * aspectY
        break
    }

    adjustedMousePoints.push({
      x: Math.floor(adjustedX),
      y: Math.floor(adjustedY)
    })
  }

  return adjustedMousePoints
}

export function segCircle(
  event,
  viewId,
  segmenting,
  scene,
  camera,
  Radius,
  volume,
  geometry,
  mousePoints
) {
  if (segmenting === false) {
    removeAllObjects(scene, 'segmentCircle')
  }

  // Calculate and adjust mouse points based on the interaction
  const adjustedPoints = calculateAndAdjustMousePoints(
    event,
    viewId,
    volume,
    geometry,
    scene,
    camera,
    Radius,
    true
  )

  // Optionally visualize the points or perform other actions
  adjustedPoints.forEach((point) => {
    // Check if the point already exists in mousePoints to avoid duplication
    const pointExists = mousePoints.some(
      (existingPoint) => existingPoint.x === point.x && existingPoint.y === point.y
    )

    if (!pointExists) {
      mousePoints.push(point) // Store for later use in segmentation
    }
  })
}

export function eraseSegment(
  mousePoints,
  label,
  Radius,
  sliceSeg,
  selectedSegment,
  setLabel,
  setIsSegmentToolAffected,
  clickedPoint,
  volume,
  viewId,
  geometry
) {
  let currentLabel = label
  let isChanged = false

  // Determine aspect ratios based on viewId and geometry
  let aspectX, aspectY
  switch (viewId) {
    case 'View1':
      aspectX = volume.RASDimensions[0] / geometry.parameters.width
      aspectY = volume.RASDimensions[2] / geometry.parameters.height
      break
    case 'View2':
      aspectX = volume.RASDimensions[0] / geometry.parameters.width
      aspectY = volume.RASDimensions[1] / geometry.parameters.height
      break
    case 'View3':
      aspectX = volume.RASDimensions[2] / geometry.parameters.width
      aspectY = volume.RASDimensions[1] / geometry.parameters.height
      break
  }

  // Adjusted radius for elliptical calculation based on aspect ratios
  const adjustedRadiusX = Radius.radius * aspectX
  const adjustedRadiusY = Radius.radius * aspectY

  // Combine mousePoints with clickedPoint, avoiding duplicates
  const combinedPoints = [...mousePoints]
  if (
    clickedPoint &&
    clickedPoint.length > 0 &&
    !mousePoints.some((p) => p.x === clickedPoint[0].x && p.y === clickedPoint[0].y)
  ) {
    combinedPoints.push(clickedPoint[0])
  }

  combinedPoints.forEach((point) => {
    if (point && 'x' in point && 'y' in point) {
      for (
        let x = Math.floor(point.x - adjustedRadiusX);
        x <= Math.ceil(point.x + adjustedRadiusX);
        x++
      ) {
        for (
          let y = Math.floor(point.y - adjustedRadiusY);
          y <= Math.ceil(point.y + adjustedRadiusY);
          y++
        ) {
          const dx = x - point.x
          const dy = y - point.y
          // Adjusting the condition for elliptical area based on aspect ratios
          if (
            (dx * dx) / (adjustedRadiusX * adjustedRadiusX) +
              (dy * dy) / (adjustedRadiusY * adjustedRadiusY) <=
            1
          ) {
            // Erase if the current label matches the selectedSegment
            if (currentLabel.data[sliceSeg.sliceAccess(x, y)] === selectedSegment) {
              currentLabel.data[sliceSeg.sliceAccess(x, y)] = 0
              isChanged = true
            }
          }
        }
      }
    }
  })

  if (isChanged) {
    setIsSegmentToolAffected(true)
    setLabel(currentLabel) // Trigger any necessary state updates or re-renders
  }
}

export function addSegment(
  mousePoints,
  Radius,
  slice,
  sliceSeg,
  volume,
  label,
  selectedSegment,
  setLabel,
  labelThresholdRange,
  setIsSegmentToolAffected,
  clickedPoint,
  viewId,
  geometry
) {
  const currentLabel = label
  let isChanged = false

  // Combine mousePoints with clickedPoint, avoiding duplicates
  const combinedPoints = [...mousePoints]
  if (
    clickedPoint &&
    !mousePoints.some((p) => p.x === clickedPoint[0].x && p.y === clickedPoint[0].y)
  ) {
    combinedPoints.push(clickedPoint[0])
  }

  let aspectX, aspectY

  switch (viewId) {
    case 'View1':
      aspectX = volume.RASDimensions[0] / geometry.parameters.width
      aspectY = volume.RASDimensions[2] / geometry.parameters.height

      break
    case 'View2':
      aspectX = volume.RASDimensions[0] / geometry.parameters.width
      aspectY = volume.RASDimensions[1] / geometry.parameters.height

      break
    case 'View3':
      aspectX = volume.RASDimensions[2] / geometry.parameters.width
      aspectY = volume.RASDimensions[1] / geometry.parameters.height

      break
  }

  combinedPoints.forEach((point) => {
    if (point && 'x' in point && 'y' in point) {
      // Adjusted radius for elliptical calculation
      const adjustedRadiusX = Radius.radius * aspectX
      const adjustedRadiusY = Radius.radius * aspectY
      // const radiusSquared = Radius.radius * Radius.radius // Original circular radius for reference

      for (
        let x = Math.floor(point.x - adjustedRadiusX);
        x <= Math.ceil(point.x + adjustedRadiusX);
        x++
      ) {
        for (
          let y = Math.floor(point.y - adjustedRadiusY);
          y <= Math.ceil(point.y + adjustedRadiusY);
          y++
        ) {
          const dx = x - point.x
          const dy = y - point.y
          // Adjusting the condition for elliptical area
          if (
            (dx * dx) / (adjustedRadiusX * adjustedRadiusX) +
              (dy * dy) / (adjustedRadiusY * adjustedRadiusY) <=
            1
          ) {
            let value = volume.data[slice.sliceAccess(x, y)]
            if (value >= labelThresholdRange.min && value <= labelThresholdRange.max) {
              let labelValue = currentLabel.data[sliceSeg.sliceAccess(x, y)]
              if (labelValue === 0) {
                currentLabel.data[sliceSeg.sliceAccess(x, y)] = selectedSegment
                isChanged = true
              }
            }
          }
        }
      }
    }
  })

  if (isChanged) {
    setIsSegmentToolAffected(true)
    setLabel(currentLabel) // Make sure to trigger any necessary state updates or re-renders
  }
}

export function createLine(event, camera, scene) {
  let pointer = new THREE.Vector3()
  let raycaster = new THREE.Raycaster()
  let bbox = event.currentTarget.getBoundingClientRect()

  pointer.set(
    ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1,
    -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1,
    0
  )
  raycaster.setFromCamera(pointer, camera)
  const intersects = raycaster.intersectObjects(scene.children)

  if (intersects.length > 0) {
    addCircle(
      scene,
      10,
      intersects[0].point.x,
      intersects[0].point.y,
      0,
      32,
      'white',
      'newlineCircle'
    )
    // console.log("the circle coordinates are: ", intersects[ 0 ].point.x, intersects[ 0 ].point.y);
  }
}
