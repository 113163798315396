/* eslint-disable react/prop-types */
import React from 'react'
import { Button, Box } from '@mui/material'
import { indicationButtonHandler } from './IndicationButtonHandler'

const IndicationButton = ({ setIsClicked, setIndication, setLocationSelected }) => {
  const options = ['Clavicle', 'Face', 'Wrist']

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        top: '40%',
        left: '45%',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
      }}
      className="modal"
    >
      <Box
        sx={{
          backgroundColor: '#232323',
          width: '120px',
          height: `${options.length * 40 + 60}px`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        className="option-content"
      >
        <Box
          sx={{
            color: '#fff',
            fontSize: '15px',
            marginBottom: '10px'
          }}
        >
          Indication
        </Box>
        {options.map((option) => (
          <Button
            key={option}
            variant="contained"
            sx={{
              color: '#fff',
              borderWidth: '3px',
              borderRadius: '8px',
              margin: '5px',
              fontSize: '15px',
              backgroundColor: '#2C622C',
              width: '150px',
              textTransform: 'none'
            }}
            onClick={() =>
              indicationButtonHandler(option, setIndication, setIsClicked, setLocationSelected)
            }
          >
            {option}
          </Button>
        ))}
      </Box>
    </Box>
  )
}

export default IndicationButton
