/* eslint-disable react/prop-types */
import React from 'react'
import { Switch, Grid, Typography } from '@mui/material'

const MainView3DViewBox = ({ viewBoxOn, setViewBoxOn }) => {
  const handleChange = (event) => {
    setViewBoxOn(event.target.checked)
  }

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: '#FFFFFF',
          fontFamily: 'Open Sans',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px',
          flex: 'none',
          order: 0,
          flexGrow: 0
        }}
      >
        3D View Box Controller
      </Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography
            sx={{ color: '#FFFFFF', fontWeight: 400, fontSize: '12px', lineHeight: '20px' }}
          >
            3D View Box
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            sx={{
              color: viewBoxOn ? '#4CAF50' : '#F44336', // Green for "On", Red for "Off"
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
              marginRight: '8px'
            }}
          >
            {viewBoxOn ? 'On' : 'Off'}
          </Typography>
        </Grid>
        <Grid item>
          <Switch checked={viewBoxOn} onChange={handleChange} />
        </Grid>
      </Grid>
    </>
  )
}

export default MainView3DViewBox
