/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react'
import { Box, useTheme, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material'
import Draggable from 'react-draggable'
import StraightenIcon from '@mui/icons-material/Straighten'
import InfoIcon from '@mui/icons-material/Info'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import OpenWithIcon from '@mui/icons-material/OpenWith'
import CloseButton from './CloseButton'

// measurement tool function
const MainViewMeasureToolBox = ({
  setMeasureConfig: setMeasureConfig,
  labelBoxMargin: labelBoxMargin,
  setShowToolBox: setShowToolBox
}) => {
  const theme = useTheme()

  const [currentMeasureMenu, setCurrentMeasureMenu] = useState('Measure')
  const draggableRef = useRef(null)

  const handleDistanceClick = () => {
    setMeasureConfig({ type: 'Measure' })
    setCurrentMeasureMenu('Measure')
  }

  const handleAngleClick = () => {
    setMeasureConfig({ type: 'Angle' })
    setCurrentMeasureMenu('Angle')
  }

  return (
    // dragging is working on any location in box
    <Draggable nodeRef={draggableRef} handle=".drag-icon">
      <Box
        ref={draggableRef}
        sx={{
          position: 'absolute',
          top: `calc(${labelBoxMargin}% + ${theme.sizes.nav.top} + 100px)`,
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '16px 0px',
          gap: '16px',
          width: '200px',
          height: '120px',
          background: '#232323',
          boxShadow: '0px 4px 20px rgba(255, 255, 255, 0.25)',
          borderRadius: '8px',
          zIndex: 9990,
          right: '10px',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Open Sans',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
              flex: 'none',
              order: 0,
              flexGrow: 0
            }}
          >
            Measurement
          </Typography>

          <Box alignItems="center">
            <OpenWithIcon
              className="drag-icon"
              sx={{
                color: '#FFFFFF',
                cursor: 'move',
                backgroundColor: '',
                borderRadius: '50%',
                fontSize: '25px',
                zIndex: 9999
              }}
            />
            <CloseButton setShowToolBox={setShowToolBox} />
          </Box>
        </Box>

        <ToggleButtonGroup
          value={currentMeasureMenu}
          exclusive
          onChange={(event, currentMeasureMenu) => setCurrentMeasureMenu(currentMeasureMenu)}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <ToggleButton
            value="Measure"
            onClick={handleDistanceClick}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#91C58E'
              }
            }}
          >
            <StraightenIcon
              sx={{
                order: 1,
                color: '#FFFFFF',
                marginRight: '10px',
                transform: 'scaleX(-1)'
              }}
            />
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: '#FFFFFF',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '20px',
                flex: 'none',
                order: 2,
                flexGrow: 0,
                marginRight: '10px',
                marginTop: '5px'
              }}
            >
              Distance
            </Typography>
            <InfoIcon sx={{ order: 3, color: '#FFFFFF', fontSize: '12px' }} />
          </ToggleButton>
          <ToggleButton
            value="Angle"
            onClick={handleAngleClick}
            sx={{
              '&.Mui-selected': {
                backgroundColor: '#91C58E'
              }
            }}
          >
            <SquareFootIcon
              sx={{
                order: 1,
                color: '#FFFFFF',
                marginRight: '10px',
                transform: 'scaleX(-1)'
              }}
            />
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: '#FFFFFF',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '20px',
                flex: 'none',
                order: 2,
                flexGrow: 0,
                marginRight: '10px',
                marginTop: '5px'
              }}
            >
              Angle
            </Typography>
            <InfoIcon sx={{ order: 3, color: '#FFFFFF', fontSize: '12px' }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Draggable>
  )
}

export default MainViewMeasureToolBox
