/* eslint-disable react/prop-types */
import { segmentationButtonHandler } from '../segmentation/SegmentationButtonHandler'
import { isolationButtonHandler } from '../isolation/IsolationButtonHandler'
import { repositionButtonHandler } from '../reposition/RepositionButtonHandler'
import { targetAnatomyButtonHandler } from '../targetAnatomy/TargetAnatomyButtonHandler'

// back button handler in second line of header
export const mainViewBackButtonHandler = async (
  indication,
  buttonState,
  setButtonState,
  buttonStates,
  sdk,
  isDemo,
  setIsLoading,
  filePath,
  setFilePath,
  backInfo,
  setBackInfo,
  userInfo,
  setLoadingValue
) => {
  if (indication === 'Face') {
    if (buttonState === 'Rep') {
      // Skip 'Iso' state because face model doesn't have isolation part
      const currentIndex = buttonStates.indexOf(buttonState)
      const backIndex = currentIndex - 2 // Skip one state
      const backState = buttonStates[backIndex]
      if (backState === 'Seg') {
        segmentationButtonHandler(
          sdk,
          isDemo,
          setIsLoading,
          filePath,
          setFilePath,
          setButtonState,
          backInfo,
          setBackInfo,
          userInfo,
          setLoadingValue,
          indication
        )
      }
    } else {
      // Proceed to back state as usual
      const currentIndex = buttonStates.indexOf(buttonState)
      const backIndex = currentIndex - 1

      if (backIndex > 0) {
        const backState = buttonStates[backIndex]
        if (backState === 'Target') {
          targetAnatomyButtonHandler(
            sdk,
            isDemo,
            setIsLoading,
            filePath,
            setFilePath,
            setButtonState,
            backInfo,
            setBackInfo,
            userInfo,
            setLoadingValue
          )
        } else if (backState === 'Rep') {
          repositionButtonHandler(
            sdk,
            isDemo,
            setIsLoading,
            filePath,
            setFilePath,
            setButtonState,
            backInfo,
            setBackInfo,
            userInfo,
            setLoadingValue
          )
        } else if (backState === 'Image') {
          setButtonState(backState)
        }
      } else {
        // console.log('The First')
        window.alert('This is the first step ')
      }
    }
  } else {
    // Proceed to next state as usual
    const currentIndex = buttonStates.indexOf(buttonState)
    const backIndex = currentIndex - 1

    if (backIndex > 0) {
      const backState = buttonStates[backIndex]
      if (backState === 'Seg') {
        segmentationButtonHandler(
          sdk,
          isDemo,
          setIsLoading,
          filePath,
          setFilePath,
          setButtonState,
          backInfo,
          setBackInfo,
          userInfo,
          setLoadingValue,
          indication
        )
      } else if (backState === 'Iso') {
        isolationButtonHandler(
          sdk,
          isDemo,
          setIsLoading,
          filePath,
          setFilePath,
          setButtonState,
          backInfo,
          setBackInfo,
          userInfo,
          setLoadingValue
        )
      } else if (backState === 'Rep') {
        repositionButtonHandler(
          sdk,
          isDemo,
          setIsLoading,
          filePath,
          setFilePath,
          setButtonState,
          backInfo,
          setBackInfo,
          userInfo,
          setLoadingValue
        )
      } else if (backState === 'Target') {
        targetAnatomyButtonHandler(
          sdk,
          isDemo,
          setIsLoading,
          filePath,
          setFilePath,
          setButtonState,
          backInfo,
          setBackInfo,
          userInfo,
          setLoadingValue
        )
      } else if (backState === 'Image') {
        setButtonState(backState)
      }
    } else {
      // console.log('The First')
      window.alert('This is the first step ')
    }
  }

  // console.log('Back')
}
