/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
import { rqlBuilder } from '@extrahorizon/javascript-sdk'
import _ from 'lodash'

export const targetAnatomyLoader = async (
  sdk,
  backInfo,
  fileName,
  userInfo,
  setBackInfo,
  setLoadingValue
) => {
  setLoadingValue(0)

  const delay = (ms) => new Promise((res) => setTimeout(res, ms))

  let resultDocument

  const schema = await sdk.data.schemas.findByName('Pipeline', {
    rql: rqlBuilder().select(['id', 'name', 'transitions']).build()
  })

  let document = await sdk.data.documents.findById(backInfo['schemaID'], backInfo['documentID'])

  let targetObjects
  let regObjToken
  let isolationObjects
  let repositionTransformMatrix
  let manualRepositionTransformMatrix

  const currentTargetObjects = document.data.targetObjects
  const latestTargetObjects = document.data.latest_version.targetObjects

  if (!_.isEqual(currentTargetObjects, latestTargetObjects)) {
    regObjToken = ''
  } else {
    regObjToken = document.data.regObjToken
    targetObjects = document.data.targetObjects
    isolationObjects = document.data.isolationObjects
    repositionTransformMatrix = document.data.repositionTransformMatrix
    manualRepositionTransformMatrix = document.data.manualRepositionTransformMatrix
  }

  await delay(1000)

  if (regObjToken === '') {
    if (process.env.REMOTE_SERVER == false) {
      console.log('not Yet setup')
    } else {
      let backendInfo = {}
      // load second backend
      backendInfo = {
        schemaID: schema.id,
        documentID: document.id,
        indication: document.data.indication
      }

      if (
        document.data.status === 'registered_file' ||
        (document.data.status !== 'target_created_obj' &&
          document.data.status !== 'create_animation_obj' &&
          document.data.status !== 'complete')
      ) {
        let startTimestamp = performance.now()

        fetch('/target_anatomy', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(backendInfo)
        })
          .then((response) => {
            let endTimestamp = performance.now()
            let timeElapsed = endTimestamp - startTimestamp

            // console.log(`Fetch request took ${timeElapsed} milliseconds.`)

            if (timeElapsed > 10000 && response.status === 504) {
              console.log('Ignoring gateway timeout after 10 seconds.')
            } else {
              if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`)
              }
            }
          })
          .catch((error) => {
            console.error('Fetch error: ', error.message)

            setLoadingValue(-1)
            // window.alert(error.message)
          })
      }

      setBackInfo({
        ...backInfo,
        documentID: document.id
      })

      // loop until resultDocument.data.test is not null
      while (true) {
        resultDocument = await sdk.data.documents.findById(backInfo['schemaID'], document.id)

        if (resultDocument.data.status === 'stop') {
          setLoadingValue(0)
          return ['stop', null, null]
        }

        if (resultDocument.data.status === 'target_created_obj') {
          setLoadingValue(25)
        }
        if (resultDocument.data.status === 'create_animation_obj') {
          setLoadingValue(50)
        }
        if (resultDocument.data.status === 'complete') {
          setLoadingValue(66)
        }

        if (resultDocument.data.regObjToken !== '') {
          break
        }
      }

      // wait for a short period of time before trying again
      await delay(1000)

      if (resultDocument.data.targetObjects !== undefined) {
        targetObjects = resultDocument.data.targetObjects
      }

      if (resultDocument.data.isolationObjects !== undefined) {
        isolationObjects = resultDocument.data.isolationObjects
      }

      if (resultDocument.data.repositionTransformMatrix !== undefined) {
        repositionTransformMatrix = resultDocument.data.repositionTransformMatrix
      }

      if (resultDocument.data.manualRepositionTransformMatrix !== undefined) {
        manualRepositionTransformMatrix = resultDocument.data.manualRepositionTransformMatrix
      }

      if (process.env.REMOTE_SERVER == false) {
        // complete tasks
        await sdk.data.documents.transition(backInfo['schemaID'], document.id, {
          id: schema.findTransitionIdByName('complete'),
          data: {
            filename: fileName,
            user: userInfo['id'],
            broken_location: resultDocument.data.broken_location,
            resultFolderToken: resultDocument.data.resultFolderToken,
            originFileToken: resultDocument.data.originFileToken,
            fileToken: resultDocument.data.fileToken,
            segToken: resultDocument.data.segToken,
            isoToken: resultDocument.data.isoToken,
            regToken: resultDocument.data.regToken,
            regVolToken: resultDocument.data.regVolToken,
            regObjToken: resultDocument.data.regObjToken,
            targetVolToken: resultDocument.data.targetVolToken,
            targetToken: resultDocument.data.targetToken
          }
        })
      }
    }
  }

  return [
    targetObjects,
    isolationObjects,
    repositionTransformMatrix,
    manualRepositionTransformMatrix
  ]
}
