/* eslint-disable react/prop-types */
import React from 'react'
import * as THREE from 'three'

import { TrackballControls } from './TrackballControlls'

import { useEffect, useRef, useState } from 'react'
import Stats from 'stats.js'
import { removeObject } from './SegmentToolHandler'
import { addLights } from '../functions/labelPositioning/AddLights'
// import { loadIsolationObj } from '../functions/targetAnatomy/LoadIsolationObj'
import { VideoPlayerControls } from './VideoPlayerControls'
import { matrix, multiply, inv, diag } from 'mathjs'
import { colorMapArray } from './ColorMap.jsx'

import {
  addBoundingBoxAndLabels,
  toggleBoundingBoxVisibility,
  calculateNewCameraPosition
} from './ViewBoxUtils.jsx'

const AnimationTargetAnatomy = ({
  mainGUIRef: mainGUIRef,
  filePath: filePath,
  viewBoxRef4: viewBoxRef4,
  stats: stats,
  checkedValues: checkedValues,
  labelColorSwitch: labelColorSwitch,
  cameraResetClicked: cameraResetClicked,
  setCameraResetClicked: setCameraResetClicked,
  rotationVector: rotationVector,
  setRotationVector: setRotationVector,
  viewBoxOn: viewBoxOn,
  setIsLoading: setIsLoading,
  isoLoadedObjects: isoLoadedObjects,
  rotateSpeed: rotateSpeed
}) => {
  const containerRef = useRef(null)
  const scene = useRef(new THREE.Scene())
  const cameraRef = useRef()
  const rendererRef = useRef()
  const controlsRef = useRef()
  const initFinished = useRef(false)

  const [initBool, setInitBool] = useState(false)
  const [objectInit, setObjectInit] = useState(false)
  const [initialCameraState, setInitialCameraState] = useState({
    set: false,
    camera: null
  })

  const cameraPositionsRef = useRef({})
  const boundingBoxMeshRef = useRef(null)

  const [objectsLoaded, setObjectsLoaded] = useState({})
  const [transformDict, setTransformDict] = useState({})
  const [additionDict, setAdditionDict] = useState({})
  const [objectInfo, setObjectInfo] = useState({})

  const identityMatrix = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]

  const animationProgress = useRef(0)
  let animationDirection = 0.001 // The speed of the animation

  const [currentTime, setCurrentTime] = useState(0)
  const duration = 100
  const [isPlaying, setIsPlaying] = useState(true)
  const currentPlayMode = useRef(false)

  const lastUpdateTime = useRef(Date.now())

  const onPlayPause = () => {
    setIsPlaying(!isPlaying)
  }

  const onForward = () => {
    setIsPlaying(false)
    const newProgress = Math.min(animationProgress.current + 0.01, 1)
    animationProgress.current = newProgress
    setCurrentTime(Math.floor(newProgress * duration))
  }

  const onBackward = () => {
    setIsPlaying(false)
    const newProgress = Math.max(animationProgress.current - 0.01, 0)
    animationProgress.current = newProgress
    setCurrentTime(Math.floor(newProgress * duration))
  }

  const onSeek = (newValue) => {
    setIsPlaying(false)
    animationProgress.current = newValue / duration
    setCurrentTime(newValue)
  }

  const decomposeMatrix = (matrix) => {
    let translationInfo = new THREE.Vector3()
    let rotation = new THREE.Quaternion()
    let scale = new THREE.Vector3()

    matrix.decompose(translationInfo, rotation, scale)

    let rotationQuaternion = new THREE.Euler().setFromQuaternion(rotation, 'XYZ')

    // Convert radians to degrees
    let rotationInfo = {
      x: THREE.MathUtils.radToDeg(rotationQuaternion.x),
      y: THREE.MathUtils.radToDeg(rotationQuaternion.y),
      z: THREE.MathUtils.radToDeg(rotationQuaternion.z)
    }

    return { translationInfo, rotationInfo }
  }

  const getNewTransformMatrix = (center, rotation, translation) => {
    // Convert rotation angles from degrees to radians
    const rotationX = THREE.MathUtils.degToRad(rotation.x)
    const rotationY = THREE.MathUtils.degToRad(rotation.y)
    const rotationZ = THREE.MathUtils.degToRad(rotation.z)

    // Create rotation matrices for each axis
    const matrixX = new THREE.Matrix4().makeRotationX(rotationX)
    const matrixY = new THREE.Matrix4().makeRotationY(rotationY)
    const matrixZ = new THREE.Matrix4().makeRotationZ(rotationZ)

    // Combine rotation matrices
    const combinedRotationMatrix = new THREE.Matrix4()
      .multiplyMatrices(matrixX, matrixY)
      .multiply(matrixZ)

    // Translate to origin, apply rotation, translate back
    const translateToOrigin = new THREE.Matrix4().makeTranslation(-center.x, -center.y, -center.z)
    const translateBack = new THREE.Matrix4().makeTranslation(center.x, center.y, center.z)

    // Combine all transformations
    const transformationMatrix = new THREE.Matrix4()
      .multiplyMatrices(translateBack, combinedRotationMatrix)
      .multiply(translateToOrigin)

    // Add the translation values directly to the matrix components
    transformationMatrix.elements[12] += translation.x
    transformationMatrix.elements[13] += translation.y
    transformationMatrix.elements[14] += translation.z

    return transformationMatrix.elements
  }

  const loadMatrixFromUrl = async (filePath, label) => {
    const url = `${
      process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
    }/files/v1/${filePath.manualRepositionTransformMatrix[label]}/file`
    try {
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const buffer = await response.arrayBuffer()
      const floatArray = new Float32Array(buffer) // Or Float64Array depending on your original format
      return floatArray
    } catch (error) {
      console.error('Failed to load matrix:', error)
      return null
    }
  }

  const updateExampleTrDictWithTFM = async (tfmFilePath, label) => {
    try {
      const response = await fetch(tfmFilePath)
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)

      const text = await response.text()
      const lines = text.split('\n')
      const parametersLine = lines.find((line) => line.startsWith('Parameters:'))
      if (!parametersLine) throw new Error('Parameters line not found')

      const parameters = parametersLine.split(':')[1].trim().split(' ').map(Number)

      const itkTransform = matrix([
        [parameters[0], parameters[1], parameters[2], parameters[9]],
        [parameters[3], parameters[4], parameters[5], parameters[10]],
        [parameters[6], parameters[7], parameters[8], parameters[11]],
        [0, 0, 0, 1]
      ])

      // Convert ITK transform from LPS to RAS
      const ras2lps = diag([-1, -1, 1, 1])
      const transformFromParentRAS = multiply(multiply(ras2lps, itkTransform), ras2lps)
      const transformToParentRAS = inv(transformFromParentRAS)

      // Extract translation part from the transformed matrix
      const translationRAS = [
        -transformToParentRAS.get([0, 3]),
        -transformToParentRAS.get([1, 3]),
        transformToParentRAS.get([2, 3])
      ]

      let formattedParameters = [
        parameters[0],
        parameters[1],
        parameters[2],
        0, // First row
        parameters[3],
        parameters[4],
        parameters[5],
        0, // Second row
        parameters[6],
        parameters[7],
        parameters[8],
        0, // Third row
        translationRAS[0],
        translationRAS[1],
        translationRAS[2],
        1 // Fourth row
      ]

      setTransformDict((prevDict) => ({
        ...prevDict,
        [label]: formattedParameters
      }))
    } catch (error) {
      console.error(`Error updating transformDict with TFM for ${label}:`, error)
      setTransformDict((prevDict) => ({
        ...prevDict,
        [label]: identityMatrix // Use identity matrix in case of any error
      }))
    }
  }

  const updateAdditionalTrDict = async (label) => {
    let secondMatrix = identityMatrix // Default to identity matrix

    // Await the promise returned by loadMatrixFromUrl and proceed if the result is truthy
    const matrixPath = filePath.manualRepositionTransformMatrix[label]

    if (matrixPath) {
      const floatArray = await loadMatrixFromUrl(filePath, label)
      if (floatArray) {
        let inputElements = Array.from(floatArray)
        // Assuming decomposeMatrix is defined elsewhere and works as expected
        const { translationInfo, rotationInfo } = decomposeMatrix(
          new THREE.Matrix4().fromArray(inputElements)
        )
        secondMatrix = getNewTransformMatrix(objectInfo[label], rotationInfo, translationInfo)
      }
    }

    setAdditionDict((prevDict) => ({
      ...prevDict,
      [label]: secondMatrix
    }))
  }

  const updateExampleTrDict = async (filePath) => {
    for (const label in filePath.isolationObjects) {
      if (Object.prototype.hasOwnProperty.call(filePath.repositionTransformMatrix, label)) {
        if (filePath.repositionTransformMatrix[label] === '') {
          setTransformDict((prevDict) => ({
            ...prevDict,
            [label]: identityMatrix
          }))
          continue
        }
        const tfmFilePath = `${
          process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
        }/files/v1/${filePath.repositionTransformMatrix[label]}/file`
        await updateExampleTrDictWithTFM(tfmFilePath, label)

        console.log(`transformation matrix found for ${label}.`)
      } else {
        // If no transformation matrix is found for this label, use an identity matrix
        setTransformDict((prevDict) => ({
          ...prevDict,
          [label]: identityMatrix
        }))

        console.log(`No transformation matrix found for ${label}. Used identity matrix.`)
      }

      await updateAdditionalTrDict(label)
    }
    console.log('ExampleTrDict updated for all labels.')
  }

  const getCenterOfObject = (object) => {
    var box = new THREE.Box3().setFromObject(object)
    var worldCenter = new THREE.Vector3()
    box.getCenter(worldCenter)
    var localCenter = new THREE.Vector3()
    localCenter.copy(worldCenter)
    object.worldToLocal(localCenter)

    return localCenter
  }

  const adjustObjectForCenteredTransform = (object) => {
    var transformerPosition = new THREE.Vector3()
    transformerPosition = getCenterOfObject(object)
    return transformerPosition
  }

  const animateTowardsTransformMatrices = (trDict, additionDict) => {
    // Animation control logic remains the same
    if (currentPlayMode.current) {
      if (animationProgress.current >= 1) {
        animationProgress.current = 0 // Reset progress to start over
      } else {
        animationProgress.current += animationDirection // Increment the global animation progress
      }
    }

    const now = Date.now()
    if (now - lastUpdateTime.current > 100) {
      setCurrentTime(Math.floor(animationProgress.current * duration))
      lastUpdateTime.current = now
    }

    // Calculate adjusted progress for phased animation
    const phaseProgress = animationProgress.current * 2
    const usingAdditionDict = phaseProgress > 1
    const adjustedProgress = usingAdditionDict ? phaseProgress - 1 : phaseProgress

    Object.keys(trDict).forEach((objectName) => {
      const object = scene.current.getObjectByName(objectName)
      if (!object) return

      let startMatrix = new THREE.Matrix4().identity()
      if (usingAdditionDict && trDict[objectName]) {
        // If using additionDict, start from the end of trDict transformation
        startMatrix.fromArray(trDict[objectName])
      }

      const targetDict = usingAdditionDict ? additionDict : trDict
      const targetMatrixArray = targetDict[objectName] || new THREE.Matrix4().identity().elements
      const targetMatrix = new THREE.Matrix4().fromArray(targetMatrixArray)

      // Check if the targetMatrix is an identity matrix when using additionDict
      const isIdentity = usingAdditionDict && targetMatrix.equals(new THREE.Matrix4().identity())

      // Decompose the start and target matrices into position, quaternion (rotation), and scale
      let startPosition = new THREE.Vector3(),
        startQuaternion = new THREE.Quaternion(),
        startScale = new THREE.Vector3()
      startMatrix.decompose(startPosition, startQuaternion, startScale)

      let targetPosition = new THREE.Vector3(),
        targetQuaternion = new THREE.Quaternion(),
        targetScale = new THREE.Vector3()
      targetMatrix.decompose(targetPosition, targetQuaternion, targetScale)

      // If using additionDict and it's an identity, keep the object at the end of trDict transformation
      if (isIdentity) {
        targetPosition.copy(startPosition)
        targetQuaternion.copy(startQuaternion)
        targetScale.copy(startScale)
      }

      // Interpolate between the start and target states based on adjustedProgress
      object.position.lerpVectors(startPosition, targetPosition, adjustedProgress)
      object.quaternion.slerpQuaternions(startQuaternion, targetQuaternion, adjustedProgress)
      object.scale.lerpVectors(startScale, targetScale, adjustedProgress)
    })
  }

  const allObjectsPresent = (trDict, scene) => {
    const allPresent = Object.keys(trDict).every((objectName) => {
      const present = !!scene.getObjectByName(objectName)
      // if (!present) {
      //   console.error(`Object ${objectName} not found in the scene.`)
      // }
      return present
    })
    return allPresent
  }

  const fps = 30
  const interval = 1000 / fps // Interval for 30 FPS in milliseconds
  let lastTime = 0

  const animate = () => {
    requestAnimationFrame(animate) // Schedule the next frame

    const now = Date.now()
    const elapsed = now - lastTime

    if (elapsed > interval) {
      lastTime = now - (elapsed % interval) // Adjust for the next frame

      if (stats != null) {
        stats.update()
      }

      // Your existing condition to check if all objects are present
      if (allObjectsPresent(transformDict, scene.current)) {
        animateTowardsTransformMatrices(transformDict, additionDict)
      }

      controlsRef.current.update() // Update controls
      render() // Render the scene
    }
  }

  function render() {
    rendererRef.current.render(scene.current, cameraRef.current)
  }

  const updateSize = () => {
    setTimeout(() => {
      rendererRef.current.setSize(viewBoxRef4.current.clientWidth, viewBoxRef4.current.clientHeight)
    }, 5000)
    rendererRef.current.setPixelRatio(window.devicePixelRatio)
    render()
  }

  useEffect(() => {
    if (!viewBoxRef4.current) {
      return
    }
    mainGUIRef.current.hide()
    if (!stats) {
      stats = new Stats()
      stats.showPanel(2) // 0: fps, 1: ms, 2: mb, 3+: custom

      stats.dom.style.position = 'fixed'
      stats.dom.style.top = '0'
      stats.dom.style.left = 'auto'
      stats.dom.style.right = '0'
      stats.dom.id = 'statsContainer' // Assign a specific name to the stats.dom element
      document.body.appendChild(stats.dom)
    }

    const width = viewBoxRef4.current.clientWidth
    const height = viewBoxRef4.current.clientHeight

    // const scene = new THREE.Scene()

    const renderer = new THREE.WebGLRenderer()
    rendererRef.current = renderer
    renderer.sortObjects = false
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(width, height)
    containerRef.current.appendChild(renderer.domElement)

    // Orthographic Cameras are supposed to be used for 2D views
    // for 3D views THREE.PerspectiveCamera  is supposed to be used.
    // However since the 3D files are not "proper" 3D objects just some texture files
    // that make the impression of a 3D object the PerspectiveCamera cant be used because
    // it creates are weird perception
    const camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 20000)
    camera.position.z = 250
    cameraRef.current = camera

    const controls = new TrackballControls(camera, renderer.domElement)
    controls.rotateSpeed = 1.0
    controls.zoomSpeed = 1.0
    controls.panSpeed = 0.5
    controls.enabled = false
    controlsRef.current = controls

    controls.target.set(0, 0, 0)
    controls.minZoom = 0.5
    controls.maxZoom = 4
    controls.enablePan = true
    controls.enableRotate = true

    addLights(scene.current)

    window.addEventListener('resize', updateSize)

    updateSize()

    setInitBool(true)

    return () => {
      // Clean up the animation loop when component unmounts
      if (containerRef.current) {
        containerRef.current.removeChild(renderer.domElement)
        rendererRef.current.dispose()

        window.removeEventListener('resize', updateSize)
        if (!stats) {
          document.body.removeChild(stats.dom)
        }
      }
      removeObject(scene.current, 'groupLights')
    }
  }, [mainGUIRef, viewBoxRef4])

  useEffect(() => {
    const allKeys = Object.keys(filePath.isolationObjects) // Keys that should be loaded
    const allLoaded = allKeys.every((key) =>
      Object.prototype.hasOwnProperty.call(isoLoadedObjects, key)
    ) // Check if all keys are loaded

    if (!allLoaded) {
      // If not all objects are loaded, exit early
      return
    }

    if (initBool && objectInit === false) {
      Object.entries(isoLoadedObjects).forEach(([objectName, { object }]) => {
        object.name = objectName
        scene.current.add(object)

        // Render the scene immediately after adding the object
        rendererRef.current.render(scene.current, cameraRef.current)

        // Update the loading state for this object
        setObjectsLoaded((prev) => ({ ...prev, [objectName]: true }))
      })

      setObjectInit(true)

      updateSize()
    }
  }, [initBool, isoLoadedObjects])

  useEffect(() => {
    if (Object.keys(checkedValues).length !== 0) {
      if (scene.current !== null) {
        scene.current.children.forEach((child) => {
          // Assume the child's name or some other property maps to the keys in checkedValues
          const childId = parseInt(child.name.replace('label', '')) // This is an assumption based on your structure

          // If childId is not NaN and we have a corresponding checkedValue...
          if (!isNaN(childId) && Object.prototype.hasOwnProperty.call(checkedValues, childId)) {
            // Set the visibility based on the checkedValue
            child.visible = checkedValues[childId]
          }
        })
      }
    }
  }, [checkedValues])

  useEffect(() => {
    // Define the neutral color (e.g., light gray RGB)
    const neutralColor = new THREE.Color(1.0, 1.0, 1.0) // Corresponds to 211/255

    const colors = colorMapArray.map((c) => c / 255)

    scene.current.children.forEach((child) => {
      if (child.name.startsWith('label')) {
        // Extract label number from child name
        const labelNumber = parseInt(child.name.replace('label', ''))

        // Calculate the index for the color in the 'colors' array
        const colorIndex = labelNumber * 3

        // Get color for the label or use neutral color based on the switch state
        const color = labelColorSwitch
          ? new THREE.Color(colors[colorIndex], colors[colorIndex + 1], colors[colorIndex + 2])
          : neutralColor

        // Apply color to the geometry of the child object
        child.traverse((subChild) => {
          if (subChild.isMesh && subChild.geometry && subChild.geometry.attributes.color) {
            const colorsArray = subChild.geometry.attributes.color.array
            for (let i = 0, n = colorsArray.length; i < n; i += 3) {
              colorsArray[i] = color.r
              colorsArray[i + 1] = color.g
              colorsArray[i + 2] = color.b
            }
            subChild.geometry.attributes.color.needsUpdate = true
          }
        })
      }
    })
  }, [labelColorSwitch])

  useEffect(() => {
    if (cameraResetClicked) {
      if (initialCameraState.set && cameraRef.current && controlsRef.current) {
        // Reset camera
        cameraRef.current.copy(initialCameraState.camera)

        // Reset controls
        controlsRef.current.target.copy(initialCameraState.controlsTarget)

        setCameraResetClicked(false)
      }
    }
  }, [cameraResetClicked])

  useEffect(() => {
    if (rotationVector !== null) {
      let z = cameraRef.current.position.z
      let y = cameraRef.current.position.y
      let x = cameraRef.current.position.x
      const zoomValue = Math.max(Math.abs(z), Math.abs(x), Math.abs(y))
      // Copy the initial camera and control targets
      cameraRef.current.copy(cameraPositionsRef.current.camera)
      controlsRef.current.target.copy(cameraPositionsRef.current.controlsTarget)

      // Calculate and set the new camera position based on rotationVector
      const newPosition = calculateNewCameraPosition(
        cameraPositionsRef.current.camera.position,
        cameraPositionsRef.current.controlsTarget,
        rotationVector
      )
      cameraRef.current.position.copy(newPosition)

      // Special handling for 'S' and 'I' to adjust the camera's up vector and lookAt
      if (rotationVector === 'S') {
        cameraRef.current.up.set(0, 0, -1) // For looking down
      } else if (rotationVector === 'I') {
        cameraRef.current.up.set(0, 0, 1) // For looking up
      }
      if (rotationVector === 'S' || rotationVector === 'I') {
        cameraRef.current.lookAt(controlsRef.current.target) // Ensure correct orientation
        cameraRef.current.position.y = rotationVector === 'I' ? -zoomValue : zoomValue
      } else if (rotationVector === 'A' || rotationVector === 'P') {
        cameraRef.current.position.z = rotationVector === 'P' ? -zoomValue : zoomValue
      } else {
        cameraRef.current.position.x = rotationVector === 'L' ? zoomValue : -zoomValue
      }

      setRotationVector(null) // Reset rotationVector to avoid re-triggering
    }
  }, [rotationVector])

  useEffect(() => {
    // This assumes objectsLoaded is a state tracking whether each object is loaded
    const allLoaded = Object.values(objectsLoaded).every((status) => status)

    if (objectInit && allLoaded) {
      // Additional check to ensure all specified objects are present in the scene
      const allObjectsPresent = Object.keys(filePath.isolationObjects).every((objectName) => {
        return scene.current.getObjectByName(objectName) !== undefined
      })

      if (allObjectsPresent) {
        Object.keys(filePath.isolationObjects).forEach((objectName) => {
          const object = scene.current.getObjectByName(objectName)
          const centerPoint = adjustObjectForCenteredTransform(object)

          setObjectInfo((prevDict) => ({
            ...prevDict,
            [objectName]: centerPoint
          }))
        })
      } else {
        console.log('Not all specified objects are present in the scene yet.')
      }
    }
  }, [objectInit, objectsLoaded])

  useEffect(() => {
    // Convert both keys of objectInfo and filePath.isolationObjects to arrays
    const objectInfoKeys = Object.keys(objectInfo)
    const isolationObjectKeys = Object.keys(filePath.isolationObjects)

    // Check if every key in isolationObjectKeys is present in objectInfoKeys
    const allKeysMatch = isolationObjectKeys.every((key) => objectInfoKeys.includes(key))

    if (allKeysMatch && isolationObjectKeys.length === objectInfoKeys.length) {
      updateExampleTrDict(filePath)
      initFinished.current = true
    }
  }, [objectInfo])

  useEffect(() => {
    // Check if all objects are loaded based on filePath.isolationObjects keys
    const allKeys = Object.keys(filePath.isolationObjects) // Keys that should be loaded
    const allLoaded = allKeys.every((key) => objectsLoaded[key] === true) // Check if all keys are loaded

    let allLoadedMatrix = false

    if (
      Object.keys(filePath.isolationObjects).length === Object.keys(transformDict).length &&
      Object.keys(filePath.isolationObjects).length === Object.keys(additionDict).length
    ) {
      // console.log('All objects have corresponding transformations:', transformDict)
      allLoadedMatrix = true
    }

    if (initFinished.current && allLoaded && allLoadedMatrix) {
      // Perform actions after all objects have been loaded
      controlsRef.current.enabled = true

      // Remove all existing 'BoundingBox' objects from the scene
      scene.current.children = scene.current.children.filter(
        (child) => child.name !== 'BoundingBox'
      )
      boundingBoxMeshRef.current = addBoundingBoxAndLabels(scene.current)

      if (cameraRef.current && controlsRef.current) {
        // Set the camera position and orientation for rotationVector = "I" (Inferior view)
        const zoomValue = Math.max(
          Math.abs(cameraRef.current.position.z),
          Math.abs(cameraRef.current.position.x),
          Math.abs(cameraRef.current.position.y)
        )

        // Position the camera below the target for an Inferior view
        cameraRef.current.position.set(0, -zoomValue, 0) // Below the object
        cameraRef.current.up.set(0, 0, 1) // Camera's up vector pointing along the Z-axis
        cameraRef.current.lookAt(controlsRef.current.target) // Ensure the camera looks at the target
      }

      // Now, save this as the initial camera state
      if (!initialCameraState.set && cameraRef.current && controlsRef.current) {
        setInitialCameraState({
          set: true,
          camera: cameraRef.current.clone(), // Save the cloned state of the camera
          controlsTarget: controlsRef.current.target.clone() // Save the cloned state of the controls' target
        })
      }

      // Save these initial positions for possible resets
      cameraPositionsRef.current = {
        camera: cameraRef.current.clone(),
        controlsTarget: controlsRef.current.target.clone()
      }

      setIsLoading(false)
      animate()
    }
  }, [initFinished, objectsLoaded, transformDict, additionDict])

  useEffect(() => {
    if (viewBoxOn === true) {
      toggleBoundingBoxVisibility(boundingBoxMeshRef.current, true) // Show
    } else {
      toggleBoundingBoxVisibility(boundingBoxMeshRef.current, false) // Hide
    }
  }, [viewBoxOn])

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.rotateSpeed = rotateSpeed
    }
  }, [rotateSpeed])

  useEffect(() => {
    currentPlayMode.current = isPlaying
  }, [isPlaying])

  return (
    <div>
      <div name="3DBox" ref={containerRef} />
      <VideoPlayerControls
        onForward={onForward}
        onBackward={onBackward}
        onSeek={onSeek}
        currentTime={currentTime}
        duration={duration}
        onPlayPause={onPlayPause}
        isPlaying={isPlaying}
      />
    </div>
  )
}

export default AnimationTargetAnatomy
