/* eslint-disable react/prop-types */
import { demoDocumentLoader } from '../demo/DemoDocumentLoader'
import { segmentationLoader } from './SegmentationLoader'

export const segmentationButtonHandler = async (
  sdk,
  isDemo,
  setIsLoading,
  filePath,
  setFilePath,
  setButtonState,
  backInfo,
  setBackInfo,
  userInfo,
  setLoadingValue,
  indication
) => {
  if (isDemo) {
    setIsLoading(true)

    let fileName = filePath['filename']

    const fileUrl = await demoDocumentLoader(sdk, fileName, 'segToken')

    setFilePath({
      ...filePath,
      seg: fileUrl,
      model: {
        ...filePath.model,
        seg: fileUrl
      }
    })

    setButtonState('Seg')
    setLoadingValue(50)

    return
  } else {
    setIsLoading(true)
    let fileName = filePath['filename']

    const fileUrl = await segmentationLoader(
      sdk,
      backInfo,
      fileName,
      userInfo,
      setBackInfo,
      setLoadingValue
    )

    if (fileUrl !== 'stop') {
      setFilePath({
        ...filePath,
        seg: fileUrl,
        iso: indication === 'Face' ? fileUrl : '',
        model: {
          ...filePath.model,
          seg: fileUrl,
          iso: indication === 'Face' ? fileUrl : ''
        }
      })

      setButtonState('Seg')
    }

    return
  }
}
