/* eslint-disable react/prop-types */

export const indicationOptions = {
  default: ['Left', 'Right', 'Both'],
  Wrist: ['Radius Distal', 'Radius Proximal', 'Ulna Distal', 'Ulna Proximal']
}

export const indicationMap = {
  default: { Left: [2], Right: [1], Both: [1, 2] },
  Wrist: {
    'Radius Distal': [1],
    'Radius Proximal': [1],
    'Ulna Distal': [2],
    'Ulna Proximal': [2],
    Hand: [3]
  }
}
