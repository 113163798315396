/* eslint-disable react/prop-types */
// anonymize dicom files
import dicomParser from 'dicom-parser'

const getDicomElementValue = (dataSet, tag) => {
  const element = dataSet.elements[tag]
  if (!element) return null

  // Check if the element's VR is undefined (implicit VR)
  if (element.vr === undefined) {
    // Read the data as a raw string for implicit VRs
    // Note: This approach might not be accurate for all VR types
    let str = ''
    for (let i = element.dataOffset; i < element.dataOffset + element.length; i++) {
      str += String.fromCharCode(dataSet.byteArray[i])
    }
    return str
  } else {
    // Handle explicit VRs
    try {
      return dicomParser.explicitElementToString(dataSet, element)
    } catch (e) {
      console.error(`Error converting element to string: ${e}`)
      return null
    }
  }
}

export const dicomHandler = async (byteArray) => {
  const dataSet = dicomParser.parseDicom(byteArray)

  // Function to safely update DICOM tag values
  const updateDicomTag = (dataSet, tag, newValue) => {
    const element = dataSet.elements[tag]
    if (!element) {
      // console.warn(`DICOM tag ${tag} not found.`);
      return // Skip if element does not exist
    }
    for (let i = 0; i < element.length; i++) {
      let char = newValue.length > i ? newValue.charCodeAt(i) : 32 // Use space character if newValue is shorter than the original value
      dataSet.byteArray[element.dataOffset + i] = char
    }
  }

  // Store original patient birth date and gender
  const originalBirthDate = getDicomElementValue(dataSet, 'x00100030')
  const originalGender = getDicomElementValue(dataSet, 'x00100040')

  // Update DICOM tags with new values
  updateDicomTag(dataSet, 'x00100010', 'anonymous') // patient name
  updateDicomTag(dataSet, 'x00100020', '999999999') // patient id
  updateDicomTag(dataSet, 'x00100030', '19000101') // patient birth date
  updateDicomTag(dataSet, 'x00100040', 'U') // patient gender
  updateDicomTag(dataSet, 'x00200010', 'ANONYMOUS') // study id

  /// Extract SeriesInstanceUID
  const seriesInstanceUID = getDicomElementValue(dataSet, 'x0020000e')

  return {
    dataSet,
    seriesInstanceUID,
    originalBirthDate,
    originalGender
  }
}
