/* eslint-disable react/prop-types */
import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import { Grid, Box, Button } from '@mui/material'

const LogoutDialog = ({ open, onClose }) => {
  const handleClose = () => {
    onClose()
  }

  const handleLogout = () => {
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('sessionExpire')
    window.location.reload(false)
  }

  if (open === 'logout') {
    return (
      <Dialog onClose={handleClose} open={open === 'logout'}>
        <Box
          sx={{
            padding: '16px'
          }}
        >
          <DialogTitle>Are you sure you want to logout?</DialogTitle>

          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Button onClick={() => handleClose()} variant="contained">
                No, stay.
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => handleLogout()} variant="contained">
                Yes, logout.
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    )
  }
}

export default LogoutDialog
