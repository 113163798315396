/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import React from 'react'

import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { CameraResetButton } from '../../../../Theme'
import { Typography } from '@mui/material'

// save button component in header component
const MainViewCameraResetButton = ({ isDemo, buttonState, setCameraResetClicked }) => {
  const handleClick = () => {
    setCameraResetClicked(true)
  }

  return (
    <CameraResetButton
      onClick={handleClick}
      style={{
        bottom:
          !isDemo && buttonState != 'Image' && buttonState != 'Target' && buttonState != 'Implant'
            ? '120px'
            : '20px'
      }}
      id="reset-button"
    >
      <RestartAltIcon sx={{ fontSize: 40 }} />
      <Typography sx={{ fontSize: 10 }}>Camera Reset</Typography>
    </CameraResetButton>
  )
}

export default MainViewCameraResetButton
