/* eslint-disable react/prop-types */
import React from 'react'
import { Box, Typography, IconButton, Button } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

const DicomInfoBox = ({ onClose }) => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 10000,
      backgroundColor: '#303030', // Box background color
      padding: 3,
      borderRadius: 2,
      maxWidth: '80%',
      color: '#fff',
      marginTop: 2
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 1,
        backgroundColor: '#303030', // Matching header color with box color
        padding: '0 8px', // Reduced padding in the header
        borderRadius: '2px 2px 0 0' // Rounded corners on top only
      }}
    >
      <IconButton aria-label="info" sx={{ color: '#fff', marginRight: 1 }}>
        <InfoIcon />
      </IconButton>
      <Typography variant="subtitle1" component="span" sx={{ flexGrow: 1 }}>
        Information
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={onClose}
        sx={{ backgroundColor: '#2C622C' }} // Close button color
      >
        Close
      </Button>
    </Box>
    <Typography variant="subtitle1">
      If you have axial, sagittal, and coronal views in individual DICOM series and you want to
      merge them for better quality images, please choose axial, sagittal, and coronal views
      together and click &apos;Select&apos;. You must select all three views simultaneously to
      merge.
    </Typography>
  </Box>
)

export default DicomInfoBox
