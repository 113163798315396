/* eslint-disable react/prop-types */
import { demoDocumentLoader } from '../demo/DemoDocumentLoader'
import { getDocument } from '../others/GetDocument'

export const implantPositioningButtonHandler = async (
  sdk,
  isDemo,
  setIsLoading,
  filePath,
  setFilePath,
  setButtonState,
  backInfo,
  setLoadingValue
) => {
  if (isDemo) {
    setIsLoading(true)

    let fileName = filePath['filename']

    const objFileUrl = await demoDocumentLoader(sdk, fileName, 'regObjToken')
    const targetObjects = await demoDocumentLoader(sdk, fileName, 'targetObjects')

    const sortedKeys = Object.keys(targetObjects).sort((a, b) => {
      return parseInt(a.replace('label', ''), 10) - parseInt(b.replace('label', ''), 10)
    })

    const sortedtargetObjects = {}
    sortedKeys.forEach((key) => {
      sortedtargetObjects[key] = targetObjects[key]
    })

    setFilePath({
      ...filePath,
      regObj: objFileUrl,
      targetObjects: sortedtargetObjects,
      repositionObjects: sortedtargetObjects
    })

    setButtonState('Implant')
    setLoadingValue(50)

    return
  } else {
    const document = await getDocument(sdk, backInfo) // get current document information
    const targetObjects = document.data.targetObjects

    const sortedKeys = Object.keys(targetObjects).sort((a, b) => {
      return parseInt(a.replace('label', ''), 10) - parseInt(b.replace('label', ''), 10)
    })

    const sortedtargetObjects = {}
    sortedKeys.forEach((key) => {
      sortedtargetObjects[key] = targetObjects[key]
    })

    if (document.data.regObjToken !== '') {
      setIsLoading(true)

      setFilePath({
        ...filePath,
        regObj: `${
          process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
        }/files/v1/${document.data.regObjToken}/file`,
        targetObjects: sortedtargetObjects
      })
    } else {
      console.log('Not available data')
      window.alert('Data is not available')
    }

    setButtonState('Implant')
    setLoadingValue(100)

    return
  }
}
