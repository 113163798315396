/* eslint-disable react/prop-types */
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js'
import { TransformControls } from 'three/addons/controls/TransformControls.js'
import * as THREE from 'three'
import { removeObject, removeObjectSceneRef } from '../../renderComponents/SegmentToolHandler'
import { addSphere } from '../../renderComponents/ScissorToolHandler3D'
import { addTextToScene } from '../../renderComponents/MeasureHandler'
import { draw3DLine } from '../../renderComponents/VariableStorage'
import { getCenterOfObject, returnMainAxisOfObject } from '../HelperFunctions/HelperFunctions'
import { implantData } from './transformData'

var implant = null
var pivot = new THREE.Group()
pivot.name = 'PivotGroupObject'

function loadImplantAsync(implant_loader, implantToken) {
  return new Promise((resolve, reject) => {
    implant_loader.load(
      `${
        process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
      }/files/v1/${implantToken.token}/file`,
      (object) => resolve(object), // on success
      undefined,
      (error) => reject(error) // on error
    )
  })
}

async function loadAndAddImplantAsync(
  scene,
  implantToken,
  scale,
  indication,
  filename,
  pca_analysis = false
) {
  if (implantToken.token !== '') {
    const implant_loader = new OBJLoader()

    try {
      const object = await loadImplantAsync(implant_loader, implantToken)
      object.traverse(function (child) {
        if (child.isMesh) {
          child.scale.set(scale, scale, scale)

          // Determine color based on indication
          let implantColor
          const indicationLower = indication.toLowerCase()

          if (indicationLower.includes('face')) {
            implantColor = new THREE.Color(0xffff00) // Yellow style
            child.material.metalness = 0.3 // Set material properties for "face"
            child.material.roughness = 0.6
          } else if (indicationLower.includes('wrist')) {
            implantColor = new THREE.Color(0xd3d3d3) // Light silver color
            child.material.metalness = 0.9 // High metalness for a shiny silver look
            child.material.roughness = 0.1 // Low roughness to enhance reflectivity
          } else {
            implantColor = new THREE.Color(0x00d2ff) // Default color if no specific indication
          }

          // Apply vertex colors to the mesh
          const colors = []
          for (let i = 0, n = child.geometry.attributes.position.count; i < n; ++i) {
            colors.push(implantColor.r, implantColor.g, implantColor.b)
          }

          child.geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3))
          child.material.vertexColors = true
          child.material.reflectivity = 0.1
          child.material.color.isColor = false
          child.geometry.getAttribute('color').needsUpdate = true
        }
      })

      implant = object
      implant.name = 'implant'

      // change implant default position to avoid overlap bone at the first
      // implant.position.copy(new THREE.Vector3(100, 0, 0))

      const matchingData = implantData.find(
        (data) => data.name === implantToken.description && data.features.bone === filename
      )

      // Apply transformation matrix if matching data is found
      if (matchingData) {
        const matrix = new THREE.Matrix4().fromArray(matchingData.transformMatrix)
        implant.applyMatrix4(matrix)
      } else {
        // Default position if no matching data
        implant.position.copy(new THREE.Vector3(0, 0, 0))
      }

      if (
        implantToken.description === 'MODUS orbita 7053' ||
        implantToken.description === 'MODUS orbita 7054'
      ) {
        implant.position.copy(new THREE.Vector3(-50, 0, 0))
      }

      scene.add(implant)

      if (pca_analysis) {
        const implantMesh = implant.children[0]
        const directions = returnMainAxisOfObject(implantMesh)
        console.log('directions: ', directions)
        const dir1 = directions[0]
        const dir2 = directions[1]
        const dir3 = directions[2]

        const implantCenter = getCenterOfObject(implant, false)

        dir1.multiplyScalar(100).add(implantCenter)
        dir2.multiplyScalar(100).add(implantCenter)
        dir3.multiplyScalar(100).add(implantCenter)

        const implantCos = new THREE.Group()
        implantCos.name = 'implantCos'
        removeObject(scene, 'implantCos')

        draw3DLineGroup(implantCenter, dir1, scene, 'white', implantCos, 'implantCos')
        draw3DLineGroup(implantCenter, dir2, scene, 'blue', implantCos, 'implantCos')
        draw3DLineGroup(implantCenter, dir3, scene, 'red', implantCos, 'implantCos')

        scene.add(implantCos)
      }
    } catch (error) {
      console.error('Error loading the implant object:', error)
    }
  }
}

function loadAndAddImplant(
  scene,
  scale,
  camera,
  renderer,
  controls,
  implantToken,
  implantInfo,
  setImplantInfo
) {
  if (implantToken.token !== '') {
    const implant_loader = new OBJLoader()
    implant_loader.load(
      // resource URL
      `${
        process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
      }/files/v1/${implantToken.token}/file`,
      // called when resource is loaded
      function (object) {
        // object.scale.set(scale, scale, scale)

        object.traverse(function (child) {
          if (child.isMesh) {
            child.scale.set(scale, scale, scale)
            // child.geometry.center()
            // child.geometry.computeVertexNormals()
            const implantColor = new THREE.Color(0x00d2ff)

            const colors = []

            for (let i = 0, n = child.geometry.attributes.position.count; i < n; ++i) {
              colors.push(implantColor.r, implantColor.g, implantColor.b)
            }
            child.geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3))
            // const colorAttribute = child.geometry.getAttribute('color');

            // for ( let j = 0, n = child.geometry.attributes.position.count; j < n; ++j ) {
            //   colorAttribute.setXYZ(j, 0, 0.823529, 1);
            // }

            child.material.vertexColors = true
            child.material.reflectivity = 0.1
            child.material.color.isColor = false
            child.geometry.getAttribute('color').needsUpdate = true
          }
        })

        implant = object
        implant.name = 'implant'

        // change implant default position to avoid overlap bone at the first
        implant.position.set(100, 0, 0)

        scene.add(implant)
        renderer.render(scene, camera)
      }
    )
    setImplantInfo((prevState) => ({
      ...prevState,
      initialized: true
    }))
  }
}
export { implant, loadAndAddImplant, pivot, loadAndAddImplantAsync }

export function moveObject(object, event, stepsize = 10) {
  var currentpos = object.position

  if (object) {
    switch (event.key) {
      case 'ArrowUp':
        object.position.z = currentpos.z + stepsize
        break

      case 'ArrowRight':
        object.position.x = currentpos.x + stepsize
        break

      case 'ArrowLeft':
        object.position.x = currentpos.x - stepsize
        break

      case 'ArrowDown':
        object.position.z = currentpos.z - stepsize
        break

      case 'u':
        object.position.y = currentpos.y + stepsize
        break

      case 'd':
        object.position.y = currentpos.y - stepsize
        break

      default:
        // Ignore other key presses
        return
    }
  }
}
collisionDetection
export function createTransformController(
  camera,
  renderer,
  scene,
  controls,
  size = 0.5,
  mode = 'translate',
  object
) {
  removeObjectSceneRef(scene, 'implantController')
  var transformer = new TransformControls(camera, renderer.current.domElement)

  transformer.addEventListener('change', function () {
    renderer.current.render(scene.current, camera)
  })
  transformer.addEventListener('mouseDown', function () {
    console.log('scene: ', scene.current)
    controls.current.enabled = false
  })
  transformer.addEventListener('mouseUp', function () {
    controls.current.enabled = true
  })
  transformer.setSpace('local')
  if (mode == 'translate' || mode == 'rotate') {
    transformer.mode = mode
    transformer.size = size
    transformer.name = 'implantController'
    scene.current.add(transformer)
  } else {
    transformer.mode = 'scale'
    transformer.size = size
    transformer.name = 'implantController'
    scene.current.add(transformer)
  }
  transformer.attach(object)
  return transformer
}

export function moveImplantPointtoPointPivotBool(
  pointOnImplant,
  implantToPoint,
  implant,
  pivotBool,
  scene
) {
  scene
  var implantPointVector
  if (!pivotBool) {
    var implantPosition = implant.position
    //calculates the Vector from the implant origin to the point on the implant in the absolute coordinate system
    //However The implant Coordinate system could be rotated
    implantPointVector = new THREE.Vector3().subVectors(pointOnImplant, implantPosition)
    var newImplantPosition = new THREE.Vector3().subVectors(implantToPoint, implantPointVector)
    implant.position.copy(newImplantPosition)
  } else {
    var pivotPosition = pivot.position
    implantPointVector = new THREE.Vector3().subVectors(pointOnImplant, pivotPosition)
    var newPivotPosition = new THREE.Vector3().subVectors(implantToPoint, implantPointVector)
    pivot.position.copy(newPivotPosition)

    var wPos = new THREE.Vector3()
    implant.getWorldPosition(wPos)
    draw3DLine(pivot.position, wPos, scene, 'green')
    draw3DLine(new THREE.Vector3(0, 0, 0), pivot.position, scene, 'green')

    // createTransformController(camera, renderer, scene, controls, size, 'rotate', pivot)
  }

  return implantToPoint
}

export function rotateAndTranslateObjectPivot(
  implantRotationPointOne,
  boneRotationPointOne,
  implant,
  implantPointVector,
  implantRotationPointTwo,
  boneRotationPointTwo,
  scene,
  pivotBool,
  pivot
) {
  /*
      boundary conditions equal 9 equations:
        b1: VectorFromOldToNewImplantposition needs to be on a sphere around the implantPointonthebone:
        || VectorFromOldToNewImplantposition - VectorfromImplantpositionToimplantPointonthebone || = || VectorfromImplantpositionToimplantPointonthebone ||
        b2: VectorFromOldToNewImplantposition + ImplantRotation*VectorfromImplantpositionToimplantPointonthebone = implantPointonthebone
        b3: VectorfromImplantpositionToimplantPointonthebone + VectorfromimplantPointOnTheBonetoNewPointOnBone(unit Vector!)
            = VectorFromOldToNewImplantposition + ImplantRotation*(VectorfromImplantpositionToimplantPointonthebone + VectorfromimplantPointOnTheBonetoNewPointOnImplant(unit Vector!))

      unknown variables: VectorFromOldToNewImplantposition (3 unknown), ImplantRotation (9 unknowns)
    */

  var planeRotation = true
  var VectorFromOldToNewImplantposition = new THREE.Vector3()
  var implant_pos = implant.position.clone()
  var pivot_pos = pivot.position.clone()
  // console.log("implant_pos before: ", implant_pos)
  // console.log("implant matrix: ", implant.matrixWorld.clone())
  // console.log("child matrix: ", implant.children[0].matrixWorld.clone())
  // console.log("pivot_pos before: ", pivot_pos)
  console.log('BEFORE!')
  console.log(
    'Object: ',
    pivot.name,
    '\n',
    pivot,
    '\nposition: ',
    pivot.position.clone(),
    '\nquaterion:\n ',
    pivot.quaternion.clone(),
    '\nmatrix:\n ',
    pivot.matrix.clone(),
    '\nmatrixworld:\n ',
    pivot.matrixWorld.clone()
  )
  console.log(
    'implant: ',
    implant,
    '\nposition: ',
    implant.position.clone(),
    '\nquaterion:\n ',
    implant.quaternion.clone(),
    '\nmatrix:\n ',
    implant.matrix.clone(),
    '\nmatrixworld:\n ',
    implant.matrixWorld.clone()
  )
  console.log(
    'implant mesh position: ',
    implant.children[0].position.clone(),
    '\nquaterion:\n ',
    implant.children[0].quaternion.clone(),
    '\nmatrix:\n ',
    implant.children[0].matrix.clone(),
    '\nmatrixworld:\n ',
    implant.children[0].matrixWorld.clone()
  )
  var VectorfromImplantpositionToimplantPointonthebone = new THREE.Vector3()
  var quaternion = new THREE.Quaternion()
  var matrix = new THREE.Matrix4()
  var VectorToNewPosition = new THREE.Vector3()

  // absolute vector from world cos to the new position of the implant or from the pivot origin
  if (!pivotBool) {
    VectorfromImplantpositionToimplantPointonthebone.subVectors(implantPointVector, implant_pos)
  } else if (pivotBool) {
    VectorfromImplantpositionToimplantPointonthebone.subVectors(implantPointVector, pivot_pos)
  }

  var VectorfromImplantpositionToimplantPointonthebone_ROTATED =
    VectorfromImplantpositionToimplantPointonthebone.clone()
  scene

  //vector from the new implant position to the first rotation points on the bone and implant in the WORLD-COS
  implantRotationPointOne.sub(implantPointVector).normalize() // Vector a
  boneRotationPointOne.sub(implantPointVector).normalize() // Vector b

  if (planeRotation) {
    // Calculate the normal vector of the respective bone and implant vectors
    // the normal vectors are going to be used for the calculation of the rotation matrix
    implantRotationPointTwo.sub(implantPointVector)
    implantRotationPointTwo //.normalize()
    boneRotationPointTwo.sub(implantPointVector) //.normalize()

    const boneNormal = new THREE.Vector3()
      .crossVectors(boneRotationPointOne, boneRotationPointTwo)
      .normalize()
    const implantNormal = new THREE.Vector3()
      .crossVectors(implantRotationPointOne, implantRotationPointTwo)
      .normalize()

    //Do the PlaneRotation
    quaternion.setFromUnitVectors(implantNormal, boneNormal)

    matrix.makeRotationFromQuaternion(quaternion)
    const rotationMatrix = new THREE.Matrix3().setFromMatrix4(matrix)

    //Calculate the new position of the implant
    VectorfromImplantpositionToimplantPointonthebone_ROTATED =
      VectorfromImplantpositionToimplantPointonthebone_ROTATED.applyMatrix3(rotationMatrix)

    VectorFromOldToNewImplantposition = VectorFromOldToNewImplantposition.subVectors(
      VectorfromImplantpositionToimplantPointonthebone,
      VectorfromImplantpositionToimplantPointonthebone_ROTATED
    )

    if (!pivotBool) {
      VectorToNewPosition.addVectors(implant_pos, VectorFromOldToNewImplantposition)
      console.log('Plane Rotation rotated and translate new pos: ', VectorToNewPosition)
      implant.position.copy(VectorToNewPosition)
      implant.children[0].applyQuaternion(quaternion)
    } else if (pivotBool) {
      VectorToNewPosition.addVectors(pivot_pos, VectorFromOldToNewImplantposition)
      console.log('Plane Rotation rotated and translate new pos: ', VectorToNewPosition)
      pivot.position.copy(VectorToNewPosition)
      pivot.children[0].applyQuaternion(quaternion)

      // draw3DLine(pivot_pos, VectorToNewPosition, scene, 'yellow')
      // var wPos = new THREE.Vector3()
      // implant.getWorldPosition(wPos)
      // draw3DLine(pivot_pos, wPos, scene, 'green')
    }

    // console.log("implant_pos after: ", implant.position.clone())
    // console.log("pivot_pos after: ", pivot.position.clone())
    // console.log("implant matrix after: ", implant.matrixWorld.clone())
    // console.log("child matrix after: ", implant.children[0].matrixWorld.clone())
    console.log('AFTER!')
    console.log(
      'Object: ',
      pivot.name,
      '\n',
      pivot,
      '\nposition: ',
      pivot.position,
      '\nquaterion:\n ',
      pivot.quaternion,
      '\nmatrix:\n ',
      pivot.matrix,
      '\nmatrixworld:\n ',
      pivot.matrixWorld
    )
    console.log(
      'implant: ',
      implant,
      '\nposition: ',
      implant.position,
      '\nquaterion:\n ',
      implant.quaternion,
      '\nmatrix:\n ',
      implant.matrix,
      '\nmatrixworld:\n ',
      implant.matrixWorld
    )
    console.log(
      'implant mesh position: ',
      implant.children[0].position,
      '\nquaterion:\n ',
      implant.children[0].quaternion,
      '\nmatrix:\n ',
      implant.children[0].matrix,
      '\nmatrixworld:\n ',
      implant.children[0].matrixWorld
    )
  } else {
    quaternion.setFromUnitVectors(implantRotationPointOne, boneRotationPointOne)

    matrix.makeRotationFromQuaternion(quaternion)
    const rotationMatrix = new THREE.Matrix3().setFromMatrix4(matrix)

    //Calculate the new position of the implant
    VectorfromImplantpositionToimplantPointonthebone_ROTATED =
      VectorfromImplantpositionToimplantPointonthebone_ROTATED.applyMatrix3(rotationMatrix)

    VectorFromOldToNewImplantposition = VectorFromOldToNewImplantposition.subVectors(
      VectorfromImplantpositionToimplantPointonthebone,
      VectorfromImplantpositionToimplantPointonthebone_ROTATED
    )

    if (!pivotBool) {
      VectorToNewPosition.addVectors(implant_pos, VectorFromOldToNewImplantposition)
      console.log('Plane Rotation rotated and translate new pos: ', VectorToNewPosition)
      implant.position.copy(VectorToNewPosition)
      implant.children[0].applyQuaternion(quaternion)
    } else if (pivotBool) {
      VectorToNewPosition.addVectors(pivot_pos, VectorFromOldToNewImplantposition)
      console.log('Plane Rotation rotated and translate new pos: ', VectorToNewPosition)
      pivot.position.copy(VectorToNewPosition)
      pivot.children[0].applyQuaternion(quaternion)
    }
  }
}

export function calcCrossProduct(vector1, vector2) {
  const x1 = vector1.x
  const y1 = vector1.y
  const z1 = vector1.z

  const x2 = vector2.x
  const y2 = vector2.y
  const z2 = vector2.z

  const x = y1 * z2 - z1 * y2
  const y = z1 * x2 - x1 * z2
  const z = x1 * y2 - y1 * x2

  const crossProduct = new THREE.Vector3(x, y, z)
  console.log('calculated Crossprduct: ', crossProduct)
  return crossProduct
}

export function implantPositioning(
  event,
  scene,
  camera,
  implantToPoint,
  pointOnImplant,
  implantRotationPointOne,
  boneRotationPointOne,
  implantRotationPointTwo,
  boneRotationPointTwo,
  firstImplantMove,
  rotationMove,
  implantPointVector,
  rotationCenter,
  implant
) {
  const raycaster = new THREE.Raycaster()
  const mouse = new THREE.Vector2()
  let bbox = event.currentTarget.getBoundingClientRect()

  mouse.x = ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1
  mouse.y = -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1
  raycaster.setFromCamera(mouse, camera)

  const searchBone = scene.getObjectByName('objBone')
  const intersectsBone = raycaster.intersectObject(searchBone)
  const searchImplant = scene.getObjectByName('implant')

  if (searchImplant !== undefined) {
    const intersectsImplant = raycaster.intersectObject(searchImplant)

    if (intersectsBone.length > 0) {
      if (firstImplantMove) {
        implantToPoint = intersectsBone[0].point
        removeObject(scene, 'bonePoint')
        addSphere(intersectsBone[0].point, scene, 'yellow', 'bonePoint', 2)
        console.log('pointOnImplant: ', pointOnImplant.length())
        console.log('implantToPoint: ', implantToPoint.length())

        if (implant && pointOnImplant.length() != 0) {
          implantPointVector = moveImplantPointtoPoint(pointOnImplant, implantToPoint, implant)
          rotationCenter = implantPointVector.clone()
          rotationCenter
          removeObject(scene, 'bonePoint')
          removeObject(scene, 'implantPoint')
          implantToPoint = new THREE.Vector3()
          pointOnImplant = new THREE.Vector3()
          firstImplantMove = false
        }
      } else if (!firstImplantMove && boneRotationPointOne.length() == 0) {
        boneRotationPointOne = null
        boneRotationPointOne = intersectsBone[0].point
        removeObject(scene, 'boneRotationPointOne')
        addSphere(intersectsBone[0].point, scene, 'white', 'boneRotationPointOne', 2)
        rotationMove = true
      } else if (
        !firstImplantMove &&
        boneRotationPointOne.length() != 0 &&
        boneRotationPointTwo.length() == 0
      ) {
        boneRotationPointTwo = null
        boneRotationPointTwo = intersectsBone[0].point
        removeObject(scene, 'boneRotationPointTwo')
        addSphere(intersectsBone[0].point, scene, 'white', 'boneRotationPointTwo', 2)

        if (
          implant &&
          implantRotationPointOne.length() != 0 &&
          implantPointVector.length() != 0 &&
          implantRotationPointTwo.length() != 0
        ) {
          rotateAndTranslateObject(
            implantRotationPointOne,
            boneRotationPointOne,
            implant,
            implantPointVector,
            implantRotationPointTwo,
            boneRotationPointTwo,
            scene
          )
          implantRotationPointOne = new THREE.Vector3()
          implantRotationPointTwo = new THREE.Vector3()
          boneRotationPointOne = new THREE.Vector3()
          boneRotationPointTwo = new THREE.Vector3()
          removeObject(scene, 'implantRotationPointOne')
          removeObject(scene, 'implantRotationPointTwo')
          removeObject(scene, 'boneRotationPointOne')
          removeObject(scene, 'boneRotationPointTwo')
          firstImplantMove = true
          rotationMove = false
        }
      }
    } else if (intersectsImplant.length > 0) {
      if (firstImplantMove) {
        pointOnImplant = intersectsImplant[0].point
        removeObject(scene, 'implantPoint')
        addSphere(intersectsImplant[0].point, scene, 'yellow', 'implantPoint', 2)
        console.log('pointOnImplant: ', pointOnImplant.length())
        console.log('implantToPoint: ', implantToPoint.length())

        if (implant && implantToPoint.length() != 0) {
          implantPointVector = moveImplantPointtoPoint(pointOnImplant, implantToPoint, implant)
          rotationCenter = implantPointVector.clone()
          rotationCenter
          removeObject(scene, 'bonePoint')
          removeObject(scene, 'implantPoint')
          implantToPoint = new THREE.Vector3()
          pointOnImplant = new THREE.Vector3()
          firstImplantMove = false
        }
      } else if (!firstImplantMove && implantRotationPointOne.length() == 0) {
        implantRotationPointOne = null
        implantRotationPointOne = intersectsImplant[0].point
        removeObject(scene, 'implantRotationPointOne')
        addSphere(intersectsImplant[0].point, scene, 'white', 'implantRotationPointOne', 2)
        rotationMove = true
      } else if (
        !firstImplantMove &&
        implantRotationPointOne.length() != 0 &&
        implantRotationPointTwo.length() == 0
      ) {
        implantRotationPointTwo = null
        implantRotationPointTwo = intersectsImplant[0].point
        removeObject(scene, 'implantRotationPointTwo')
        addSphere(intersectsImplant[0].point, scene, 'white', 'implantRotationPointTwo', 2)

        if (
          implant &&
          boneRotationPointOne.length() != 0 &&
          implantPointVector.length() != 0 &&
          boneRotationPointTwo.length() != 0
        ) {
          rotateAndTranslateObject(
            implantRotationPointOne,
            boneRotationPointOne,
            implant,
            implantPointVector,
            implantRotationPointTwo,
            boneRotationPointTwo,
            scene
          )
          implantRotationPointOne = new THREE.Vector3()
          implantRotationPointTwo = new THREE.Vector3()
          boneRotationPointOne = new THREE.Vector3()
          boneRotationPointTwo = new THREE.Vector3()
          removeObject(scene, 'implantRotationPointOne')
          removeObject(scene, 'implantRotationPointTwo')
          removeObject(scene, 'boneRotationPointOne')
          removeObject(scene, 'boneRotationPointTwo')
          firstImplantMove = true
          rotationMove = false

          rotationMove
        }
      }
    }
  }
}

function collisionDetection(implant_object, bone_object, scene, colBool, step_size = 1500) {
  removeObjectSceneRef(scene, 'interlines')
  const group = new THREE.Group()
  group.name = 'lineGroup'
  scene.current.add(group)
  console.log('scene.current', scene.current)
  var verticesList = []
  for (
    var vertexIndex = 0;
    vertexIndex < implant_object.geometry.attributes.position.array.length;
    vertexIndex += step_size
  ) {
    var localVertex = new THREE.Vector3()
      .fromBufferAttribute(implant_object.geometry.attributes.position, vertexIndex)
      .clone()
    var globalVertex = localVertex.clone().applyMatrix4(implant_object.matrixWorld)
    // var directionVector = globalVertex.clone().sub(implant_object.parent.position)
    var origin = new THREE.Vector3(0, 0, 0)
    // var collisionray = new THREE.Raycaster(
    //   implant_object.parent.position,
    //   globalVertex.clone().normalize()
    // )
    var collisionray = new THREE.Raycaster(origin, globalVertex.clone().normalize())
    var collisionResults = collisionray.intersectObject(bone_object)
    // draw3DLineGroup( origin, globalVertex, scene.current, 'blue', group, 'interlines')
    if (collisionResults.length > 0) {
      //} && collisionResults[0].distance < directionVector.length()) {
      verticesList.push(vertexIndex)
      // console.log("collisionTextBoolDetection: ",colBool)
      // console.log("verticesList: ", verticesList)

      if (colBool === false) {
        var p = new THREE.Vector3(0, 0, 0)
        console.log('collision_moveImplant!', collisionResults)
        addTextToScene(scene.current, 'COLLISION', p, 'yellow', 35, 'collision')
        colBool = true
      }
    }
    var counter = vertexIndex + step_size
    if (counter >= implant_object.geometry.attributes.position.array.length) {
      console.log('we ve reashed the end!: ', verticesList)
      const colorAttribute = implant_object.geometry.getAttribute('color')
      const redColor = new THREE.Color('red')
      verticesList.forEach((vertic) => {
        colorAttribute.setXYZ(vertic, redColor.r, redColor.g, redColor.b)
      })
      // if(verticesList.length != 0){
      //   for (
      //     var ind = 0;
      //     ind < 20000;
      //     ind += 1)
      //     {
      //       colorAttribute.setXYZ(ind, redColor.r, redColor.g, redColor.b);
      //   }
      //   colorAttribute.needsUpdate = true
      // }
    }
  }
}
drawVerticesLines
function drawVerticesLines(implant_object, bone_object, scene, colBool, step_size = 1500) {
  removeObjectSceneRef(scene, 'linesGroup')
  removeObjectSceneRef(scene, 'circle1')
  removeObjectSceneRef(scene, 'circle2')
  const group = new THREE.Group()
  group.name = 'linesGroup'
  scene.current.add(group)
  console.log('scene.current', scene.current)
  addSphere(implant_object.position, scene.current, 'blue', 'circle1')
  addSphere(implant_object.parent.position, scene.current, 'green', 'circle2')
  for (
    var vertexIndex = 0;
    vertexIndex < implant_object.geometry.attributes.position.array.length;
    vertexIndex += step_size
  ) {
    var localVertex = new THREE.Vector3()
      .fromBufferAttribute(implant_object.geometry.attributes.position, vertexIndex)
      .clone()
    var globalVertex = localVertex.clone().applyMatrix4(implant_object.matrixWorld)

    draw3DLineGroup(
      implant_object.parent.position,
      globalVertex,
      scene.current,
      'yellow',
      group,
      'linesGroup'
    )
  }
}

export function rotateCenter(
  pivotpoint,
  implant,
  scene,
  camera,
  renderer,
  controls,
  pivotController,
  rotationConfig,
  size,
  mode = 'rotate',
  addTransformer = false
) {
  console.log('pivotpoint: ', pivotpoint)
  var newImplantPositionPivot = new THREE.Vector3()

  if (rotationConfig.type === 'setPosition') {
    newImplantPositionPivot.subVectors(pivotpoint, implant.position)

    pivot.position.copy(pivotpoint)
    scene.current.add(pivot)
    pivot.add(implant)
    implant.position.copy(newImplantPositionPivot)
    implant.position.multiplyScalar(-1)

    if (addTransformer == true) {
      createTransformController(camera, renderer, scene, controls, size, mode, pivot)
    }
  } else if (rotationConfig.type === 'Center') {
    var implantBox = new THREE.Box3().setFromObject(implant)
    // Calculate the center of the bounding box
    var implantCenter = new THREE.Vector3()
    implantBox.getCenter(implantCenter)
    newImplantPositionPivot.subVectors(implantCenter, implant.position)

    pivot.position.copy(implantCenter)
    scene.current.add(pivot)
    pivot.add(implant)
    implant.position.copy(newImplantPositionPivot)
    implant.position.multiplyScalar(-1)

    if (addTransformer == true) {
      createTransformController(camera, renderer, scene, controls, size, mode, pivot)
    }
  }
}

export function draw3DLineGroup(point1, point2, scene, color = 'green', group, name) {
  const curve = new THREE.LineCurve3(point1, point2)
  const tubeGeometry = new THREE.TubeGeometry(curve, 1, 1, 8, false)
  const tubeMaterial = new THREE.MeshBasicMaterial({ color: color })
  const tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial)
  group.name = name

  group.add(tubeMesh)
}
draw3DLine

export function createTriangle(point1, point2, point3, scene, name = 'triangle', color = 'white') {
  // Create a geometry for the triangle
  const triangleGeometry = new THREE.BufferGeometry()

  const vertices = new Float32Array([
    point1.x,
    point1.y,
    point1.z, // v0
    point2.x,
    point2.y,
    point2.z, // v1
    point3.x,
    point3.y,
    point3.z
  ])

  const indices = [0, 1, 2]

  triangleGeometry.setIndex(indices)
  triangleGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3))
  triangleGeometry.computeVertexNormals()

  // Create a material for the triangle
  const triangleMaterial = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide })
  triangleMaterial.transparent = true
  triangleMaterial.opacity = 0.9

  // Create a mesh using the geometry and material
  const triangleMesh = new THREE.Mesh(triangleGeometry, triangleMaterial)
  triangleMesh.name = name

  // Add the mesh to the scene
  scene.add(triangleMesh)
}

export function createCircle(
  radius,
  position,
  orientation,
  scene,
  color = 'white',
  name = 'positionCircle'
) {
  var circleNormal = new THREE.Vector3(0, 0, 1)

  var quaternion = new THREE.Quaternion()
  const geometry = new THREE.CircleGeometry(radius, 64)
  const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide })
  material.transparent = true
  material.opacity = 0.4
  const circle = new THREE.Mesh(geometry, material)
  circle.name = name
  circle.position.copy(position)

  quaternion.setFromUnitVectors(circleNormal, orientation.clone().normalize())
  circle.applyQuaternion(quaternion)

  scene.add(circle)

  console.log('circle added: ', scene)
}

export function rotateAndTranslateObject(
  implantRotationPointOne,
  boneRotationPointOne,
  implant,
  implantPointVector,
  implantRotationPointTwo,
  boneRotationPointTwo,
  scene,
  pivotBool = null,
  pivot = null
) {
  /*
      boundary conditions equal 9 equations:
        b1: VectorFromOldToNewImplantposition needs to be on a sphere around the implantPointonthebone:
        || VectorFromOldToNewImplantposition - VectorfromImplantpositionToimplantPointonthebone || = || VectorfromImplantpositionToimplantPointonthebone ||
        b2: VectorFromOldToNewImplantposition + ImplantRotation*VectorfromImplantpositionToimplantPointonthebone = implantPointonthebone
        b3: VectorfromImplantpositionToimplantPointonthebone + VectorfromimplantPointOnTheBonetoNewPointOnBone(unit Vector!)
            = VectorFromOldToNewImplantposition + ImplantRotation*(VectorfromImplantpositionToimplantPointonthebone + VectorfromimplantPointOnTheBonetoNewPointOnImplant(unit Vector!))

      unknown variables: VectorFromOldToNewImplantposition (3 unknown), ImplantRotation (9 unknowns)
    */
  scene, pivotBool, pivot
  var VectorFromOldToNewImplantposition = new THREE.Vector3()
  var implant_pos = implant.position.clone()

  var VectorFromImplantpositionToImplantPointOnTheBone = new THREE.Vector3()
  var quaternion = new THREE.Quaternion()
  var matrix = new THREE.Matrix4()
  var VectorToNewPosition = new THREE.Vector3()

  var localimplantRotationPointOne = new THREE.Vector3()
  var localimplantRotationPointTwo = new THREE.Vector3()

  var localboneRotationPointOne = new THREE.Vector3()
  var localboneRotationPointTwo = new THREE.Vector3()

  localimplantRotationPointOne.subVectors(implantRotationPointOne, implantPointVector)
  localimplantRotationPointTwo.subVectors(implantRotationPointTwo, implantPointVector)

  let boneHalv = new THREE.Vector3()
  localboneRotationPointOne.subVectors(boneRotationPointOne, implantPointVector)
  localboneRotationPointTwo.subVectors(boneRotationPointTwo, implantPointVector)
  boneHalv = halvAngleVector(localboneRotationPointOne.clone(), localboneRotationPointTwo.clone())

  // absolute vector from world cos to the new position of the implant or from the pivot origin
  VectorFromImplantpositionToImplantPointOnTheBone.subVectors(implantPointVector, implant_pos)

  var VectorFromImplantpositionToImplantPointOnTheBone_ROTATED =
    VectorFromImplantpositionToImplantPointOnTheBone.clone()

  //vector from the new implant position to the first rotation points on the bone and implant in the WORLD-COS
  implantRotationPointOne.sub(implantPointVector).normalize() // Vector a
  boneRotationPointOne.sub(implantPointVector).normalize() // Vector b

  // Calculate the normal vector of the respective bone and implant vectors
  // the normal vectors are going to be used for the calculation of the rotation matrix
  implantRotationPointTwo.sub(implantPointVector).normalize()
  boneRotationPointTwo.sub(implantPointVector).normalize()

  const boneNormal = new THREE.Vector3()
    .crossVectors(boneRotationPointOne, boneRotationPointTwo)
    .normalize()
  const implantNormal = new THREE.Vector3()
    .crossVectors(implantRotationPointOne, implantRotationPointTwo)
    .normalize()

  quaternion.setFromUnitVectors(implantNormal, boneNormal)

  matrix.makeRotationFromQuaternion(quaternion)
  let rotationMatrix = new THREE.Matrix3().setFromMatrix4(matrix)

  //Calculate the new position of the implant
  VectorFromImplantpositionToImplantPointOnTheBone_ROTATED =
    VectorFromImplantpositionToImplantPointOnTheBone_ROTATED.applyMatrix3(rotationMatrix)

  VectorFromOldToNewImplantposition = VectorFromOldToNewImplantposition.subVectors(
    VectorFromImplantpositionToImplantPointOnTheBone,
    VectorFromImplantpositionToImplantPointOnTheBone_ROTATED
  )

  VectorToNewPosition.addVectors(implant_pos, VectorFromOldToNewImplantposition)
  implant.position.copy(VectorToNewPosition)
  implant.applyQuaternion(quaternion)

  //Second rotation with angle halv vectors

  localimplantRotationPointOne = localimplantRotationPointOne.applyMatrix3(rotationMatrix)
  localimplantRotationPointTwo = localimplantRotationPointTwo.applyMatrix3(rotationMatrix)

  let implantHalv = new THREE.Vector3()
  implantHalv = halvAngleVector(
    localimplantRotationPointOne.clone(),
    localimplantRotationPointTwo.clone()
  )

  implant_pos = implant.position.clone()

  VectorFromImplantpositionToImplantPointOnTheBone = new THREE.Vector3()

  VectorFromImplantpositionToImplantPointOnTheBone.subVectors(implantPointVector, implant_pos)
  VectorFromImplantpositionToImplantPointOnTheBone_ROTATED =
    VectorFromImplantpositionToImplantPointOnTheBone.clone()

  quaternion.setFromUnitVectors(implantHalv.clone().normalize(), boneHalv.clone().normalize())
  matrix.makeRotationFromQuaternion(quaternion)
  rotationMatrix = new THREE.Matrix3().setFromMatrix4(matrix)

  //Calculate the new position of the implant
  VectorFromImplantpositionToImplantPointOnTheBone_ROTATED =
    VectorFromImplantpositionToImplantPointOnTheBone_ROTATED.applyMatrix3(rotationMatrix)

  VectorFromOldToNewImplantposition = VectorFromOldToNewImplantposition.subVectors(
    VectorFromImplantpositionToImplantPointOnTheBone,
    VectorFromImplantpositionToImplantPointOnTheBone_ROTATED
  )

  VectorToNewPosition.addVectors(implant_pos, VectorFromOldToNewImplantposition)
  implant.position.copy(VectorToNewPosition)
  implant.applyQuaternion(quaternion)
}

export function moveImplantPointtoPoint(
  pointOnImplant,
  implantToPoint,
  implant,
  scene,
  sphere = true,
  setFlipAxis,
  axisDirection1 = new THREE.Vector3(),
  axisDirection2 = new THREE.Vector3()
) {
  if (axisDirection1.length() != 0) {
    setFlipAxis({ axis: axisDirection1 })
  }
  //Align Implant with main axis of the bone

  var quaternion = new THREE.Quaternion()
  if (axisDirection1.length() != 0 && axisDirection2.length() != 0) {
    let localPointOnImplant = pointOnImplant.clone()
    implant.worldToLocal(localPointOnImplant)

    //First Rotation
    let zCoordinate = new THREE.Vector3()
    implant.getWorldDirection(zCoordinate)

    quaternion.setFromUnitVectors(
      zCoordinate.clone().normalize(),
      axisDirection1.clone().normalize()
    )
    implant.applyQuaternion(quaternion)

    //Second Rotation
    var yVector = new THREE.Vector3(0, 25, 0)
    implant.localToWorld(yVector)
    yVector.sub(implant.position).normalize()
    var quaternion2 = new THREE.Quaternion()
    quaternion2.setFromUnitVectors(yVector, axisDirection2.clone().normalize())
    var combinedQuaternion = new THREE.Quaternion()
    combinedQuaternion.multiplyQuaternions(quaternion2, quaternion)
    // implant.applyQuaternion(combinedQuaternion)

    implant.localToWorld(localPointOnImplant)
    pointOnImplant = localPointOnImplant.clone()
  } else if (axisDirection1.length() != 0) {
    let localPointOnImplant = pointOnImplant.clone()
    implant.worldToLocal(localPointOnImplant)

    //First Rotation
    let zCoordinate = new THREE.Vector3()
    implant.getWorldDirection(zCoordinate)

    quaternion.setFromUnitVectors(
      zCoordinate.clone().normalize(),
      axisDirection1.clone().normalize()
    )
    implant.applyQuaternion(quaternion)

    implant.localToWorld(localPointOnImplant)
    pointOnImplant = localPointOnImplant.clone()
  }

  var implantPointVector
  removeObject(scene, 'implantController')

  var implantPosition = implant.position

  //calculates the Vector from the implant origin to the point on the implant in the absolute coordinate system
  //However The implant Coordinate system could be rotated
  implantPointVector = new THREE.Vector3().subVectors(pointOnImplant, implantPosition)
  var newImplantPosition = new THREE.Vector3().subVectors(implantToPoint, implantPointVector)
  implant.position.copy(newImplantPosition)

  if (sphere) {
    removeObject(scene, 'circle')
    addSphere(implantToPoint, scene, 'blue', 'circle', 1)
  }
}

function createWireFrame(object, scene, color = 'green') {
  const boxHelper = new THREE.BoxHelper(object, new THREE.Color(color))
  scene.add(boxHelper)
}
createWireFrame

function halvAngleVector(vector1, vector2) {
  let halvAngleVector = vector1.clone().normalize()

  let vec1 = vector1.clone().normalize()
  let vec2 = vector2.clone().normalize()

  let angle = vec1.angleTo(vec2) / 2

  const axis = new THREE.Vector3().crossVectors(vec1, vec2).normalize()
  const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, angle)
  halvAngleVector.applyQuaternion(quaternion)

  return halvAngleVector
}

export function secondIntuitiveMovement(
  firstBonePoint,
  secondBonePoint,
  secondImplantPoint,
  implant
) {
  var implant_pos = implant.position.clone()

  var VectorFromImplantPositionToFirstBonePoint = new THREE.Vector3()
  var VectorToNewPosition = new THREE.Vector3()
  var VectorFromOldToNewImplantposition = new THREE.Vector3()
  var quaternion = new THREE.Quaternion()
  var matrix = new THREE.Matrix4()

  VectorFromImplantPositionToFirstBonePoint.subVectors(firstBonePoint, implant_pos)
  var VectorFromImplantPositionToFirstBonePoint_ROTATED =
    VectorFromImplantPositionToFirstBonePoint.clone()

  var directionVectorBone = new THREE.Vector3().subVectors(secondBonePoint, firstBonePoint)
  var directionVectorImplant = new THREE.Vector3().subVectors(secondImplantPoint, firstBonePoint)

  quaternion.setFromUnitVectors(
    directionVectorImplant.clone().normalize(),
    directionVectorBone.clone().normalize()
  )
  matrix.makeRotationFromQuaternion(quaternion)
  let rotationMatrix = new THREE.Matrix3().setFromMatrix4(matrix)

  VectorFromImplantPositionToFirstBonePoint_ROTATED =
    VectorFromImplantPositionToFirstBonePoint_ROTATED.applyMatrix3(rotationMatrix)

  VectorFromOldToNewImplantposition = VectorFromOldToNewImplantposition.subVectors(
    VectorFromImplantPositionToFirstBonePoint,
    VectorFromImplantPositionToFirstBonePoint_ROTATED
  )

  VectorToNewPosition.addVectors(implant_pos, VectorFromOldToNewImplantposition)

  implant.position.copy(VectorToNewPosition)
  implant.applyQuaternion(quaternion)

  console.log('Second Move Finished')
}

export function thirdIntuitiveMovement(
  firstBonePoint,
  secondBonePoint,
  thirdBonePoint,
  thirdImplantPoint,
  scene,
  implant
) {
  var implant_pos = implant.position.clone()

  var VectorFromImplantPositionToFirstBonePoint = new THREE.Vector3() //This is going to be the rotation axis
  var VectorToNewPosition = new THREE.Vector3()
  var VectorFromOldToNewImplantposition = new THREE.Vector3()
  var quaternion = new THREE.Quaternion()
  // var quaternion2 = new THREE.Quaternion()
  var matrix = new THREE.Matrix4()

  let old = false

  VectorToNewPosition, VectorFromOldToNewImplantposition, quaternion, matrix

  VectorFromImplantPositionToFirstBonePoint.subVectors(firstBonePoint, implant_pos)
  var VectorFromImplantPositionToFirstBonePoint_ROTATED =
    VectorFromImplantPositionToFirstBonePoint.clone()

  // var rotationAxis = new THREE.Vector3().subVectors(secondBonePoint, firstBonePoint).normalize()

  //The Implant vector must be rotated around the rotation-axis "rotationAxis" until it is equal to the bone vector
  var directionVectorBone = new THREE.Vector3().subVectors(thirdBonePoint, firstBonePoint)
  var directionVectorImplant = new THREE.Vector3().subVectors(thirdImplantPoint, firstBonePoint)
  // console.log("directionVectorImplant: ", directionVectorImplant.clone())

  var directionSecondBonePoint = new THREE.Vector3().subVectors(secondBonePoint, firstBonePoint)

  const boneNormal = new THREE.Vector3()
    .crossVectors(directionVectorBone, directionSecondBonePoint)
    .normalize()
  const implantNormal = new THREE.Vector3()
    .crossVectors(directionVectorImplant, directionSecondBonePoint)
    .normalize()

  // var angle = boneNormal.angleTo(implantNormal)
  // console.log("angle: ", angle)

  // removeObject(scene, 'l1')
  // removeObject(scene, 'l2')
  // removeObject(scene, 'l3')
  // removeObject(scene, 'l4')
  // removeObject(scene, 'l5')
  // draw3DLine(firstBonePoint, boneNormal.clone().multiplyScalar(100).add(firstBonePoint), scene, 'purple', 'l1')
  // draw3DLine(firstBonePoint, implantNormal.clone().multiplyScalar(100).add(firstBonePoint), scene, 'yellow', 'l2')
  // draw3DLine(firstBonePoint, secondBonePoint, scene, 'green', 'l3')
  // draw3DLine(firstBonePoint, thirdImplantPoint, scene, 'blue', 'l4')
  // draw3DLine(firstBonePoint, thirdBonePoint, scene, 'red', 'l4')

  // quaternion2.setFromAxisAngle(rotationAxis, angle);
  quaternion.setFromUnitVectors(implantNormal, boneNormal)
  // console.log("quaternion: ", quaternion)
  // console.log("quaternion2: ", quaternion2)
  matrix.makeRotationFromQuaternion(quaternion)
  let rotationMatrix = new THREE.Matrix3().setFromMatrix4(matrix)

  // let rot = directionVectorImplant.clone().applyMatrix3(rotationMatrix).add(firstBonePoint)
  // draw3DLine(firstBonePoint, rot, scene, 'white', 'l5')

  VectorFromImplantPositionToFirstBonePoint_ROTATED.applyMatrix3(rotationMatrix)

  VectorFromOldToNewImplantposition = VectorFromOldToNewImplantposition.subVectors(
    VectorFromImplantPositionToFirstBonePoint,
    VectorFromImplantPositionToFirstBonePoint_ROTATED
  )

  VectorToNewPosition.addVectors(implant_pos, VectorFromOldToNewImplantposition)
  implant.position.copy(VectorToNewPosition)
  implant.applyQuaternion(quaternion)

  if (old) {
    var vec1 = directionVectorBone.clone().normalize()
    var vec2 = directionVectorImplant.clone().normalize()
    removeObject(scene, 'line1')
    removeObject(scene, 'line2')
    removeObject(scene, 'line3')
    draw3DLine(firstBonePoint, thirdBonePoint, scene, 'white', 'line1')
    draw3DLine(firstBonePoint, thirdImplantPoint, scene, 'white', 'line2')
    quaternion.setFromUnitVectors(vec2, vec1)
    matrix.makeRotationFromQuaternion(quaternion)
    let rotationMatrix = new THREE.Matrix3().setFromMatrix4(matrix)
    vec2.applyMatrix3(rotationMatrix).multiplyScalar(100)
    vec2.add(firstBonePoint)
    draw3DLine(firstBonePoint, vec2, scene, 'purple', 'line3')

    VectorFromImplantPositionToFirstBonePoint_ROTATED.applyMatrix3(rotationMatrix)

    VectorFromOldToNewImplantposition = VectorFromOldToNewImplantposition.subVectors(
      VectorFromImplantPositionToFirstBonePoint,
      VectorFromImplantPositionToFirstBonePoint_ROTATED
    )

    VectorToNewPosition.addVectors(implant_pos, VectorFromOldToNewImplantposition)
    implant.position.copy(VectorToNewPosition)
    implant.applyQuaternion(quaternion)
  }
}

export function calculateCountercathete(alpha, a, c, vectorC) {
  /*
  This calculates the point of a CounterCathete of a triangle where three points A,B,C are given.
  Vector C is the vector between B and C
  Vector A is the vector between B and A
  a and c are the respective lengths of the vectors
  The function is build from sentence of pythagoras and sin(alpha) = countercathete/hypotenuse
  alpha is the angle between vector C and a Vector A
  VectorD has the same direction as Vector C but a different length
  */

  //    D = (squareroot( 1 - ( (sin(alpha)^2) / a^4) ) * (a/c) ) * C
  // alpha = angle

  // Compute the squared sine of alpha
  var sinSquaredAlpha = Math.pow(Math.sin(alpha), 2)

  // Compute the square root term
  var squareRootTerm = Math.sqrt(1 - sinSquaredAlpha / Math.pow(a, 4))

  // Calculate vector D
  var scalar = (a / c) * squareRootTerm
  var vectorD = vectorC.clone().multiplyScalar(scalar)

  // console.log("vectorD: ", vectorD)
  // console.log("vectorC: ", vectorC)

  return vectorD
}

export function rotateImplant(
  scene,
  object,
  firstBonePoint,
  degree,
  deepCopy,
  applyTransformMatrices,
  calculateTransformMatrices
) {
  var object_pos = object.position.clone()

  let xCoordinate = new THREE.Vector3(1, 0, 0)
  object.localToWorld(xCoordinate)
  xCoordinate.sub(object_pos).normalize()

  var rotationPivot = new THREE.Group()
  rotationPivot.name = 'rotationPivot'

  var translation = new THREE.Vector3().subVectors(object_pos, firstBonePoint)
  rotationPivot.position.copy(firstBonePoint)

  rotationPivot.add(object)
  object.position.copy(translation)
  scene.add(rotationPivot)

  var quaternion = new THREE.Quaternion()
  if (degree == '180') {
    quaternion.setFromAxisAngle(xCoordinate.clone().normalize(), -Math.PI)
    rotationPivot.applyQuaternion(quaternion)
  } else if (degree == 'P90') {
    quaternion.setFromAxisAngle(xCoordinate.clone().normalize(), -Math.PI / 2)
    rotationPivot.applyQuaternion(quaternion)
  } else if (degree == 'M90') {
    quaternion.setFromAxisAngle(xCoordinate.clone().normalize(), Math.PI / 2)
    rotationPivot.applyQuaternion(quaternion)
  }

  rotationPivot.updateWorldMatrix()

  var currentState = deepCopy(calculateTransformMatrices())

  object.removeFromParent()
  scene.add(object)

  applyTransformMatrices(deepCopy(currentState))
}
