import React from 'react'
import { Typography, Button, Grid, Box, TextField } from '@mui/material'
import render from '../../assets/login_render_img.png'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { authenticationSDK } from './AuthenticationSDK'
import { rqlBuilder } from '@extrahorizon/javascript-sdk'

function Register() {
  const navigate = useNavigate()
  const textFieldStyle = {
    backgroundColor: '#727272',
    borderRadius: '4px',

    input: {
      color: '#fff',

      borderColor: '#fff'
    },
    label: { color: '#fff' },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#fff'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#fff'
      }
    },
    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff'
    }
  }
  const [emailInput, setEmailInput] = useState('')
  const [firstNameInput, setFirstNameInput] = useState('')
  const [lastNameInput, setLastNameInput] = useState('')
  const [affiliatedCompanyInput, setAffiliatedCompanyInput] = useState('')

  const [passInput, setPassInput] = useState('')
  const [repeatPassInput, setRepeatPassInput] = useState('')

  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value)
  }
  const handleFirstNameInputChange = (event) => {
    setFirstNameInput(event.target.value)
  }
  const handleLastNameInputChange = (event) => {
    setLastNameInput(event.target.value)
  }

  const handleAffiliatedCompanyInputChange = (event) => {
    setAffiliatedCompanyInput(event.target.value)
  }
  const handlePassInputChange = (event) => {
    setPassInput(event.target.value)
  }
  const handleRepeatPassInputChange = (event) => {
    setRepeatPassInput(event.target.value)
  }

  const checkEmailAvailability = async (sdk, email) => {
    let available = await sdk.users.isEmailAvailable(email)
    return available
  }

  const checkPasswordRepeat = (pass, repeatPass) => {
    if (pass === repeatPass) {
      return true
    } else {
      return false
    }
  }

  const checkPasswordValid = (password) => {
    let valid = true
    let numbers = /[0-9]/g
    if (password === password.toLowerCase() || password === password.toUpperCase()) {
      alert('password must contain at least one lowercase character and one capital character')
      valid = false
    }
    if (password.length < 8) {
      alert('password must be at least 8 characters long')
      valid = false
    }
    if (!password.match(numbers)) {
      alert('password must contain at least one number')
      valid = false
    }

    return valid
  }

  const checkValues = () => {
    let valid = true
    if (emailInput.length === 0) {
      alert('Please enter an email')
      valid = false
    }
    if (firstNameInput.length === 0) {
      alert('Please enter a first name')
      valid = false
    }
    if (lastNameInput.length === 0) {
      alert('Please enter a last name')
      valid = false
    }
    if (affiliatedCompanyInput.length === 0) {
      alert('Please enter an affiliated company')
      valid = false
    }
    if (passInput.length === 0) {
      alert('Please enter a password')
      valid = false
    }
    if (repeatPassInput.length === 0) {
      alert('Please repeat your password')
      valid = false
    }
    return valid
  }
  const registerUser = async (sdk, firstName, lastName, email, password, affiliatedCompany) => {
    const response = await sdk.users.createAccount({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,

      phoneNumber: '+49012345678',
      birthday: '2022-02-22',
      country: 'DE',
      gender: 1,
      language: 'EN',
      timeZone: 'Europe/London'
    })
    await sdk.data.documents.create('additionalUserInfo', {
      userId: response.id,
      affiliatedCompany: affiliatedCompany
    })

    // Add staff role to user
    let rql = rqlBuilder().eq('email', email).build()
    await sdk.users.globalRoles.addToUsers(rql, {
      roles: ['651424d5347cd96d71a06eca']
    })
  }

  const handleRegistration = async () => {
    let authentication = await authenticationSDK(
      process.env.REACT_APP_EXTRAHORIZON_EMAIL,
      process.env.REACT_APP_EXTRAHORIZON_PASSWORD
    )
    if (!authentication.result) {
      alert('Unable to register. Please try again later!')
    } else if (checkValues() && checkPasswordValid(passInput)) {
      const result = await checkEmailAvailability(authentication.sdk, emailInput)
      if (!result.emailAvailable) {
        alert('Entered email is already registered!')
      } else if (!checkPasswordRepeat(passInput, repeatPassInput)) {
        alert('Entered passwords do not match!')
      } else {
        await registerUser(
          authentication.sdk,
          firstNameInput,
          lastNameInput,
          emailInput,
          passInput,
          affiliatedCompanyInput
        )

        alert('User succesfully created! Please verify your email!')
        navigate('/', { replace: true })
      }
    }
  }

  useEffect(() => {}, [])

  return (
    <Box
      sx={{
        backgroundColor: '#191919',
        width: '100%',
        height: '100vh'
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          padding: '16px',
          height: '100vh'
        }}
      >
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={5}
          sx={{
            height: '100vh'
          }}
        >
          <Grid item>
            <Box
              sx={{
                backgroundColor: '#383838',
                borderRadius: '20px',
                padding: '32px',
                color: '#fff',
                maxWidth: '70%'
              }}
            >
              <Typography variant="h4">Register new Account</Typography>
              <Typography variant="h6">
                Already have an account?{' '}
                <a style={{ color: '#1976d2' }} href="/">
                  Login
                </a>
                .{' '}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1, color: '#fff' }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={emailInput}
                  onChange={handleEmailInputChange}
                  autoFocus
                  sx={textFieldStyle}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="firstName"
                  label="First Name"
                  id="name"
                  value={firstNameInput}
                  onChange={handleFirstNameInputChange}
                  sx={textFieldStyle}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  id="name"
                  value={lastNameInput}
                  onChange={handleLastNameInputChange}
                  sx={textFieldStyle}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="affiliatedCompany"
                  label="Affiliated Company"
                  id="affiliatedCompany"
                  value={affiliatedCompanyInput}
                  onChange={handleAffiliatedCompanyInputChange}
                  sx={textFieldStyle}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={passInput}
                  onChange={handlePassInputChange}
                  sx={textFieldStyle}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="repeatpassword"
                  label="Repeat Password"
                  type="password"
                  id="repeatpassword"
                  value={repeatPassInput}
                  onChange={handleRepeatPassInputChange}
                  sx={textFieldStyle}
                />

                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleRegistration}
                >
                  Register
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={7}
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '100vh'
          }}
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              height: '100%'
            }}
          >
            <Box
              component="img"
              src={render}
              alt="Mango Rendering"
              sx={{ maxWidth: '70vh', margin: '16px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
export default Register
