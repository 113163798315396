/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react'
import { Box, useTheme, Typography, Slider, Grid, TextField } from '@mui/material'
import Draggable from 'react-draggable' // add drag function to move box
import OpenWithIcon from '@mui/icons-material/OpenWith'
import CloseButton from './CloseButton'

// brush tool function in toolbox
const MainViewBrushToolBox = ({
  Radius: Radius,
  setRadius: setRadius,
  volumeRange: volumeRange,
  SetLabelThresholdRange: SetLabelThresholdRange,
  labelBoxMargin: labelBoxMargin,
  setShowToolBox: setShowToolBox
}) => {
  const theme = useTheme()
  const handleBrushRadiusChange = (event, newValue) => {
    setRadius({ radius: newValue })
  }

  const [threshold, setThreshold] = useState([20, 50]) // Default threshold values

  const draggableRef = useRef(null)

  // State for keeping track of the minimum and maximum possible threshold values
  const [thresholdRange, setThresholdRange] = useState({ min: -Infinity, max: Infinity })

  // Update the thresholdRange state as necessary.
  // For example, if you have a function that gets the new minimum and maximum threshold values:
  const updateThresholdRange = (newMin, newMax) => {
    setThresholdRange({ min: newMin, max: newMax })
  }

  const handleThresholdChange = (event, newValue) => {
    setThreshold(newValue)
  }

  const handleMinThresholdChange = (event) => {
    let value = parseInt(event.target.value)
    value = Math.min(value, threshold[1])
    setThreshold([value, threshold[1]])
  }

  const handleMaxThresholdChange = (event) => {
    let value = parseInt(event.target.value)
    value = Math.max(value, threshold[0])
    setThreshold([threshold[0], value])
  }

  useEffect(() => {
    if (volumeRange.min !== 0.0 || volumeRange.max !== 0.0) {
      const newThreshold = [volumeRange.min, volumeRange.max]
      setThreshold(newThreshold)
      updateThresholdRange(newThreshold[0], newThreshold[1])

      SetLabelThresholdRange({
        min: newThreshold[0],
        max: newThreshold[1]
      })
    }
  }, [volumeRange])

  useEffect(() => {
    if (volumeRange.min !== 0.0 || volumeRange.max !== 0.0) {
      SetLabelThresholdRange({
        min: threshold[0],
        max: threshold[1]
      })
    }
  }, [threshold])

  return (
    // dragging is only working at the first line of this box
    <Draggable nodeRef={draggableRef} handle=".drag-icon">
      <Box
        ref={draggableRef}
        sx={{
          position: 'absolute',
          top: `calc(${labelBoxMargin}% + ${theme.sizes.nav.top} + 100px)`,
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '16px',
          gap: '16px',
          width: '240px',
          background: '#232323',
          boxShadow: '0px 4px 20px rgba(255, 255, 255, 0.25)',
          borderRadius: '8px',
          zIndex: 9990,
          right: '10px',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Open Sans',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
              flex: 'none',
              order: 0,
              flexGrow: 0
            }}
          >
            Brush
          </Typography>

          <div>
            <OpenWithIcon
              className="drag-icon"
              sx={{
                color: '#FFFFFF',
                cursor: 'move',
                backgroundColor: '',
                borderRadius: '50%',
                fontSize: '25px',
                zIndex: 9999
              }}
            />
            <CloseButton setShowToolBox={setShowToolBox} />
          </div>
        </Box>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography
              sx={{ color: '#FFFFFF', fontWeight: 400, fontSize: '12px', lineHeight: '20px' }}
            >
              Brush Radius
            </Typography>
          </Grid>
          <Grid item xs>
            {/* slider to choose radius of brush */}
            <Slider
              value={Radius.radius}
              onChange={handleBrushRadiusChange}
              valueLabelDisplay="auto"
              min={0}
              max={25}
              step={1}
              sx={{
                color: '#FFFFFF',
                width: '100%' // Set the width of the slider to 100%
              }}
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" spacing={2} sx={{ marginTop: '2px' }}>
          <Grid item>
            <Typography
              sx={{ color: '#FFFFFF', fontWeight: 400, fontSize: '12px', lineHeight: '20px' }}
            >
              Threshold Range
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center" spacing={2} sx={{ marginTop: '1px' }}>
          <Grid item xs={6}>
            <TextField
              value={threshold[0]}
              onChange={handleMinThresholdChange}
              variant="outlined"
              type="number"
              sx={{
                '& .MuiOutlinedInput-input': {
                  color: '#FFFFFF',
                  background: 'transparent',
                  border: '1px solid #FFFFFF',
                  borderRadius: '4px',
                  height: '28px', // Adjust the height
                  width: '60px', // Adjust the width
                  padding: '4px 8px', // Adjust the padding
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '20px'
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFFFFF'
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFFFFF'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFFFFF'
                  }
                }
              }}
              InputProps={{
                inputProps: {
                  min: thresholdRange.min,
                  max: threshold[1], // Use the min from thresholdRange and max from threshold
                  step: 1
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <TextField
              value={threshold[1]}
              onChange={handleMaxThresholdChange}
              variant="outlined"
              type="number"
              sx={{
                '& .MuiOutlinedInput-input': {
                  color: '#FFFFFF',
                  background: 'transparent',
                  border: '1px solid #FFFFFF',
                  borderRadius: '4px',
                  height: '28px', // Adjust the height
                  width: '60px', // Adjust the width
                  padding: '4px 8px', // Adjust the padding
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '20px'
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFFFFF'
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFFFFF'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFFFFF'
                  }
                }
              }}
              InputProps={{
                inputProps: {
                  min: threshold[0],
                  max: thresholdRange.max, // Use the min from threshold and max from thresholdRange
                  step: 1
                }
              }}
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" spacing={2}>
          <Grid item xs>
            <Slider
              value={threshold}
              onChange={handleThresholdChange}
              valueLabelDisplay="auto"
              min={thresholdRange.min} // Use the min from thresholdRange
              max={thresholdRange.max} // Use the max from thresholdRange
              step={1}
              sx={{
                color: '#FFFFFF',
                width: '100%'
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Draggable>
  )
}

export default MainViewBrushToolBox
