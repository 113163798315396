/* eslint-disable react/prop-types */
import React from 'react'
import { Switch, Typography, styled, Box } from '@mui/material'

// Creating a styled box that matches your MainViewSmallButton design
const StyledSwitchBox = styled(Box)(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row', // Changed to row for inline elements
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 16px', // Adjust the padding as needed
  border: '2px solid #91C58E',
  borderRadius: '8px',
  width: 'fit-content', // Adjusted to fit the content
  height: '36px', // Adjust height as needed
  marginLeft: '60px',
  marginTop: '5px'
}))

const MainViewAnimationSwitchButton = ({ animationOn, setAnimationOn }) => {
  const handleChange = (event) => {
    setAnimationOn(event.target.checked)
  }

  return (
    <StyledSwitchBox>
      <Typography
        sx={{
          color: !animationOn ? '#4CAF50' : '#BDBDBD',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px'
        }}
      >
        Normal View
      </Typography>
      <Switch checked={animationOn} onChange={handleChange} />
      <Typography
        sx={{
          color: animationOn ? '#4CAF50' : '#BDBDBD',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px'
        }}
      >
        Animation View
      </Typography>
    </StyledSwitchBox>
  )
}

export default MainViewAnimationSwitchButton
