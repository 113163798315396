/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { mainviewSaveButtonHandler } from './MainViewSaveButtonHandler'
import SaveIcon from '@mui/icons-material/Save'
import { SaveButton } from '../../../../Theme'
import { Typography } from '@mui/material'
import { UserContext } from '../../../../App'

// save button component in header component
const MainViewSaveButton = ({
  sdk,
  backInfo,
  buttonState,
  label,
  setIsSaving,
  filePath,
  setFilePath,
  repoScene
}) => {
  const { manualRepositionMatrix } = useContext(UserContext)

  return (
    <SaveButton
      onClick={() =>
        mainviewSaveButtonHandler(
          sdk,
          backInfo,
          buttonState,
          label,
          setIsSaving,
          filePath,
          setFilePath,
          repoScene,
          manualRepositionMatrix
        )
      }
      id="save-button"
    >
      <SaveIcon sx={{ fontSize: 40 }} />
      <Typography>Save</Typography>
    </SaveButton>
  )
}

export default MainViewSaveButton
