/* eslint-disable react/prop-types */
import { getSchema } from '../others/GetSchema'
import { getDocument } from '../others/GetDocument'
import { getCurrentFullDate } from '../others/GetCurrentDate'
import { uploadLabelData } from './UploadLabelData'
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js'

// save button handler in header component
export const mainviewSaveButtonHandler = async (
  sdk,
  backInfo,
  buttonState,
  label,
  setIsSaving,
  filePath,
  setFilePath,
  repoScene,
  manualRepositionMatrix
) => {
  // Function to serialize a three.js mesh to OBJ format
  function exportOBJ(mesh) {
    // Use the OBJExporter from three.js
    const exporter = new OBJExporter()
    const result = exporter.parse(mesh)
    return result
  }

  if (buttonState !== 'Seg' && buttonState !== 'Iso' && buttonState !== 'Rep') {
    window.alert(
      'wrong header button state. Only Segmentation, Isolation and Repositioning can save.'
    )
    return
  }

  const schema = await getSchema(sdk) // get current schema information
  const document = await getDocument(sdk, backInfo) // get current document information
  const currentDate = getCurrentFullDate() // get current time

  // no allow to modify demo files
  if (document.data.user === 'demo') {
    console.log('you cannot change demo file')
  } else {
    // create prompt while saving
    setIsSaving(true)

    if (buttonState === 'Rep') {
      let manualRepositionMatrixFiles = {}

      for (const objectName of Object.keys(manualRepositionMatrix)) {
        let matrixArray = new Float32Array(manualRepositionMatrix[objectName])

        const fileName = `${document.data.filename}_manual_reposition_${objectName}.bin`

        const matrixBlob = new Blob([matrixArray], { type: 'application/octet-stream' })

        // Upload the tfm file
        const labelFileMetaData = await uploadLabelData(
          fileName,
          matrixBlob,
          sdk,
          document.data.user
        )

        manualRepositionMatrixFiles[objectName] = labelFileMetaData.tokens[0].token
      }

      const labelRegex = /^label\d+$/ // Regular expression to match "label" followed by numbers
      const labeledChildren = repoScene.children.filter((child) => labelRegex.test(child.name))
      let newObjFiles = {}

      for (const mesh of labeledChildren) {
        const fileName = `${document.data.filename}_${mesh.name}.obj` // Create a filename for the OBJ file
        const objData = exportOBJ(mesh) // Serialize the mesh to OBJ format

        // Convert the OBJ string to a Blob for uploading if required by your SDK
        const labelBlob = new Blob([objData], { type: 'application/octet-stream' })

        // Upload the OBJ file
        const labelFileMetaData = await uploadLabelData(
          fileName,
          labelBlob,
          sdk,
          document.data.user
        )

        newObjFiles[mesh.name] = labelFileMetaData.tokens[0].token
      }

      await sdk.data.documents.update(schema.id, document.id, {
        repositionObjects: newObjFiles,
        regObjToken: '',
        manualRepositionTransformMatrix: manualRepositionMatrixFiles,
        status: 'registered_file',
        history: {
          date: currentDate,
          repositionObjects: document.data.repositionObjects
        }
      })

      setFilePath({
        ...filePath,
        regObj: ''
      })

      setIsSaving(false) // close prompt because the saving process is done

      return ''
    } else {
      let labelToken = '' // the variable to get current label token

      if (buttonState === 'Seg') {
        labelToken = document.data.segToken
      } else if (buttonState === 'Iso') {
        labelToken = document.data.isoToken
      }

      const LabelData = label.data // current label data
      const labelBlob = new Blob([LabelData], { type: 'application/octet-stream' }) // create label data into blob format

      // upload current label data into file service
      const labelFileMetaData = await uploadLabelData(
        document.data.filename,
        labelBlob,
        sdk,
        document.data.user
      )

      // create form data to save necessary information to send backend server
      const formData = new FormData()
      formData.append('data', labelFileMetaData.tokens[0].token) // new label data
      formData.append('userID', document.data.user)
      formData.append('filename', document.data.filename)
      formData.append('buttonState', buttonState)
      formData.append('fileToken', labelToken) // old label data
      formData.append(
        'dimension',
        JSON.stringify([label.RASDimensions[0], label.RASDimensions[1], label.RASDimensions[2]])
      )

      try {
        const response = await fetch('/save', {
          method: 'POST',
          body: formData
        })

        if (!response.ok) {
          throw new Error('Request failed with status ' + response.status)
        }

        const data = await response.json()
        const newToken = data.dataToken

        // save new token into document depends on your button state
        if (buttonState === 'Seg') {
          await sdk.data.documents.update(schema.id, document.id, {
            segToken: newToken,
            isoToken: '',
            regToken: '',
            regObjToken: '',
            status: 'segmented_file',
            history: {
              date: currentDate,
              fileToken: document.data.fileToken,
              segToken: document.data.segToken
            }
          })

          setFilePath({
            ...filePath,
            iso: '',
            reg: '',
            reg_image: '',
            regObj: '',
            target_image: '',
            target: ''
          })
        } else if (buttonState === 'Iso') {
          await sdk.data.documents.update(schema.id, document.id, {
            isoToken: newToken,
            regToken: '',
            regObjToken: '',
            status: 'isolated_file',
            history: {
              date: currentDate,
              isoToken: document.data.isoToken
            }
          })

          setFilePath({
            ...filePath,
            reg: '',
            reg_image: '',
            regObj: '',
            target_image: '',
            target: ''
          })
        }

        setIsSaving(false) // close prompt because the saving process is done

        return newToken
      } catch (error) {
        console.error('Fetch error: ', error.message)
        window.alert(error.message)
        setIsSaving(false)
        // Optional: return undefined or a specific error token if you need to signal a failure to the caller
      }
    }
  }
}
