//this is the collection of styles to use for our web application
import { styled, Button, IconButton } from '@mui/material'
import { createTheme } from '@mui/material/styles'

export const VerticalIconButton = styled(IconButton)(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  color: '#D9D9D9',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px 16px',
  left: '20%',
  right: '20%',
  top: '0%',
  bottom: '0%',
  border: '2px solid #91C58E',
  borderRadius: '8px',
  fontSize: '1em',
  width: '120px',
  height: '35px',
  '&:hover': {
    background: '#91C58E'
  }
}))

export const SaveButton = styled(Button)(() => ({
  position: 'fixed',
  right: 20,
  bottom: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#2C622C',
  boxShadow:
    '0px 9px 46px rgba(52, 52, 52, 0.65), 0px -9px 26px rgba(52, 52, 52, 0.65), 5px 9px 26px rgba(52, 52, 52, 0.65)',
  borderRadius: '50%',
  width: '80px',
  height: '80px',
  zIndex: 9999,
  color: '#FFFFFF',
  '&:hover': {
    background: '#4CAF50'
  }
}))

export const CameraResetButton = styled(Button)(() => ({
  position: 'fixed',
  right: 20,
  bottom: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#2C622C',
  boxShadow:
    '0px 9px 46px rgba(52, 52, 52, 0.65), 0px -9px 26px rgba(52, 52, 52, 0.65), 5px 9px 26px rgba(52, 52, 52, 0.65)',
  borderRadius: '50%',
  width: '80px',
  height: '80px',
  zIndex: 9999,
  color: '#FFFFFF',
  '&:hover': {
    background: '#4CAF50'
  }
}))

export const MainViewSmallButton = styled(Button)(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 16px',
  left: '0%',
  right: '54.84%',
  top: '0%',
  bottom: '0%',
  border: '2px solid #91C58E',
  borderRadius: '8px',
  width: '30px',
  height: '35px',
  '& .MuiButton-startIcon': {
    margin: '0px',
    marginLeft: '-5px'
  },
  '&:hover': {
    background: '#91C58E'
  }
}))

export const BackNextButton = styled(Button)(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(217, 217, 217, 0.16)',
  border: '2px solid #ADADAD',
  borderRadius: '5px',
  color: '#D9D9D9',
  fontFamily: 'Open Sans',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
  height: '35px !important',
  position: 'relative',
  '& .MuiButton-startIcon': {
    margin: '0px',
    marginRight: '-4px',
    marginLeft: '4px'
  },
  '& .MuiButton-endIcon': {
    margin: '0px'
  },
  '&:hover': {
    background: 'rgba(172, 236, 161, 0.25)',
    border: '2px solid #91C58E',
    borderRadius: '5px'
  }
}))
// StepButton to use for header buttons
export const StepButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: '8px',
  paddingLeft: '16px',
  paddingRight: '16px',
  borderRadius: '0px',
  boxShadow: '0 0 0px 0px',
  borderBottom: 'solid',
  borderColor: 'transparent',
  borderWidth: '3px',
  fontSize: '1.3em',

  '&:active': {
    borderColor: 'transparent transparent #181 transparent'
  },

  '&:hover': {
    borderBottom: 'solid',
    borderColor: 'transparent transparent #181 transparent',
    borderWidth: '5px'
  }
}))

export const HeaderButtonContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px',
  cursor: 'pointer', // change cursor when hovering over the button
  marginLeft: '-30px' // Negative left margin for overlap
})

export const TextContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Segoe UI',
  fontStyle: 'normal',
  fontSize: '18px',
  fontWeight: 500,
  color: '#FFFFFF'
})

export const RectanglePart = styled('div')(({ hover, active, done }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  width: '145px',
  height: '40px',
  background: hover
    ? '#2C622C'
    : done
    ? 'rgba(172, 236, 161, 0.25)'
    : active
    ? '#2C622C'
    : 'rgba(217, 217, 217, 0.12)',
  fontStyle: 'normal',
  fontSize: '16px',
  fontWeight: 600,
  color: '#FFFFFF'
}))

export const RightTrianglePart = styled('div')(({ hover, active, done }) => ({
  width: '36px',
  height: '60px',
  background: hover
    ? '#2C622C'
    : done
    ? 'rgba(172, 236, 161, 0.25)'
    : active
    ? '#2C622C'
    : 'rgba(217, 217, 217, 0.12)',
  clipPath: 'polygon(0 0, 20% 0, 100% 50%, 20% 100%, 0% 100%)'
}))

export const LeftTrianglePart = styled('div')(({ hover, active, done }) => ({
  width: '36px',
  height: '60px',
  background: hover
    ? '#2C622C'
    : done
    ? 'rgba(172, 236, 161, 0.25)'
    : active
    ? '#2C622C'
    : 'rgba(217, 217, 217, 0.12)',
  clipPath: 'polygon(100% 0%, 20% 0%, 95% 50%, 20% 100%, 100% 100%)'
}))

export const appTheme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: {
      light: '#ACECA1',
      main: '#629460',
      dark: '#036019',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000'
    },
    background: {
      highlight: '#505050',
      light: '#383838',
      main: '#191919',
      toolbar: '#232323',
      contrastText: '#000'
    }
  },
  sizes: {
    nav: {
      top: '55px',
      left: '100px',
      footer: '70px'
    }
  }
})
