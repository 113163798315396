import React from 'react'
import { Typography, Button, Grid, Box, TextField } from '@mui/material'
import render from '../../assets/login_render_img.png'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { authenticationSDK } from './AuthenticationSDK'

function SetNewPassword() {
  const navigate = useNavigate()
  const textFieldStyle = {
    backgroundColor: '#727272',
    borderRadius: '4px',

    input: {
      color: '#fff',
      borderColor: '#fff'
    },
    label: { color: '#fff' },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#fff'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#fff'
      }
    },
    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff'
    }
  }
  const windowUrl = window.location.search
  const params = new URLSearchParams(windowUrl)
  const hashInput = params.get('hash')

  const [passInput, setPassInput] = useState('')
  const [repeatPassInput, setRepeatPassInput] = useState('')

  const handlePassInputChange = (event) => {
    setPassInput(event.target.value)
  }
  const handleRepeatPassInputChange = (event) => {
    setRepeatPassInput(event.target.value)
  }

  const checkPasswordRepeat = (pass, repeatPass) => {
    if (pass === repeatPass) {
      return true
    } else {
      return false
    }
  }
  //TODO
  /* const registerUser = async (sdk, hash, password) => {
    await sdk.users.createAccount({});
    // Dario at Action!
  };
  */
  const checkPasswordValid = (password) => {
    let valid = true
    if (password === password.toLowerCase() || password === password.toUpperCase()) {
      alert('password must contain at least one lowercase character and one capital character')
      valid = false
    }
    if (password.length < 8) {
      alert('password must be at least 8 characters long')
      valid = false
    }

    // check if password contains a number
    let numbers = /[0-9]/g
    if (!password.match(numbers)) {
      alert('password must contain at least one number')
      valid = false
    }

    return valid
  }
  const handleNewPassword = async () => {
    let authentication = await authenticationSDK(
      process.env.REACT_APP_EXTRAHORIZON_EMAIL,
      process.env.REACT_APP_EXTRAHORIZON_PASSWORD
    )
    if (!authentication.result) {
      alert('Unable to register. Please try again later!')
    } else if (checkPasswordValid(passInput)) {
      //await authentication.sdk.users.validateEmailActivation({ hash: 'f77fa87753d9701e236d756b7fe179454240f4b9',});
      //await authentication.sdk.users.requestEmailActivation('julien@mangomedical.io');
      if (!checkPasswordRepeat(passInput, repeatPassInput)) {
        alert('Entered passwords do not match!')
      } else {
        let reset_res = await authentication.sdk.users.validatePasswordReset({
          hash: hashInput,
          newPassword: repeatPassInput
        })
        if (!reset_res) {
          alert('Password reset failed! Please check your input hash and try again.')
        } else {
          alert('Password successfully changed! Please login with your new password')
          navigate('/', { replace: true })
        }
      }
    }
  }

  useEffect(() => {}, [])

  return (
    <Box
      sx={{
        backgroundColor: '#191919',
        width: '100%',
        height: '100vh'
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          padding: '16px',
          height: '100vh'
        }}
      >
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={5}
          sx={{
            height: '100vh'
          }}
        >
          <Grid item>
            <Box
              sx={{
                backgroundColor: '#383838',
                borderRadius: '20px',
                padding: '32px',
                color: '#fff',
                maxWidth: '70%'
              }}
            >
              <Typography variant="h4">Define new Password</Typography>
              <Typography variant="h6">
                Remember password?{' '}
                <a style={{ color: '#1976d2' }} href="/">
                  Login
                </a>
                .{' '}
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1, color: '#fff' }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={passInput}
                  onChange={handlePassInputChange}
                  sx={textFieldStyle}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="repeatpassword"
                  label="Repeat Password"
                  type="password"
                  id="repeatpassword"
                  value={repeatPassInput}
                  onChange={handleRepeatPassInputChange}
                  sx={textFieldStyle}
                />

                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleNewPassword}
                >
                  Set new password
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={7}
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '100vh'
          }}
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              height: '100%'
            }}
          >
            <Box
              component="img"
              src={render}
              alt="Mango Rendering"
              sx={{ maxWidth: '70vh', margin: '16px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
export default SetNewPassword
