/* eslint-disable react/prop-types */

export const updateDocument = async (sdk, backInfo, indication, fractureLocation) => {
  const delay = (ms) => new Promise((res) => setTimeout(res, ms))

  await sdk.data.documents.update(backInfo.schemaID, backInfo.documentID, {
    broken_location: fractureLocation,
    indication: indication,
    side: fractureLocation
  })

  await delay(1000)

  return
}
