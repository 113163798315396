import { OBJLoader } from 'three/addons/loaders/OBJLoader.js'
import * as THREE from 'three'
import { draw3DLineGroup } from '../implantPositioning/MoveImplant'
import PCA from 'pca-js'

export function add1x1x1Cube(
  scene,
  position = new THREE.Vector3(),
  xScale = 1,
  yScale = 1,
  zScale = 1,
  color = 'white',
  name = '1by1by1Cube'
) {
  /*Adds a cube to the scene that has a 1x1x1 mm dimension. The dimensions can be adjust by setting different scales */
  const cubeLoader = new OBJLoader()
  cubeLoader.load(
    // resource URL
    process.env.PUBLIC_URL + '1mmCube.obj',
    // called when resource is loaded
    function (object) {
      object.traverse(function (child) {
        if (child.isMesh) {
          child.scale.set(xScale, yScale, zScale)
          child.material.reflectivity = 0.5
          child.material.color.set(color)
          child.material.castShadow = true
          child.material.transparency = true
          child.material.transparent = true
          child.material.opacity = 0.5
        }
      })

      object.name = name

      // change implant default position to avoid overlap bone at the first
      object.position.copy(position)

      scene.add(object)
    }
  )
}

export function addCOS(position, scene, scale = 50) {
  var cosGroup = new THREE.Group()
  cosGroup.name = 'cosGroup'
  var xVector, yVector, zVector
  xVector = new THREE.Vector3(scale, 0, 0).add(position)
  yVector = new THREE.Vector3(0, scale, 0).add(position)
  zVector = new THREE.Vector3(0, 0, scale).add(position)

  draw3DLineGroup(position, xVector, scene, 'green', cosGroup, 'cosGroup')
  draw3DLineGroup(position, yVector, scene, 'blue', cosGroup, 'cosGroup')
  draw3DLineGroup(position, zVector, scene, 'red', cosGroup, 'cosGroup')

  console.log('Add COS')
  scene.add(cosGroup)
}

export function getCenterOfObject(object, local = true) {
  var box = new THREE.Box3().setFromObject(object)
  var worldCenter = new THREE.Vector3()
  box.getCenter(worldCenter)

  var localCenter = new THREE.Vector3()
  localCenter.copy(worldCenter)
  object.worldToLocal(localCenter)

  if (local) {
    return localCenter
  } else {
    return worldCenter
  }
}

export function returnMainAxisOfObject(objectMesh) {
  const vert = objectMesh.geometry.attributes.position
  const verticData = float32ArrayToVertices(vert.array)

  var analysis = PCA.getEigenVectors(verticData)

  return [
    new THREE.Vector3().fromArray(analysis[0].vector),
    new THREE.Vector3().fromArray(analysis[1].vector),
    new THREE.Vector3().fromArray(analysis[2].vector)
  ]
}

export function float32ArrayToVertices(float32Array, maxPoints = 2871) {
  // Check if the array length is a multiple of 3
  if (float32Array.length % 3 !== 0) {
    console.error('Invalid array length. It should be a multiple of 3.')
    return null
  }

  const l = float32Array.length
  var stepsize = 1
  const max = maxPoints //2871 is a hardcoded number which was found by testing

  while (l / (3 * stepsize) > max) {
    stepsize++
  }

  console.log('stepsize: ', stepsize)

  // Convert Float32Array to regular JavaScript array
  const floatArray = Array.from(float32Array)

  // Create a 3D vertices array
  const vertices = []

  // Iterate through the floatArray and group coordinates into sets of 3
  for (let i = 0; i < floatArray.length; i += stepsize) {
    vertices.push([floatArray[i], floatArray[i + 1], floatArray[i + 2]])
  }
  return vertices
}

export function createWireFrame(object, scene, color = 'green') {
  const boxHelper = new THREE.BoxHelper(object, new THREE.Color(color))
  scene.add(boxHelper)
}
