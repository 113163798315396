/* eslint-disable react/prop-types */
export const indicationButtonHandler = async (
  option,
  setIndication,
  setIsClicked,
  setLocationSelected
) => {
  setIndication(option)
  setIsClicked(false)
  setLocationSelected(true)
}
