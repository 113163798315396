/* eslint-disable react/prop-types */

// function to upload label data into file service
export const uploadLabelData = async (fileName, data, sdk, id) => {
  const labelFileMetaData = await sdk.files.create(fileName, data, {
    tags: [id, fileName]
  })

  return labelFileMetaData
}
