/* eslint-disable react/prop-types */
import React from 'react'
import IconButton from '@mui/material/IconButton'
import Slider from '@mui/material/Slider'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import FastForwardIcon from '@mui/icons-material/FastForward'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import Box from '@mui/material/Box'

export const VideoPlayerControls = ({
  onForward,
  onBackward,
  onSeek,
  currentTime,
  duration,
  onPlayPause,
  isPlaying
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        border: '2px solid #91C58E',
        borderRadius: '50px',
        top: '93%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 20px',
        gap: 1,
        width: 'auto',
        maxWidth: '600px',
        zIndex: 1000,
        bgcolor: 'rgba(0,0,0,0.5)'
      }}
    >
      <IconButton onClick={onBackward} sx={{ color: 'white' }}>
        <FastRewindIcon />
      </IconButton>
      <IconButton onClick={onPlayPause} sx={{ color: 'white' }}>
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </IconButton>
      <IconButton onClick={onForward} sx={{ color: 'white' }}>
        <FastForwardIcon />
      </IconButton>
      <Slider
        sx={{
          width: 250,
          color: 'white'
        }}
        min={0}
        max={duration}
        value={currentTime}
        onChange={(e, newValue) => onSeek(newValue)}
        aria-labelledby="animation-time-slider"
        valueLabelDisplay="auto"
      />
    </Box>
  )
}
