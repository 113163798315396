// BoundingBoxUtils.js
import * as THREE from 'three'

const createTextLabel = (text, isVolume) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  // Adjust the canvas size to accommodate the text
  canvas.width = 512 // Adjust as needed to fit your text
  canvas.height = 256 // Adjust as needed for text height

  context.font = '150px Arial'
  context.fillStyle = 'gray'

  context.save() // Save the current state of the context

  if (isVolume) {
    // Only flip the context if isVolume is true
    context.scale(1, -1) // Flip context vertically
    context.fillText(text, 50, -150) // Adjust Y position according to flipped context
  } else {
    context.fillText(text, 50, 150) // Adjust Y position for normal context
  }

  context.restore() // Restore the original context state after drawing

  const texture = new THREE.CanvasTexture(canvas)
  texture.minFilter = THREE.LinearFilter
  const material = new THREE.MeshBasicMaterial({
    map: texture,
    transparent: true,
    depthTest: false
  })

  return material
}

export const addBoundingBoxAndLabels = (scene) => {
  const aabb = new THREE.Box3().setFromObject(scene)
  const min = aabb.min
  const max = aabb.max

  const widthBox = max.x - min.x
  const heightBox = max.y - min.y
  const depthBox = max.z - min.z

  const boxGeometry = new THREE.BoxGeometry(widthBox, heightBox, depthBox)
  boxGeometry.translate((max.x + min.x) / 2, (max.y + min.y) / 2, (max.z + min.z) / 2)

  const edges = new THREE.EdgesGeometry(boxGeometry)
  const lineMaterial = new THREE.LineBasicMaterial({ color: 0x800080 })
  const boundingBoxMesh = new THREE.LineSegments(edges, lineMaterial)
  boundingBoxMesh.name = 'BoundingBox'

  const labels = ['A', 'P', 'S', 'I', 'L', 'R']
  const offset = 10

  labels.forEach((label, index) => {
    const labelMaterial = createTextLabel(label, false)
    const labelGeometry = new THREE.PlaneGeometry(widthBox / 4, heightBox / 4)
    const labelMesh = new THREE.Mesh(labelGeometry, labelMaterial)
    const middlePoint = (max.y + min.y) / 2

    // Adjust label positioning as before
    switch (index) {
      case 0:
        labelMesh.position.set((max.x + min.x) / 2, middlePoint, max.z + offset)
        break
      case 1: // Back
        labelMesh.position.set((max.x + min.x) / 2, middlePoint, min.z - offset)
        break
      case 2: // Top
        labelMesh.position.set((max.x + min.x) / 2, max.y + offset, (max.z + min.z) / 2)
        labelMesh.rotation.x = -Math.PI / 2
        break
      case 3: // Bottom
        labelMesh.position.set((max.x + min.x) / 2, min.y - offset, (max.z + min.z) / 2)
        labelMesh.rotation.x = Math.PI / 2
        break
      case 4: // Right
        labelMesh.position.set(max.x + offset, middlePoint, (max.z + min.z) / 2)
        labelMesh.rotation.y = Math.PI / 2
        break
      case 5: // Left
        labelMesh.position.set(min.x - offset, middlePoint, (max.z + min.z) / 2)
        labelMesh.rotation.y = -Math.PI / 2
        break
    }

    boundingBoxMesh.add(labelMesh)

    toggleBoundingBoxVisibility(boundingBoxMesh, false)
  })

  scene.add(boundingBoxMesh)
  return boundingBoxMesh // Return the mesh for further manipulation
}

export const toggleBoundingBoxVisibility = (boundingBoxMesh, isVisible) => {
  if (boundingBoxMesh) {
    boundingBoxMesh.visible = isVisible
  }
}

export const calculateNewCameraPosition = (position, target, rotationVector) => {
  const direction = new THREE.Vector3().subVectors(position, target).normalize()
  let newDirection = direction.clone()
  const distance = position.distanceTo(target)

  switch (rotationVector) {
    case 'P': // Posterior (180 degrees rotation around Y-axis)
      newDirection.x *= -1
      newDirection.z *= -1
      break
    case 'L': // Left (-90 degrees rotation around Y-axis)
      newDirection = new THREE.Vector3(direction.z, direction.y, -direction.x)
      break
    case 'R': // Right (90 degrees rotation around Y-axis)
      newDirection = new THREE.Vector3(-direction.z, direction.y, direction.x)
      break
    case 'S': // Superior (move up)
      return new THREE.Vector3(target.x, target.y + distance, target.z)
    case 'I': // Inferior (move down)
      return new THREE.Vector3(target.x, target.y - distance, target.z)
    case 'A':
      break
    default:
      console.warn('Unknown rotation vector:', rotationVector)
      return position // Return the original position if the rotation vector is unknown
  }

  // Calculate the new camera position for 'P', 'L', 'R'
  const newPosition = new THREE.Vector3().addVectors(target, newDirection.multiplyScalar(distance))
  return newPosition
}

export const addBoundingBoxAndLabelsThreeScene = (scene) => {
  const aabb = new THREE.Box3().setFromObject(scene)
  const min = aabb.min
  const max = aabb.max

  const widthBox = max.x - min.x
  const heightBox = max.y - min.y
  const depthBox = max.z - min.z

  const boxGeometry = new THREE.BoxGeometry(widthBox, heightBox, depthBox)
  boxGeometry.translate((max.x + min.x) / 2, (max.y + min.y) / 2, (max.z + min.z) / 2)

  const edges = new THREE.EdgesGeometry(boxGeometry)
  const lineMaterial = new THREE.LineBasicMaterial({ color: 0x800080 })
  const boundingBoxMesh = new THREE.LineSegments(edges, lineMaterial)
  boundingBoxMesh.name = 'BoundingBox'

  const labels = ['A', 'P', 'S', 'I', 'L', 'R']
  const offset = 10

  labels.forEach((label, index) => {
    const labelMaterial = createTextLabel(label, true)
    const labelGeometry = new THREE.PlaneGeometry(widthBox / 4, heightBox / 4)
    const labelMesh = new THREE.Mesh(labelGeometry, labelMaterial)
    const middlePoint = (max.y + min.y) / 2

    // Adjust label positioning as before
    switch (index) {
      case 0: // Now placing 'P' at the front
        labelMesh.position.set(min.x - offset, middlePoint, (max.z + min.z) / 2)
        labelMesh.rotation.y = -Math.PI / 2
        break
      case 1: // Now placing 'A' at the back
        labelMesh.position.set(max.x + offset, middlePoint, (max.z + min.z) / 2)
        labelMesh.rotation.y = Math.PI / 2
        break
      case 2: // Top
        labelMesh.position.set((max.x + min.x) / 2, min.y - offset, (max.z + min.z) / 2)
        labelMesh.rotation.x = Math.PI / 2
        break
      case 3: // Bottom
        labelMesh.position.set((max.x + min.x) / 2, max.y + offset, (max.z + min.z) / 2)
        labelMesh.rotation.x = -Math.PI / 2
        break
      case 4: // Now placing 'R' on the left
        labelMesh.position.set((max.x + min.x) / 2, middlePoint, min.z - offset)
        break
      case 5: // Now placing 'L' on the right
        labelMesh.position.set((max.x + min.x) / 2, middlePoint, max.z + offset)

        break
    }

    boundingBoxMesh.add(labelMesh)

    toggleBoundingBoxVisibility(boundingBoxMesh, false)
  })

  scene.add(boundingBoxMesh)
  return boundingBoxMesh // Return the mesh for further manipulation
}

export const calculateNewCameraPositionThreeScene = (position, target, rotationVector) => {
  const direction = new THREE.Vector3().subVectors(position, target).normalize()
  let newDirection = direction.clone()
  const distance = position.distanceTo(target)

  // Apply initial rotation based on the rotation vector
  switch (rotationVector) {
    case 'P': // Posterior (180 degrees rotation around Y-axis)
      newDirection = new THREE.Vector3(-direction.z, direction.y, direction.x)
      break
    case 'L': // Left (-90 degrees rotation around Y-axis)
      break
    case 'R': // Right (90 degrees rotation around Y-axis)
      newDirection.x *= -1
      newDirection.z *= -1
      break
    case 'S': // Superior (move up)
      return new THREE.Vector3(target.x, target.y - distance, target.z)
    case 'I': // Inferior (move down)
      return new THREE.Vector3(target.x, target.y + distance, target.z)
    case 'A':
      newDirection = new THREE.Vector3(direction.z, direction.y, -direction.x)
      break
    default:
      console.warn('Unknown rotation vector:', rotationVector)
      return position // Return the original position if the rotation vector is unknown
  }

  // Apply an additional 45-degree rotation around the Y-axis to the direction
  const angle = 45 * (Math.PI / 180) // Convert 45 degrees to radians
  const rotatedDirection = new THREE.Vector3(
    newDirection.x * Math.cos(angle) - newDirection.z * Math.sin(angle),
    newDirection.y,
    newDirection.x * Math.sin(angle) + newDirection.z * Math.cos(angle)
  )

  // Calculate the new camera position for the rotated direction
  const newPosition = new THREE.Vector3().addVectors(
    target,
    rotatedDirection.multiplyScalar(distance)
  )
  return newPosition
}
