export const colorMapArray = [
  0, 0, 0, 255, 109, 131, 48, 255, 48, 35, 177, 235, 255, 244, 155, 112, 238, 238, 255, 225, 235,
  255, 180, 114, 225, 255, 225, 121, 218, 230, 216, 166, 216, 255, 129, 150, 255, 201, 195, 238,
  226, 101, 235, 255, 244, 0, 255, 169, 20, 161, 250, 221, 99, 221, 210, 210, 250, 255, 109, 131,
  255, 251, 161, 33, 174, 240, 53, 251, 53, 255, 72, 0, 240, 15, 15, 225, 241, 255, 225, 255, 255,
  245, 245, 195, 255, 225, 235, 255, 244, 225, 220, 220, 220, 241, 241, 255, 225, 255, 225, 255,
  255, 225, 250, 230, 210, 253, 237, 207, 238, 226, 101, 255, 223, 171, 255, 235, 221, 255, 244,
  244, 245, 245, 245, 250, 250, 170, 235, 255, 244, 112, 238, 238, 255, 180, 114, 255, 201, 137,
  255, 214, 155, 255, 241, 185, 255, 244, 155, 193, 255, 255, 210, 210, 250, 116, 202, 230, 230,
  106, 199, 35, 0, 255, 255, 255, 193, 245, 245, 195, 255, 180, 114, 184, 181, 208, 85, 119, 153,
  137, 95, 217, 255, 109, 131, 255, 109, 131, 255, 109, 131, 255, 153, 50, 255, 153, 50, 255, 153,
  50, 255, 153, 50, 255, 153, 50, 255, 153, 50, 255, 153, 153, 255, 153, 153, 255, 153, 153, 255,
  153, 153, 255, 153, 153, 255, 153, 153, 153, 203, 255, 153, 203, 255, 153, 203, 255, 153, 203,
  255, 153, 255, 153, 153, 255, 153, 153, 255, 153, 153, 255, 153, 153, 255, 153, 153, 255, 153,
  153, 153, 255, 153, 153, 255, 153, 153, 255, 153, 153, 255, 153, 153, 255, 204, 204, 204, 204,
  204, 204, 204, 204, 204, 204, 204, 204, 250, 250, 170, 225, 241, 255, 130, 170, 222, 255, 235,
  221, 210, 210, 250, 116, 202, 230, 255, 194, 0, 116, 202, 230, 255, 153, 255, 140, 245, 0, 205,
  255, 255, 153, 153, 255, 255, 153, 50, 255, 153, 153, 255, 0, 0, 255, 0, 0, 204, 101, 204, 255,
  233, 153, 255, 244, 225, 255, 109, 131, 255, 244, 155, 116, 202, 230, 255, 153, 50, 210, 210, 250,
  115, 252, 213, 255, 191, 116, 255, 194, 0, 116, 202, 230, 255, 153, 255, 140, 245, 0, 205, 255,
  255, 153, 153, 255, 255, 153, 50, 255, 153, 153, 255, 0, 0, 255, 0, 0, 204, 101, 204, 255, 233,
  153, 255, 244, 225, 255, 109, 131, 255, 244, 155, 116, 202, 230, 255, 153, 50, 210, 210, 250, 115,
  252, 213, 255, 191, 116, 255, 194, 0, 116, 202, 230, 255, 153, 255, 140, 245, 0, 205, 255, 255,
  153, 153, 255
]
