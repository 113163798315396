/* eslint-disable react/prop-types */
import { demoDocumentLoader } from '../demo/DemoDocumentLoader'
import { isolationLoader } from './IsolationLoader'

export const isolationButtonHandler = async (
  sdk,
  isDemo,
  setIsLoading,
  filePath,
  setFilePath,
  setButtonState,
  backInfo,
  setBackInfo,
  userInfo,
  setLoadingValue
) => {
  if (isDemo) {
    setIsLoading(true)

    let fileName = filePath['filename']
    const fileUrl = await demoDocumentLoader(sdk, fileName, 'isoToken')

    setFilePath({
      ...filePath,
      iso: fileUrl,
      model: {
        ...filePath.model,
        iso: fileUrl
      }
    })

    setButtonState('Iso')
    setLoadingValue(50)

    return
  } else {
    setIsLoading(true)

    let fileName = filePath['filename']

    const fileUrl = await isolationLoader(
      sdk,
      backInfo,
      fileName,
      userInfo,
      setBackInfo,
      setLoadingValue
    )

    if (fileUrl !== 'stop') {
      setFilePath({
        ...filePath,
        iso: fileUrl,
        model: {
          ...filePath.model,
          iso: fileUrl
        }
      })

      setButtonState('Iso')
    }

    return
  }
}
