import * as THREE from 'three'

// export function createClosedForm(event){
//       if (scissor.on) {
//         let raycaster = new THREE.Raycaster();
//         const rect = renderer.domElement.getBoundingClientRect();

//         const mouse = {
//           x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
//           y: -((event.clientY - rect.top) / rect.height) * 2 + 1,
//           z: -((event.clientZ - rect.top) / rect.height) * 2 + 1,
//         };

//         raycaster.setFromCamera(mouse, camera);
//         const intersects = raycaster.intersectObjects( scene.children );

//         if ( intersects.length > 0 ) {
//           if ( segmentating ){
//             // console.log("the circle coordinates are: ", intersects[ 0 ].point.x, intersects[ 0 ].point.y);

//             // Not done yet: After the pointer has been released, a line should be drawn from the first to the last point in order to close the curvature
//             closedFormPoints.push({
//               x: Math.floor(intersects[0].point.x),
//               y: Math.floor(intersects[0].point.y),
//               z: Math.floor(intersects[0].point.z)
//             });

//             addSphere(intersects[0].point);
//             animate();
//             //console.log("The curvature points are: ", closedFormPoints)
//           }
//         }
//       }
//       animate();

//     }

// export function draw3DLine(pos1, pos2) {
//     // Create a curve from position1 to position2
//     const curve = new THREE.LineCurve3(pos1, pos2);

//     // Create a tube geometry along the curve
//     const tubeGeometry = new THREE.TubeGeometry(curve, 1, 1, 8, false);

//     // Create a material for the tube
//     const tubeMaterial = new THREE.MeshBasicMaterial({ color: 'red' });

//     // Create a mesh from the tube geometry and material
//     const tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial);
//     tubeMesh.name = "3DLine";
//     scene.add(tubeMesh);
// }

// export function onPointerRelease(){
//     //dummy export function / demo for visualization and checking purposes
//     if (scissor !== "None"){
//     console.log("Set segmentating")
//     setSegmentating(false);
//     }
//     console.log("Number of Points in lisT: ", closedFormPoints.length);

//     for(let i = -1; i < closedFormPoints.length -1; i++){
//     let start = closedFormPoints[i];
//     let end = closedFormPoints[i+1];
//     console.log("Draw Line Number ", i+2)
//     draw3DLine(start, end);
//     animate()
//     }

//     console.log("points: ", closedFormPoints);

//     closedFormPoints = [];

//     animate();
// }

export function addSphere(position, scene, color = 'red', name = 'sphere', rad = 5) {
  // Create the sphere geometry
  const radius = rad // Adjust the radius as needed
  const widthSegments = 32 // Adjust the level of detail
  const heightSegments = 32 // Adjust the level of detail
  const sphereGeometry = new THREE.SphereGeometry(radius, widthSegments, heightSegments)

  // Create a material for the sphere
  const sphereMaterial = new THREE.MeshBasicMaterial({ color: color }) // Adjust the color as needed

  // Create the mesh using the geometry and material
  const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial)

  // Set the position of the sphere
  const xPosition = position.x
  const yPosition = position.y
  const zPosition = position.z
  sphereMesh.position.set(xPosition, yPosition, zPosition)
  sphereMesh.name = name

  // Add the sphere to the scene
  scene.add(sphereMesh)
}

export function getCoordinatesIn3DView(event, camera, scene, renderer) {
  // Get the bounding rectangle of the renderer element
  const rect = renderer.domElement.getBoundingClientRect()

  // Calculate the normalized device coordinates (NDC) based on the mouse event
  const mouse = {
    x: ((event.clientX - rect.left) / rect.width) * 2 - 1,
    y: -((event.clientY - rect.top) / rect.height) * 2 + 1
  }
  // Create a raycaster from the camera position and direction
  const raycaster = new THREE.Raycaster()
  raycaster.setFromCamera(mouse, camera)

  // Intersect the ray with the scene to get the intersection point
  const intersects = raycaster.intersectObjects(scene.children)
  if (intersects.length > 0) {
    // Return the coordinates of the first intersection point
    console.log('Intersection at ', intersects[0].point)
    console.log('Intersections: ', intersects)
    addSphere(intersects[0].point, scene)
  } else {
    console.log('No intersection')
  }
}

export function returnCameraOrientationAndPosition(camera) {
  //return the Orientation and Position

  // var cameraRotation = camera.rotation;
  var cameraPosition = camera.position
  // console.log("The orientation or rotatiaon of the camera is around\nx: ", cameraRotation.x, "\ny: ", cameraRotation.y, "\nz: ", cameraRotation.z);
  console.log('The camera position is: ', cameraPosition)
}

function deactiveCameraMovement(controls) {
  console.log('Deactivate Camera')
  controls
  // controls.enabled = false; // Enable camera controls by default
}

export function PointClick(event, camera, scene, renderer, controls) {
  getCoordinatesIn3DView(event, camera, scene, renderer)
  returnCameraOrientationAndPosition(camera)
  deactiveCameraMovement(controls)
}
