// javascript sdk in extrahorizon (https://docs.extrahorizon.com/javascript-sdk/features/authentication)
import { createOAuth2Client } from '@extrahorizon/javascript-sdk'

// authentication function to first login
export async function authenticationSDK(user, pass) {
  const sdk = createOAuth()

  try {
    await sdk.auth.authenticate({
      password: pass,
      username: user
    })

    return { result: true, sdk: sdk }
  } catch (error) {
    return { result: false, sdk: null }
  }
}

// authentication function to refresh token
export async function authenticatedSDKRefresh(refreshToken) {
  const sdk = createOAuth()

  try {
    await sdk.auth.authenticate({ refreshToken })
    return { result: true, sdk: sdk }
  } catch (error) {
    return { result: false, sdk: null }
  }
}

// Oauth2 function and load refresh token
const createOAuth = () => {
  return createOAuth2Client({
    host: process.env.REACT_APP_HOST_NAME,
    clientId: process.env.REACT_APP_CLIENT_ID,
    freshTokensCallback: (tokenData) => {
      localStorage.setItem('refreshToken', tokenData.refreshToken)
      let currentDate = new Date()
      currentDate.setHours(currentDate.getHours() + 1)
      localStorage.setItem('sessionExpire', currentDate)
    }
  })
}
