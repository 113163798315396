/* eslint-disable react/prop-types */
import React from 'react'
import { Switch, Grid, Typography } from '@mui/material'

const MainViewCrossHairs = ({ crosshairsOn, setCrosshairsOn }) => {
  const handleChange = (event) => {
    setCrosshairsOn(event.target.checked)
  }

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: '#FFFFFF',
          fontFamily: 'Open Sans',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px',
          flex: 'none',
          order: 0,
          flexGrow: 0
        }}
      >
        Crosshairs Controller
      </Typography>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography
            sx={{ color: '#FFFFFF', fontWeight: 400, fontSize: '12px', lineHeight: '20px' }}
          >
            crosshairs
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            sx={{
              color: crosshairsOn ? '#4CAF50' : '#F44336',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
              marginRight: '8px'
            }}
          >
            {crosshairsOn ? 'On' : 'Off'}
          </Typography>
        </Grid>
        <Grid item>
          <Switch checked={crosshairsOn} onChange={handleChange} />
        </Grid>
      </Grid>
    </>
  )
}

export default MainViewCrossHairs
