/* eslint-disable react/prop-types */
import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export default function CircularProgressWithLabel({ loadingValue, niftiProgress }) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress disableShrink />
      {niftiProgress.loadingStatus !== 'downloading' && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {false && (
            <Typography variant="caption" component="div" color="white">
              {`${Math.round(loadingValue)}%`}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}
