/* eslint-disable react/prop-types */

import React from 'react'
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

function WarnDialog({
  open: open,
  setOpen: setOpen,
  warnMessage: warnMessage,
  currentButton: currentButton,
  setButtonState: setButtonState,
  setIsLoading: setIsLoading,
  setLoadingValue: setLoadingValue,
  setCurrentPage: setCurrentPage, // setCurrentView in App
  backInfo: backInfo
}) {
  const handleClose = () => {
    setOpen(false)
  }

  const handleReturn = () => {
    // This function manages the user's action to return from the dialog. It first stops any loading process and resets
    // the progress. Then, depending on whether the user was in the middle of a task (indicated by 'currentButton'),
    // it either navigates back to the 'activeCase' page or restores the previous state of the task. Finally, it closes the dialog.
    setIsLoading(false)
    setLoadingValue(0)

    if (currentButton === 'None') {
      setCurrentPage('activeCase')
    } else {
      setButtonState(currentButton)
    }

    setOpen(false)

    fetch('/stop', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(backInfo)
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`)
        }
      })
      .catch((error) => {
        console.error('Fetch error: ', error.message)
        window.alert(error.message)
      })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="warn-dialog-title"
      aria-describedby="warn-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: '#232323',
          color: '#FFFFFF'
        }
      }}
      sx={{
        zIndex: 9999
      }}
      Modal={true}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: 'absolute', right: 8, top: 8, color: '#FFFFFF' }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        id="warn-dialog-title"
        sx={{ color: '#FF5733', fontWeight: 'bold', fontSize: '25px' }}
      >
        {'WARNING'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="confirm-dialog-description"
          sx={{ color: '#FFFFFF', fontSize: '18px' }}
        >
          {warnMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ flex: '1 1 auto' }}>
          <Button
            onClick={handleReturn}
            sx={{
              backgroundColor: '#2C622C',
              color: '#FFFFFF',
              paddingBottom: '10px',
              '&:hover': {
                backgroundColor: '#4CAF50'
              }
            }}
          >
            Return
          </Button>
        </Box>
        <Box sx={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: '#D32F2F',
              color: '#FFFFFF',
              paddingBottom: '10px',
              '&:hover': {
                backgroundColor: '#b22b2b'
              }
            }}
          >
            OK
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default WarnDialog
