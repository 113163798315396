/* eslint-disable react/prop-types */
import { rqlBuilder } from '@extrahorizon/javascript-sdk'

// load each case based on user id
export const affiliatedCompanyLoader = async (sdk, user_id) => {
  const response = await sdk.data.documents.find('additionalUserInfo', {
    rql: rqlBuilder().eq('data.userId', user_id).build()
  })
  return response.data[0]?.data?.affiliatedCompany || 'Mango Medical'
}
