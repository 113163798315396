/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { Box, Typography } from '@mui/material'
import OpenWithIcon from '@mui/icons-material/OpenWith'
import Draggable from 'react-draggable'

const AutoImplantInfoBox = ({ automatedPlacementStatus: automatedPlacementStatus }) => {
  const draggableRef = useRef(null)

  return (
    <Draggable nodeRef={draggableRef} handle=".drag-icon">
      <Box
        ref={draggableRef}
        sx={{
          position: 'absolute',
          flexDirection: 'column',
          top: '75%',
          left: '10%',
          transform: 'translateX(-50%)',
          zIndex: 10000,
          alignItems: 'flex-start',
          backgroundColor: '#303030', // Box background color
          padding: '16px 0px',
          borderRadius: '8px',
          width: '310px',
          maxWidth: '90%',
          color: '#fff',
          marginTop: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 1,
            backgroundColor: '#303030', // Matching header color with box color
            padding: '0 8px', // Reduced padding in the header
            borderRadius: '2px 2px 0 0' // Rounded corners on top only
          }}
        >
          <div>
            <OpenWithIcon
              className="drag-icon"
              sx={{
                color: '#FFFFFF',
                cursor: 'move',
                backgroundColor: '',
                borderRadius: '50%',
                fontSize: '25px',
                zIndex: 9999
              }}
            />
          </div>
          <Typography variant="h6" component="span" sx={{ flexGrow: 1 }}>
            Automated Placement Status
          </Typography>
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            marginLeft: '20px',
            marginRight: '20px'
          }}
        >
          <div>
            <Typography variant="body2">
              First Bone Point:{' '}
              <span
                style={{
                  float: 'right',
                  color: automatedPlacementStatus.firstBonePoint == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {automatedPlacementStatus.firstBonePoint}
              </span>
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              First Implant Point:{' '}
              <span
                style={{
                  float: 'right',
                  color: automatedPlacementStatus.firstImplantPoint == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {automatedPlacementStatus.firstImplantPoint}
              </span>
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              Second Bone Point:{' '}
              <span
                style={{
                  float: 'right',
                  color: automatedPlacementStatus.secondBonePoint == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {automatedPlacementStatus.secondBonePoint}
              </span>
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              Second Implant Point:{' '}
              <span
                style={{
                  float: 'right',
                  color:
                    automatedPlacementStatus.secondImplantPoint == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {automatedPlacementStatus.secondImplantPoint}
              </span>
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              Third Bone Point:{' '}
              <span
                style={{
                  float: 'right',
                  color: automatedPlacementStatus.thirdBonePoint == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {automatedPlacementStatus.thirdBonePoint}
              </span>
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              Third Implant Point:{' '}
              <span
                style={{
                  float: 'right',
                  color: automatedPlacementStatus.thirdImplantPoint == 'DEFINED' ? 'green' : 'white'
                }}
              >
                {automatedPlacementStatus.thirdImplantPoint}
              </span>
            </Typography>
          </div>
        </div>
      </Box>
    </Draggable>
  )
}

export default AutoImplantInfoBox
