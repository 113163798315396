/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
import { rqlBuilder } from '@extrahorizon/javascript-sdk'

export const repositionLoader = async (
  sdk,
  backInfo,
  fileName,
  userInfo,
  setBackInfo,
  setLoadingValue
) => {
  setLoadingValue(0)

  const delay = (ms) => new Promise((res) => setTimeout(res, ms))
  let resultDocument

  const schema = await sdk.data.schemas.findByName('Pipeline', {
    rql: rqlBuilder().select(['id', 'name', 'transitions']).build()
  })

  let document = await sdk.data.documents.findById(backInfo['schemaID'], backInfo['documentID'])

  const resultFolderToken = document.data.resultFolderToken
  const fractureLocation = document.data.side
  const originFileToken = document.data.originFileToken
  const fileToken = document.data.fileToken
  const segToken = document.data.segToken
  const isoToken =
    document.data.indication === 'Face' ? document.data.segToken : document.data.isoToken

  let regToken
  let regVolToken
  let repositionObjects = {}
  let predictedRepositionObjects = {}

  // update document to start new pipeline based on new data
  if (document.data.indication !== 'Face') {
    if (document.data.isoToken !== document.data.latest_version.isoToken) {
      regToken = ''
      regVolToken = ''
    } else {
      regToken = document.data.regToken
      regVolToken = document.data.regVolToken
      if (document.data.repositionObjects !== undefined) {
        repositionObjects = document.data.repositionObjects
        predictedRepositionObjects = document.data.ml_prediction.repositionObjects
      }
    }
  } else {
    if (document.data.segToken !== document.data.latest_version.segToken) {
      regToken = ''
      regVolToken = ''
    } else {
      regToken = document.data.regToken
      regVolToken = document.data.regVolToken
      if (document.data.repositionObjects !== undefined) {
        repositionObjects = document.data.repositionObjects
        predictedRepositionObjects = document.data.ml_prediction.repositionObjects
      }
    }
  }

  await delay(1000)

  if ((regToken === '' || regVolToken === '') && isoToken != '') {
    if (process.env.REMOTE_SERVER == false) {
      await sdk.data.documents.transition(backInfo['schemaID'], document.id, {
        id: schema.findTransitionIdByName('reconstruction'),
        data: {
          filename: fileName,
          user: userInfo['id'],
          broken_location: fractureLocation,
          resultFolderToken: resultFolderToken,
          originFileToken: originFileToken,
          fileToken: fileToken,
          segToken: segToken,
          isoToken: isoToken
        }
      })
    } else {
      let backendInfo = {}

      if (document.data.indication == 'Face') {
        // Face

        backendInfo = {
          schemaID: schema.id,
          documentID: document.id,
          indication: document.data.indication
          // method: ['probreg'], // it can be only one 'simpleitk'
          // transformationType: ['affine']
          // can be only one or some {"versor", "translation", "euler", "affine"} for simpleitk
        }
      } else {
        // Clavicle, Wrist
        // load second backend
        backendInfo = {
          schemaID: schema.id,
          documentID: document.id,
          indication: document.data.indication
        }
      }

      if (document.data.indication == 'Wrist' || document.data.indication === 'Clavicle') {
        if (
          document.data.status === 'isolated_file' ||
          (document.data.status !== 'isolated_file' &&
            document.data.status !== 'repo_preprocessed' &&
            document.data.status !== 'shape_completed' &&
            document.data.status !== 'matched' &&
            document.data.status !== 'registered' &&
            document.data.status !== 'repo_postprocessed' &&
            document.data.status !== 'registered_file')
        ) {
          let startTimestamp = performance.now()

          fetch('/repositioning', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(backendInfo)
          })
            .then((response) => {
              let endTimestamp = performance.now()
              let timeElapsed = endTimestamp - startTimestamp

              // console.log(`Fetch request took ${timeElapsed} milliseconds.`)

              if (timeElapsed > 10000 && response.status === 504) {
                console.log('Ignoring gateway timeout after 10 seconds.')
              } else {
                if (!response.ok) {
                  throw new Error(`Error ${response.status}: ${response.statusText}`)
                }
              }
            })
            .catch((error) => {
              console.error('Fetch error: ', error.message)

              setLoadingValue(-1)
              window.alert(error.message)
            })
        }
      } else if (document.data.indication == 'Face') {
        if (
          document.data.status === 'segmented_file' ||
          (document.data.status !== 'segmented_file' &&
            document.data.status !== 'repo_preprocessed' &&
            document.data.status !== 'shape_completed' &&
            document.data.status !== 'registered' &&
            document.data.status !== 'repo_postprocessed' &&
            document.data.status !== 'registered_file')
        ) {
          let startTimestamp = performance.now()

          fetch('/repositioning', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(backendInfo)
          })
            .then((response) => {
              let endTimestamp = performance.now()
              let timeElapsed = endTimestamp - startTimestamp

              // console.log(`Fetch request took ${timeElapsed} milliseconds.`)

              if (timeElapsed > 10000 && response.status === 504) {
                console.log('Ignoring gateway timeout after 10 seconds.')
              } else {
                if (!response.ok) {
                  throw new Error(`Error ${response.status}: ${response.statusText}`)
                }
              }
            })
            .catch((error) => {
              console.error('Fetch error: ', error.message)

              setLoadingValue(-1)
              window.alert(error.message)
            })
        }
      } else {
        let startTimestamp = performance.now()

        fetch('/repositioning', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(backendInfo)
        })
          .then((response) => {
            let endTimestamp = performance.now()
            let timeElapsed = endTimestamp - startTimestamp

            // console.log(`Fetch request took ${timeElapsed} milliseconds.`)

            if (timeElapsed > 10000 && response.status === 504) {
              console.log('Ignoring gateway timeout after 10 seconds.')
            } else {
              if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`)
              }
            }
          })
          .catch((error) => {
            console.error('Fetch error: ', error.message)

            setLoadingValue(-1)
            window.alert(error.message)
          })
      }
    }

    setBackInfo({
      ...backInfo,
      documentID: document.id
    })

    // loop until resultDocument.data.test is not null
    while (true) {
      resultDocument = await sdk.data.documents.findById(backInfo['schemaID'], document.id)

      if (resultDocument.data.status === 'stop') {
        setLoadingValue(0)
        return ['stop', null, null, null]
      }

      if (document.data.indication == 'Wrist' || document.data.indication === 'Clavicle') {
        if (resultDocument.data.status === 'repo_preprocessed') {
          setLoadingValue(15)
        }

        if (resultDocument.data.status === 'shape_completed') {
          setLoadingValue(30)
        }

        if (resultDocument.data.status === 'matched') {
          setLoadingValue(45)
        }

        if (resultDocument.data.status === 'registered') {
          setLoadingValue(60)
        }

        if (resultDocument.data.status === 'repo_postprocessed') {
          setLoadingValue(75)
        }

        if (resultDocument.data.status === 'registered_file') {
          setLoadingValue(90)
        }

        if (resultDocument.data.regVolToken !== '' && resultDocument.data.regToken !== '') {
          break
        }
      } else if (document.data.indication == 'Face') {
        if (resultDocument.data.status === 'repo_preprocessed') {
          setLoadingValue(20)
        }

        if (resultDocument.data.status === 'shape_completed') {
          setLoadingValue(40)
        }

        if (resultDocument.data.status === 'registered') {
          setLoadingValue(60)
        }

        if (resultDocument.data.status === 'repo_postprocessed') {
          setLoadingValue(80)
        }

        if (resultDocument.data.status === 'registered_file') {
          setLoadingValue(90)
        }

        if (resultDocument.data.regVolToken !== '' && resultDocument.data.regToken !== '') {
          break
        }
      } else {
        if (resultDocument.data.regVolToken !== '' && resultDocument.data.regToken !== '') {
          break
        }
      }

      // wait for a short period of time before trying again
      await delay(1000)
    }

    regVolToken = resultDocument.data.regVolToken
    regToken = resultDocument.data.regToken

    if (resultDocument.data.repositionObjects !== undefined) {
      repositionObjects = resultDocument.data.repositionObjects
      predictedRepositionObjects = resultDocument.data.ml_prediction.repositionObjects
    }

    if (
      document.data.indication !== 'Wrist' &&
      document.data.indication !== 'Clavicle' &&
      document.data.indication !== 'Face'
    ) {
      if (process.env.REMOTE_SERVER == false) {
        // complete tasks
        await sdk.data.documents.transition(backInfo['schemaID'], document.id, {
          id: schema.findTransitionIdByName('complete'),
          data: {
            filename: fileName,
            user: userInfo['id'],
            broken_location: resultDocument.data.broken_location,
            resultFolderToken: resultDocument.data.resultFolderToken,
            originFileToken: resultDocument.data.originFileToken,
            fileToken: resultDocument.data.fileToken,
            segToken: resultDocument.data.segToken,
            isoToken: resultDocument.data.isoToken,
            regToken: resultDocument.data.regToken,
            regVolToken: resultDocument.data.regVolToken,
            regObjToken: resultDocument.data.regObjToken
          }
        })
      } else {
        await sdk.data.documents.update(backInfo['schemaID'], resultDocument.id, {
          data: { status: 'complete' }
        })
      }
    }
  }

  const fileUrl = `${
    process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
  }/files/v1/${regVolToken}/file`
  const segFileUrl = `${
    process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
  }/files/v1/${regToken}/file`

  return [fileUrl, segFileUrl, repositionObjects, predictedRepositionObjects]
}
