import { useRouteError, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export default function ErrorPage() {
  const error = useRouteError()
  const navigate = useNavigate()
  console.error(error)

  useEffect(() => {
    navigate('/', { replace: true })
  }, [error, navigate])
}
