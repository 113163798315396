/* eslint-disable react/prop-types */
import * as THREE from 'three'
import React, { useCallback, useContext } from 'react'

import { TrackballControls } from './TrackballControlls'
import { TransformControls } from 'three/addons/controls/TransformControls.js'

// import { OBJLoader } from 'three/addons/loaders/OBJLoader.js'

import { useEffect, useRef, useState } from 'react'
import Stats from 'stats.js'
import {
  loadAndAddImplant,
  implant,
  moveImplantPointtoPoint,
  secondIntuitiveMovement,
  thirdIntuitiveMovement,
  createTriangle,
  createCircle,
  loadAndAddImplantAsync,
  rotateImplant,
  moveObject
} from '../functions/implantPositioning/MoveImplant'
import { addSphere } from './ScissorToolHandler3D'
import { removeObject, removeObjectSceneRef } from './SegmentToolHandler'
// import { implantTargetAnatomyLoader } from '../functions/implantPositioning/ImplantTargetAnatomyLoader'
import { addLights } from '../functions/labelPositioning/AddLights'
import { colorMapArray } from './ColorMap.jsx'
import PCA from 'pca-js'
import { UserContext } from '../../../App'
import { drawTransparent3DLine } from './VariableStorage'

import {
  addBoundingBoxAndLabels,
  toggleBoundingBoxVisibility,
  calculateNewCameraPosition
} from './ViewBoxUtils.jsx'

import requestAnimationFrame from 'dat.gui/src/dat/utils/requestAnimationFrame'

const RenderingThreeSceneImplant = ({
  mainGUIRef: mainGUIRef,
  filePath: filePath,
  buttonState: buttonState,
  viewBoxRef4: viewBoxRef4,
  stats: stats,
  movementConfig: movementConfig,
  implantToken: implantToken,
  implantInfo: implantInfo,
  setImplantInfo: setImplantInfo,
  isRedo: isRedo,
  setIsRedo: setIsRedo,
  isUndo: isUndo,
  setIsUndo: setIsUndo,
  redoStack: redoStack,
  setRedoStack: setRedoStack,
  undoStack: undoStack,
  setUndoStack: setUndoStack,
  labelColorSwitch: labelColorSwitch,
  centerPoint: centerPoint,
  setMovementConfig: setMovementConfig,
  setCurrentMovementMenu: setCurrentMovementMenu,
  cameraResetClicked: cameraResetClicked,
  setCameraResetClicked: setCameraResetClicked,
  isRestore: isRestore,
  setIsRestore: setIsRestore,
  setAutomatedPlacementStatus: setAutomatedPlacementStatus,
  checkedValues: checkedValues,
  rotationVector: rotationVector,
  setRotationVector: setRotationVector,
  viewBoxOn: viewBoxOn,
  setIsLoading: setIsLoading,
  regObjLoaded: regObjLoaded,
  repLoadedObjects: repLoadedObjects,
  indication: indication,
  rotateSpeed: rotateSpeed
}) => {
  const containerRef = useRef(null)
  const scene = useRef(new THREE.Scene())
  const cameraRef = useRef()
  const rendererRef = useRef()
  const controlsRef = useRef()
  const transformDict = useRef(null)

  const [checkingTrigger, setCheckingTrigger] = useState(false)

  const [currentState, setCurrentState] = useState(null)
  const [initBool, setInitBool] = useState(false)

  const [initFinished, setInitFinished] = useState(false)

  const [boneCenter, setBoneCenter] = useState({ centerVector: new THREE.Vector3() })

  const [flipAxis, setFlipAxis] = useState({ axis: new THREE.Vector3() })
  flipAxis

  const [initialCameraState, setInitialCameraState] = useState({
    set: false,
    camera: null
  })

  const [controllerPoint, setControllerPoint] = useState({
    point: null,
    state: null,
    active: false,
    init: false,
    label: null,
    mode: null
  })

  const [intuitiveState, setIntuitiveState] = useState({
    firstImplantPoint: new THREE.Vector3(),
    firstBonePoint: new THREE.Vector3(),
    secondImplantPoint: new THREE.Vector3(),
    secondBonePoint: new THREE.Vector3(),
    thirdImplantPoint: new THREE.Vector3(),
    thirdBonePoint: new THREE.Vector3(),
    firstMove: true,
    secondMove: false,
    reset: false
  })

  const { flip, setShowFlipBox, showFlipBox } = useContext(UserContext)

  const [objectsLoaded, setObjectsLoaded] = useState({})

  const cameraPositionsRef = useRef({})
  const boundingBoxMeshRef = useRef(null)

  useEffect(() => {
    if (undoStack.length !== 0) {
      console.log('undoStack : ', undoStack)
    }
  }, [undoStack])

  useEffect(() => {
    setIntuitiveState((prevState) => ({
      ...prevState,
      firstImplantPoint: new THREE.Vector3(),
      firstBonePoint: new THREE.Vector3(),
      secondImplantPoint: new THREE.Vector3(),
      secondBonePoint: new THREE.Vector3(),
      thirdImplantPoint: new THREE.Vector3(),
      thirdBonePoint: new THREE.Vector3(),
      firstMove: true,
      secondMove: false
    }))
    setAutomatedPlacementStatus((prevState) => ({
      ...prevState,
      firstImplantPoint: 'Not defined',
      firstBonePoint: 'Not defined',
      secondImplantPoint: 'Not defined',
      secondBonePoint: 'Not defined',
      thirdImplantPoint: 'Not defined',
      thirdBonePoint: 'Not defined'
    }))

    setMovementConfig({ type: 'none' })
    setCurrentMovementMenu('none')
    setInitBool(false)
  }, [])

  useEffect(() => {
    if (controllerPoint.init == false) {
      setControllerPoint((prevState) => ({
        ...prevState,
        state: centerPoint,
        init: true
      }))
    }
  }, [controllerPoint])

  const transformer_init = useRef(null)
  const transformer = useRef(null)
  var pivot = new THREE.Group()
  pivot.name = 'PivotGroupObject'

  const [stateController, setStateController] = useState({
    pivotBool: true,
    implantToPoint: new THREE.Vector3(),
    pointOnImplant: new THREE.Vector3(),
    implantRotationPointOne: new THREE.Vector3(),
    boneRotationPointOne: new THREE.Vector3(),
    implantRotationPointTwo: new THREE.Vector3(),
    boneRotationPointTwo: new THREE.Vector3(),
    firstImplantMove: true,
    rotationMove: false,
    rotationCenter: new THREE.Vector3(),
    pivotController: null,
    reset: false
  })

  const [attachmentBool, setAttachmentBool] = useState(false)

  function render() {
    rendererRef.current.render(scene.current, cameraRef.current)
  }

  let lastTime = useRef(Date.now())
  const renderLoopRef = useRef(null)
  const fps = 30
  const interval = 1000 / fps

  function tick() {
    const now = Date.now()
    const elapsed = now - lastTime.current

    if (elapsed > interval) {
      lastTime.current = now - (elapsed % interval)

      if (stats != null) {
        stats.update()
      }

      // Ensure trackball controls are updated in the animation loop
      if (controlsRef.current != null) {
        controlsRef.current.update()
      }

      render()
    }

    requestAnimationFrame(tick)
  }

  function animate() {
    if (renderLoopRef.current === null) {
      renderLoopRef.current = requestAnimationFrame(tick)
      console.debug('render loop initiated')
    } else {
      console.debug('render loop already initiated, ignoring')
    }
  }

  function calcTransformationMatrix(object) {
    // Make sure the matrix is updated

    object.updateWorldMatrix()

    /* because we attach the object to a groupObject in
    case of "object Point" we need to use the world Matrix
    because the matrix is constant inside the group object*/

    const matrix = object.matrixWorld

    //let matrix4x4 = convertArray16ToArray4x4(matrix.elements)
    return matrix.elements
  }

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj))
  }

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false
      }
    }
    return true
  }

  function areObjectsEqual(obj1, obj2) {
    if (obj1 == null || obj2 == null) {
      return false
    }
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      const value1 = obj1[key]
      const value2 = obj2[key]

      if (Array.isArray(value1) && Array.isArray(value2)) {
        // Compare two arrays
        if (!arraysAreEqual(value1, value2)) {
          return false
        }
      } else {
        // Compare non-array values
        if (value1 !== value2) {
          return false
        }
      }
    }

    return true
  }

  function getObjectByName(scene, name) {
    let desiredObject = null

    // Traverse the entire scene
    scene.traverse(function (object) {
      if (object.name === name) {
        desiredObject = object
      }
    })

    return desiredObject
  }

  function calculateTransformMatrices() {
    //actually matrix not matrices
    let objectName = 'implant'
    let transformDict = {}
    var object = getObjectByName(scene.current, objectName)
    if (object !== null) {
      transformDict[objectName] = calcTransformationMatrix(object)
    }

    return transformDict
  }

  function applyTransformMatrices(trDict) {
    //actually matrix not matrices
    let objectName = 'implant'
    var object = getObjectByName(scene.current, objectName)

    if (object !== null) {
      let new_matrix_elements = trDict[objectName]
      let position = new THREE.Vector3()
      let quaternion = new THREE.Quaternion()
      let scale = new THREE.Vector3()
      new THREE.Matrix4().fromArray(new_matrix_elements).decompose(position, quaternion, scale)
      object.position.copy(position)
      object.quaternion.copy(quaternion)
      object.scale.copy(scale)
      object.updateMatrix()
    }
    animate()
  }

  // function loadBoneAsync(bone_loader, url) {
  //   return new Promise((resolve, reject) => {
  //     bone_loader.load(
  //       url,
  //       (object) => resolve(object), // on success
  //       undefined,
  //       (error) => reject(error) // on error
  //     )
  //   })
  // }

  function resetState() {
    setStateController((prevState) => ({
      ...prevState,
      pivotBool: true,
      implantToPoint: new THREE.Vector3(),
      pointOnImplant: new THREE.Vector3(),
      implantRotationPointOne: new THREE.Vector3(),
      boneRotationPointOne: new THREE.Vector3(),
      implantRotationPointTwo: new THREE.Vector3(),
      boneRotationPointTwo: new THREE.Vector3(),
      firstImplantMove: true,
      rotationMove: false,
      rotationCenter: new THREE.Vector3(),
      pivotController: null,
      reset: false
    }))
    removeObject(scene.current, 'implantPoint')
    removeObject(scene.current, 'bonePoint')
    removeObject(scene.current, 'implantRotationPointOne')
    removeObject(scene.current, 'implantRotationPointTwo')
    removeObject(scene.current, 'boneRotationPointOne')
    removeObject(scene.current, 'boneRotationPointTwo')
  }

  function resetIntuitiveState() {
    setIntuitiveState((prevState) => ({
      ...prevState,
      firstImplantPoint: new THREE.Vector3(),
      firstBonePoint: new THREE.Vector3(),
      secondImplantPoint: new THREE.Vector3(),
      secondBonePoint: new THREE.Vector3(),
      thirdImplantPoint: new THREE.Vector3(),
      thirdBonePoint: new THREE.Vector3(),
      firstMove: true,
      secondMove: false,
      reset: false
    }))
    setAutomatedPlacementStatus((prevState) => ({
      ...prevState,
      firstImplantPoint: 'Not defined',
      firstBonePoint: 'Not defined',
      secondImplantPoint: 'Not defined',
      secondBonePoint: 'Not defined',
      thirdImplantPoint: 'Not defined',
      thirdBonePoint: 'Not defined'
    }))

    removeObject(scene.current, 'firstImplantPoint')
    removeObject(scene.current, 'secondImplantPoint')
    removeObject(scene.current, 'thirdImplantPoint')
    removeObject(scene.current, 'firstBonePoint')
    removeObject(scene.current, 'secondBonePoint')
    removeObject(scene.current, 'thirdBonePoint')
    removeObject(scene.current, 'triangle')
    removeObject(scene.current, 'positionCircle')
    removeObject(scene.current, 'secondSphere')
  }

  function getCenterOfObject(object, local = true) {
    var box = new THREE.Box3().setFromObject(object)
    var worldCenter = new THREE.Vector3()
    box.getCenter(worldCenter)

    var localCenter = new THREE.Vector3()
    localCenter.copy(worldCenter)
    object.worldToLocal(localCenter)

    if (local) {
      return localCenter
    } else {
      return worldCenter
    }
  }

  function logState(event) {
    const raycaster = new THREE.Raycaster()
    const mouse = new THREE.Vector2()
    let bbox = event.currentTarget.getBoundingClientRect()

    mouse.x = ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1
    mouse.y = -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1
    raycaster.setFromCamera(mouse, cameraRef.current)

    const intersectsObjects = raycaster.intersectObjects(scene.current.children)

    if (intersectsObjects.length != 0 && implant != undefined) {
      for (var i = 0; i < intersectsObjects.length; i++) {
        //intersection with the implant
        if (intersectsObjects[i].object.name === implant.children['0'].name) {
          if (stateController.firstImplantMove) {
            setStateController((prevState) => ({
              ...prevState,
              pointOnImplant: intersectsObjects[i].point,
              reset: true
            }))
            removeObject(scene.current, 'implantPoint')
            addSphere(intersectsObjects[i].point, scene.current, 'yellow', 'implantPoint', 2)
          } else if (
            !stateController.firstImplantMove &&
            stateController.implantRotationPointOne.length() == 0
          ) {
            setStateController((prevState) => ({
              ...prevState,
              implantRotationPointOne: intersectsObjects[i].point
            }))
            removeObject(scene.current, 'implantRotationPointOne')
            addSphere(
              intersectsObjects[i].point,
              scene.current,
              'white',
              'implantRotationPointOne',
              2
            )
            setStateController((prevState) => ({
              ...prevState,
              rotationMove: true
            }))
          } else if (
            !stateController.firstImplantMove &&
            stateController.implantRotationPointOne.length() != 0 &&
            stateController.implantRotationPointTwo.length() == 0
          ) {
            setStateController((prevState) => ({
              ...prevState,
              implantRotationPointTwo: intersectsObjects[i].point
            }))
            removeObject(scene.current, 'implantRotationPointTwo')
            addSphere(
              intersectsObjects[i].point,
              scene.current,
              'white',
              'implantRotationPointTwo',
              2
            )

            if (
              implant &&
              stateController.boneRotationPointOne.length() != 0 &&
              stateController.implantToPoint.length() != 0 &&
              stateController.boneRotationPointTwo.length() != 0
            ) {
              removeObject(scene.current, 'implantRotationPointOne')
              removeObject(scene.current, 'implantRotationPointTwo')
              removeObject(scene.current, 'boneRotationPointOne')
              removeObject(scene.current, 'boneRotationPointTwo')

              setMovementConfig({ type: 'none' })
              setCurrentMovementMenu('none')

              animate()
            }
          }
          break
        } else if (
          intersectsObjects[i].object.name === 'grp1' ||
          intersectsObjects[i].object.name === 'objBone' ||
          intersectsObjects[i].object.parent.name === 'objBone'
        ) {
          if (stateController.firstImplantMove) {
            setStateController((prevState) => ({
              ...prevState,
              implantToPoint: intersectsObjects[i].point,
              rotationCenter: intersectsObjects[i].point,
              reset: true
            }))

            removeObject(scene.current, 'bonePoint')
            addSphere(intersectsObjects[i].point, scene.current, 'yellow', 'bonePoint', 2)
          } else if (
            !stateController.firstImplantMove &&
            stateController.boneRotationPointOne.length() == 0
          ) {
            setStateController((prevState) => ({
              ...prevState,
              boneRotationPointOne: intersectsObjects[i].point,
              rotationMove: true
            }))

            removeObject(scene.current, 'boneRotationPointOne')
            addSphere(intersectsObjects[i].point, scene.current, 'white', 'boneRotationPointOne', 2)
          } else if (
            !stateController.firstImplantMove &&
            stateController.boneRotationPointOne.length() != 0 &&
            stateController.boneRotationPointTwo.length() == 0
          ) {
            setStateController((prevState) => ({
              ...prevState,
              boneRotationPointTwo: intersectsObjects[i].point
            }))
            removeObject(scene.current, 'boneRotationPointTwo')
            addSphere(intersectsObjects[i].point, scene.current, 'white', 'boneRotationPointTwo', 2)
            if (
              implant &&
              stateController.implantRotationPointOne.length() != 0 &&
              stateController.implantToPoint.length() != 0 &&
              stateController.implantRotationPointTwo.length() != 0
            ) {
              removeObject(scene.current, 'implantRotationPointOne')
              removeObject(scene.current, 'implantRotationPointTwo')
              removeObject(scene.current, 'boneRotationPointOne')
              removeObject(scene.current, 'boneRotationPointTwo')
              setMovementConfig({ type: 'none' })
              setCurrentMovementMenu('none')

              animate()
            }
          }
          break
        }
      }
    }
  }

  function addOpacSphere(position, scene, color = 'red', name = 'sphere', rad = 5, opacity = 1) {
    // Create the sphere geometry
    const radius = rad // Adjust the radius as needed
    const widthSegments = 32 // Adjust the level of detail
    const heightSegments = 32 // Adjust the level of detail
    const sphereGeometry = new THREE.SphereGeometry(radius, widthSegments, heightSegments)

    // Create a material for the sphere
    const sphereMaterial = new THREE.MeshBasicMaterial({ color: color }) // Adjust the color as needed
    sphereMaterial.transparent = true
    sphereMaterial.opacity = opacity

    // Create the mesh using the geometry and material
    const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial)

    // Set the position of the sphere
    const xPosition = position.x
    const yPosition = position.y
    const zPosition = position.z
    sphereMesh.position.set(xPosition, yPosition, zPosition)
    sphereMesh.name = name

    // Add the sphere to the scene
    scene.add(sphereMesh)
  }

  function logIntuitiveImplantMovement(event) {
    let sphereRadius

    const raycaster = new THREE.Raycaster()
    const mouse = new THREE.Vector2()
    let bbox = event.currentTarget.getBoundingClientRect()

    mouse.x = ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1
    mouse.y = -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1
    raycaster.setFromCamera(mouse, cameraRef.current)

    const intersectsObjects = raycaster.intersectObjects(scene.current.children)
    if (intersectsObjects.length != 0 && implant != undefined) {
      for (var i = 0; i < intersectsObjects.length; i++) {
        //intersection with the implant
        if (intersectsObjects[i].object.name === implant.children['0'].name) {
          if (intuitiveState.firstImplantPoint.length() == 0) {
            setIntuitiveState((prevState) => ({
              ...prevState,
              firstImplantPoint: intersectsObjects[i].point.clone(),
              reset: true
            }))
            setAutomatedPlacementStatus((prevState) => ({
              ...prevState,
              firstImplantPoint: 'DEFINED'
            }))
            removeObject(scene.current, 'firstImplantPoint')
            addSphere(intersectsObjects[i].point, scene.current, 'white', 'firstImplantPoint', 2)
            break
          } else if (
            intuitiveState.secondImplantPoint.length() == 0 &&
            intuitiveState.secondMove == true
          ) {
            console.log('Second ImplantPoint: ', intuitiveState)

            setIntuitiveState((prevState) => ({
              ...prevState,
              secondImplantPoint: intersectsObjects[i].point.clone()
            }))
            setAutomatedPlacementStatus((prevState) => ({
              ...prevState,
              secondImplantPoint: 'DEFINED'
            }))
            removeObject(scene.current, 'secondSphere')
            sphereRadius = new THREE.Vector3()
              .subVectors(intuitiveState.firstBonePoint, intersectsObjects[i].point.clone())
              .length()
            addOpacSphere(
              intuitiveState.firstBonePoint,
              scene.current,
              'white',
              'secondSphere',
              sphereRadius,
              0.4
            )

            removeObject(scene.current, 'secondImplantPoint')
            addSphere(intersectsObjects[i].point, scene.current, 'white', 'secondImplantPoint', 2)
            break
          } else if (
            intuitiveState.thirdImplantPoint.length() == 0 &&
            !intuitiveState.firstMove &&
            !intuitiveState.secondMove
          ) {
            setIntuitiveState((prevState) => ({
              ...prevState,
              thirdImplantPoint: intersectsObjects[i].point
            }))
            setAutomatedPlacementStatus((prevState) => ({
              ...prevState,
              thirdImplantPoint: 'DEFINED'
            }))

            removeObject(scene.current, 'thirdImplantPoint')
            addSphere(intersectsObjects[i].point, scene.current, 'white', 'thirdImplantPoint', 2)
            break
          }
        } else if (
          intersectsObjects[i].name === 'grp1' ||
          intersectsObjects[i].object.name === 'objBone' ||
          intersectsObjects[i].object.parent.name === 'objBone'
        ) {
          if (intuitiveState.firstBonePoint.length() == 0) {
            setIntuitiveState((prevState) => ({
              ...prevState,
              firstBonePoint: intersectsObjects[i].point,
              reset: true
            }))
            setAutomatedPlacementStatus((prevState) => ({
              ...prevState,
              firstBonePoint: 'DEFINED'
            }))
            removeObject(scene.current, 'firstBonePoint')
            addSphere(intersectsObjects[i].point, scene.current, 'white', 'firstBonePoint', 2)
            break
          } else if (intuitiveState.secondBonePoint.length() == 0 && intuitiveState.secondMove) {
            setIntuitiveState((prevState) => ({
              ...prevState,
              secondBonePoint: intersectsObjects[i].point
            }))
            setAutomatedPlacementStatus((prevState) => ({
              ...prevState,
              secondBonePoint: 'DEFINED'
            }))
            removeObject(scene.current, 'secondSphere')
            sphereRadius = new THREE.Vector3()
              .subVectors(intuitiveState.firstBonePoint, intersectsObjects[i].point.clone())
              .length()
            addOpacSphere(
              intuitiveState.firstBonePoint,
              scene.current,
              'white',
              'secondSphere',
              sphereRadius,
              0.4
            )

            removeObject(scene.current, 'secondBonePoint')
            addSphere(intersectsObjects[i].point, scene.current, 'white', 'secondBonePoint', 2)
            break
          } else if (
            intuitiveState.thirdBonePoint.length() == 0 &&
            !intuitiveState.firstMove &&
            !intuitiveState.secondMove
          ) {
            setIntuitiveState((prevState) => ({
              ...prevState,
              thirdBonePoint: intersectsObjects[i].point
            }))
            setAutomatedPlacementStatus((prevState) => ({
              ...prevState,
              thirdBonePoint: 'DEFINED'
            }))
            removeObject(scene.current, 'thirdBonePoint')
            addSphere(intersectsObjects[i].point, scene.current, 'white', 'thirdBonePoint', 2)
            break
          }
        }
      }

      // console.log('current scene.current.children : ', scene.current.children)

      const lastChild = scene.current.children[scene.current.children.length - 1]
      const lastChildName = lastChild ? lastChild.name : null

      const Points = [
        'firstImplantPoint',
        'firstBonePoint',
        'secondImplantPoint',
        'secondBonePoint',
        'thirdImplantPoint',
        'thirdBonePoint'
      ]

      const currentIndex = Points.indexOf(lastChildName)
      let nextIndex = currentIndex + 1
      if (nextIndex >= Points.length) {
        nextIndex = 0
      }
    }
  }

  function float32ArrayToVertices(float32Array) {
    // Check if the array length is a multiple of 3
    if (float32Array.length % 3 !== 0) {
      console.error('Invalid array length. It should be a multiple of 3.')
      return null
    }

    const l = float32Array.length
    var stepsize = 1
    const maxPoints = 2871 //this is a hardcoded number which was found by testing

    while (l / (3 * stepsize) > maxPoints) {
      stepsize++
    }

    // Convert Float32Array to regular JavaScript array
    const floatArray = Array.from(float32Array)

    // Create a 3D vertices array
    const vertices = []

    // Iterate through the floatArray and group coordinates into sets of 3
    for (let i = 0; i < floatArray.length; i += stepsize) {
      vertices.push([floatArray[i], floatArray[i + 1], floatArray[i + 2]])
    }
    return vertices
  }

  const [pcaDirections, setPcaDirections] = useState({
    direction1: new THREE.Vector3(),
    direction2: new THREE.Vector3(),
    direction3: new THREE.Vector3()
  })

  const keyPressed = useCallback(
    (event) => {
      if (movementConfig.type === 'translate') {
        let pivotObject = getObjectByName(scene.current, 'PivotGroupObject')

        transformDict.current = deepCopy(calculateTransformMatrices())
        if (pivotObject != undefined && pivotObject != null) {
          moveObject(pivotObject, event, 1)
          var sphereObject = getObjectByName(scene.current, 'pointSphere')
          if (sphereObject != null) {
            sphereObject.position.copy(pivotObject.position)
          }
        }

        if (!areObjectsEqual(transformDict.current, calculateTransformMatrices())) {
          if (undoStack.length === 0) {
            setCurrentState(deepCopy(transformDict.current))
          }

          setCheckingTrigger(true)
        }
      }
    },
    [transformer, movementConfig]
  )

  const updateSize = () => {
    setTimeout(() => {
      rendererRef.current.setSize(viewBoxRef4.current.clientWidth, viewBoxRef4.current.clientHeight)
    }, 5000)
    rendererRef.current.setPixelRatio(window.devicePixelRatio)
    render()
  }

  // useState to keep latest 3D scene, such as camera position, rotate, and zoom
  useEffect(() => {
    const width = viewBoxRef4.current.clientWidth
    const height = viewBoxRef4.current.clientHeight

    const camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 25000)
    camera.position.z = 250

    const renderer = new THREE.WebGLRenderer()
    renderer.sortObjects = false
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(width, height)
    containerRef.current.appendChild(renderer.domElement)

    const controls = new TrackballControls(camera, renderer.domElement)
    controls.rotateSpeed = 1.0
    controls.zoomSpeed = 1.0
    controls.panSpeed = 0.5
    controls.enabled = false

    if (!viewBoxRef4.current) {
      return
    }
    // mainGUIRef.current.hide()
    if (!stats) {
      stats = new Stats()
      stats.showPanel(2) // 0: fps, 1: ms, 2: mb, 3+: custom
      stats.dom.style.position = 'fixed'
      stats.dom.style.top = '0'
      stats.dom.style.left = 'auto'
      stats.dom.style.right = '0'
      stats.dom.id = 'statsContainer' // Assign a specific name to the stats.dom element
      document.body.appendChild(stats.dom)
    }

    cameraRef.current = camera
    rendererRef.current = renderer
    controlsRef.current = controls

    //containerRef.current.addEventListener('resize', updateSize)
    window.addEventListener('resize', updateSize)

    updateSize()

    setRedoStack([])
    setUndoStack([])
    setIsRestore(false)

    animate()
    setInitBool(true)
    addLights(scene.current)

    return () => {
      removeObjectSceneRef(scene, 'groupLights')

      // Clean up the animation loop when component unmounts
      if (containerRef.current) {
        containerRef.current.removeChild(renderer.domElement)

        rendererRef.current.dispose()

        window.removeEventListener('resize', updateSize)
        if (!stats) {
          document.body.removeChild(stats.dom)
        }
      }
    }
  }, [mainGUIRef, viewBoxRef4])

  // Add Implant
  useEffect(() => {
    //currently the Controller is defined when the implant is loaded
    //This function needs to be added outside of the loadAndAddImplant
    //We can add this with buttons:
    //First button: Add Implant, when Implant is added, second Button
    // is available to set mode of the controller and change it without reloading the implant
    //the scale needs to be adjusted according to the dimensions of the volume

    let scale = 1

    if (buttonState === 'Implant' && rendererRef.current) {
      //movementConfig: scale, rotate or translate

      removeObjectSceneRef(scene, 'implant')
      removeObjectSceneRef(scene, 'implantController')

      // if (controllerBool.state) {
      //   controllerBool.controller.dispose()
      //   setControllerBool({
      //     state: false,
      //     controller: null
      //   })
      // }

      // reset undostack
      setUndoStack([])
      setRedoStack([])

      // loadAndAddImplant(
      //   scene.current,
      //   scale,
      //   cameraRef.current,
      //   rendererRef.current,
      //   controlsRef.current,
      //   implantToken,
      //   implantInfo,
      //   setImplantInfo
      // )

      loadAndAddImplantAsync(scene.current, implantToken, scale, indication, filePath.filename)

      animate()
    }
    return () => {
      removeObjectSceneRef(scene, 'implant')
    }
  }, [implantToken.token])

  // Bone Loader
  useEffect(() => {
    if (Object.keys(regObjLoaded).length === 0) {
      return
    }

    // const scaleValue = 1

    // async function loadAndSetupBone() {
    //   if (initBool) {
    //     const bone_loader = new OBJLoader()

    //     try {
    //       const object = await loadBoneAsync(bone_loader, filePath['regObj'])

    //       object.traverse(function (child) {
    //         if (child.isMesh) {
    //           child.material.color.set(0x296639)
    //           child.material.visible = false
    //           child.scale.set(scaleValue, scaleValue, scaleValue)
    //         }
    //       })

    //       object.name = 'objBone'
    //       scene.current.add(object)

    //       var box = new THREE.Box3().setFromObject(object)
    //       var center = new THREE.Vector3()
    //       box.getCenter(center)

    //       setBoneCenter({ centerVector: center })
    //       controlsRef.current.target.copy(center)
    //       controlsRef.current.update()
    //       animate()
    //     } catch (error) {
    //       console.error('Error loading the bone object:', error)
    //     }
    //   }
    // }

    //remove implant if there are exist before
    scene.current.children.forEach((child) => {
      if (child.name === 'implant') {
        scene.current.remove(child) // Remove the child from the scene
      }
    })

    var b = getObjectByName(scene.current, 'objBone')

    if (b == null || b == undefined) {
      const object = regObjLoaded[0].object

      const objectName = 'objBone' // Assign an appropriate object name if needed
      object.name = objectName // Set the object name
      scene.current.add(object) // Add the object to the scene

      // Create a bounding box around the object and calculate its center
      const box = new THREE.Box3().setFromObject(object)
      const center = new THREE.Vector3()
      box.getCenter(center)

      // Update the bone center state and the controls
      setBoneCenter({ centerVector: center })
      controlsRef.current.target.copy(center)
      controlsRef.current.update()

      updateSize()

      // Start animation loop if not already running
      animate()
    }

    return () => {
      removeObjectSceneRef(scene, 'objBone')
    }
  }, [initBool, regObjLoaded])

  //intuitive Moving of ImplantHandler
  useEffect(() => {
    var radius
    //Move First Time
    if (
      intuitiveState.firstMove &&
      intuitiveState.firstImplantPoint.length() != 0 &&
      intuitiveState.firstBonePoint.length() != 0
    ) {
      transformDict.current = deepCopy(calculateTransformMatrices())
      moveImplantPointtoPoint(
        intuitiveState.firstImplantPoint,
        intuitiveState.firstBonePoint,
        implant,
        scene.current,
        false,
        setFlipAxis,
        pcaDirections.direction3
        // pcaDirections.direction1
      )
      // setShowFlipBox(true)
      animate()
      if (!areObjectsEqual(transformDict.current, calculateTransformMatrices())) {
        if (undoStack.length === 0) {
          setCurrentState(deepCopy(transformDict.current))
        }
        setCheckingTrigger(true)
      }
      setIntuitiveState((prevState) => ({
        ...prevState,
        firstMove: false,
        secondMove: true
      }))
      removeObject(scene.current, 'firstImplantPoint')
      removeObject(scene.current, 'firstBonetPoint')
    }
    //second Move
    else if (
      intuitiveState.secondMove &&
      intuitiveState.secondImplantPoint.length() != 0 &&
      intuitiveState.secondBonePoint.length() != 0
    ) {
      transformDict.current = deepCopy(calculateTransformMatrices())
      secondIntuitiveMovement(
        intuitiveState.firstBonePoint,
        intuitiveState.secondBonePoint,
        intuitiveState.secondImplantPoint,
        implant
      )
      if (!areObjectsEqual(transformDict.current, calculateTransformMatrices())) {
        if (undoStack.length === 0) {
          setCurrentState(deepCopy(transformDict.current))
        }
        setCheckingTrigger(true)
      }

      animate()
      setIntuitiveState((prevState) => ({
        ...prevState,
        secondMove: false
      }))
      removeObject(scene.current, 'secondSphere')
      removeObject(scene.current, 'secondImplantPoint')
      // removeObject(scene.current, 'secondBonePoint')
    }
    //third Move
    else if (
      !intuitiveState.secondMove &&
      !intuitiveState.firstMove &&
      (intuitiveState.thirdImplantPoint.length() != 0 ||
        intuitiveState.thirdBonePoint.length() != 0)
    ) {
      //When both third points have been set
      if (
        intuitiveState.thirdImplantPoint.length() != 0 &&
        intuitiveState.thirdBonePoint.length() != 0
      ) {
        removeObject(scene.current, 'thirdImplantPoint')
        removeObject(scene.current, 'thirdBonePoint')
        removeObject(scene.current, 'triangle')
        removeObject(scene.current, 'positionCircle')
        transformDict.current = deepCopy(calculateTransformMatrices())
        thirdIntuitiveMovement(
          intuitiveState.firstBonePoint,
          intuitiveState.secondBonePoint,
          intuitiveState.thirdBonePoint,
          intuitiveState.thirdImplantPoint,
          scene.current,
          implant
        )
        if (!areObjectsEqual(transformDict.current, calculateTransformMatrices())) {
          if (undoStack.length === 0) {
            setCurrentState(deepCopy(transformDict.current))
          }
          setCheckingTrigger(true)
        }

        animate()
        resetIntuitiveState()
        setMovementConfig({ type: 'none' })
        setCurrentMovementMenu('none')
      } else if (intuitiveState.thirdImplantPoint.length() != 0) {
        //Create ring in order to make visible where the point on the implant would attach

        removeObject(scene.current, 'positionCircle')

        let A = new THREE.Vector3()
        let C = new THREE.Vector3()
        let projectionVector = new THREE.Vector3()

        A.subVectors(intuitiveState.thirdImplantPoint, intuitiveState.firstBonePoint)
        C.subVectors(intuitiveState.secondBonePoint, intuitiveState.firstBonePoint)

        projectionVector.copy(A)
        projectionVector.projectOnVector(C).add(intuitiveState.firstBonePoint)

        radius = new THREE.Vector3()
          .subVectors(intuitiveState.thirdImplantPoint, projectionVector)
          .length()
        createCircle(radius, projectionVector, C, scene.current)
      } else if (intuitiveState.thirdBonePoint.length() != 0) {
        //Create Triangle
        // removeObject(scene.current, 'triangle')

        removeObject(scene.current, 'positionCircle')

        let A = new THREE.Vector3()
        let C = new THREE.Vector3()
        let projectionVector = new THREE.Vector3()

        A.subVectors(intuitiveState.thirdBonePoint, intuitiveState.firstBonePoint)
        C.subVectors(intuitiveState.secondBonePoint, intuitiveState.firstBonePoint)

        projectionVector.copy(A)
        projectionVector.projectOnVector(C).add(intuitiveState.firstBonePoint)

        radius = new THREE.Vector3()
          .subVectors(intuitiveState.thirdBonePoint, projectionVector)
          .length()
        createCircle(radius, projectionVector, C, scene.current)

        createTriangle
        // createTriangle(
        //   intuitiveState.firstBonePoint,
        //   intuitiveState.secondBonePoint,
        //   intuitiveState.thirdBonePoint,
        //   scene.current
        // )
      }
    }
  }, [intuitiveState, implant])

  // Implant Movement and Mousedown handler
  useEffect(() => {
    function onPointerDown(event) {
      if (initBool) {
        transformDict.current = deepCopy(calculateTransformMatrices())

        if (movementConfig.type === 'auto') {
          logIntuitiveImplantMovement(event)
          // logState(event)
          logState
        } else if (!centerPoint) {
          const raycaster = new THREE.Raycaster()
          const mouse = new THREE.Vector2()
          let bbox = event.currentTarget.getBoundingClientRect()

          mouse.x = ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1
          mouse.y = -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1
          raycaster.setFromCamera(mouse, cameraRef.current)

          const intersectsObjects = raycaster.intersectObjects(scene.current.children)
          if (intersectsObjects.length != 0 && !controllerPoint.active) {
            var interSectedObject = intersectsObjects[0]
            removeObject(scene.current, 'pointSphere')
            addSphere(interSectedObject.point, scene.current, 'white', 'pointSphere', 1)
            setControllerPoint((prevState) => ({
              ...prevState,
              point: interSectedObject.point.clone()
            }))
            if (
              initBool &&
              implant != undefined &&
              movementConfig.type !== 'none' &&
              movementConfig.type != 'auto'
            ) {
              if (transformer.current !== null) {
                transformer.current.detach()
                removeObject(scene.current, '3DLabelController')
              }
              if (!transformer_init.current) {
                removeObject(scene.current, '3DLabelController')
                transformer.current = new TransformControls(
                  cameraRef.current,
                  rendererRef.current.domElement
                )
                let object = getObjectByName(scene.current, 'implant')
                if (object !== null) {
                  var transformerPosition = new THREE.Vector3()
                  transformerPosition = interSectedObject.point.clone()
                  pivot.position.copy(transformerPosition)
                  scene.current.add(pivot)

                  var newObjectPositionPivot = new THREE.Vector3()
                  newObjectPositionPivot.subVectors(transformerPosition, object.position)

                  pivot.add(object)
                  object.position.copy(newObjectPositionPivot)
                  object.position.multiplyScalar(-1)

                  setAttachmentBool(true)

                  transformer.current.setSpace('local')
                  transformer.current.mode = movementConfig.type || 'translate'
                  transformer.current.size = 0.75
                  transformer.current.name = '3DLabelController'
                  transformer.current.attach(pivot)

                  var sphereObject = getObjectByName(scene.current, 'pointSphere')

                  transformer.current.addEventListener('change', function () {
                    // Manually check and adjust object's position, rotation, or scale.
                    sphereObject.position.copy(pivot.position)
                    animate()
                  })

                  transformer.current.addEventListener('mouseDown', function () {
                    transformDict.current = deepCopy(calculateTransformMatrices())
                    controlsRef.current.enabled = false
                  })

                  transformer.current.addEventListener('mouseUp', function () {
                    controlsRef.current.enabled = true

                    if (!areObjectsEqual(transformDict.current, calculateTransformMatrices())) {
                      if (undoStack.length === 0) {
                        setCurrentState(deepCopy(transformDict.current))
                      }
                      setCheckingTrigger(true)
                    }
                  })

                  scene.current.add(transformer.current)

                  setControllerPoint((prevState) => ({
                    ...prevState,
                    active: true,
                    mode: movementConfig.type
                  }))
                }
                transformer_init.current = false
              } else {
                transformer_init.current = true
              }
              animate()
            }
            //remove Pivot and Controller
          }
        }
      }

      animate()
    }

    if (movementConfig.type == 'none') {
      removeObject(scene.current, 'pointSphere')
      // setShowFlipBox(false)
      if (stateController.reset) {
        resetState()
      }
      if (intuitiveState.reset) {
        resetIntuitiveState()
      }
    }

    if (centerPoint) {
      removeObject(scene.current, 'pointSphere')
      if (
        initBool &&
        implant != undefined &&
        movementConfig.type !== 'none' &&
        movementConfig.type !== 'auto'
      ) {
        // setShowFlipBox(false)
        if (stateController.reset) {
          resetState()
        }
        if (intuitiveState.reset) {
          resetIntuitiveState()
        }

        if (transformer.current !== null) {
          transformer.current.detach()
          removeObject(scene.current, '3DLabelController')
          let object = getObjectByName(scene.current, 'implant')
          if (object !== null) {
            objectWorldMatrix = new THREE.Matrix4()
            objectWorldMatrix.copy(object.matrixWorld)

            const position1 = new THREE.Vector3()
            const quaternion1 = new THREE.Quaternion()
            const scale1 = new THREE.Vector3()
            object.matrixWorld.decompose(position1, quaternion1, scale1)

            pivot.remove(object)
            scene.current.add(object)
            object.position.copy(position1)
            object.rotation.setFromQuaternion(quaternion1)
            setAttachmentBool(false)
            removeObject(scene.current, 'PivotGroupObject')
            pivot = new THREE.Group()
            pivot.name = 'PivotGroupObject'
          }
          if (controllerPoint.active) {
            setControllerPoint((prevState) => ({
              ...prevState,
              active: false
            }))
          }
        }
        if (!transformer_init.current) {
          removeObject(scene.current, '3DLabelController')
          transformer.current = new TransformControls(
            cameraRef.current,
            rendererRef.current.domElement
          )

          let object = getObjectByName(scene.current, 'implant')
          if (implant != undefined) {
            var transformerPosition = new THREE.Vector3()
            transformerPosition = getCenterOfObject(object, false)

            scene.current.add(pivot)
            pivot.position.copy(transformerPosition)

            var newObjectPositionPivot = new THREE.Vector3()
            newObjectPositionPivot.subVectors(transformerPosition, object.position)

            pivot.add(object)
            object.position.copy(newObjectPositionPivot)
            object.position.multiplyScalar(-1)

            setAttachmentBool(true)

            transformer.current.setSpace('local')
            transformer.current.mode = movementConfig.type || 'translate'
            transformer.current.size = 0.75
            transformer.current.name = '3DLabelController'
            transformer.current.rotationSnap = THREE.MathUtils.degToRad(0.01)
            transformer.current.attach(pivot)

            transformer.current.addEventListener('change', function () {
              animate()
            })
            transformer.current.addEventListener('mouseDown', function () {
              transformDict.current = deepCopy(calculateTransformMatrices())
              controlsRef.current.enabled = false
            })
            transformer.current.addEventListener('mouseUp', function () {
              controlsRef.current.enabled = true

              if (!areObjectsEqual(transformDict.current, calculateTransformMatrices())) {
                if (undoStack.length === 0) {
                  setCurrentState(deepCopy(transformDict.current))
                }
                setCheckingTrigger(true)
              }
            })
            scene.current.add(transformer.current)
          }
          transformer_init.current = false
        } else {
          transformer_init.current = true
        }

        animate()
      }

      if (initBool && (movementConfig.type === 'none' || movementConfig.type === 'auto')) {
        if (movementConfig === 'none' && stateController.reset) {
          resetState()
          // setShowFlipBox(false)
        }
        if (movementConfig === 'none' && intuitiveState.reset) {
          resetIntuitiveState()
          // setShowFlipBox(false)
        }
        if (transformer.current !== null) {
          transformer.current.detach()
          removeObject(scene.current, '3DLabelController')
          let object = getObjectByName(scene.current, 'implant')
          if (object !== null) {
            objectWorldMatrix = new THREE.Matrix4()
            objectWorldMatrix.copy(object.matrixWorld)

            const position1 = new THREE.Vector3()
            const quaternion1 = new THREE.Quaternion()
            const scale1 = new THREE.Vector3()
            object.matrixWorld.decompose(position1, quaternion1, scale1)

            pivot.remove(object)
            scene.current.add(object)
            object.position.copy(position1)
            object.rotation.setFromQuaternion(quaternion1)
            setAttachmentBool(false)
            removeObject(scene.current, 'PivotGroupObject')
            pivot = new THREE.Group()
            pivot.name = 'PivotGroupObject'
          }
          if (controllerPoint.active) {
            setControllerPoint((prevState) => ({
              ...prevState,
              active: false
            }))
          }
        }

        animate()
      }

      if (
        controllerPoint.mode != movementConfig.type &&
        controllerPoint.active == true &&
        transformer.current != null
      ) {
        // setShowFlipBox(false)
        if (stateController.reset) {
          resetState()
        }
        if (intuitiveState.reset) {
          resetIntuitiveState()
        }
        transformer.current.setMode(movementConfig.type)
        setControllerPoint((prevState) => ({
          ...prevState,
          mode: movementConfig.type
        }))
      }
    } else if (!centerPoint) {
      var objectWorldMatrix

      if (initBool && (movementConfig.type === 'none' || movementConfig.type === 'auto')) {
        if (movementConfig === 'none' && stateController.reset) {
          resetState()
          // setShowFlipBox(false)
        }
        if (movementConfig === 'none' && intuitiveState.reset) {
          resetIntuitiveState()
          // setShowFlipBox(false)
        }
        removeObject(scene.current, 'pointSphere')
        if (transformer.current !== null) {
          transformer.current.detach()
          removeObject(scene.current, '3DLabelController')
          let object = getObjectByName(scene.current, 'implant')
          if (object !== null) {
            objectWorldMatrix = new THREE.Matrix4()
            objectWorldMatrix.copy(object.matrixWorld)

            const position1 = new THREE.Vector3()
            const quaternion1 = new THREE.Quaternion()
            const scale1 = new THREE.Vector3()
            object.matrixWorld.decompose(position1, quaternion1, scale1)

            pivot.remove(object)
            scene.current.add(object)
            object.position.copy(position1)
            object.rotation.setFromQuaternion(quaternion1)
            setAttachmentBool(false)
            removeObject(scene.current, 'PivotGroupObject')
            pivot = new THREE.Group()
            pivot.name = 'PivotGroupObject'
          }
          if (controllerPoint.active) {
            setControllerPoint((prevState) => ({
              ...prevState,
              active: false
            }))
          }
        }
      }

      if (
        controllerPoint.mode != movementConfig.type &&
        controllerPoint.active == true &&
        transformer.current != null
      ) {
        // setShowFlipBox(false)
        if (stateController.reset) {
          resetState()
        }
        if (intuitiveState.reset) {
          resetIntuitiveState()
        }
        transformer.current.setMode(movementConfig.type)
        setControllerPoint((prevState) => ({
          ...prevState,
          mode: movementConfig.type
        }))
      }
    }

    containerRef.current.addEventListener('click', onPointerDown)
    document.addEventListener('keydown', keyPressed)
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('click', onPointerDown)
        document.removeEventListener('keydown', keyPressed)
      }
    }
  }, [
    movementConfig,
    stateController,
    centerPoint,
    initBool,
    controllerPoint,
    intuitiveState,
    implant
  ])

  //reset implant movement states when when Center Point is switched
  useEffect(() => {
    resetState()
    resetIntuitiveState()
    setShowFlipBox(false)
  }, [centerPoint])

  useEffect(() => {
    if (checkingTrigger) {
      setCheckingTrigger(false)
      if (currentState !== null) {
        setUndoStack([...undoStack, currentState])
      }

      setRedoStack([])

      setCurrentState(deepCopy(calculateTransformMatrices()))
    }
  }, [checkingTrigger])

  useEffect(() => {
    if (isUndo === true) {
      // setShowFlipBox(false)
      setMovementConfig({ type: 'none' })
      setCurrentMovementMenu('none')
      setControllerPoint((prevState) => ({
        ...prevState,
        active: false
      }))

      if (!attachmentBool) {
        if (undoStack.length === 0) {
          window.alert('there are no data for undo')
          setIsUndo(false)
          return
        } else if (undoStack.length === 1) {
          setRedoStack([...redoStack, deepCopy(calculateTransformMatrices())])

          const lastState = undoStack.pop()

          applyTransformMatrices(deepCopy(lastState))

          setCurrentState(deepCopy(calculateTransformMatrices()))
          setIsUndo(false)
        } else {
          const lastState = undoStack.pop()

          setRedoStack([...redoStack, deepCopy(calculateTransformMatrices())])
          applyTransformMatrices(deepCopy(lastState))

          // setCurrentState(new Float32Array(lastState))
          setCurrentState(deepCopy(calculateTransformMatrices()))
          setIsUndo(false)
        }
      }
    }
  }, [isUndo, attachmentBool])

  useEffect(() => {
    if (isRedo === true) {
      setIsRedo(false)

      if (redoStack.length === 0) {
        window.alert('there are no more redo')
        return
      } else {
        const nextState = redoStack.pop()
        setUndoStack([...undoStack, deepCopy(calculateTransformMatrices())])
        applyTransformMatrices(deepCopy(nextState))
        setCurrentState(deepCopy(nextState))
      }
    }
  }, [isRedo])

  useEffect(() => {
    const removeImplantsRecursively = (parent) => {
      for (let i = parent.children.length - 1; i >= 0; i--) {
        const child = parent.children[i]
        if (child.name === 'implant') {
          parent.remove(child)
        } else {
          removeImplantsRecursively(child)
        }
      }
    }

    if (isRestore) {
      let scale = 1

      setMovementConfig({ type: 'none' })
      setCurrentMovementMenu('none')
      setControllerPoint((prevState) => ({
        ...prevState,
        active: false
      }))

      setUndoStack([])
      setRedoStack([])

      // Remove 'TransformControls' object from the scene
      const transformControls = scene.current.children.find(
        (child) => child instanceof TransformControls // Replace with the correct condition to identify your TransformControls
      )
      if (transformControls) {
        scene.current.remove(transformControls)
      }

      // In your useEffect
      removeImplantsRecursively(scene.current)

      // loadAndAddImplant(
      //   scene.current,
      //   scale,
      //   cameraRef.current,
      //   rendererRef.current,
      //   controlsRef.current,
      //   implantToken,
      //   implantInfo,
      //   setImplantInfo
      // )
      loadAndAddImplant
      implantInfo

      loadAndAddImplantAsync(scene.current, implantToken, scale, indication, filePath.filename)

      setImplantInfo((prevState) => ({
        ...prevState,
        initialized: true
      }))

      animate()

      setIsRestore(false)

      setImplantInfo((prevState) => ({
        ...prevState,
        initialized: false
      }))
    }
  }, [isRestore])

  //Loads Target Anatomy Files
  useEffect(() => {
    const allKeys = Object.keys(filePath.repositionObjects) // Keys that should be loaded
    const allLoaded = allKeys.every((key) =>
      Object.prototype.hasOwnProperty.call(repLoadedObjects, key)
    ) // Check if all keys are loaded

    if (!allLoaded) {
      // If not all objects are loaded, exit early
      return
    }

    if (initBool && initFinished == false) {
      // implantTargetAnatomyLoader(
      //   scene.current,
      //   cameraRef.current,
      //   rendererRef.current,
      //   filePath,
      //   setInitFinished
      // )

      Object.entries(repLoadedObjects).forEach(([objectName, { object }]) => {
        object.name = objectName
        scene.current.add(object)

        // Render the scene immediately after adding the object
        rendererRef.current.render(scene.current, cameraRef.current)

        // Update the loading state for this object
        setObjectsLoaded((prev) => ({ ...prev, [objectName]: true }))
      })

      setInitFinished(true)

      animate()
    }
  }, [initBool, initFinished, repLoadedObjects])

  // Lay-over of the colored obj files with the bone-obj
  useEffect(() => {
    function calculateBoundingBox(objects) {
      const boundingBox = new THREE.Box3()
      objects.forEach((obj) => {
        if (obj) {
          boundingBox.expandByObject(obj)
        }
      })
      return boundingBox
    }

    function repositionObjects(objects, diffVector) {
      objects.forEach((obj) => {
        if (obj) {
          obj.position.add(diffVector)
        }
      })
    }

    if (initFinished) {
      const targetObjects = Object.keys(filePath.targetObjects).map((name) =>
        scene.current.getObjectByName(name)
      )
      const boundingBox = calculateBoundingBox(targetObjects)

      const boundingBoxCenter = new THREE.Vector3()
      boundingBox.getCenter(boundingBoxCenter)
      const diffVector = new THREE.Vector3().subVectors(boneCenter.centerVector, boundingBoxCenter)

      repositionObjects(targetObjects, diffVector)

      targetObjects.forEach((object) => {
        if (object) {
          // Using functional state update to ensure the correct state is captured
          setObjectsLoaded((prev) => ({
            ...prev,
            [object.name]: true // Mark the object as loaded
          }))
        }
      })
    }

    return () => {
      removeObjectSceneRef(scene, 'BBox')
    }
  }, [initFinished, filePath.targetObjects, boneCenter])

  useEffect(() => {
    const neutralColor = new THREE.Color(1.0, 1.0, 1.0) // Corresponds to 211/255

    const colors = colorMapArray.map((c) => c / 255)

    scene.current.children.forEach((child) => {
      if (child.name.startsWith('label')) {
        // Extract label number from child name
        const labelNumber = parseInt(child.name.replace('label', ''))

        // Calculate the index for the color in the 'colors' array
        const colorIndex = labelNumber * 3

        // Get color for the label or use neutral color based on the switch state
        const color = labelColorSwitch
          ? new THREE.Color(colors[colorIndex], colors[colorIndex + 1], colors[colorIndex + 2])
          : neutralColor

        // Apply color to the geometry of the child object
        child.traverse((subChild) => {
          if (subChild.isMesh && subChild.geometry && subChild.geometry.attributes.color) {
            const colorsArray = subChild.geometry.attributes.color.array
            for (let i = 0, n = colorsArray.length; i < n; i += 3) {
              colorsArray[i] = color.r
              colorsArray[i + 1] = color.g
              colorsArray[i + 2] = color.b
            }
            subChild.geometry.attributes.color.needsUpdate = true
          }
        })
      }
    })

    // Trigger a render update if necessary
    animate()
  }, [labelColorSwitch])

  useEffect(() => {
    if (cameraResetClicked) {
      if (initialCameraState.set && cameraRef.current && controlsRef.current) {
        // Reset camera
        cameraRef.current.copy(initialCameraState.camera)

        // Reset controls
        controlsRef.current.target.copy(initialCameraState.controlsTarget)

        setCameraResetClicked(false)
        animate()
      }
    }
  }, [cameraResetClicked])

  useEffect(() => {
    if (Object.keys(checkedValues).length !== 0) {
      if (scene.current !== null) {
        scene.current.children.forEach((child) => {
          // Assume the child's name or some other property maps to the keys in checkedValues
          const childId = parseInt(child.name.replace('label', '')) // This is an assumption based on your structure

          // If childId is not NaN and we have a corresponding checkedValue...
          if (!isNaN(childId) && Object.prototype.hasOwnProperty.call(checkedValues, childId)) {
            // Set the visibility based on the checkedValue
            child.visible = checkedValues[childId]
          }
        })

        animate()
      }
    }
  }, [checkedValues])
  //PCA use effect
  useEffect(() => {
    if (initFinished) {
      let boneObj = getObjectByName(scene.current, 'objBone')

      if (boneObj != null && boneObj != undefined && boneCenter.centerVector.length() != 0) {
        let boneMesh = boneObj.children[0]
        let vert = boneMesh.geometry.attributes.position
        let verticData = float32ArrayToVertices(vert.array, 500)
        let analysis = PCA.getEigenVectors(verticData)
        let dir1 = new THREE.Vector3().fromArray(analysis[0].vector).add(boneCenter.centerVector)
        let dir2 = new THREE.Vector3().fromArray(analysis[1].vector).add(boneCenter.centerVector)
        let dir3 = new THREE.Vector3().fromArray(analysis[2].vector).add(boneCenter.centerVector)

        setPcaDirections((prevState) => ({
          ...prevState,
          direction1: new THREE.Vector3().subVectors(dir1, boneCenter.centerVector).normalize(),
          direction2: new THREE.Vector3().subVectors(dir2, boneCenter.centerVector).normalize(),
          direction3: new THREE.Vector3().subVectors(dir3, boneCenter.centerVector).normalize()
        }))

        vert = null
        verticData = null
        analysis = null
        boneMesh = null
        boneObj = null
      }
    }
  }, [initFinished, boneCenter])

  //Rotate Implant use Effect
  useEffect(() => {
    if (implant != undefined && implant != null) {
      transformDict.current = deepCopy(calculateTransformMatrices())

      if (showFlipBox) {
        rotateImplant(
          scene.current,
          implant,
          intuitiveState.firstBonePoint,
          flip.type,
          deepCopy,
          applyTransformMatrices,
          calculateTransformMatrices
        )
      } else {
        var axisPoint = new THREE.Vector3()
        axisPoint = getCenterOfObject(implant, false)

        if (attachmentBool) {
          var parent = implant.parent

          var saveState = deepCopy(calculateTransformMatrices())
          implant.removeFromParent()
          scene.current.add(implant)
          applyTransformMatrices(deepCopy(saveState))

          rotateImplant(
            scene.current,
            implant,
            axisPoint,
            flip.type,
            deepCopy,
            applyTransformMatrices,
            calculateTransformMatrices
          )
          parent.attach(implant)
        } else {
          rotateImplant(
            scene.current,
            implant,
            axisPoint,
            flip.type,
            deepCopy,
            applyTransformMatrices,
            calculateTransformMatrices
          )
        }
      }

      if (!areObjectsEqual(transformDict.current, calculateTransformMatrices())) {
        if (undoStack.length === 0) {
          setCurrentState(deepCopy(transformDict.current))
        }
        setCheckingTrigger(true)
      }
      animate()
    }
  }, [flip])

  useEffect(() => {
    if (showFlipBox) {
      const geometry = implant.children[0].geometry

      // Make sure the geometry is up-to-date
      geometry.computeBoundingBox()

      // Bounding box dimensions
      const boundingBox = geometry.boundingBox
      const width = boundingBox.max.x - boundingBox.min.x

      let zCoordinatePositive = new THREE.Vector3(1, 0, 0)
      implant.localToWorld(zCoordinatePositive)

      let zCoordinateNegative = zCoordinatePositive.clone()

      zCoordinatePositive
        .sub(implant.position)
        .multiplyScalar(width)
        .add(intuitiveState.firstBonePoint)
      zCoordinateNegative
        .sub(implant.position)
        .multiplyScalar(-width)
        .add(intuitiveState.firstBonePoint)
      removeObject(scene.current, 'rotationLine')
      drawTransparent3DLine(
        zCoordinatePositive,
        zCoordinateNegative,
        scene.current,
        'white',
        'rotationLine',
        0.5,
        0.5
      )
    } else {
      removeObject(scene.current, 'rotationLine')
    }
  }, [showFlipBox])

  useEffect(() => {
    if (
      !intuitiveState.firstMove &&
      intuitiveState.secondBonePoint.length() == 0 &&
      intuitiveState.secondImplantPoint.length() == 0 &&
      intuitiveState.thirdBonePoint.length() == 0 &&
      intuitiveState.thirdImplantPoint.length() == 0
    ) {
      setShowFlipBox(true)
    } else {
      setShowFlipBox(false)
    }
  }, [intuitiveState])

  useEffect(() => {
    if (rotationVector !== null) {
      let z = cameraRef.current.position.z
      let y = cameraRef.current.position.y
      let x = cameraRef.current.position.x
      const zoomValue = Math.max(Math.abs(z), Math.abs(x), Math.abs(y))
      // Copy the initial camera and control targets
      cameraRef.current.copy(cameraPositionsRef.current.camera)
      controlsRef.current.target.copy(cameraPositionsRef.current.controlsTarget)

      // Calculate and set the new camera position based on rotationVector
      const newPosition = calculateNewCameraPosition(
        cameraPositionsRef.current.camera.position,
        cameraPositionsRef.current.controlsTarget,
        rotationVector
      )
      cameraRef.current.position.copy(newPosition)

      // Special handling for 'S' and 'I' to adjust the camera's up vector and lookAt
      if (rotationVector === 'S') {
        cameraRef.current.up.set(0, 0, -1) // For looking down
      } else if (rotationVector === 'I') {
        cameraRef.current.up.set(0, 0, 1) // For looking up
      }

      if (rotationVector === 'S' || rotationVector === 'I') {
        cameraRef.current.lookAt(controlsRef.current.target) // Ensure correct orientation
        cameraRef.current.position.y = rotationVector === 'I' ? -zoomValue : zoomValue
      } else if (rotationVector === 'A' || rotationVector === 'P') {
        cameraRef.current.position.z = rotationVector === 'P' ? -zoomValue : zoomValue
      } else {
        cameraRef.current.position.x = rotationVector === 'L' ? zoomValue : -zoomValue
      }

      animate() // Update the scene
      setRotationVector(null) // Reset rotationVector to avoid re-triggering
    }
  }, [rotationVector])

  useEffect(() => {
    let boneObj = getObjectByName(scene.current, 'objBone')

    // Check if all objects are loaded based on filePath.isolationObjects keys
    const allKeys = Object.keys(filePath.repositionObjects) // Keys that should be loaded
    const allLoaded = allKeys.every((key) => objectsLoaded[key] === true) // Check if all keys are loaded

    // console.log('allKeys : ', allKeys)
    // console.log('objectsLoaded : ', objectsLoaded)

    if (
      boneObj != null &&
      boneObj != undefined &&
      boneCenter.centerVector.length() != 0 &&
      allLoaded
    ) {
      // Perform actions after all objects have been loaded
      controlsRef.current.enabled = true

      // Remove all existing 'BoundingBox' objects from the scene
      scene.current.children = scene.current.children.filter(
        (child) => child.name !== 'BoundingBox'
      )
      boundingBoxMeshRef.current = addBoundingBoxAndLabels(scene.current)

      if (cameraRef.current && controlsRef.current) {
        // Set the camera position and orientation for rotationVector = "I" (Inferior view)
        const zoomValue = Math.max(
          Math.abs(cameraRef.current.position.z),
          Math.abs(cameraRef.current.position.x),
          Math.abs(cameraRef.current.position.y)
        )

        // Position the camera below the target for an Inferior view
        cameraRef.current.position.set(0, -zoomValue, 0) // Below the object
        cameraRef.current.up.set(0, 0, 1) // Camera's up vector pointing along the Z-axis
        cameraRef.current.lookAt(controlsRef.current.target) // Ensure the camera looks at the target
      }

      // Now, save this as the initial camera state
      if (!initialCameraState.set && cameraRef.current && controlsRef.current) {
        setInitialCameraState({
          set: true,
          camera: cameraRef.current.clone(), // Save the cloned state of the camera
          controlsTarget: controlsRef.current.target.clone() // Save the cloned state of the controls' target
        })
      }

      // Save these initial positions for possible resets
      cameraPositionsRef.current = {
        camera: cameraRef.current.clone(),
        controlsTarget: controlsRef.current.target.clone()
      }

      setIsLoading(false)
      animate()
    }
  }, [initFinished, objectsLoaded, boneCenter])

  useEffect(() => {
    if (viewBoxOn === true) {
      toggleBoundingBoxVisibility(boundingBoxMeshRef.current, true) // Show
    } else {
      toggleBoundingBoxVisibility(boundingBoxMeshRef.current, false) // Hide
    }
  }, [viewBoxOn])

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.rotateSpeed = rotateSpeed
    }
  }, [rotateSpeed])

  return <div name="3DBox" ref={containerRef} />
}

export default RenderingThreeSceneImplant
