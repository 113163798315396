/* eslint-disable react/prop-types */
import { segmentationButtonHandler } from '../segmentation/SegmentationButtonHandler'
import { isolationButtonHandler } from '../isolation/IsolationButtonHandler'
import { repositionButtonHandler } from '../reposition/RepositionButtonHandler'
import { targetAnatomyButtonHandler } from '../targetAnatomy/TargetAnatomyButtonHandler'
import { implantPositioningButtonHandler } from '../implantPositioning/ImplantPositioningButtonHandler'

// next button function in the second line of header
export const mainViewNextButtonHandler = async (
  indication,
  buttonState,
  setButtonState,
  buttonStates,
  sdk,
  isDemo,
  setIsLoading,
  filePath,
  setFilePath,
  backInfo,
  setBackInfo,
  userInfo,
  setLoadingValue
) => {
  if (indication === 'Face') {
    if (buttonState === 'Seg') {
      // Skip 'Iso' state because face doesn't have isolation model
      const currentIndex = buttonStates.indexOf(buttonState)
      const nextIndex = currentIndex + 2 // Skip one state
      const nextState = buttonStates[nextIndex]

      if (nextState === 'Rep') {
        repositionButtonHandler(
          sdk,
          isDemo,
          setIsLoading,
          filePath,
          setFilePath,
          setButtonState,
          backInfo,
          setBackInfo,
          userInfo,
          setLoadingValue
        )
      }
    } else {
      // Proceed to next state as usual
      const currentIndex = buttonStates.indexOf(buttonState)
      const nextIndex = currentIndex + 1

      if (nextIndex < buttonStates.length) {
        const nextState = buttonStates[nextIndex]
        if (nextState === 'Seg') {
          segmentationButtonHandler(
            sdk,
            isDemo,
            setIsLoading,
            filePath,
            setFilePath,
            setButtonState,
            backInfo,
            setBackInfo,
            userInfo,
            setLoadingValue,
            indication
          )
        } else if (nextState === 'Target') {
          targetAnatomyButtonHandler(
            sdk,
            isDemo,
            setIsLoading,
            filePath,
            setFilePath,
            setButtonState,
            backInfo,
            setBackInfo,
            userInfo,
            setLoadingValue
          )
        } else if (nextState === 'Implant') {
          implantPositioningButtonHandler(
            sdk,
            isDemo,
            setIsLoading,
            filePath,
            setFilePath,
            setButtonState,
            backInfo,
            setLoadingValue
          )
        }
      } else {
        // console.log('The End')
        window.alert('This is the end step')
      }
    }
  } else {
    // Proceed to next state as usual
    const currentIndex = buttonStates.indexOf(buttonState)
    const nextIndex = currentIndex + 1

    if (nextIndex < buttonStates.length) {
      const nextState = buttonStates[nextIndex]
      if (nextState === 'Seg') {
        segmentationButtonHandler(
          sdk,
          isDemo,
          setIsLoading,
          filePath,
          setFilePath,
          setButtonState,
          backInfo,
          setBackInfo,
          userInfo,
          setLoadingValue,
          indication
        )
      } else if (nextState === 'Iso') {
        isolationButtonHandler(
          sdk,
          isDemo,
          setIsLoading,
          filePath,
          setFilePath,
          setButtonState,
          backInfo,
          setBackInfo,
          userInfo,
          setLoadingValue
        )
      } else if (nextState === 'Rep') {
        repositionButtonHandler(
          sdk,
          isDemo,
          setIsLoading,
          filePath,
          setFilePath,
          setButtonState,
          backInfo,
          setBackInfo,
          userInfo,
          setLoadingValue
        )
      } else if (nextState === 'Target') {
        targetAnatomyButtonHandler(
          sdk,
          isDemo,
          setIsLoading,
          filePath,
          setFilePath,
          setButtonState,
          backInfo,
          setBackInfo,
          userInfo,
          setLoadingValue
        )
      } else {
        // (nextState === 'Implant')
        implantPositioningButtonHandler(
          sdk,
          isDemo,
          setIsLoading,
          filePath,
          setFilePath,
          setButtonState,
          backInfo,
          setLoadingValue
        )
      }
    } else {
      // console.log('The End')
      window.alert('This is the end step')
    }
  }

  // console.log('Next')
}
