/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Box, Typography, Button } from '@mui/material'

const ConfirmBox = ({ setConfirm }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleNo = () => {
    setConfirm(false)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          top: '40%',
          left: '40%',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999
        }}
        className="modal"
      >
        <Box
          sx={{
            boxSizing: 'border-box',
            width: '500px',
            height: '128px',
            background: 'rgba(217, 217, 217, 0.12)',
            border: isHovered ? '2px solid #2C622C' : '2px solid transparent',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginTop: '10px',
            padding: '20px'
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Open Sans',
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px',
              color: '#FFFFFF',
              marginBottom: '12px'
            }}
          >
            Are you sure you want to proceed to the next step?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around'
            }}
          >
            <Button variant="contained" color="error" onClick={handleNo}>
              No
            </Button>
            <Button variant="contained" color="success" onClick={() => setConfirm(true)}>
              Yes
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ConfirmBox
