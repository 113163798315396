/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  HeaderButtonContainer,
  RectanglePart,
  LeftTrianglePart,
  RightTrianglePart,
  TextContainer
} from '../../../../Theme'
import { screwPositioningButtonHandler } from './ScrewPositioningButtonHandler'

const ScrewPositioningButton = ({ setButtonState, buttonStates, buttonState }) => {
  const [hover, setHover] = useState(false)
  const isActive = buttonState === 'Screw'
  const isDone = buttonStates.indexOf(buttonState) > buttonStates.indexOf('Screw')

  return (
    <HeaderButtonContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => screwPositioningButtonHandler(setButtonState)}
    >
      <LeftTrianglePart hover={hover} active={isActive} done={isDone} />
      <RectanglePart
        sx={{
          width: '145px'
        }}
        hover={hover}
        active={isActive}
        done={isDone}
      />
      <RightTrianglePart hover={hover} active={isActive} done={isDone} />
      <TextContainer>Screw Positioning</TextContainer>
    </HeaderButtonContainer>
  )
}

export default ScrewPositioningButton
