import { Box, CircularProgress } from '@mui/material'
import { authenticationSDK } from './AuthenticationSDK'
import React, { useEffect } from 'react'
import { appTheme } from '../../Theme'
import { useNavigate } from 'react-router-dom'

const Activate = () => {
  const windowUrl = window.location.search
  const params = new URLSearchParams(windowUrl)
  const hash = params.get('hash')

  const navigate = useNavigate()
  useEffect(() => {
    const activateUser = async () => {
      let authentication = await authenticationSDK(
        process.env.REACT_APP_EXTRAHORIZON_EMAIL,
        process.env.REACT_APP_EXTRAHORIZON_PASSWORD
      )
      if (!authentication.result) {
        alert('Unable to activate. Please try again later!')
      } else {
        try {
          await authentication.sdk.users.validateEmailActivation({
            hash: hash
          })
          alert('User succesfully activated! ')
        } catch (error) {
          alert(error)
        }
      }

      navigate('/', { replace: true })
    }

    activateUser()
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        bottom: '50%',
        left: '50%',
        zIndex: 9999 // set high z-index value
      }}
      className="modal"
    >
      <Box sx={{ backgroundColor: '#232323' }} className="modal-content">
        <CircularProgress />
        <Box sx={{ color: appTheme.palette.common.white }} className="modal-text">
          Loading...
        </Box>
      </Box>
    </Box>
  )
}

export default Activate
