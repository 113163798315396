/* eslint-disable react/prop-types */

import React, { useContext } from 'react'
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { confirmDialogHandler } from './ConfirmDialogHandler'

import { UserContext } from '../../../../App'

function ConfirmDialog({
  open,
  setOpen,
  nextButtonType,
  indication,
  buttonState,
  setButtonState,
  buttonStates,
  sdk,
  isDemo,
  setIsLoading,
  filePath,
  setFilePath,
  backInfo,
  setBackInfo,
  userInfo
}) {
  const { setLoadingValue } = useContext(UserContext)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: '#232323',
          color: '#FFFFFF', // Sets default text color to white
          zIndex: 9999 // set high z-index value
        }
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: 'absolute', right: 8, top: 8, color: '#FFFFFF' }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        id="confirm-dialog-title"
        sx={{ color: '#FF5733', fontWeight: 'bold', fontSize: '25px' }}
      >
        {'CONFIRMATION'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="confirm-dialog-description"
          sx={{ color: '#FFFFFF', fontSize: '18px' }}
        >
          Are you sure you want to proceed next step?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            confirmDialogHandler(
              setOpen,
              nextButtonType,
              indication,
              buttonState,
              setButtonState,
              buttonStates,
              sdk,
              isDemo,
              setIsLoading,
              filePath,
              setFilePath,
              backInfo,
              setBackInfo,
              userInfo,
              setLoadingValue
            )
          }
          sx={{
            backgroundColor: '#036019',
            color: '#FFFFFF',
            paddingBottom: '10px',
            '&:hover': {
              backgroundColor: '#024712'
            }
          }}
        >
          Yes
        </Button>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: '#D32F2F',
            color: '#FFFFFF',
            paddingBottom: '10px',
            '&:hover': {
              backgroundColor: '#b22b2b'
            }
          }}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
