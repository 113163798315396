/* eslint-disable react/prop-types */
import React from 'react'
import { Typography, Button, Grid, Box, Link, TextField } from '@mui/material'
import render from '../../assets/login_render_img.png'
import { authenticationSDK } from './AuthenticationSDK'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

function Login({
  setSdk: setSdk,
  isAuthenticated: isAuthenticated,
  setAuthenticated: setAuthenticated,
  setUserInfo: setUserInfo
}) {
  const navigate = useNavigate()
  const textFieldStyle = {
    backgroundColor: '#727272',
    width: '80%',
    borderRadius: '4px',

    input: {
      color: '#fff',
      borderColor: '#fff'
    },
    label: { color: '#fff' },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#fff'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#fff'
      }
    },
    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff'
    }
  }
  const [emailInput, setEmailInput] = useState('')
  const [passInput, setPassInput] = useState('')
  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value)
  }
  const handlePassInputChange = (event) => {
    setPassInput(event.target.value)
  }

  const handleLogin = async () => {
    const authenticated = await authenticationSDK(emailInput, passInput)
    if (authenticated.result) {
      setSdk(authenticated.sdk)
      setAuthenticated(true)
      setUserInfo(await authenticated.sdk.users.me())
    } else {
      alert('Wrong email or password!')
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home', { replace: true })
    }
  }, [isAuthenticated, navigate])

  return (
    <Box
      sx={{
        backgroundColor: '#191919',
        width: '100%',
        height: '100vh'
      }}
    >
      {/* Logo and text box */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          padding: '22px 16px', // Adjusted to fit both logo and text based on their layouts.
          display: 'flex',
          alignItems: 'center' // Ensures the text is aligned with the logo.
        }}
      >
        <Box
          component="img"
          src={`logo512.png`} // Use the PUBLIC_URL to reference the public directory.
          alt="Logo"
          sx={{
            width: '71px',
            height: '71px'
          }}
        />
        <Typography
          sx={{
            marginLeft: '0px', // Adjust based on visual preference for spacing between logo and text.
            marginTop: '10px',
            color: '#FFFFFF', // Assuming you want the text in white to match the rest of the theme.
            fontSize: '28px' // Example font size, adjust as needed.
          }}
        >
          MangoMedical
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          padding: '16px',
          height: '100vh'
        }}
      >
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={5}
          sx={{
            height: '100vh'
          }}
        >
          <Grid item>
            <Box
              sx={{
                backgroundColor: '#383838',
                borderRadius: '20px',
                padding: '50px 18px',
                color: '#fff',
                maxWidth: '70%',
                textAlign: 'center'
              }}
            >
              <Typography variant="h4" sx={{ mb: 1 }}>
                Login to Account
              </Typography>
              <Typography sx={{ marginLeft: '-0.5em' }}>
                {"Don't have an account?"}{' '}
                <a style={{ color: '#1976d2' }} href="/register">
                  Register
                </a>
                .
              </Typography>
              <Box component="form" noValidate sx={{ mt: 2.5, color: '#fff' }}>
                <TextField
                  margin="normal"
                  required
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={emailInput}
                  onChange={handleEmailInputChange}
                  sx={textFieldStyle}
                />
                <TextField
                  margin="normal"
                  required
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={passInput}
                  onChange={handlePassInputChange}
                  sx={textFieldStyle}
                />
                <Grid
                  container
                  justifyContent="space-between"
                  sx={{ width: '90%', textAlign: 'right' }}
                >
                  <Grid item xs>
                    <Link href="/resetpassword" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>

                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, width: '80%' }}
                  onClick={handleLogin}
                >
                  Sign In
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={7}
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '100vh'
          }}
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              height: '100%'
            }}
          >
            <Box
              component="img"
              src={render}
              alt="Mango Rendering"
              sx={{ maxWidth: '90vh', margin: '16px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Login
