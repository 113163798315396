/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import CloseButton from '../mainView/CloseButton'
import Draggable from 'react-draggable'
import OpenWithIcon from '@mui/icons-material/OpenWith'

const ImplantTranslationInfoBox = ({ setTranslationInfo: setTranslationInfo }) => {
  const draggableRef = useRef(null)
  return (
    <Draggable nodeRef={draggableRef} handle=".drag-icon">
      <Box
        ref={draggableRef}
        sx={{
          position: 'fixed',
          top: '48%',
          left: '10%',
          transform: 'translateX(-50%)',
          zIndex: 10000,
          backgroundColor: '#303030', // Box background color
          padding: 3,
          borderRadius: 2,
          width: '610px',
          maxWidth: '80%',
          color: '#fff',
          marginTop: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 1,
            backgroundColor: '#303030', // Matching header color with box color
            padding: '0 8px', // Reduced padding in the header
            borderRadius: '2px 2px 0 0' // Rounded corners on top only
          }}
        >
          <IconButton aria-label="info" sx={{ color: '#fff', marginRight: 1 }}>
            <InfoIcon />
          </IconButton>
          <Typography variant="subtitle1" component="span" sx={{ flexGrow: 1 }}>
            Translation
          </Typography>
          <div>
            <OpenWithIcon
              className="drag-icon"
              sx={{
                color: '#FFFFFF',
                cursor: 'move',
                backgroundColor: '',
                borderRadius: '50%',
                fontSize: '25px',
                zIndex: 9999
              }}
            />
            <CloseButton setShowToolBox={setTranslationInfo} />
          </div>
        </Box>
        <Typography variant="body1" sx={{ fontSize: '14px' }}>
          To adjust the position of the implant, there are two methods available: using a controller
          or keyboard commands.
          <br />
          <br />
          Using the Controller:
          <br />
          Locate the arrow on the controller that corresponds to the desired direction of movement.
          <br />
          Click and hold this arrow, then drag it to the new position. Release the mouse button to
          set the implant in place.
          <br />
          <br />
          Using the Keyboard:
          <br />
          Use the arrow keys for two-dimensional movement:
          <br />
          Press the LEFT or RIGHT arrow keys to move the implant along the red axis.
          <br />
          Press the UP or DOWN arrow keys to move the implant along the blue axis.
          <br />
          Press the U or D key to move the implant along the green axis.
          <br />
          <br />
          Each key press moves the implant by 1mm.
        </Typography>
      </Box>
    </Draggable>
  )
}

export default ImplantTranslationInfoBox
