/* eslint-disable react/prop-types */
import React, { useRef } from 'react'
import { Box, useTheme, Typography, Slider, Grid } from '@mui/material'
import Draggable from 'react-draggable' // add drag function to move box
import OpenWithIcon from '@mui/icons-material/OpenWith'
import CloseButton from './CloseButton'

// eraser tool function in toolbox
const MainViewEraserToolBox = ({
  Radius: Radius,
  setRadius: setRadius,
  labelBoxMargin: labelBoxMargin,
  setShowToolBox: setShowToolBox
}) => {
  const theme = useTheme()

  const draggableRef = useRef(null)

  const handleBrushRadiusChange = (event, newValue) => {
    setRadius({ radius: newValue })
  }

  return (
    // dragging is only working at the first line of this box
    <Draggable nodeRef={draggableRef} handle=".drag-icon">
      <Box
        ref={draggableRef}
        sx={{
          position: 'absolute',
          top: `calc(${labelBoxMargin}% + ${theme.sizes.nav.top} + 100px)`,
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '16px',
          gap: '16px',
          width: '240px',
          background: '#232323',
          boxShadow: '0px 4px 20px rgba(255, 255, 255, 0.25)',
          borderRadius: '8px',
          zIndex: 9990,
          right: '10px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Open Sans',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px',
              flex: 'none',
              order: 0,
              flexGrow: 0
            }}
          >
            Eraser
          </Typography>
          <div>
            <OpenWithIcon
              className="drag-icon"
              sx={{
                color: '#FFFFFF',
                cursor: 'move',
                backgroundColor: '',
                borderRadius: '50%',
                fontSize: '25px',
                zIndex: 9999
              }}
            />
            <CloseButton setShowToolBox={setShowToolBox} />
          </div>
        </Box>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography
              sx={{ color: '#FFFFFF', fontWeight: 400, fontSize: '12px', lineHeight: '20px' }}
            >
              Eraser Radius
            </Typography>
          </Grid>
          <Grid item xs>
            {/* slider to choose radius of eraser */}
            <Slider
              value={Radius.radius}
              onChange={handleBrushRadiusChange}
              valueLabelDisplay="auto"
              min={0}
              max={25}
              step={1}
              sx={{
                color: '#FFFFFF',
                width: '100%' // Set the width of the slider to 100%
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Draggable>
  )
}

export default MainViewEraserToolBox
