/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  HeaderButtonContainer,
  RectanglePart,
  LeftTrianglePart,
  RightTrianglePart,
  TextContainer
} from '../../../../Theme'
import { implantOptimizationButtonHandler } from './ImplantOptimizationButtnHandler'

const ImplantOptimizationButton = ({ setButtonState, buttonStates, buttonState }) => {
  const [hover, setHover] = useState(false)
  const isActive = buttonState === 'Opt'
  const isDone = buttonStates.indexOf(buttonState) > buttonStates.indexOf('Opt')

  return (
    <HeaderButtonContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => implantOptimizationButtonHandler(setButtonState)}
    >
      <LeftTrianglePart hover={hover} active={isActive} done={isDone} />
      <RectanglePart
        sx={{
          width: '165px'
        }}
        hover={hover}
        active={isActive}
        done={isDone}
      />
      <RightTrianglePart hover={hover} active={isActive} done={isDone} />
      <TextContainer>Implant Optimization</TextContainer>
    </HeaderButtonContainer>
  )
}

export default ImplantOptimizationButton
