import { FileLoader, Loader, Matrix4, Vector3 } from 'three'
import { Volume } from 'three/addons/misc/Volume'
import nifti from 'nifti-reader-js'

function extractAccessToken(url) {
  const regex = /\/v1\/(.+?)\/file/
  const match = url.match(regex)
  return match ? match[1] : null
}

async function getFileSize(sdk, accessToken) {
  try {
    const fileDetails = await sdk.files.getDetails(accessToken)
    if (fileDetails && fileDetails.size) {
      const sizeInBytes = fileDetails.size
      const sizeInMegabytes = sizeInBytes / (1024 * 1024) // Convert bytes to megabytes
      return sizeInMegabytes
    }
    throw new Error('File size information is missing')
  } catch (error) {
    console.error('Error fetching file details:', error)
    return null
  }
}

class NIFTILoader extends Loader {
  constructor(manager, filePath, setIsLoading, setLoadingValue, sdk, dataType, setNiftiProgress) {
    super(manager)
    this.filePath = filePath
    this.setIsLoading = setIsLoading
    this.setLoadingValue = setLoadingValue
    this.sdk = sdk
    this.dataType = dataType
    this.setNiftiProgress = setNiftiProgress
  }

  async load(url, onLoad, onProgress, onError) {
    const scope = this
    const loader = new FileLoader(scope.manager)

    const accessToken = extractAccessToken(url)

    let fileSize = null

    if (accessToken && this.dataType === 'volume' && this.sdk) {
      // Fetch metadata if dataType is 'volume' and sdk is provided
      fileSize = await getFileSize(this.sdk, accessToken)
      if (fileSize === null) {
        console.error('Failed to fetch file size')
        return // Abort loading if file size fetch failed
      }
    }

    if (this.dataType === 'volume' && this.setNiftiProgress) {
      this.setNiftiProgress({
        filesize: fileSize,
        loadingStatus: 'downloading' // Update the filesize
      })
    }

    loader.setPath(scope.path)
    loader.setResponseType('arraybuffer')
    loader.setRequestHeader(scope.requestHeader)
    loader.setWithCredentials(scope.withCredentials)

    loader.load(
      url,
      function (data) {
        try {
          const parsedData = scope.parse(data)
          onLoad(parsedData)
        } catch (e) {
          if (onError) {
            onError(e)
          } else {
            console.error(e)
          }

          scope.manager.itemError(url)
        }
      },
      onProgress,
      onError
    )
  }

  parse(data) {
    let niftiHeader = null
    let niftiImage = null

    if (nifti.isCompressed(data)) {
      data = nifti.decompress(data)
    }

    if (nifti.isNIFTI(data)) {
      niftiHeader = nifti.readHeader(data)
      niftiImage = nifti.readImage(niftiHeader, data)

      if (nifti.hasExtension(niftiHeader)) {
        nifti.readExtensionData(niftiHeader, data)
      }
    }

    const volume = new Volume()

    // Parse the (unzipped) data to a datastream of the correct type
    switch (niftiHeader.datatypeCode) {
      case nifti.NIFTI1.TYPE_UINT8:
        volume.data = new Uint8Array(niftiImage)
        break
      case nifti.NIFTI1.TYPE_INT16:
        volume.data = new Int16Array(niftiImage)
        break
      case nifti.NIFTI1.TYPE_INT32:
        volume.data = new Int32Array(niftiImage)
        break
      case nifti.NIFTI1.TYPE_FLOAT32:
        volume.data = new Float32Array(niftiImage)
        break
      case nifti.NIFTI1.TYPE_FLOAT64:
        volume.data = new Float64Array(niftiImage)
        break
      case nifti.NIFTI1.TYPE_INT8:
        volume.data = new Int8Array(niftiImage)
        break
      case nifti.NIFTI1.TYPE_UINT16:
        volume.data = new Uint16Array(niftiImage)
        break
      case nifti.NIFTI1.TYPE_UINT32:
        volume.data = new Uint32Array(niftiImage)
        break
      default:
        volume.data = niftiImage
    }

    // Get the min and max intensities
    const minMax = volume.computeMinMax()
    const min = minMax[0]
    const max = minMax[1]

    // Get the image dimensions
    volume.dimensions = [niftiHeader.dims[1], niftiHeader.dims[2], niftiHeader.dims[3]]
    volume.xLength = volume.dimensions[0]
    volume.yLength = volume.dimensions[1]
    volume.zLength = volume.dimensions[2]

    // Adjust matrix
    volume.spacing = [niftiHeader.pixDims[1], niftiHeader.pixDims[2], niftiHeader.pixDims[3]]
    volume.matrix = new Matrix4()
    volume.matrix.set(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)

    // Create a rotation matrix for rotating the Y slice by 90 degrees clockwise
    const rotationMatrix = new Matrix4().makeRotationY(-Math.PI / 2)
    volume.matrix.multiply(rotationMatrix)

    volume.inverseMatrix = new Matrix4()
    volume.inverseMatrix.copy(volume.matrix).invert()

    volume.RASDimensions = new Vector3(volume.xLength, volume.yLength, volume.zLength)
      .applyMatrix4(volume.matrix)
      .round()
      .toArray()
      .map(Math.abs)

    volume.axisOrder = ['x', 'y', 'z']
    if (volume.lowerThreshold === -Infinity) {
      volume.lowerThreshold = min
    }

    if (volume.upperThreshold === Infinity) {
      volume.upperThreshold = max
    }

    const regex = /\/files\/v1\/(.*?)\//
    const match = this.filePath.match(regex)

    if (match && match[1]) {
      const id = match[1]
      volume.id = id
    }

    return volume
  }
}

export { NIFTILoader }
