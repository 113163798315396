/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import JSZip from 'jszip'
import { dicomHandler } from './DicomHandler'
import { folderLoader } from './FolderLoader'

const sanitizeFileName = (name) => {
  // Replace spaces with underscores and remove non-alphanumeric characters (except underscores)
  return name.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_]/g, '')
}

const formatPatientInfo = (info) => {
  // Format gender
  if (info.gender === 'M') {
    info.gender = 'Male'
  } else if (info.gender === 'F') {
    info.gender = 'Female'
  } else if (info.gender === 'O') {
    info.gender = 'Other'
  }
  // Do not change if it's already in the desired format

  // Format birthday
  if (info.birthday && info.birthday.length === 8 && info.birthday.indexOf('/') === -1) {
    info.birthday = `${info.birthday.slice(0, 4)}/${info.birthday.slice(
      4,
      6
    )}/${info.birthday.slice(6)}`
  }
  // Do not change if it's already in the desired format or not in 'YYYYMMDD' format
}

export const folderHandler = async (
  event,
  isDemo,
  setDemo,
  backInfo,
  setBackInfo,
  setButtonState,
  userInfo,
  isRenderingThreeDisabled,
  setIsRenderingThreeDisabled,
  setFilePath,
  sdk,
  setFileLoadingStatus,
  setDocumentUpdateState,
  setFileName,
  chosenDicom
) => {
  setFileLoadingStatus('progress')

  if (isDemo) setDemo(!isDemo)

  const patientInfo = {
    gender: 'Other',
    birthday: '1900/01/01',
    updated: false
  }

  const files = event.target.files

  const filePath = files[0].webkitRelativePath
  const folderPath = filePath.split('/').slice(-2, -1)[0]
  const encodedFolderPath = encodeURIComponent(folderPath)
  const fileNameWoExtension = sanitizeFileName(
    encodedFolderPath + '_' + chosenDicom[0].seriesDescription
  )

  setFileName(fileNameWoExtension)

  const seriesMap = new Map()

  // Group files by seriesInstanceUID
  for (const Dicom of chosenDicom) {
    seriesMap.set(Dicom.seriesInstanceUID, {
      seriesDescription: Dicom.seriesDescription,
      files: []
    })
  }

  let processedFilesCount = 0
  const totalFilesCount = files.length

  for (let file of files) {
    const reader = new FileReader()

    reader.onload = async (e) => {
      const arrayBuffer = e.target.result
      const byteArray = new Uint8Array(arrayBuffer)
      const data = await dicomHandler(byteArray)

      if (seriesMap.has(data.seriesInstanceUID)) {
        seriesMap.get(data.seriesInstanceUID).files.push({ file, data })
      }

      if (patientInfo.updated === false) {
        patientInfo.updated = true
        if (data.originalGender !== null && data.originalGender !== '') {
          patientInfo.gender = data.originalGender
        }
        if (data.originalBirthDate !== null && data.originalBirthDate !== '') {
          patientInfo.birthday = data.originalBirthDate
        }

        formatPatientInfo(patientInfo)
      }

      processedFilesCount++
      if (allFilesProcessed()) {
        // console.log(seriesMap)
        await processSeriesMap(seriesMap)
      }
    }

    reader.readAsArrayBuffer(file)
  }

  function allFilesProcessed() {
    return processedFilesCount === totalFilesCount
  }

  async function processSeriesMap(seriesMap) {
    const finalZip = new JSZip()

    for (const [seriesUID, seriesData] of seriesMap.entries()) {
      console.log('Processing series: ', seriesUID)
      const seriesZip = new JSZip()
      for (const { file, data } of seriesData.files) {
        const myBuffer = new Blob([data.dataSet.byteArray.buffer])
        seriesZip.file(file.name, myBuffer)
      }

      const seriesZipBuffer = await seriesZip.generateAsync({ type: 'arraybuffer' })
      finalZip.file(seriesData.seriesDescription + '.zip', seriesZipBuffer)
    }

    finalZip.generateAsync({ type: 'arraybuffer' }).then(async function (dicomBuffer) {
      const zipFileName = fileNameWoExtension + '.zip'

      // Create a Blob object from the ArrayBuffer:
      const zipBlob = new Blob([dicomBuffer], {
        type: 'application/x-gzip'
      })

      const fileUrl = await folderLoader(
        zipFileName,
        zipBlob,
        sdk,
        userInfo['id'],
        fileNameWoExtension,
        setBackInfo,
        backInfo,
        'dicom_upload',
        setFileLoadingStatus,
        setDocumentUpdateState,
        patientInfo
      )

      setFilePath({
        ...filePath,
        filename: fileNameWoExtension,
        image: fileUrl
      })

      setButtonState('Image')

      if (isRenderingThreeDisabled) setIsRenderingThreeDisabled(!isRenderingThreeDisabled)

      event.target.value = null
    })
  }

  return
}
