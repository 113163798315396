import * as THREE from 'three'
import { FontLoader } from 'three/addons/loaders/FontLoader.js'
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js'
import { removeObject, removeAllObjects } from './SegmentToolHandler'
import { reCalcCoordinates, setMeasureCoordinates } from './VariableStorage'
// import { addSphere } from "./ScissorToolHandler3D";
import { eventEmitter } from '../TwoDViews'

function addCircle(
  scene,
  radius,
  centerX,
  centerY,
  centerZ = 0,
  segments = 32,
  color = 0xfa8072,
  name = 'circle'
) {
  // Create the geometry for circle
  const circleGeometry = new THREE.CircleGeometry(radius, segments)

  // Create the material for circle
  const circleMaterial = new THREE.MeshBasicMaterial({
    color: color,
    opacity: 1,
    transparent: true
  })

  // Create the circle mesh
  const circle = new THREE.Mesh(circleGeometry, circleMaterial)

  // Set the center of the circle
  circle.position.set(centerX, centerY, centerZ)
  circle.name = name
  // Add the circle to the scene
  scene.add(circle)
}

export function calculateAngle(c1, p1, p2) {
  // Calculate the vectors
  const vector1 = new THREE.Vector3().subVectors(p1, c1)
  const vector2 = new THREE.Vector3().subVectors(p2, c1)

  // Calculate the dot product
  const dotProduct = vector1.dot(vector2)

  // Calculate the magnitudes of the vectors
  const magnitude1 = vector1.length()
  const magnitude2 = vector2.length()

  // Calculate the cosine of the angle using the dot product and magnitudes
  const cosineAngle = dotProduct / (magnitude1 * magnitude2)

  // Calculate the angle in radians
  const angleInRadians = Math.acos(cosineAngle)

  // Convert the angle to degrees
  const angleInDegrees = THREE.MathUtils.radToDeg(angleInRadians)
  return angleInDegrees
}

const loadFont = async () => {
  const loader = new FontLoader()
  return new Promise((resolve, reject) => {
    loader.load(
      'https://raw.githubusercontent.com/mrdoob/three.js/master/examples/fonts/helvetiker_regular.typeface.json',
      resolve,
      undefined,
      reject
    )
  })
}

export async function addTextToScene(
  scene,
  text,
  position,
  color = 0xfa8072,
  fontSize = 10,
  name = 'measure'
) {
  const font = await loadFont()

  // Create the text geometry
  const textGeometry = new TextGeometry(text, {
    font: font,
    size: fontSize,
    height: 1,
    curveSegments: 10
  })

  // Create the text material
  const textMaterial = new THREE.MeshBasicMaterial({ color })

  // Create the text mesh
  const textMesh = new THREE.Mesh(textGeometry, textMaterial)

  // Set the text mesh position to the passed in position
  textMesh.position.set(position.x, position.y, position.z)
  textMesh.name = name

  // Add the text mesh to the scene
  scene.add(textMesh)
}

export function drawGreenLine(
  scene,
  measureConfig,
  point1,
  point2,
  text = '',
  point3 = null,
  thickness = 0.5
) {
  // Create a curve from point1 to point2
  const curve = new THREE.LineCurve3(point1, point2)

  // Create a tube geometry along the curve
  const tubeGeometry = new THREE.TubeGeometry(curve, 1, thickness, 8, false)

  // Create a material for the tube
  const tubeMaterial = new THREE.MeshBasicMaterial({ color: 0xfa8072 })

  // Create a mesh from the tube geometry and material
  const tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial)
  tubeMesh.name = 'line'
  scene.add(tubeMesh)

  if (text) {
    let midPoint
    // Calculate the midpoint of the tube
    if (measureConfig.type == 'Measure') {
      midPoint = new THREE.Vector3().addVectors(point1, point2).multiplyScalar(0.5)
    } else if (measureConfig.type == 'Angle' && point3) {
      midPoint = new THREE.Vector3().addVectors(point2, point3).multiplyScalar(0.5)
    }

    // Create the text sprite at the tube midpoint
    addTextToScene(scene, text, midPoint, 0xfa8072, 15)
  }
}

export function calculateDistance(point1, point2, spacing, aspectX, aspectY, axis) {
  const scale = { x: spacing[0], y: spacing[1], z: spacing[2] }
  let distance = 0

  if (axis === 'X') {
    // Calculate the square of the scaled distance between the points in each dimension
    if (scale) {
      const dx = Math.pow((point2.x - point1.x) * aspectX * scale.z, 2)
      const dy = Math.pow((point2.y - point1.y) * aspectY * scale.y, 2)
      const dz = Math.pow((point2.z - point1.z) * scale.z, 2)
      distance = Math.sqrt(dx + dy + dz)
    } else {
      const dx = Math.pow(point2.x - point1.x, 2)
      const dy = Math.pow(point2.y - point1.y, 2)
      const dz = Math.pow(point2.z - point1.z, 2)
      distance = Math.sqrt(dx + dy + dz)
    }
  } else if (axis === 'Y') {
    if (scale) {
      const dx = Math.pow((point2.x - point1.x) * aspectX * scale.x, 2)
      const dy = Math.pow((point2.y - point1.y) * aspectY * scale.z, 2)
      const dz = Math.pow((point2.z - point1.z) * scale.z, 2)
      distance = Math.sqrt(dx + dy + dz)
    } else {
      const dx = Math.pow(point2.x - point1.x, 2)
      const dy = Math.pow(point2.y - point1.y, 2)
      const dz = Math.pow(point2.z - point1.z, 2)
      distance = Math.sqrt(dx + dy + dz)
    }
  } else if (axis === 'Z') {
    if (scale) {
      const dx = Math.pow((point2.x - point1.x) * aspectX * scale.x, 2)
      const dy = Math.pow((point2.y - point1.y) * aspectY * scale.y, 2)
      const dz = Math.pow((point2.z - point1.z) * scale.z, 2)
      distance = Math.sqrt(dx + dy + dz)
    } else {
      const dx = Math.pow(point2.x - point1.x, 2)
      const dy = Math.pow(point2.y - point1.y, 2)
      const dz = Math.pow(point2.z - point1.z, 2)
      distance = Math.sqrt(dx + dy + dz)
    }
  }

  return distance
}

function convertAngleToTHREEFormat(angle, format = 'degrees') {
  if (format === 'degrees') {
    // Convert angle from degrees to radians
    return THREE.MathUtils.degToRad(angle)
  } else if (format === 'radians') {
    // Return the angle as it is (already in radians)
    return angle
  } else {
    // Invalid format specified
    throw new Error('Invalid angle format. Supported formats are "degrees" and "radians".')
  }
}

function determineQuarters(point1, point2, point3, flag) {
  const relativePoint1 = new THREE.Vector3().subVectors(point1, point2)
  const relativePoint3 = new THREE.Vector3().subVectors(point3, point2)

  const quadrant1 = relativePoint1.x >= 0 && relativePoint1.y >= 0
  const quadrant2 = relativePoint1.x < 0 && relativePoint1.y >= 0
  const quadrant3 = relativePoint1.x < 0 && relativePoint1.y < 0
  const quadrant4 = relativePoint1.x >= 0 && relativePoint1.y < 0

  const q1 = quadrant1 ? 1 : quadrant2 ? 2 : quadrant3 ? 3 : quadrant4 ? 4 : 'Unknown'
  const quadrant1p = relativePoint3.x >= 0 && relativePoint3.y >= 0
  const quadrant2p = relativePoint3.x < 0 && relativePoint3.y >= 0
  const quadrant3p = relativePoint3.x < 0 && relativePoint3.y < 0
  const quadrant4p = relativePoint3.x >= 0 && relativePoint3.y < 0

  const q2 = quadrant1p ? 1 : quadrant2p ? 2 : quadrant3p ? 3 : quadrant4p ? 4 : 'Unknown'
  return flag === 1 ? q1 : q2
}

export function drawAngleArc(scene, point1, point2, point3, angle) {
  const q1 = determineQuarters(point1, point2, point3, 1)
  const q2 = determineQuarters(point1, point2, point3, 2)
  // console.log(q1, q2);

  const radius = 30
  const centerX = point2.x
  const centerY = point2.y
  const segments = 32
  const color = 0xfa8072
  const name = 'arc'

  let start = calculateAngleOrigin(point1, point2).toFixed(2)
  let end = calculateAngleOrigin(point3, point2).toFixed(2)

  if (q1 === 3) start = 360 - parseFloat(start)
  if (q2 === 3) end = 360 - parseFloat(end)

  if (q1 === 4) start = 360 - parseFloat(start)
  if (q2 === 4) end = 360 - parseFloat(end)

  const startAngle = convertAngleToTHREEFormat(start)
  const endAngle = convertAngleToTHREEFormat(end)

  // Calculate the central angle

  // Create the geometry for the arc

  if (startAngle < endAngle) {
    if (toDegrees(endAngle) - toDegrees(startAngle) <= 180) {
      const centralAngle = convertAngleToTHREEFormat(angle)
      console.log(
        'startAngle ',
        toDegrees(startAngle),
        ' endAngle ',
        toDegrees(endAngle),
        ' centralAngle ',
        toDegrees(centralAngle)
      ) //////////////////////////////////////////

      const arcGeometry = new THREE.CircleGeometry(radius, segments, startAngle, centralAngle)

      // Create the material for the arc
      const arcMaterial = new THREE.MeshBasicMaterial({
        color: color,
        linewidth: 10,
        opacity: 1,
        transparent: true
      })

      // Create the arc mesh
      const arc = new THREE.Mesh(arcGeometry, arcMaterial)

      // Set the center of the arc
      arc.position.set(centerX, centerY, 0)
      arc.name = name

      // Add the arc to the scene
      scene.add(arc)
    } else {
      const centralAngle = convertAngleToTHREEFormat(angle)
      // console.log("startAngle ",toDegrees(startAngle)," endAngle ",toDegrees(endAngle)," centralAngle ",toDegrees(centralAngle)); //////////////////////////////////////////

      const arcGeometry = new THREE.CircleGeometry(radius, segments, endAngle, centralAngle)

      // Create the material for the arc
      const arcMaterial = new THREE.MeshBasicMaterial({
        color: color,
        linewidth: 10,
        opacity: 1,
        transparent: true
      })

      // Create the arc mesh
      const arc = new THREE.Mesh(arcGeometry, arcMaterial)

      // Set the center of the arc
      arc.position.set(centerX, centerY, 0)
      arc.name = name

      // Add the arc to the scene
      scene.add(arc)
    }
  } else {
    if (toDegrees(startAngle) - toDegrees(endAngle) <= 180) {
      const centralAngle = convertAngleToTHREEFormat(angle)
      console.log(
        'startAngle ',
        toDegrees(startAngle),
        ' endAngle ',
        toDegrees(endAngle),
        ' centralAngle ',
        toDegrees(centralAngle)
      ) //////////////////////////////////////////
      const arcGeometry = new THREE.CircleGeometry(radius, segments, endAngle, centralAngle)

      // Create the material for the arc
      const arcMaterial = new THREE.MeshBasicMaterial({
        color: color,
        linewidth: 10,
        opacity: 1,
        transparent: true
      })

      // Create the arc mesh
      const arc = new THREE.Mesh(arcGeometry, arcMaterial)

      // Set the center of the arc
      arc.position.set(centerX, centerY, 0)
      arc.name = name

      // Add the arc to the scene
      scene.add(arc)
    } else {
      const centralAngle = convertAngleToTHREEFormat(angle)
      console.log(
        'startAngle ',
        toDegrees(startAngle),
        ' endAngle ',
        toDegrees(endAngle),
        ' centralAngle ',
        toDegrees(centralAngle)
      ) //////////////////////////////////////////
      const arcGeometry = new THREE.CircleGeometry(radius, segments, startAngle, centralAngle)

      // Create the material for the arc
      const arcMaterial = new THREE.MeshBasicMaterial({
        color: color,
        linewidth: 10,
        opacity: 1,
        transparent: true
      })

      // Create the arc mesh
      const arc = new THREE.Mesh(arcGeometry, arcMaterial)

      // Set the center of the arc
      arc.position.set(centerX, centerY, 0)
      arc.name = name

      // Add the arc to the scene
      scene.add(arc)
    }
  }
}

function toDegrees(radians) {
  return radians * (180 / Math.PI)
}

export function calculateAngleOrigin(p1, origin) {
  const onepoint = new THREE.Vector3(origin.x + 1, origin.y, origin.z)

  // Calculate the vectors
  const vector1 = new THREE.Vector3().subVectors(onepoint, origin)
  const vector2 = new THREE.Vector3().subVectors(p1, origin)

  // Calculate the dot product
  const dotProduct = vector1.dot(vector2)

  // Calculate the magnitudes of the vectors
  const magnitude1 = vector1.length()
  const magnitude2 = vector2.length()

  // Calculate the cosine of the angle using the dot product and magnitudes
  const cosineAngle = dotProduct / (magnitude1 * magnitude2)

  // Calculate the angle in radians
  const angleInRadians = Math.acos(cosineAngle)

  // Convert the angle to degrees
  const angleInDegrees = THREE.MathUtils.radToDeg(angleInRadians)
  return angleInDegrees
}

export function measure(
  event,
  viewId,
  scene,
  camera,
  volume,
  geometry,
  measureConfig,
  measureDots
) {
  let pointer = new THREE.Vector3()
  let raycaster = new THREE.Raycaster()
  let bbox = event.currentTarget.getBoundingClientRect()
  let aspectX, aspectY
  let axis

  if (viewId === 'View1') {
    aspectX = volume.RASDimensions[0] / geometry.parameters.width
    aspectY = volume.RASDimensions[2] / geometry.parameters.height
    axis = 'Y'
  } else if (viewId === 'View2') {
    aspectX = volume.RASDimensions[0] / geometry.parameters.width
    aspectY = volume.RASDimensions[1] / geometry.parameters.height
    axis = 'Z'
  }

  if (viewId === 'View3') {
    aspectX = volume.RASDimensions[2] / geometry.parameters.width
    aspectY = volume.RASDimensions[1] / geometry.parameters.height
    axis = 'X'
  }

  pointer.set(
    ((event.clientX - bbox.x) / event.currentTarget.clientWidth) * 2 - 1,
    -((event.clientY - bbox.y) / event.currentTarget.clientHeight) * 2 + 1,
    0
  )
  raycaster.setFromCamera(pointer, camera)
  const intersects = raycaster.intersectObjects(scene.children)

  if (Object.keys(measureDots).length == 0) {
    measureDots['first'] = intersects[0].point
  } else if (Object.keys(measureDots).length == 1) {
    measureDots['second'] = intersects[0].point
    if (measureConfig.type == 'Measure') {
      const distance = calculateDistance(
        measureDots.first,
        measureDots.second,
        volume.spacing,
        aspectX,
        aspectY,
        axis
      ).toFixed(2)
      drawGreenLine(
        scene,
        measureConfig,
        measureDots.first,
        measureDots.second,
        'L : ' + distance.toString() + ' mm'
      )
    } else {
      drawGreenLine(scene, measureConfig, measureDots.first, measureDots.second)
    }
  } else if (Object.keys(measureDots).length == 2) {
    if (measureConfig.type == 'Measure') {
      delete measureDots.second
      removeAllObjects(scene, 'circle')
      removeObject(scene, 'line')
      removeObject(scene, 'measure')
      measureDots['first'] = intersects[0].point
    } else {
      measureDots['third'] = intersects[0].point
      const angle = calculateAngle(
        measureDots.second,
        measureDots.first,
        measureDots.third
      ).toFixed(2)
      drawGreenLine(
        scene,
        measureConfig,
        measureDots.second,
        measureDots.third,
        'A : ' + angle.toString() + '°',
        measureDots.first
      )
      // console.log('Angle in Between : ', angle);
      drawAngleArc(scene, measureDots.first, measureDots.second, measureDots.third, angle)
    }
  } else if (Object.keys(measureDots).length == 3) {
    delete measureDots.second
    delete measureDots.third

    removeAllObjects(scene, 'circle')
    removeAllObjects(scene, 'line')
    removeObject(scene, 'measure')
    removeObject(scene, 'arc')
    measureDots['first'] = intersects[0].point
  }

  if (intersects.length > 0) {
    addCircle(scene, 5, intersects[0].point.x, intersects[0].point.y)
  }
}

export function measure3D(
  event,
  viewId,
  scene,
  camera,
  volume,
  geometry,
  measureConfig,
  _measureDots,
  slider_value
) {
  /*
    Creates a line in a 3D view
    event: event,
    viewId: viewId,
    scene: scene,
    camera: camera,
    volume: volume,
    geometry: geometry,
    measureConfig: measureConfig,
    _measureDots: List where the measure points are added to,
    slider_value: axis value where the 2D measure points are set
  */

  let _pointer = new THREE.Vector3()
  let _raycaster = new THREE.Raycaster()
  let _bbox = event.currentTarget.getBoundingClientRect()
  let dummyvariable

  // coordinates of the 2D-scene
  _pointer.set(
    ((event.clientX - _bbox.x) / event.currentTarget.clientWidth) * 2 - 1,
    -((event.clientY - _bbox.y) / event.currentTarget.clientHeight) * 2 + 1,
    0
  )

  _raycaster.setFromCamera(_pointer, camera)
  const _intersects = _raycaster.intersectObjects(scene.children)

  // Depending how many points have been added to the list
  // and what the configuration of the measurement is the point will
  // be added as the firstm second or third point to the list
  if (Object.keys(_measureDots).length == 0) {
    _measureDots['first'] = reCalcCoordinates(
      viewId,
      _intersects[0].point,
      slider_value,
      geometry,
      volume
    )
  } else if (Object.keys(_measureDots).length == 1) {
    _measureDots['second'] = reCalcCoordinates(
      viewId,
      _intersects[0].point,
      slider_value,
      geometry,
      volume
    )
    // after the second point has been added these functions trigger a method to create a line 3D
    setMeasureCoordinates(_measureDots, viewId) //adds the point to a list that can be accesed anywhere
    eventEmitter.emit('measureFucntionCalled', dummyvariable) //calls a function in 3D view
  } else if (Object.keys(_measureDots).length == 2) {
    if (measureConfig.type == 'Measure') {
      delete _measureDots.second
      _measureDots['first'] = reCalcCoordinates(
        viewId,
        _intersects[0].point,
        slider_value,
        geometry,
        volume
      )
    } else {
      _measureDots['third'] = reCalcCoordinates(
        viewId,
        _intersects[0].point,
        slider_value,
        geometry,
        volume
      )
      // after the third point has been added these functions trigger a method to create a line 3D
      setMeasureCoordinates(_measureDots, viewId) //adds the point to a list that can be accesed anywhere
      eventEmitter.emit('measureFucntionCalled', dummyvariable) //calls a function in 3D view
    }
  } else if (Object.keys(_measureDots).length == 3) {
    //If the measurement starts again, all points are getting erase or overwritten
    delete _measureDots.second
    delete _measureDots.third

    _measureDots['first'] = _intersects[0].point
  }
}
