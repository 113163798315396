/* eslint-disable react/prop-types */
import React from 'react'
import { Typography, Slider, Grid } from '@mui/material'

// brightness and contrast function in view setting
const MainViewBrightContrast = ({
  brightness: brightness,
  setBrightness: setBrightness,
  contrast: contrast,
  setContrast: setContrast
}) => {
  const handleBrightnessChange = (event, newValue) => {
    setBrightness({ brightness: newValue })
  }

  const handleContrastChange = (event, newValue) => {
    setContrast({ contrast: newValue })
  }

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: '#FFFFFF',
          fontFamily: 'Open Sans',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '20px',
          flex: 'none',
          order: 0,
          flexGrow: 0
        }}
      >
        Brightness & Contrast
      </Typography>

      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography
            sx={{ color: '#FFFFFF', fontWeight: 400, fontSize: '12px', lineHeight: '20px' }}
          >
            Brightness
          </Typography>
        </Grid>
        <Grid item xs>
          <Slider
            value={brightness.brightness}
            onChange={handleBrightnessChange}
            valueLabelDisplay="auto"
            min={1}
            max={10}
            step={0.1}
            sx={{
              color: '#FFFFFF',
              width: '95%',
              '& .MuiSlider-thumb': {
                width: 12, // Set the width of the thumb element to a smaller value
                height: 12 // Set the height of the thumb element to a smaller value
              }
            }}
          />
        </Grid>
      </Grid>

      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography
            sx={{ color: '#FFFFFF', fontWeight: 400, fontSize: '12px', lineHeight: '20px' }}
          >
            Contrast
          </Typography>
        </Grid>
        <Grid item xs>
          <Slider
            value={contrast.contrast}
            onChange={handleContrastChange}
            valueLabelDisplay="auto"
            min={0}
            max={10}
            step={0.1}
            sx={{
              color: '#FFFFFF',
              width: '95%',
              '& .MuiSlider-thumb': {
                width: 12, // Set the width of the thumb element to a smaller value
                height: 12 // Set the height of the thumb element to a smaller value
              },
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px'
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default MainViewBrightContrast
