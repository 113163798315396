/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import IndicationButton from './IndicationButton'
import FractureLocationButton from './FractureLocationButton'

const CaseDetailsBox = ({
  indication,
  setIndication,
  setFractureLocation,
  indicationSelected,
  setIndicationSelected,
  locationSelected,
  setLocationSelected
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleIndicationClick = () => {
    setIndicationSelected(true)
  }

  return (
    <>
      <Box
        component="button"
        onClick={handleIndicationClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          boxSizing: 'border-box',
          width: '240px',
          height: '50px', // Reduced height
          background: isHovered ? '#368036' : '#2C622C', // Change color on hover
          border: isHovered ? '2px solid #2C622C' : '2px solid transparent',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          marginTop: '20px',
          transition: 'background-color 0.3s' // Smooth transition for hover effect
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '32px',
            color: '#FFFFFF'
          }}
        >
          Specify Case Details
        </Typography>
      </Box>

      {indicationSelected && (
        <IndicationButton
          setIsClicked={setIndicationSelected}
          setIndication={setIndication}
          setLocationSelected={setLocationSelected}
        />
      )}

      {locationSelected && (
        <FractureLocationButton
          indication={indication}
          setFractureLocation={setFractureLocation}
          setLocationSelected={setLocationSelected}
        />
      )}
    </>
  )
}

export default CaseDetailsBox
