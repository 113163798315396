/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
import JSZip from 'jszip'

export const mainViewDownloadButtonHandler = async (sdk, backInfo, buttonState, newToken) => {
  try {
    // Retrieve the document
    const docData = await sdk.data.documents.findById(backInfo['schemaID'], backInfo['documentID'])
    let filename = docData.data.filename
    let fileTokens = [] // Using an array to handle multiple downloads

    switch (buttonState) {
      case 'Image':
        fileTokens.push({ token: docData.data.fileToken, suffix: '_image' })
        break
      case 'Seg':
        fileTokens.push({
          token: newToken === '' ? docData.data.segToken : newToken,
          suffix: '_seg'
        })
        break
      case 'Iso':
        fileTokens.push({
          token: newToken === '' ? docData.data.isoToken : newToken,
          suffix: '_iso'
        })
        break
      case 'Rep': {
        const zip = new JSZip()
        const promises = []

        // Collect .obj files
        for (let label in docData.data.repositionObjects) {
          const token = docData.data.repositionObjects[label]
          const promise = sdk.files.retrieve(token).then((buffer) => {
            zip.file(docData.data.filename + `_${label}.obj`, buffer)
          })
          promises.push(promise)
        }

        // Collect original .obj files
        for (let label in docData.data.ml_prediction.repositionObjects) {
          const token = docData.data.ml_prediction.repositionObjects[label]
          const promise = sdk.files.retrieve(token).then((buffer) => {
            zip.file(docData.data.filename + `_original_${label}.obj`, buffer)
          })
          promises.push(promise)
        }

        // Collect other specified files
        const otherTokens = [
          {
            token: docData.data.regVolToken,
            filename: docData.data.filename + '_registration_volume.nii.gz'
          },
          {
            token: newToken === '' ? docData.data.regToken : newToken,
            filename: docData.data.filename + '_registration.nii.gz'
          }
        ]

        otherTokens.forEach((item) => {
          const promise = sdk.files.retrieve(item.token).then((buffer) => {
            zip.file(item.filename, buffer)
          })
          promises.push(promise)
        })

        // Wait for all files to be fetched and added to the zip
        await Promise.all(promises)

        // Generate the zip file as a blob
        const zipBlob = await zip.generateAsync({ type: 'blob' })
        fileTokens.push({ blob: zipBlob, suffix: '_reposition' })
        break
      }
      default:
        throw new Error('Invalid buttonState or missing fileToken.')
    }

    for (const fileInfo of fileTokens) {
      if (fileInfo.blob) {
        // Inform the user about the current file being downloaded
        window.alert(`Downloading ${filename + fileInfo.suffix}.zip...`)

        const blobURL = URL.createObjectURL(fileInfo.blob)

        const tempLink = window.document.createElement('a')
        tempLink.href = blobURL
        tempLink.setAttribute('download', filename + fileInfo.suffix + '.zip') // Modified for zip extension
        window.document.body.appendChild(tempLink)
        tempLink.click()
        window.document.body.removeChild(tempLink)

        URL.revokeObjectURL(blobURL)
      } else if (fileInfo.token) {
        // Inform the user about the current file being downloaded
        window.alert(`Downloading ${filename + fileInfo.suffix}.nii.gz...`)

        // Retrieve the file as a buffer
        const fileBuffer = await sdk.files.retrieve(fileInfo.token)

        // Convert the buffer into a Blob
        const fileBlob = new Blob([fileBuffer], { type: 'application/x-gzip' })

        // Create a URL for the Blob
        const blobURL = URL.createObjectURL(fileBlob)

        // Trigger a download using a temporary anchor element
        const tempLink = window.document.createElement('a')
        tempLink.href = blobURL
        tempLink.setAttribute('download', filename + fileInfo.suffix + '.nii.gz')
        window.document.body.appendChild(tempLink)
        tempLink.click()
        window.document.body.removeChild(tempLink)

        // Clean up by revoking the Blob URL
        URL.revokeObjectURL(blobURL)
      } else {
        throw new Error('Missing blob or file token.')
      }
    }
  } catch (error) {
    console.error('Error downloading the file:', error)
  }
}
