/* eslint-disable react/prop-types */

export const uploadFileData = async (
  zipFileName,
  zipBlob,
  sdk,
  id,
  fileNameWoExtension,
  setFileLoadingStatus
) => {
  const fileMetaData = await sdk.files.create(zipFileName, zipBlob, {
    tags: [id, fileNameWoExtension]
  })

  const fileSizeInBytes = zipBlob.size
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024)

  console.log('file size : ' + fileSizeInBytes)
  console.log('file size : ' + fileSizeInMB + ' MB')

  setFileLoadingStatus('done')

  return fileMetaData
}
