/* eslint-disable react/prop-types */
import { rqlBuilder } from '@extrahorizon/javascript-sdk'

export const demoDocumentLoader = async (sdk, fileName, fileType) => {
  const schema = await sdk.data.schemas.findByName('Pipeline', {
    rql: rqlBuilder().select(['id', 'name', 'transitions']).build()
  })

  const myRql = rqlBuilder().eq('data.user', 'demo').eq('data.filename', fileName).build()

  const demoDocuments = await sdk.data.documents.findFirst(schema.id, { rql: myRql })

  if (fileType === 'side') {
    const side = demoDocuments.data[fileType]

    return side
  } else {
    const fileToken = demoDocuments.data[fileType]

    let fileUrl = ''

    if (
      fileType === 'repositionObjects' ||
      fileType === 'targetObjects' ||
      fileType === 'isolationObjects' ||
      fileType === 'repositionTransformMatrix'
    ) {
      fileUrl = fileToken
    } else {
      fileUrl = `${
        process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
      }/files/v1/${fileToken}/file`
    }
    return fileUrl
  }
}
