/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button
} from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'
import InfoIcon from '@mui/icons-material/Info'

import DicomInfoBox from './DicomInfoBox'

const DicomSeriesRows = ({ dicomSeriesInfo, setChosenDicom, setIsDicomSelected }) => {
  const [selectedRows, setSelectedRows] = useState([])

  const [showDicomInfoBox, setShowDicomInfoBox] = useState(true)
  if (!dicomSeriesInfo || Object.keys(dicomSeriesInfo).length === 0) {
    return null
  }

  const handleCloseInfoBox = () => {
    setShowDicomInfoBox(false)
  }

  const handleOpenInfoBox = () => {
    setShowDicomInfoBox(true)
  }

  const handleRowClick = (uid) => {
    const newSelectedRows = selectedRows.includes(uid)
      ? selectedRows.filter((rowUid) => rowUid !== uid)
      : [...selectedRows, uid]
    setSelectedRows(newSelectedRows)
  }

  const handleChooseClick = () => {
    if (selectedRows.length === 1 || selectedRows.length === 3) {
      const chosenDicomData = selectedRows.map((uid) => ({
        seriesInstanceUID: uid,
        ...dicomSeriesInfo[uid]
      }))

      console.log(chosenDicomData)

      setChosenDicom(chosenDicomData)
      setIsDicomSelected(false)
    } else {
      window.alert(
        'Please select either one or exactly three DICOM series.(axial, sagittal and coronal)'
      )
    }
  }

  // Sort dicomSeriesInfo by series number in ascending order
  const sortedDicomSeriesInfo = Object.entries(dicomSeriesInfo).sort((a, b) => {
    return a[1].seriesNumber.localeCompare(b[1].seriesNumber, undefined, { numeric: true })
  })

  return (
    <>
      {showDicomInfoBox && <DicomInfoBox onClose={handleCloseInfoBox} />}
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
          backgroundColor: '#303030',
          padding: 3,
          borderRadius: 2,
          maxWidth: '90%',
          maxHeight: '90%',
          overflow: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 2
          }}
        >
          <Typography variant="h6" sx={{ color: '#fff' }}>
            DICOM Database
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<InfoIcon />}
              onClick={handleOpenInfoBox}
              sx={{ marginRight: 1 }} // Add some spacing between buttons
            >
              Info
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CheckIcon />}
              onClick={handleChooseClick}
            >
              Select
            </Button>
          </Box>
        </Box>
        <Box sx={{ backgroundColor: '#303030', padding: 2 }}>
          <TableContainer component={Paper} sx={{ backgroundColor: '#303030' }}>
            <Table sx={{ color: '#fff' }}>
              <TableHead>
                <TableRow sx={{ borderBottom: '1px solid #fff' }}>
                  <TableCell sx={{ color: '#fff' }}>Series #</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Series Description</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Modality</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Size</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedDicomSeriesInfo.map(([uid, info]) => (
                  <TableRow
                    key={uid}
                    selected={selectedRows.includes(uid)}
                    onClick={() => handleRowClick(uid)}
                    hover
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: '#303030',
                      '&:hover': {
                        backgroundColor: '#2C622C'
                      },
                      '&.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: '#2C622C'
                      },
                      borderBottom: '1px solid #fff'
                    }}
                  >
                    <TableCell sx={{ color: '#fff' }}>{info.seriesNumber}</TableCell>
                    <TableCell sx={{ color: '#fff' }}>{info.seriesDescription}</TableCell>
                    <TableCell sx={{ color: '#fff' }}>{info.modality}</TableCell>
                    <TableCell sx={{ color: '#fff' }}>{info.size}</TableCell>
                    <TableCell sx={{ color: '#fff' }}>{info.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  )
}

export default DicomSeriesRows
