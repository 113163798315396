/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Typography, Slider, Grid } from '@mui/material'

const ISOSlider = ({
  setISOThreshold: setISOThreshold,
  ISOThreshold: ISOThreshold,
  volumeRange: volumeRange,
  isoSliderInfo: isoSliderInfo,
  setIsoSliderInfo: setIsoSliderInfo
}) => {
  const [ISOThresholdValue, setISOThresholdValue] = useState({ min: 0.0, max: Infinity })

  const [translatedISOThreshold, setTranslatedISOThreshold] = useState([
    ISOThreshold.min,
    ISOThreshold.max
  ])

  useEffect(() => {
    setTranslatedISOThreshold([ISOThreshold.min, ISOThreshold.max])
  }, [ISOThreshold])

  const handleThresholdChange = (event, newValue) => {
    setISOThreshold({
      min: newValue[0],
      max: newValue[1]
    })
  }

  const updateThresholdRange = (newMin, newMax) => {
    setISOThresholdValue({ min: newMin, max: newMax })
  }

  useEffect(() => {
    if (volumeRange.min !== 0.0 || volumeRange.max !== 0.0) {
      var newThreshold
      if (volumeRange.min < 0) {
        newThreshold = [0, volumeRange.max]
      } else {
        newThreshold = [volumeRange.min, volumeRange.max]
      }
      updateThresholdRange(newThreshold[0], newThreshold[1])

      if (!isoSliderInfo.initialized) {
        setISOThreshold({
          min: isoSliderInfo.min,
          max: isoSliderInfo.max
        })

        setIsoSliderInfo((prevState) => ({
          ...prevState,
          initialized: true
        }))
      }
    }
  }, [])

  return (
    <>
      {
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '20px',
            flex: 'none',
            order: 0,
            flexGrow: 0
          }}
        >
          ISO Threshold Value
        </Typography>
      }

      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography
            sx={{ color: '#FFFFFF', fontWeight: 400, fontSize: '12px', lineHeight: '20px' }}
          >
            Threshold
          </Typography>
        </Grid>
        <Grid item xs>
          <Slider
            value={translatedISOThreshold}
            onChange={handleThresholdChange}
            min={ISOThresholdValue.min} // Use the min from ISOThreshold
            max={ISOThresholdValue.max} // Use the max from ISOThreshold
            step={10}
            sx={{
              color: '#FFFFFF',
              // marginLeft: '10%',
              width: '90%',
              '& .MuiSlider-thumb': {
                width: 12, // Set the width of the thumb element to a smaller value
                height: 12 // Set the height of the thumb element to a smaller value
              },
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '20px'
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ISOSlider
