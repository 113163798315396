/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
import { rqlBuilder } from '@extrahorizon/javascript-sdk'
import { uploadFileData } from './UploadFileData'

export const folderLoader = async (
  zipFileName,
  zipBlob,
  sdk,
  id,
  fileNameWoExtension,
  setBackInfo,
  backInfo,
  transitionName,
  setFileLoadingStatus,
  setDocumentUpdateStatus,
  patientInfo
) => {
  const delay = (ms) => new Promise((res) => setTimeout(res, ms))
  let resultDocument

  const fileMetaData = await uploadFileData(
    zipFileName,
    zipBlob,
    sdk,
    id,
    fileNameWoExtension,
    setFileLoadingStatus
  )

  await delay(1000)

  setDocumentUpdateStatus('progress')

  const schema = await sdk.data.schemas.findByName('Pipeline', {
    rql: rqlBuilder().select(['id', 'name', 'transitions']).build()
  })

  const document = await sdk.data.documents.create(schema.id, {
    filename: '',
    user: '',
    broken_location: '',
    resultFolderToken: '',
    originFileToken: '',
    fileToken: '',
    segToken: '',
    isoToken: '',
    regToken: '',
    regVolToken: '',
    targetVolToken: '',
    targetToken: '',
    patientInfo: {
      gender: patientInfo.gender,
      birthday: patientInfo.birthday
    }
  })

  await delay(1000)

  setBackInfo({
    ...backInfo,
    schemaID: schema.id,
    documentID: document.id
  })

  if (process.env.REMOTE_SERVER == false) {
    await sdk.data.documents.transition(schema.id, document.id, {
      id: schema.findTransitionIdByName(transitionName),
      data: {
        filename: fileNameWoExtension,
        user: id,
        originFileToken: fileMetaData.tokens[0].token
      }
    })
  } else {
    // load second backend
    const backendInfo = {
      schemaID: schema.id,
      documentID: document.id,
      filename: fileNameWoExtension,
      user: id,
      originFileToken: fileMetaData.tokens[0].token
    }

    let startTimestamp = performance.now()

    fetch('/' + transitionName, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(backendInfo)
    })
      .then((response) => {
        let endTimestamp = performance.now()
        let timeElapsed = endTimestamp - startTimestamp

        // console.log(`Fetch request took ${timeElapsed} milliseconds.`)

        if (timeElapsed > 10000 && response.status === 504) {
          console.log('Ignoring gateway timeout after 10 seconds.')
        } else {
          if (!response.ok) {
            throw new Error(`Error ${response.status}: ${response.statusText}`)
          }
        }
      })
      .catch((error) => {
        console.error('Fetch error: ', error.message)

        setDocumentUpdateStatus('none')
        setFileLoadingStatus('none')
        window.alert(error.message)
      })
  }

  // loop until resultDocument.data.test is not null
  while (true) {
    resultDocument = await sdk.data.documents.findById(schema.id, document.id)

    if (resultDocument.data.fileToken !== '') {
      break
    }

    // wait for a short period of time before trying again
    await delay(1000)
  }

  setDocumentUpdateStatus('done')

  return `${
    process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
  }/files/v1/${resultDocument.data.fileToken}/file`
}
