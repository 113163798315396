/* eslint-disable react/prop-types */
import { rqlBuilder } from '@extrahorizon/javascript-sdk'

// load each case based on user id
export const activeCaseLoader = async (sdk, user_id, limit, offset) => {
  const schema = await sdk.data.schemas.findByName('Pipeline', {
    rql: rqlBuilder().select(['id', 'name', 'transitions']).build()
  })

  const myRql = rqlBuilder()
    .or(
      rqlBuilder().eq('data.user', 'demo').intermediate(),
      rqlBuilder().eq('data.user', user_id).intermediate()
    )
    .limit(limit, offset)
    .build()

  const activeCases = await sdk.data.documents.find(schema.id, { rql: myRql })

  return activeCases.data
}

export default activeCaseLoader
