/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react'
import { Box, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { createCaseBackButtonHandler } from './CreateCaseBackButtonHandler'
import { createCaseNextButtonHandler } from './CreateCaseNextButtonHandler'
import ProgressBar from './CreateNewCaseProgressBar'
import DropzoneComponent from './Dropzone'
import { BackNextButton } from '../../../Theme'

import { UserContext } from '../../../App'

import { updateDocument } from './UpdateDocument'
import CaseDetailsBox from './CaseDetailsBox'
import ConfirmBox from './ConfirmBox'

// create case view component
function CreateNewCase({
  buttonType,
  isDemo,
  setDemo,
  isRenderingThreeDisabled,
  setIsRenderingThreeDisabled,
  setIsLoading,
  backInfo,
  setBackInfo,
  setButtonState,
  userInfo,
  filePath,
  setFilePath,
  sdk,
  setCurrentView,
  indication,
  setIndication
}) {
  const [fileLoadingStatus, setFileLoadingStatus] = useState('none') // check file loading status for loading progress bar
  const [documentUpdateStatus, setDocumentUpdateStatus] = useState('none') // check file data to load into extrahorizon
  const [fractureLocation, setFractureLocation] = useState('')
  const [fileName, setFileName] = useState('')
  const progressTitle = 'Preprocessing'

  const [indicationSelected, setIndicationSelected] = useState(false)
  const [locationSelected, setLocationSelected] = useState(false)

  const [showConfirmBox, setShowConfirmBox] = useState(false)

  const {
    setLoadingValue,
    setIsFirstfileSelected,
    setMainView,
    setImplantToken,
    setCurrentButton,
    setIsoSliderInfo,
    setISOThreshold,
    volumeRange,
    setsliderSagittal,
    setsliderCoronal,
    setsliderAxial
  } = useContext(UserContext)

  useEffect(() => {
    if (indication !== '' && fileName !== '') {
      if (documentUpdateStatus === 'done') {
        updateDocument(sdk, backInfo, indication, fractureLocation)
      }

      if (documentUpdateStatus !== 'done') {
        alert('File Loading is incomplete. Please wait until the File loading is complete')
        setIndication('')
        setIndicationSelected(false)
        setLocationSelected(false)
      }
    }
  }, [indication])

  useEffect(() => {
    if (fractureLocation !== '' && fileName !== '') {
      if (documentUpdateStatus === 'done') {
        updateDocument(sdk, backInfo, indication, fractureLocation)
      }
    }
  }, [fractureLocation])

  useEffect(() => {
    if (indication !== '' && fileName !== '' && fractureLocation !== '') {
      // update fracture location to use it later
      setFilePath({
        ...filePath,
        side: fractureLocation
      })

      setShowConfirmBox(true)
    } else {
      setShowConfirmBox(false)
    }
  }, [indication, fileName, fractureLocation])

  const handleConfirmation = (confirm) => {
    if (confirm) {
      setShowConfirmBox(false)
      createCaseNextButtonHandler(
        sdk,
        backInfo,
        setCurrentView,
        setIsLoading,
        indication,
        fractureLocation,
        setLoadingValue,
        setIsFirstfileSelected,
        setMainView,
        setImplantToken,
        setCurrentButton,
        setIsoSliderInfo,
        setISOThreshold,
        volumeRange,
        setsliderSagittal,
        setsliderCoronal,
        setsliderAxial,
        filePath,
        setFilePath
      )
    } else {
      // Handle the case where user cancels the action
      setShowConfirmBox(false) // Hide the ConfirmBox
      setIndication('')
      setFractureLocation('')
    }
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#191919',
          height: '100vh',
          padding: '16px'
        }}
      >
        {/* Heading */}
        <Typography variant="h4" gutterBottom sx={{ color: 'white' }}>
          Create New Patient Case
        </Typography>

        {/* Active Patient Cases */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10em', marginBottom: '1em' }}>
          <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold' }}>
            Upload File
          </Typography>

          {false && (
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <BackNextButton
                startIcon={<ArrowBackIosIcon />}
                sx={{ width: '6em' }}
                onClick={() => createCaseBackButtonHandler()}
              >
                Back
              </BackNextButton>
              <BackNextButton
                endIcon={<ArrowForwardIosIcon />}
                sx={{ width: '6em' }}
                onClick={() =>
                  createCaseNextButtonHandler(
                    sdk,
                    backInfo,
                    setCurrentView,
                    setIsLoading,
                    indication,
                    fractureLocation,
                    setLoadingValue,
                    setIsFirstfileSelected,
                    setMainView,
                    setImplantToken,
                    setCurrentButton,
                    setIsoSliderInfo,
                    setISOThreshold,
                    volumeRange,
                    setsliderSagittal,
                    setsliderCoronal,
                    setsliderAxial,
                    filePath,
                    setFilePath
                  )
                }
              >
                Next
              </BackNextButton>
            </Box>
          )}
        </Box>

        <Box>
          <Typography variant="h10" gutterBottom sx={{ color: 'white' }}>
            Upload new patient cases for review. Supports Nifti and DICOM files.
            <br />
            <br />
            Use folder selection for direct uploads, or .zip for grouped files.
          </Typography>
        </Box>

        {fileLoadingStatus === 'none' && (
          <DropzoneComponent
            buttonType={buttonType}
            isDemo={isDemo}
            setDemo={setDemo}
            isRenderingThreeDisabled={isRenderingThreeDisabled}
            setIsRenderingThreeDisabled={setIsRenderingThreeDisabled}
            backInfo={backInfo}
            setBackInfo={setBackInfo}
            setButtonState={setButtonState}
            userInfo={userInfo}
            filePath={filePath}
            setFilePath={setFilePath}
            sdk={sdk}
            setFileLoadingStatus={setFileLoadingStatus}
            setDocumentUpdateStatus={setDocumentUpdateStatus}
            setFileName={setFileName}
          />
        )}

        {showConfirmBox && <ConfirmBox setConfirm={handleConfirmation} />}

        {fileLoadingStatus !== 'none' && (
          <ProgressBar title={fileName} status={fileLoadingStatus} />
        )}

        {documentUpdateStatus !== 'none' && (
          <ProgressBar title={progressTitle} status={documentUpdateStatus} />
        )}

        {fileLoadingStatus === 'done' && documentUpdateStatus === 'done' && (
          <CaseDetailsBox
            indication={indication}
            setIndication={setIndication}
            setFractureLocation={setFractureLocation}
            indicationSelected={indicationSelected}
            setIndicationSelected={setIndicationSelected}
            locationSelected={locationSelected}
            setLocationSelected={setLocationSelected}
          />
        )}
      </Box>
    </>
  )
}

export default CreateNewCase
