/* eslint-disable react/prop-types */
import { updateDocument } from './UpdateDocument'

export const createCaseNextButtonHandler = async (
  sdk,
  backInfo,
  setCurrentView,
  setIsLoading,
  indication,
  fractureLocation,
  setLoadingValue,
  setIsFirstfileSelected,
  setMainView,
  setImplantToken,
  setCurrentButton,
  setIsoSliderInfo,
  setISOThreshold,
  volumeRange,
  setsliderSagittal,
  setsliderCoronal,
  setsliderAxial,
  filePath,
  setFilePath
) => {
  // conditional statements to get necessary information before move to main view
  if (backInfo.documentID !== '') {
    setMainView('whole')
    setImplantToken({ token: '', description: '' })

    setCurrentButton('None')

    setIsoSliderInfo({
      initialized: false,
      min: null,
      max: null
    })

    setISOThreshold({
      min: volumeRange.min,
      max: volumeRange.max
    })

    setsliderSagittal({
      max: 0,
      current: -1
    })

    setsliderCoronal({
      max: 0,
      current: -1
    })

    setsliderAxial({
      max: 0,
      current: -1
    })

    setFilePath({
      ...filePath,
      seg: '',
      iso: '',
      reg: '',
      reg_image: '',
      regObj: '',
      target_image: '',
      target: '',
      repositionObjects: {},
      isolationObjects: {},
      repositionTransformMatrix: {},
      manualRepositionTransformMatrix: {},
      targetObjects: {},
      side: '',
      model: {
        image: filePath.image,
        seg: '',
        iso: '',
        regObj: '',
        isolationObjects: {},
        repositionObjects: {},
        repositionTransformMatrix: {},
        targetObjects: {}
      }
    })

    const resultDocument = await sdk.data.documents.findById(backInfo.schemaID, backInfo.documentID)

    if (resultDocument.data.fileToken !== '') {
      if (indication !== '' && fractureLocation !== '') {
        await updateDocument(sdk, backInfo, indication, fractureLocation)

        setIsFirstfileSelected(true)
        setCurrentView('main')
        setIsLoading(true)
        setLoadingValue(50)

        // run segmentation function in backend API in advance

        let backendSegInfo = {}

        if (indication == 'Clavicle') {
          // load second backend
          backendSegInfo = {
            schemaID: backInfo.schemaID,
            documentID: backInfo.documentID,
            indication: indication
          }
        } else {
          // Face

          backendSegInfo = {
            schemaID: backInfo.schemaID,
            documentID: backInfo.documentID,
            indication: indication,
            architecture: '3d_lowres'
          }
        }

        let startTimestamp = performance.now()

        fetch('/segmentation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(backendSegInfo)
        })
          .then((response) => {
            let endTimestamp = performance.now()
            let timeElapsed = endTimestamp - startTimestamp

            // console.log(`Fetch request took ${timeElapsed} milliseconds.`)

            if (timeElapsed > 10000 && response.status === 504) {
              console.log('Ignoring gateway timeout after 10 seconds.')
            } else {
              if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`)
              }
            }
          })
          .catch((error) => {
            console.error('Fetch error: ', error.message)

            window.alert(error.message)
            setLoadingValue(-1)
          })
      } else {
        console.log('you must choose indication and fracture location')
        window.alert('you must choose indication and fracture location')
      }
    } else {
      console.log('not done document')
    }
  } else {
    console.log('not done preprocess')
  }
}
