/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Box, useTheme, styled, Grid } from '@mui/material'
import * as THREE from 'three'
import { Volume } from 'three/addons/misc/Volume'

import { UserContext } from '../../App'

import { DDSLoader } from 'three-stdlib'
import { useEffect, useRef, useState, useContext } from 'react'
import { NIFTILoader } from './renderComponents/NIFTILoader'
import dat from 'dat.gui'
import RenderingThreeScene from './renderComponents/RenderingThreeScene'
import RenderingThreeSceneImplant from './renderComponents/RenderingThreeSceneImplant'
import RenderingThreeSceneReposition from './renderComponents/RenderingThreeSceneReposition'
import RenderingThreeSceneTargetAnatomy from './renderComponents/RenderingThreeSceneTargetAnatomy'
import AnimationTargetAnatomy from './renderComponents/AnimationTargetAnatomy'
// import RenderingThreeScene2D from './renderComponents/RenderingThreeScene2D'
import RenderingAxialView from './renderComponents/RenderingAxialView'
import RenderingCoronalView from './renderComponents/RenderingCoronalView'
import RenderingSagittalView from './renderComponents/RenderingSagittalView'

import { AxialSlider, CoronalSlider, SagittalSlider } from './renderComponents/Slider'

import { EventEmitter } from 'events'
import AutoImplantInfoBox from './renderComponents/AutoImplantInfoBox'
import AutomatedPlacementInfoBox from './functions/InfoBoxes/AutomatedPlacementInfoBox'
import RepositionTranslationInfoBox from './functions/InfoBoxes/RepositionTranslationInfoBox'
import ImplantTranslationInfoBox from './functions/InfoBoxes/ImplantTranslationInfoBox'
import PositionInfoBox from './renderComponents/PositionInfoBox'

import CameraCompass from './renderComponents/CameraCompass'

import { OBJLoader } from 'three/addons/loaders/OBJLoader.js'
import { colorMapArray } from './renderComponents/ColorMap'

THREE.DefaultLoadingManager.addHandler(/\.dds$/i, new DDSLoader())

const MainRenderBox = styled(Box)(({ theme }) => ({
  top: `calc(${theme.sizes.nav.top} + 60px)`, // Add additional space here, adjust as needed
  left: theme.sizes.nav.left,
  bottom: '0px',
  right: '0px',
  padding: '10px',
  paddingTop: '40px',
  marginLeft: '5px',
  color: '#fff',
  flex: '1'
}))

const volConfig = { sagittal: 0, axial: 0, coronal: 0 }

const config3D = {
  volConfig: {
    clim1: 0,
    clim2: 0,
    renderStyle: 'iso',
    isoThreshold: 500,
    maxisoThreshold: 1000000,
    colorMap: 'gray',
    ctOpacity: 0.5
  },
  segConfig: {
    segClim1: 0.4,
    segClim2: 1.4,
    segRenderStyle: 'iso',
    segThreshold: 0.6,
    segColorMap: 'viridis'
  },

  visibility: {
    ctVisibilityControl: true,
    segVisibilityControl: true
  }
}

const ViewBox = styled(Box)(() => ({
  backgroundColor: '#121212',
  height: '100%'
}))

export const eventEmitter = new EventEmitter()

function TwoDViews({
  filePath: filePath,
  buttonState: buttonState,
  setIsLoading: setIsLoading,
  isLoading: isLoading,
  setVolume: setVolume,
  volume: volume,
  setLabel: setLabel,
  label: label,
  mainView: currentView,
  setMainView: setCurrentView,
  on3DRender: on3DRender,
  setOn3DRender: setOn3DRender,
  measureConfig: measureConfig,
  Radius: Radius,
  segmentConfig: segmentConfig,
  selectedSegment: selectedSegment,
  showToolBox: showToolBox,
  brightness: brightnessTool, //not to confuse variable name (from brightness in setting)
  contrast: contrastTool, // not to confuse variable name (from contrast in setting)
  volumeRange: volumeRange,
  setVolumeRange: setVolumeRange,
  labelThresholdRange: labelThresholdRange,
  movementConfig: movementConfig,
  implantToken: implantToken,
  isSegmentationVisible: isSegmentationVisible,
  isVolumeVisible: isVolumeVisible,
  ISOThreshold: thresholdTool,
  isoSliderInfo: isoSliderInfo,
  setIsoSliderInfo: setIsoSliderInfo,
  currentPanel: currentPanel,
  setMovementConfig: setMovementConfig,
  setCurrentMovementMenu: setCurrentMovementMenu,
  centerPoint: centerPoint,
  autoPlacementInfo: autoPlacementInfo,
  setAutoPlacementInfo: setAutoPlacementInfo,
  isMovementExpanded: isMovementExpanded,
  automatedPlacementStatus: automatedPlacementStatus,
  setAutomatedPlacementStatus: setAutomatedPlacementStatus,
  isoThresholdMinimumDefault: isoThresholdMinimumDefault,
  selectedView: selectedView,
  indication: indication
}) {
  const theme = useTheme()
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const mainRenderBoxRef = useRef(null)
  const mainGUIRef = useRef(null)

  const viewBoxRef1 = useRef(null)
  const viewBoxRef2 = useRef(null)
  const viewBoxRef3 = useRef(null)
  const viewBoxRef4 = useRef(null)
  const threeDFolderRef = useRef(null)
  const [is3DWholeView, setis3DWholeView] = useState(false)
  const [doubleClickRendering, setDoubleClickRendering] = useState(false)
  const [IsLabelChange, setIsLabelChange] = useState(false)

  const [predictedData, setPredictedData] = useState(null)

  const [rotationVector, setRotationVector] = useState(null)
  const [crosshairsPositionXY, setCrosshairsPositionXY] = useState({
    x: 0,
    y: 0,
    z: 0
  })
  const [crosshairsPositionXX, setCrosshairsPositionXX] = useState({
    x: 0,
    y: 0,
    z: 0
  })
  const [crosshairsPositionYX, setCrosshairsPositionYX] = useState({
    x: 0,
    y: 0,
    z: 0
  })
  const [crosshairsPositionYY, setCrosshairsPositionYY] = useState({
    x: 0,
    y: 0,
    z: 0
  })
  const [crosshairsPositionZX, setCrosshairsPositionZX] = useState({
    x: 0,
    y: 0,
    z: 0
  })
  const [crosshairsPositionZY, setCrosshairsPositionZY] = useState({
    x: 0,
    y: 0,
    z: 0
  })
  const [volumeDimensions, setVolumeDimensions] = useState({ x: null, y: null, z: null })
  const [originalVolume, setOriginalVolume] = useState(null)
  const [volume2D, setVolume2D] = useState(null)
  const [isIntensityChange, SetIsIntensityChange] = useState(false)
  const {
    loadingValue,
    setLoadingValue,
    checkedValues,
    selectedValue,
    setSelectedValue,
    uniqueValues,
    isSegmentToolAffected,
    setIsSegmentToolAffected,
    isUndo,
    setIsUndo,
    isRedo,
    setIsRedo,
    undoStack,
    setUndoStack,
    redoStack,
    setRedoStack,
    sliderSagittal,
    setsliderSagittal,
    sliderAxial,
    setsliderAxial,
    sliderCoronal,
    setsliderCoronal,
    setRepoScene,
    handleWarnDialog,
    setCurrentButton,
    labelColorSwitch,
    cameraResetClicked,
    setCameraResetClicked,
    isRestore,
    setIsRestore,
    setTranslationInfo,
    translationInfo,
    viewBoxOn,
    setViewBoxOn,
    animationOn,
    crosshairsOn,
    setManualRepositionMatrix,
    setObjectPositionInfo,
    sdk,
    setNiftiProgress,
    SetAllViewLoaded,
    rotateSpeed
  } = useContext(UserContext)

  const [currentState, setCurrentState] = useState(null)

  // useState for cursorstyle
  const [cursorStyle, setCursorStyle] = useState({ cursor: 'default' })
  const [currentFiles, setCurrentFiles] = useState({
    image: '',
    label: ''
  })

  // let oldImagePath = ''
  let on3DRenderController
  let stats = null

  // useState to keep latest 3D scene, such as camera position, rotate, and zoom
  const [threeSceneInfo, setThreeSceneInfo] = useState({
    initialized: false,
    controlsTarget: new THREE.Vector3(),
    camera: null,
    set: false,
    originalCamera: null,
    originalControls: null
  })

  const [implantInfo, setImplantInfo] = useState({
    initialized: false,
    implantPosition: new THREE.Vector3(),
    implantRotation: new THREE.Vector3()
  })

  const [finalLoad, setFinalLoad] = useState({
    beforeSliderAxial: false,
    afterSliderAxial: true
  })

  const [coronalViewLoaded, setCoronalViewLoaded] = useState(false)
  const [sagittalViewLoaded, setSagittalViewLoaded] = useState(false)

  const [repLoadedObjects, setRepLoadedObjects] = useState({})
  const [isoLoadedObjects, setIsoLoadedObjects] = useState({})
  const [regObjLoaded, setRegObjLoaded] = useState([])

  const handleViewClick = (view) => {
    if (view !== 'view4') {
      if (currentView === 'whole' || currentView.includes('dual')) {
        setCurrentView(view)
      } else {
        setCurrentView('whole')
      }
    } else {
      if (buttonState !== 'Target' && buttonState !== 'Implant') {
        if (currentView === 'whole' || currentView.includes('dual')) {
          setCurrentView('view4')
          setViewBoxOn(false)
          if (on3DRender.on === true) {
            on3DRenderController = threeDFolderRef.current.__controllers.find(
              (controller) => controller.property === 'on'
            )

            on3DRenderController.setValue(false)

            setDoubleClickRendering(true)
          }
        } else {
          setCurrentView('whole')

          if (on3DRender.on === true) {
            on3DRenderController = threeDFolderRef.current.__controllers.find(
              (controller) => controller.property === 'on'
            )

            on3DRenderController.setValue(false)

            setDoubleClickRendering(true)
          }
        }
      }
    }
  }

  function deepCopyVolume(original) {
    const copiedVolume = new Volume()

    // Copying typed arrays based on datatypeCode
    if (original.data instanceof Uint8Array) {
      copiedVolume.data = new Uint8Array(original.data)
    } else if (original.data instanceof Int16Array) {
      copiedVolume.data = new Int16Array(original.data)
    } else if (original.data instanceof Int32Array) {
      copiedVolume.data = new Int32Array(original.data)
    } else if (original.data instanceof Float32Array) {
      copiedVolume.data = new Float32Array(original.data)
    } else if (original.data instanceof Float64Array) {
      copiedVolume.data = new Float64Array(original.data)
    } else if (original.data instanceof Int8Array) {
      copiedVolume.data = new Int8Array(original.data)
    } else if (original.data instanceof Uint16Array) {
      copiedVolume.data = new Uint16Array(original.data)
    } else if (original.data instanceof Uint32Array) {
      copiedVolume.data = new Uint32Array(original.data)
    } else {
      // Fallback for non-typed array data
      copiedVolume.data = [...original.data]
    }

    // Copy matrices and vectors
    copiedVolume.matrix = original.matrix.clone()
    copiedVolume.inverseMatrix = original.inverseMatrix.clone()

    // Copy simple properties
    // copiedVolume.windowLow = original.windowLow
    // copiedVolume.windowHigh = original.windowHigh
    copiedVolume.lowerThreshold = original.lowerThreshold
    copiedVolume.upperThreshold = original.upperThreshold

    // Copy array properties
    copiedVolume.dimensions = [...original.dimensions]
    copiedVolume.spacing = [...original.spacing]
    copiedVolume.RASDimensions = [...original.RASDimensions]
    copiedVolume.axisOrder = [...original.axisOrder]

    copiedVolume.xLength = copiedVolume.dimensions[0]
    copiedVolume.yLength = copiedVolume.dimensions[1]
    copiedVolume.zLength = copiedVolume.dimensions[2]

    // Copy any additional properties if necessary
    copiedVolume.id = original.id

    const minMax = copiedVolume.computeMinMax()
    const min = minMax[0]
    const max = minMax[1]
    // attach the scalar range to the volume
    copiedVolume.windowLow = min
    copiedVolume.windowHigh = max

    return copiedVolume
  }

  function extractNumber(str) {
    const matches = str.match(/\d+/)
    return matches ? parseInt(matches[0], 10) : null
  }

  // Function to load 3D objects using OBJLoader and update state
  const loadImage3DObjects = (filePathKey, setLoadedObjects, type) => {
    const colors = colorMapArray.map((c) => c / 255)
    const labelLoader = new OBJLoader()
    const objectNames = Object.keys(filePathKey)

    // Create promises for loading each object
    const loadPromises = objectNames.map((objectName) => {
      return new Promise((resolve, reject) => {
        labelLoader.load(
          `/files/v1/${filePathKey[objectName]}/file`,
          (object) => {
            object.traverse((child) => {
              if (child.isMesh) {
                // Set object properties, such as scale and materials
                child.scale.set(1.0, 1.0, 1.0)
                const labelColors = []

                if (type === 'Image') {
                  const neutralColor = new THREE.Color(1.0, 1.0, 1.0)

                  for (let i = 0, n = child.geometry.attributes.position.count; i < n; ++i) {
                    labelColors.push(neutralColor.r, neutralColor.g, neutralColor.b)
                  }
                } else {
                  var k = extractNumber(objectName)
                  for (let i = 0, n = child.geometry.attributes.position.count; i < n; ++i) {
                    labelColors.push(colors[k * 3], colors[k * 3 + 1], colors[k * 3 + 2])
                  }
                }
                child.geometry.setAttribute(
                  'color',
                  new THREE.Float32BufferAttribute(labelColors, 3)
                )
                child.material = new THREE.MeshStandardMaterial({ vertexColors: true })
                child.material.reflectivity = 1
                child.material.color.isColor = false
                child.geometry.getAttribute('color').needsUpdate = true
                child.name = objectName
              }
            })

            // Resolve with object mapped by its label name
            resolve({
              [objectName]: {
                object,
                token: filePathKey[objectName] // Include the token in the resolved value
              }
            })
          },
          undefined,
          (error) => {
            console.error(`Error loading ${objectName}:`, error)
            reject(error)
          }
        )
      })
    })

    // Update state once all objects are loaded
    Promise.all(loadPromises)
      .then((objectsArray) => {
        // Merge all objects into a single dictionary
        const objectsDict = objectsArray.reduce((acc, obj) => ({ ...acc, ...obj }), {})
        setLoadedObjects(objectsDict)
      })
      .catch((error) => {
        console.error('Error loading objects:', error)
      })
  }

  const loadRegObj = (filePathKey, setLoadedObjects) => {
    const BoneLoader = new OBJLoader()
    const scaleValue = 1.0 // Define the scale value as needed

    return new Promise((resolve, reject) => {
      BoneLoader.load(
        filePathKey,
        (object) => {
          // Traverse the loaded object to configure its properties
          object.traverse((child) => {
            if (child.isMesh) {
              child.material.color.set(0x296639) // Set the color
              child.material.visible = false // Set visibility
              child.scale.set(scaleValue, scaleValue, scaleValue) // Set scale
            }
          })

          object.name = 'objBone' // Set the object name

          // Update state with the loaded object and its token in a list format
          setLoadedObjects([
            { object, token: filePathKey } // Add the object and token to the list
          ])

          resolve() // Resolve the promise after updating the state
        },
        undefined,
        (error) => {
          console.error(`Error loading ${filePathKey}:`, error)
          reject(error) // Reject the promise if an error occurs
        }
      )
    })
  }

  useEffect(() => {
    if (currentView === 'whole') {
      if (!coronalViewLoaded && !sagittalViewLoaded) {
        setIsLoading(true)
        setLoadingValue(50)
        setNiftiProgress((prevState) => ({
          ...prevState,
          loadingStatus: 'rendering'
        }))
      }
      if (on3DRender.on === true) {
        on3DRenderController = threeDFolderRef.current.__controllers.find(
          (controller) => controller.property === 'on'
        )

        on3DRenderController.setValue(false)

        setDoubleClickRendering(true)
      }

      if (selectedView && selectedView !== 'none') {
        SetIsIntensityChange(true)
      }
    } else if (currentView === 'view4 whole') {
      handleViewClick('view4')
    } else if (currentView.includes('dual')) {
      if (on3DRender.on === true) {
        on3DRenderController = threeDFolderRef.current.__controllers.find(
          (controller) => controller.property === 'on'
        )

        on3DRenderController.setValue(false)

        setDoubleClickRendering(true)
      }

      if (selectedView && selectedView !== 'none') {
        SetIsIntensityChange(true)
      }
    } else if (currentView === 'view1' || currentView === 'view2' || currentView === 'view3') {
      if (selectedView && selectedView !== 'none') {
        SetIsIntensityChange(true)
      }
    }
  }, [currentView])

  // add loading value useEffect for Loading progress
  useEffect(() => {
    if (isLoading === true) {
      if (loadingValue === 100) {
        if (buttonState !== 'Rep' && buttonState !== 'Target' && buttonState !== 'Implant') {
          setIsLoading(false)
          setLoadingValue(0)
          handleWarnDialog(false)
          setCurrentButton(buttonState)
          setNiftiProgress({
            loadingStatus: 'done',
            filesize: 0
          })
        }
      }

      // server error
      if (loadingValue === -1) {
        setIsLoading(false)
        setNiftiProgress({
          loadingStatus: 'done',
          filesize: 0
        })
      }
    } else {
      setLoadingValue(0)
      setIsLoading(false)
    }
  }, [loadingValue])

  // handle when user check and uncheck label box
  useEffect(() => {
    if (
      Object.keys(checkedValues).length !== 0 &&
      buttonState !== 'Rep' &&
      buttonState !== 'Target' &&
      buttonState !== 'Implant'
    ) {
      setIsLabelChange(true)
    }
  }, [checkedValues])

  useEffect(() => {
    if (
      label !== null &&
      buttonState !== 'Rep' &&
      buttonState !== 'Target' &&
      buttonState !== 'Implant'
    ) {
      setIsLabelChange(true)
    }
  }, [uniqueValues])

  useEffect(() => {
    if (IsLabelChange === true) {
      setIsLabelChange(false)
    }
  }, [IsLabelChange])

  // useEffect to change cursor type based on tool box
  useEffect(() => {
    if (showToolBox === 'Brush') {
      setCursorStyle({ cursor: `url(${process.env.PUBLIC_URL + '/brush.png'}) 28 7, auto` })
      // url, cursor location
    } else if (showToolBox === 'Eraser') {
      setCursorStyle({ cursor: `url(${process.env.PUBLIC_URL + '/eraser.png'}) 7 28, auto` })
    } else if (showToolBox === 'Scissors') {
      setCursorStyle({ cursor: `url(${process.env.PUBLIC_URL + '/scissors.png'}) 28 7, auto` })
    } else {
      setCursorStyle({ cursor: 'default' })
    }
  }, [showToolBox])

  useEffect(() => {
    setWidth(mainRenderBoxRef.current.clientWidth)
    setHeight(mainRenderBoxRef.current.clientHeight)

    setVolume(null)
    setLabel(null)
  }, [])

  useEffect(() => {
    if (on3DRender.on === true) {
      if (buttonState != 'None' && mainGUIRef.current) {
        if (typeof mainGUIRef.current.__folders['Volume 3D'] === 'undefined') {
          const vol3DFolder = mainGUIRef.current.addFolder('Volume 3D')
          vol3DFolder.hide()
        }
      }

      if (buttonState != 'Image' && buttonState != 'None' && mainGUIRef.current) {
        if (typeof mainGUIRef.current.__folders['Segmentation 3D'] === 'undefined') {
          const seg3DFolder = mainGUIRef.current.addFolder('Segmentation 3D')
          seg3DFolder.hide()
        }
      }
    } else {
      if (mainGUIRef.current) {
        if (buttonState != 'None' && mainGUIRef.current) {
          if (typeof mainGUIRef.current.__folders['Volume 3D'] !== 'undefined') {
            mainGUIRef.current.removeFolder(mainGUIRef.current.__folders['Volume 3D'])
          }
        }

        if (buttonState != 'Image' && buttonState != 'None' && mainGUIRef.current) {
          if (typeof mainGUIRef.current.__folders['Segmentation 3D'] !== 'undefined') {
            mainGUIRef.current.removeFolder(mainGUIRef.current.__folders['Segmentation 3D'])
          }
        }

        const statsContainers = document.querySelectorAll('[id="statsContainer"]')

        if (statsContainers) {
          stats === null
        }

        statsContainers.forEach((container) => {
          if (container.parentNode) {
            container.parentNode.removeChild(container)
          }
        })

        const render3DContainer = document.getElementById('3DBox')
        if (render3DContainer && render3DContainer.parentNode === document.body) {
          document.body.removeChild(render3DContainer)
        }
      }
    }
  }, [on3DRender])

  useEffect(() => {
    if (mainGUIRef.current) {
      if (
        !is3DWholeView &&
        buttonState !== 'Rep' &&
        buttonState !== 'Target' &&
        buttonState !== 'Implant'
      ) {
        setCurrentView('view1')
      }
    }
  }, [is3DWholeView])

  useEffect(() => {
    if (width === 0 && height === 0) {
      return
    }
    if (!mainRenderBoxRef.current) {
      return
    }
    if (isLoading === true) {
      volConfig.sagittal = -1
      volConfig.axial = -1
      volConfig.coronal = -1

      // define 3D view
      config3D.volConfig.clim1 = 143.556
      config3D.volConfig.clim2 = 419.736
      config3D.volConfig.renderStyle = 'iso'
      config3D.volConfig.isoThreshold = 0
      config3D.volConfig.maxisoThreshold = 10000
      config3D.volConfig.colorMap = 'gray'
      config3D.volConfig.colorTransfer = [
        24, -3024, 0, 0, 0, 143.556, 0.615686, 0.356863, 0.184314, 166.222, 0.882353, 0.603922,
        0.290196, 214.389, 1, 1, 1, 419.736, 1, 0.937033, 0.954531, 3071, 0.827451, 0.658824, 1
      ]
      config3D.segConfig.segClim1 = 0.4
      config3D.segConfig.segClim2 = 1.4
      config3D.segConfig.segRenderStyle = 'iso'
      config3D.segConfig.segThreshold = 0.6
      config3D.segConfig.segColorMap = 'viridis'

      config3D.visibility.ctVisibilityControl = true
      config3D.visibility.segVisibilityControl = true

      if (buttonState == 'Seg' || buttonState == 'Iso') {
        config3D.volConfig.ctOpacity = 0.5
      } else {
        config3D.volConfig.ctOpacity = 1.0
      }
    }

    if (buttonState != 'None') {
      let labelFilePath = ''
      let imageFilePath = ''

      let predictedLabelPath = ''

      if (buttonState == 'Image') {
        if (filePath['image'] === '') {
          return
        }
        imageFilePath = filePath['image']

        setLabel(null)
      } else if (buttonState == 'Seg') {
        if (filePath['seg'] === '') {
          return
        }

        labelFilePath = filePath['seg']
        imageFilePath = filePath['image']

        predictedLabelPath = filePath.model.seg
      } else if (buttonState == 'Iso') {
        if (filePath['iso'] === '') {
          return
        }
        labelFilePath = filePath['iso']
        imageFilePath = filePath['image']

        predictedLabelPath = filePath.model.iso
      } else if (buttonState == 'Rep') {
        if (Object.keys(filePath['repositionObjects']).length === 0) {
          return
        }

        labelFilePath = ''
        imageFilePath = filePath['reg_image']

        predictedLabelPath = ''

        setLabel(null)
        // setIsLoading(false)

        if (Object.keys(repLoadedObjects).length !== 0) {
          const repositionObjects = filePath['repositionObjects']

          const tokensMatch = Object.keys(repositionObjects).every((key) => {
            return repLoadedObjects[key]?.token === repositionObjects[key]
          })

          // Reload objects if tokens do not match
          if (!tokensMatch) {
            setRepLoadedObjects({})
            loadImage3DObjects(filePath['repositionObjects'], setRepLoadedObjects, 'Rep')
          }
        } else {
          // Initial load when isoLoadedObjects is empty
          loadImage3DObjects(filePath['repositionObjects'], setRepLoadedObjects, 'Rep')
        }
      } else if (buttonState == 'Target') {
        if (
          Object.keys(filePath['repositionObjects']).length === 0 ||
          Object.keys(filePath['isolationObjects']).length === 0
        ) {
          return
        }

        setLabel(null)

        if (Object.keys(repLoadedObjects).length !== 0) {
          const repositionObjects = filePath['repositionObjects']

          const tokensMatch = Object.keys(repositionObjects).every((key) => {
            return repLoadedObjects[key]?.token === repositionObjects[key]
          })

          // Reload objects if tokens do not match
          if (!tokensMatch) {
            setRepLoadedObjects({})
            loadImage3DObjects(filePath['repositionObjects'], setRepLoadedObjects, 'Rep')
          }
        } else {
          // Initial load when isoLoadedObjects is empty
          loadImage3DObjects(filePath['repositionObjects'], setRepLoadedObjects, 'Rep')
        }

        if (Object.keys(isoLoadedObjects).length !== 0) {
          const isolationObjects = filePath['isolationObjects']

          const tokensMatch = Object.keys(isolationObjects).every((key) => {
            return isoLoadedObjects[key]?.token === isolationObjects[key]
          })

          // Reload objects if tokens do not match
          if (!tokensMatch) {
            setIsoLoadedObjects({})
            loadImage3DObjects(filePath['isolationObjects'], setIsoLoadedObjects, 'Iso')
          }
        } else {
          // Initial load when isoLoadedObjects is empty
          loadImage3DObjects(filePath['isolationObjects'], setIsoLoadedObjects, 'Iso')
        }
      } else {
        if (Object.keys(filePath['repositionObjects']).length === 0 || filePath['regObj'] === '') {
          return
        }

        labelFilePath = ''
        imageFilePath = ''

        setLabel(null)
        // setIsLoading(false)

        if (Object.keys(repLoadedObjects).length !== 0) {
          const repositionObjects = filePath['repositionObjects']

          const tokensMatch = Object.keys(repositionObjects).every((key) => {
            return repLoadedObjects[key]?.token === repositionObjects[key]
          })

          // Reload objects if tokens do not match
          if (!tokensMatch) {
            setRepLoadedObjects({})
            loadImage3DObjects(filePath['repositionObjects'], setRepLoadedObjects, 'Rep')
          }
        } else {
          // Initial load when isoLoadedObjects is empty
          loadImage3DObjects(filePath['repositionObjects'], setRepLoadedObjects, 'Rep')
        }

        // whole bone
        if (Object.keys(regObjLoaded).length !== 0) {
          const regObj = filePath['regObj']

          // Compare the token of the first (or relevant) object in regObjLoaded to the regObj token
          const tokensMatch = regObjLoaded[0]?.token === regObj

          // Reload objects if tokens do not match
          if (!tokensMatch) {
            setRegObjLoaded([])
            loadRegObj(filePath['regObj'], setRegObjLoaded)
          }
        } else {
          // Initial load when isoLoadedObjects is empty
          console.log('first whole registration bone load')
          loadRegObj(filePath['regObj'], setRegObjLoaded)
        }
      }

      if (isLoading === true && labelFilePath !== '') {
        if (buttonState !== 'Rep' && buttonState !== 'Target' && buttonState !== 'Implant') {
          const labManager = new THREE.LoadingManager()
          const labelLoader = new NIFTILoader(
            labManager,
            labelFilePath,
            setIsLoading,
            setLoadingValue,
            sdk,
            null,
            null
          )

          labelLoader.load(labelFilePath, function (loadedLabel) {
            setPredictedData(new Float32Array(loadedLabel.data))
            setLabel(loadedLabel)
          })

          setNiftiProgress((prevState) => ({
            ...prevState,
            loadingStatus: 'rendering'
          }))

          // const labPredictedManager = new THREE.LoadingManager()
          // const labelPredictedLoader = new NIFTILoader(
          //   labPredictedManager,
          //   predictedLabelPath,
          //   null,
          //   null,
          //   sdk,
          //   null,
          //   null
          // )

          // labelPredictedLoader.load(predictedLabelPath, function (loadedLabel) {
          //   setPredictedData(new Float32Array(loadedLabel.data))
          // })
        } else {
          setLabel(null)

          setPredictedData(null)
        }
      }

      if (isLoading === true && imageFilePath !== '') {
        if (currentFiles.image !== imageFilePath) {
          if (buttonState !== 'Rep' && buttonState !== 'Target' && buttonState !== 'Implant') {
            setCurrentFiles((prevFiles) => ({
              ...prevFiles, // Spread the previous state to keep other properties
              image: imageFilePath // Update only the image property
            }))

            const volManager = new THREE.LoadingManager()

            const volLoader = new NIFTILoader(
              volManager,
              imageFilePath,
              setIsLoading,
              setLoadingValue,
              sdk,
              'volume',
              setNiftiProgress
            )

            volLoader.load(imageFilePath, function (loadedVolume) {
              setNiftiProgress((prevState) => ({
                ...prevState,
                loadingStatus: 'rendering'
              }))

              setVolume(loadedVolume)

              const copiedVolume = deepCopyVolume(loadedVolume)
              setOriginalVolume(copiedVolume)
            })
          }
        } else {
          if (buttonState !== 'Target' && buttonState !== 'Implant') {
            setNiftiProgress((prevState) => ({
              ...prevState,
              loadingStatus: 'rendering'
            }))
          }
        }
      }
    }

    mainGUIRef.current = new dat.GUI()
    mainGUIRef.current.hide()

    // add new button for 3D redering
    if (buttonState != 'None') {
      const on3DFolder = mainGUIRef.current.addFolder('3D')

      on3DFolder
        .add(on3DRender, 'on')
        .name('3D output')
        .onChange(function () {
          setOn3DRender({ ...on3DRender })
        })

      on3DRenderController = on3DFolder.__controllers.find(
        (controller) => controller.property === 'on'
      )

      threeDFolderRef.current = on3DFolder

      on3DFolder.hide()
      const viewFolder = mainGUIRef.current.addFolder('Views')
      viewFolder.hide()

      if (currentPanel === 'main') {
        if (buttonState === 'Rep' || buttonState === 'Target' || buttonState === 'Implant') {
          on3DRenderController.setValue(true)
          setCurrentView('view4')
        } else {
          // setCurrentView('whole')
          setCurrentView('view1')
        }
      }
    }

    // Finished creating the test buttons!

    if (buttonState != 'None') {
      const volume3DFolder = mainGUIRef.current.addFolder('Volume 3D')

      volume3DFolder.hide() // hide volume 3D setting
    }

    if (buttonState != 'Image' && buttonState != 'None') {
      const seg3DFolder = mainGUIRef.current.addFolder('Segmentation 3D')

      seg3DFolder.hide() // hide segmentation 3D setting
    }

    if (mainGUIRef.current) {
      mainGUIRef.current.domElement.style.position = 'absolute'
      mainGUIRef.current.domElement.style.right = '0'
      mainGUIRef.current.domElement.style.top = '0'
      mainGUIRef.current.domElement.style.zIndex = '999'
      mainGUIRef.current.domElement.style.marginTop = '28px'

      // containerRef.current.appendChild(guiref.current.domElement);
      mainRenderBoxRef.current.appendChild(mainGUIRef.current.domElement)
    }

    return () => {
      if (mainRenderBoxRef.current) {
        if (mainGUIRef.current) {
          mainRenderBoxRef.current.removeChild(mainGUIRef.current.domElement)
        }
      }
    }
  }, [mainGUIRef, buttonState, width, height, filePath, currentPanel])

  // useEffect to handle 3D button in the second line of header component
  useEffect(() => {
    if (mainGUIRef.current) {
      mainGUIRef.current.hide()

      on3DRenderController = threeDFolderRef.current.__controllers.find(
        (controller) => controller.property === 'on'
      )

      if (on3DRender.on === true) {
        on3DRenderController.setValue(false)
      }

      if (buttonState === 'Rep' || buttonState === 'Target' || buttonState === 'Implant') {
        setCurrentView('view4')
        setis3DWholeView(true)

        if (on3DRender.on === false) {
          on3DRenderController.setValue(true)
        }
      } else {
        setCurrentView('view1')
        setis3DWholeView(false)
      }

      // reset undostack and redostack
      setUndoStack([])
      setRedoStack([])
      setCurrentState(null)
      setIsRestore(false)

      //reset threeSceneInfo
      setThreeSceneInfo({
        initialized: false,
        controlsTarget: null,
        camera: null,
        set: false,
        originalCamera: null,
        originalControls: null
      })

      setManualRepositionMatrix({})
      setObjectPositionInfo({
        init: false,
        trans: null,
        rotation: null
      })

      setCoronalViewLoaded(false)
      setSagittalViewLoaded(false)

      SetAllViewLoaded(false) // this is use State to check all views are loaded at least one time
    }
  }, [buttonState])

  useEffect(() => {
    if (originalVolume === null) return

    let windowWidth, windowLevel

    if (selectedView === 'brain') {
      windowWidth = 100
      windowLevel = 50
    } else if (selectedView === 'air') {
      windowWidth = 1000
      windowLevel = -426
    } else if (selectedView === 'bone') {
      windowWidth = 1000
      windowLevel = 400
    } else if (selectedView === 'abdomen') {
      windowWidth = 350
      windowLevel = 40
    } else if (selectedView === 'lung') {
      windowWidth = 1400
      windowLevel = -500
    } else {
      windowWidth = 2000
      windowLevel = 0
    }

    // Calculate window minimum and maximum
    const winMin = windowLevel - windowWidth / 2
    const winMax = windowLevel + windowWidth / 2

    // Define output range
    const outMin = 0
    const outMax = 255

    const copiedVolume = new Volume()
    let copiedData = new Float32Array(originalVolume.data)

    if (selectedView !== 'none') {
      // Apply intensity windowing using map
      copiedData = copiedData.map((value) => {
        if (value < winMin) {
          return outMin
        } else if (value > winMax) {
          return outMax
        } else {
          return ((value - winMin) / (winMax - winMin)) * (outMax - outMin) + outMin
        }
      })

      // Ensure all values are clipped to the output range using map
      copiedData = copiedData.map((value) => Math.max(outMin, Math.min(value, outMax)))
    }

    copiedVolume.data = copiedData

    // Copy matrices and vectors
    copiedVolume.matrix = originalVolume.matrix.clone()
    copiedVolume.inverseMatrix = originalVolume.inverseMatrix.clone()

    // Copy simple properties
    // copiedVolume.windowLow = original.windowLow
    // copiedVolume.windowHigh = original.windowHigh
    copiedVolume.lowerThreshold = originalVolume.lowerThreshold
    copiedVolume.upperThreshold = originalVolume.upperThreshold

    // Copy array properties
    copiedVolume.dimensions = [...originalVolume.dimensions]
    copiedVolume.spacing = [...originalVolume.spacing]
    copiedVolume.RASDimensions = [...originalVolume.RASDimensions]
    copiedVolume.axisOrder = [...originalVolume.axisOrder]

    copiedVolume.xLength = copiedVolume.dimensions[0]
    copiedVolume.yLength = copiedVolume.dimensions[1]
    copiedVolume.zLength = copiedVolume.dimensions[2]

    // Copy any additional properties if necessary
    copiedVolume.id = originalVolume.id

    const minMax = copiedVolume.computeMinMax()
    const min = minMax[0]
    const max = minMax[1]
    // attach the scalar range to the volume
    copiedVolume.windowLow = min
    copiedVolume.windowHigh = max

    setVolume2D(copiedVolume)
    SetIsIntensityChange(true)
  }, [selectedView])

  // useEffect to handle 3D view when any view is maximized or minimized by double click
  useEffect(() => {
    if (doubleClickRendering) {
      on3DRenderController = threeDFolderRef.current.__controllers.find(
        (controller) => controller.property === 'on'
      )

      if (on3DRender.on === false) {
        on3DRenderController.setValue(true)
      }

      setDoubleClickRendering(false)
    }
  }, [doubleClickRendering])

  // useEffect to change from brightness value in setting to brigtness in gui folder
  useEffect(() => {
    if (mainGUIRef.current) {
      const guiFolder = mainGUIRef.current.__folders['Brightness & Contrast']

      if (!guiFolder) {
        return
      }

      let brightnessCotroller = guiFolder.__controllers.find(
        (controller) => controller.property === 'brightness'
      )

      brightnessCotroller.setValue(brightnessTool.brightness)
    }
  }, [brightnessTool])

  // useEffect to change from contrast value in setting to contrast in gui folder
  useEffect(() => {
    if (mainGUIRef.current) {
      const guiFolder = mainGUIRef.current.__folders['Brightness & Contrast']

      if (!guiFolder) {
        return
      }

      let contrastCotroller = guiFolder.__controllers.find(
        (controller) => controller.property === 'contrast'
      )

      contrastCotroller.setValue(contrastTool.contrast)
    }
  }, [contrastTool])

  // useEffect to find minimum and maximum volume range
  useEffect(() => {
    if (originalVolume === null) {
      return
    }

    const { data, RASDimensions } = originalVolume

    const volumeSize = RASDimensions[0] * RASDimensions[1] * RASDimensions[2]
    const floatArray = new Float32Array(data.buffer, data.byteOffset, volumeSize)

    let maxValue = -Infinity
    let minValue = Infinity

    // Find the maximum and minimum values
    for (let i = 0; i < volumeSize; i++) {
      const value = floatArray[i]
      if (value > maxValue) {
        maxValue = value
      }
      if (value < minValue) {
        minValue = value
      }
    }

    setVolumeRange({
      ...volumeRange,
      min: minValue,
      max: maxValue
    })

    setIsoSliderInfo({
      ...isoSliderInfo,
      min: minValue < 200 ? isoThresholdMinimumDefault : minValue,
      max: maxValue
    })

    setFinalLoad({ ...finalLoad, beforeSliderAxial: true })
  }, [originalVolume])

  useEffect(() => {
    if (label !== null) {
      const newData = new Float32Array(label.data)

      setUndoStack([])
      setRedoStack([])

      setCurrentState(newData)
    }
  }, [label])

  useEffect(() => {
    if (isSegmentToolAffected === true) {
      setIsSegmentToolAffected(false)

      if (currentState !== null) {
        setUndoStack([...undoStack, currentState])
      }

      setRedoStack([])

      setCurrentState(new Float32Array(label.data))
    }
  }, [isSegmentToolAffected])

  useEffect(() => {
    if (buttonState === 'Seg' || buttonState === 'Iso') {
      if (isRedo === true) {
        setIsRedo(false)

        if (redoStack.length === 0) {
          console.log('no more redo')

          return
        } else {
          const nextState = redoStack.pop()
          setUndoStack([...undoStack, new Float32Array(label.data)])
          label.data = new Float32Array(nextState)
          setLabel(label)
          setCurrentState(new Float32Array(nextState))

          setIsLabelChange(true)
        }
      }
    }
  }, [isRedo])

  useEffect(() => {
    if (buttonState === 'Seg' || buttonState === 'Iso') {
      if (isUndo === true) {
        setIsUndo(false)

        if (undoStack.length === 0) {
          window.alert('there are no data for undo')
          return
        } else if (undoStack.length === 1) {
          setRedoStack([...redoStack, new Float32Array(label.data)])

          const lastState = undoStack.pop()
          label.data = new Float32Array(lastState)

          setLabel(label)

          setCurrentState(new Float32Array(lastState))
        } else {
          const lastState = undoStack.pop()

          setRedoStack([...redoStack, new Float32Array(label.data)])
          label.data = new Float32Array(lastState)

          setLabel(label)
          setCurrentState(new Float32Array(lastState))
        }

        setIsLabelChange(true)
      }
    }
  }, [isUndo])

  useEffect(() => {
    if (isRestore && label) {
      if (buttonState === 'Seg' || buttonState === 'Iso') {
        label.data = new Float32Array(predictedData)
      }
    }

    if (isRestore) {
      setUndoStack([])
      setRedoStack([])
    }
  }, [isRestore])

  useEffect(() => {
    if ((currentView === 'view1' || currentView === 'whole') && volume && volumeDimensions) {
      const { x, z } = volumeDimensions
      if (x && z) {
        const yAxialPosition =
          (((Math.floor(sliderAxial.max / 2) - sliderAxial.current) / (sliderAxial.max / 2)) *
            x.height) /
          2
        const yZPosition =
          (((Math.floor(sliderAxial.max / 2) - sliderAxial.current) / (sliderAxial.max / 2)) *
            z.height) /
          2

        setCrosshairsPositionXY({
          y: yAxialPosition,
          x: crosshairsPositionXY.x,
          z: 0
        })

        setCrosshairsPositionZY({
          y: yZPosition,
          x: crosshairsPositionZY.x,
          z: 0
        })
      }
    }
  }, [sliderAxial])

  useEffect(() => {
    if ((currentView === 'view2' || currentView === 'whole') && volume && volumeDimensions) {
      const { x, y } = volumeDimensions
      if (x && y) {
        const xCoronalPosition =
          (((sliderCoronal.current - Math.floor(sliderCoronal.max / 2)) / (sliderCoronal.max / 2)) *
            x.width) /
          2
        const yCoronalPosition =
          (((Math.ceil(sliderCoronal.max / 2) - sliderCoronal.current) / (sliderCoronal.max / 2)) *
            y.height) /
          2

        setCrosshairsPositionXX({
          y: crosshairsPositionXX.y,
          x: xCoronalPosition,
          z: 0
        })

        setCrosshairsPositionYY({
          y: yCoronalPosition,
          x: crosshairsPositionYY.x,
          z: 0
        })
      }
    }
  }, [sliderCoronal])

  useEffect(() => {
    if ((currentView === 'view3' || currentView === 'whole') && volume && volumeDimensions) {
      const { y, z } = volumeDimensions
      if (y && z) {
        const xSagittalPositionY =
          (((sliderSagittal.current - Math.floor(sliderSagittal.max / 2)) /
            (sliderSagittal.max / 2)) *
            y.width) /
          2
        const xSagittalPositionZ =
          (((sliderSagittal.current - Math.floor(sliderSagittal.max / 2)) /
            (sliderSagittal.max / 2)) *
            z.width) /
          2

        setCrosshairsPositionYX({
          y: crosshairsPositionYX.y,
          x: xSagittalPositionY,
          z: 0
        })

        setCrosshairsPositionZX({
          y: crosshairsPositionZX.y,
          x: xSagittalPositionZ,
          z: 0
        })
      }
    }
  }, [sliderSagittal])

  useEffect(() => {
    if (finalLoad.beforeSliderAxial) {
      setFinalLoad({
        beforeSliderAxial: false,
        afterSliderAxial: true
      })

      const tmpValue = sliderAxial.current - 1

      setsliderAxial({
        ...sliderAxial,
        current: tmpValue
      })
    }

    if (finalLoad.afterSliderAxial) {
      const tmpValue = sliderAxial.current + 1

      setsliderAxial({
        ...sliderAxial,
        current: tmpValue
      })

      setFinalLoad({
        beforeSliderAxial: false,
        afterSliderAxial: false
      })

      if (buttonState == 'Seg' || buttonState == 'Iso') {
        setLoadingValue(100)
      }
    }
  }, [finalLoad])

  useEffect(() => {
    if (coronalViewLoaded) {
      if (buttonState == 'Seg' || buttonState == 'Iso') {
        if (!sliderCoronal.currrent) {
          setsliderCoronal({
            ...sliderCoronal,
            current: Math.floor(volume.RASDimensions[2] / 2),
            max: volume.RASDimensions[2] - 1
          })
        }
      }

      setLoadingValue(75)
    }
  }, [coronalViewLoaded])

  useEffect(() => {
    if (sagittalViewLoaded) {
      if (buttonState == 'Seg' || buttonState == 'Iso') {
        if (!sliderSagittal.currrent) {
          setsliderSagittal({
            ...sliderSagittal,
            current: Math.floor(volume.RASDimensions[0] / 2),
            max: volume.RASDimensions[0] - 1
          })
        }
      }

      setLoadingValue(100)

      SetAllViewLoaded(true)
    }
  }, [sagittalViewLoaded])

  return (
    <>
      {buttonState === 'Implant' && movementConfig.type === 'auto' && (
        <AutoImplantInfoBox automatedPlacementStatus={automatedPlacementStatus} />
      )}
      {buttonState === 'Implant' && isMovementExpanded === true && autoPlacementInfo == true && (
        <AutomatedPlacementInfoBox setAutoPlacementInfo={setAutoPlacementInfo} />
      )}
      {buttonState === 'Rep' && isMovementExpanded === true && translationInfo == true && (
        <RepositionTranslationInfoBox setTranslationInfo={setTranslationInfo} />
      )}
      {buttonState === 'Rep' && isMovementExpanded === true && <PositionInfoBox />}

      {buttonState === 'Implant' && isMovementExpanded === true && translationInfo == true && (
        <ImplantTranslationInfoBox setTranslationInfo={setTranslationInfo} />
      )}

      <MainRenderBox name="MainRenderBox" ref={mainRenderBoxRef} theme={theme} style={cursorStyle}>
        <Grid
          container
          alignItems="stretch"
          rowSpacing={1}
          columnSpacing={1}
          sx={{ height: '100%', flexGrow: 1 }}
        >
          {buttonState !== 'Target' && buttonState !== 'Implant' && (
            <Grid
              item
              xs={currentView === 'whole' || currentView === 'dual Axial' ? 6 : 12} // Half width for 'whole' and 'dual', full width for 'view1'
              sx={{
                display:
                  currentView === 'view1' || currentView === 'whole' || currentView === 'dual Axial'
                    ? 'block'
                    : 'none',
                height: currentView === 'whole' ? '50%' : '100%',
                maxWidth: '100%',
                flexBasis: currentView === 'whole' ? '50%' : '100%'
              }}
            >
              <ViewBox
                name="View1"
                ref={viewBoxRef1}
                onDoubleClick={() => handleViewClick('view1')}
              >
                <Box sx={{ position: 'relative', height: '100%' }}>
                  <AxialSlider sliderAxial={sliderAxial} setsliderAxial={setsliderAxial} />
                  <RenderingAxialView
                    mainGUIRef={mainGUIRef}
                    buttonState={buttonState}
                    volume={volume}
                    label={label}
                    viewBoxRef={viewBoxRef1}
                    currentView={currentView}
                    volConfig={volConfig}
                    measureConfig={measureConfig}
                    segmentConfig={segmentConfig}
                    Radius={Radius}
                    selectedSegment={selectedSegment}
                    setLabel={setLabel}
                    slider={sliderAxial}
                    setslider={setsliderAxial}
                    sliderType={'axial'}
                    axis={'y'}
                    rASDimensionsIndex={1}
                    view={'View1'} //change to lowercase in refactoring
                    setCurrentView={setCurrentView}
                    brightness={brightnessTool}
                    contrast={contrastTool}
                    labelThresholdRange={labelThresholdRange}
                    isSegmentationVisible={isSegmentationVisible}
                    IsLabelChange={IsLabelChange}
                    labelColorSwitch={labelColorSwitch}
                    isRestore={isRestore}
                    setIsRestore={setIsRestore}
                    predictedData={predictedData}
                    crosshairsPositionX={crosshairsPositionYX}
                    crosshairsPositionY={crosshairsPositionYY}
                    crosshairsOn={crosshairsOn}
                    sliderSagittal={sliderSagittal}
                    setsliderSagittal={setsliderSagittal}
                    setVolumeDimensions={setVolumeDimensions}
                    volumeDimensions={volumeDimensions}
                    volume2D={volume2D}
                    SetIsIntensityChange={SetIsIntensityChange}
                    isIntensityChange={isIntensityChange}
                    setLoadingValue={setLoadingValue}
                  />
                </Box>
              </ViewBox>
            </Grid>
          )}
          {buttonState !== 'Target' && buttonState !== 'Implant' && (
            <Grid
              item
              xs={currentView === 'whole' || currentView === 'dual Coronal' ? 6 : 12} // Half width for 'whole' and 'dual', full width for 'view1'
              sx={{
                display:
                  currentView === 'view2' ||
                  currentView === 'whole' ||
                  currentView === 'dual Coronal'
                    ? 'block'
                    : 'none',
                height: currentView === 'whole' ? '50%' : '100%',
                maxWidth: '100%',
                flexBasis: currentView === 'whole' ? '50%' : '100%'
              }}
            >
              <ViewBox
                name="View2"
                ref={viewBoxRef2}
                onDoubleClick={() => handleViewClick('view2')}
              >
                <Box sx={{ position: 'relative', height: '100%' }}>
                  <CoronalSlider
                    sliderCoronal={sliderCoronal}
                    setsliderCoronal={setsliderCoronal}
                  />
                  <RenderingCoronalView
                    mainGUIRef={mainGUIRef}
                    buttonState={buttonState}
                    volume={volume}
                    label={label}
                    viewBoxRef={viewBoxRef2}
                    currentView={currentView}
                    volConfig={volConfig}
                    measureConfig={measureConfig}
                    segmentConfig={segmentConfig}
                    Radius={Radius}
                    selectedSegment={selectedSegment}
                    setLabel={setLabel}
                    slider={sliderCoronal}
                    setslider={setsliderCoronal}
                    axis="z"
                    rASDimensionsIndex={2}
                    sliderType={'coronal'}
                    view={'View2'} //change to lowercase in refactoring
                    setCurrentView={setCurrentView}
                    brightness={brightnessTool}
                    contrast={contrastTool}
                    labelThresholdRange={labelThresholdRange}
                    isSegmentationVisible={isSegmentationVisible}
                    IsLabelChange={IsLabelChange}
                    labelColorSwitch={labelColorSwitch}
                    isRestore={isRestore}
                    setIsRestore={setIsRestore}
                    predictedData={predictedData}
                    crosshairsPositionX={crosshairsPositionZX}
                    crosshairsPositionY={crosshairsPositionZY}
                    crosshairsOn={crosshairsOn}
                    sliderSagittal={sliderSagittal}
                    setsliderSagittal={setsliderSagittal}
                    setVolumeDimensions={setVolumeDimensions}
                    volumeDimensions={volumeDimensions}
                    volume2D={volume2D}
                    SetIsIntensityChange={SetIsIntensityChange}
                    isIntensityChange={isIntensityChange}
                    coronalViewLoaded={coronalViewLoaded}
                    setCoronalViewLoaded={setCoronalViewLoaded}
                    axialViewBoxRef={viewBoxRef1}
                  />
                </Box>
              </ViewBox>
            </Grid>
          )}
          {buttonState !== 'Target' && buttonState !== 'Implant' && (
            <Grid
              item
              xs={currentView === 'whole' || currentView === 'dual Sagittal' ? 6 : 12} // Half width for 'whole' and 'dual', full width for 'view1'
              sx={{
                display:
                  currentView === 'view3' ||
                  currentView === 'whole' ||
                  currentView === 'dual Sagittal'
                    ? 'block'
                    : 'none',
                height: currentView === 'whole' ? '50%' : '100%',
                maxWidth: '100%',
                flexBasis: currentView === 'whole' ? '50%' : '100%'
              }}
            >
              <ViewBox
                name="View3"
                ref={viewBoxRef3}
                onDoubleClick={() => handleViewClick('view3')}
              >
                <Box sx={{ position: 'relative', height: '100%' }}>
                  <SagittalSlider
                    sliderSagittal={sliderSagittal}
                    setsliderSagittal={setsliderSagittal}
                  />
                  <RenderingSagittalView
                    mainGUIRef={mainGUIRef}
                    buttonState={buttonState}
                    volume={volume}
                    label={label}
                    viewBoxRef={viewBoxRef3}
                    currentView={currentView}
                    volConfig={volConfig}
                    measureConfig={measureConfig}
                    segmentConfig={segmentConfig}
                    Radius={Radius}
                    selectedSegment={selectedSegment}
                    setLabel={setLabel}
                    slider={sliderSagittal}
                    setslider={setsliderSagittal}
                    axis={'x'}
                    rASDimensionsIndex={0}
                    sliderType={'sagittal'}
                    view={'View3'} //change to lowercase in refactoring
                    setCurrentView={setCurrentView}
                    brightness={brightnessTool}
                    contrast={contrastTool}
                    labelThresholdRange={labelThresholdRange}
                    isSegmentationVisible={isSegmentationVisible}
                    IsLabelChange={IsLabelChange}
                    labelColorSwitch={labelColorSwitch}
                    isRestore={isRestore}
                    setIsRestore={setIsRestore}
                    predictedData={predictedData}
                    crosshairsPositionX={crosshairsPositionXX}
                    crosshairsPositionY={crosshairsPositionXY}
                    crosshairsOn={crosshairsOn}
                    sliderSagittal={sliderSagittal}
                    setsliderSagittal={setsliderSagittal}
                    setVolumeDimensions={setVolumeDimensions}
                    volumeDimensions={volumeDimensions}
                    volume2D={volume2D}
                    SetIsIntensityChange={SetIsIntensityChange}
                    isIntensityChange={isIntensityChange}
                    sagittalViewLoaded={sagittalViewLoaded}
                    setSagittalViewLoaded={setSagittalViewLoaded}
                    axialViewBoxRef={viewBoxRef1}
                  />
                </Box>
              </ViewBox>
            </Grid>
          )}
          <Grid
            item
            xs={currentView === 'whole' || currentView.includes('dual') ? 6 : 12}
            style={{
              display:
                currentView === 'view4' || currentView === 'whole' || currentView.includes('dual')
                  ? 'block'
                  : 'none'
            }}
            sx={{
              height: currentView === 'whole' ? '50%' : '100%',
              maxWidth: '100%',
              flexBasis: currentView === 'whole' ? '50%' : '100%'
            }}
          >
            <ViewBox
              name="View4"
              ref={viewBoxRef4}
              onDoubleClick={
                buttonState === 'Rep' || buttonState === 'Target' || buttonState === 'Implant'
                  ? undefined
                  : () => handleViewClick('view4')
              }
            >
              <CameraCompass setRotationVector={setRotationVector}></CameraCompass>

              {buttonState !== 'Implant' &&
              buttonState !== 'Rep' &&
              buttonState !== 'Target' &&
              on3DRender.on === true ? (
                <RenderingThreeScene
                  mainGUIRef={mainGUIRef}
                  buttonState={buttonState}
                  volume={originalVolume}
                  label={label}
                  viewBoxRef4={viewBoxRef4}
                  config3D={config3D}
                  stats={stats}
                  threeSceneInfo={threeSceneInfo}
                  setThreeSceneInfo={setThreeSceneInfo}
                  isSegmentationVisible={isSegmentationVisible}
                  isVolumeVisible={isVolumeVisible}
                  ISOThreshold={thresholdTool}
                  labelThresholdRange={labelThresholdRange}
                  isoSliderInfo={isoSliderInfo}
                  setIsoSliderInfo={setIsoSliderInfo}
                  IsLabelChange={IsLabelChange}
                  labelColorSwitch={labelColorSwitch}
                  cameraResetClicked={cameraResetClicked}
                  setCameraResetClicked={setCameraResetClicked}
                  isRestore={isRestore}
                  setIsRestore={setIsRestore}
                  predictedData={predictedData}
                  rotationVector={rotationVector}
                  setRotationVector={setRotationVector}
                  viewBoxOn={viewBoxOn}
                  segmentConfig={segmentConfig}
                  selectedSegment={selectedSegment}
                  setIsSegmentToolAffected={setIsSegmentToolAffected}
                  setIsLabelChange={setIsLabelChange}
                  rotateSpeed={rotateSpeed}
                />
              ) : buttonState === 'Implant' && on3DRender.on === true ? (
                <RenderingThreeSceneImplant
                  mainGUIRef={mainGUIRef}
                  filePath={filePath}
                  buttonState={buttonState}
                  viewBoxRef4={viewBoxRef4}
                  stats={stats}
                  segmentConfig={segmentConfig}
                  selectedSegment={selectedSegment}
                  movementConfig={movementConfig}
                  implantToken={implantToken}
                  implantInfo={implantInfo}
                  setImplantInfo={setImplantInfo}
                  isRedo={isRedo}
                  setIsRedo={setIsRedo}
                  isUndo={isUndo}
                  setIsUndo={setIsUndo}
                  redoStack={redoStack}
                  setRedoStack={setRedoStack}
                  undoStack={undoStack}
                  setUndoStack={setUndoStack}
                  labelColorSwitch={labelColorSwitch}
                  centerPoint={centerPoint}
                  setMovementConfig={setMovementConfig}
                  setCurrentMovementMenu={setCurrentMovementMenu}
                  cameraResetClicked={cameraResetClicked}
                  setCameraResetClicked={setCameraResetClicked}
                  isRestore={isRestore}
                  setIsRestore={setIsRestore}
                  setAutomatedPlacementStatus={setAutomatedPlacementStatus}
                  checkedValues={checkedValues}
                  rotationVector={rotationVector}
                  setRotationVector={setRotationVector}
                  viewBoxOn={viewBoxOn}
                  setIsLoading={setIsLoading}
                  regObjLoaded={regObjLoaded}
                  repLoadedObjects={repLoadedObjects}
                  indication={indication}
                  rotateSpeed={rotateSpeed}
                />
              ) : buttonState === 'Rep' && on3DRender.on === true ? (
                <RenderingThreeSceneReposition
                  mainGUIRef={mainGUIRef}
                  filePath={filePath}
                  buttonState={buttonState}
                  movementConfig={movementConfig}
                  viewBoxRef4={viewBoxRef4}
                  stats={stats}
                  checkedValues={checkedValues}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  setRepoScene={setRepoScene}
                  isRedo={isRedo}
                  setIsRedo={setIsRedo}
                  isUndo={isUndo}
                  setIsUndo={setIsUndo}
                  redoStack={redoStack}
                  setRedoStack={setRedoStack}
                  undoStack={undoStack}
                  setUndoStack={setUndoStack}
                  setMovementConfig={setMovementConfig}
                  setCurrentMovementMenu={setCurrentMovementMenu}
                  centerPoint={centerPoint}
                  labelColorSwitch={labelColorSwitch}
                  cameraResetClicked={cameraResetClicked}
                  setCameraResetClicked={setCameraResetClicked}
                  isRestore={isRestore}
                  setIsRestore={setIsRestore}
                  rotationVector={rotationVector}
                  setRotationVector={setRotationVector}
                  viewBoxOn={viewBoxOn}
                  setIsLoading={setIsLoading}
                  repLoadedObjects={repLoadedObjects}
                  rotateSpeed={rotateSpeed}
                />
              ) : buttonState === 'Target' && on3DRender.on === true ? (
                animationOn ? (
                  <AnimationTargetAnatomy
                    mainGUIRef={mainGUIRef}
                    filePath={filePath}
                    viewBoxRef4={viewBoxRef4}
                    stats={stats}
                    checkedValues={checkedValues}
                    labelColorSwitch={labelColorSwitch}
                    cameraResetClicked={cameraResetClicked}
                    setCameraResetClicked={setCameraResetClicked}
                    rotationVector={rotationVector}
                    setRotationVector={setRotationVector}
                    viewBoxOn={viewBoxOn}
                    setIsLoading={setIsLoading}
                    isoLoadedObjects={isoLoadedObjects}
                    rotateSpeed={rotateSpeed}
                  />
                ) : (
                  <RenderingThreeSceneTargetAnatomy
                    mainGUIRef={mainGUIRef}
                    filePath={filePath}
                    viewBoxRef4={viewBoxRef4}
                    stats={stats}
                    checkedValues={checkedValues}
                    labelColorSwitch={labelColorSwitch}
                    cameraResetClicked={cameraResetClicked}
                    setCameraResetClicked={setCameraResetClicked}
                    rotationVector={rotationVector}
                    setRotationVector={setRotationVector}
                    viewBoxOn={viewBoxOn}
                    setIsLoading={setIsLoading}
                    repLoadedObjects={repLoadedObjects}
                    rotateSpeed={rotateSpeed}
                  />
                )
              ) : null}
            </ViewBox>
          </Grid>
        </Grid>
      </MainRenderBox>
    </>
  )
}

export default TwoDViews
