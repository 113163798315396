/* eslint-disable react/prop-types */
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'

const CloseButton = ({ setShowToolBox }) => {
  const closeToolBox = () => {
    setShowToolBox(false)
  }

  return (
    <IconButton
      aria-label="close"
      sx={{ float: 'right', color: 'white', padding: '0' }}
      onClick={closeToolBox} // Move the onClick handler here
    >
      <CloseIcon sx={{ fontSize: '25px' }} />
    </IconButton>
  )
}
export default CloseButton
