/* eslint-disable react/prop-types */

export const CurrentCaseButtonHandler = async (
  setCurrentView,
  isClickedCase,
  setIsRenderingThreeDisabled,
  setLoadingValue,
  setIsLoading,
  on3DRender,
  setOn3DRender
) => {
  if (isClickedCase === false) {
    setIsRenderingThreeDisabled(false)
    setIsLoading(true)
    setLoadingValue(50)
    setCurrentView('main')

    if (on3DRender.on === true) {
      setOn3DRender({ on: !on3DRender.on })
    }
  }
}
