/* eslint-disable react/prop-types */
/* eslint-disable no-constant-condition */
import { rqlBuilder } from '@extrahorizon/javascript-sdk'

export const isolationLoader = async (
  sdk,
  backInfo,
  fileName,
  userInfo,
  setBackInfo,
  setLoadingValue
) => {
  setLoadingValue(0)

  const delay = (ms) => new Promise((res) => setTimeout(res, ms))
  let resultDocument

  const schema = await sdk.data.schemas.findByName('Pipeline', {
    rql: rqlBuilder().select(['id', 'name', 'transitions']).build()
  })

  let document = await sdk.data.documents.findById(backInfo['schemaID'], backInfo['documentID'])

  const resultFolderToken = document.data.resultFolderToken
  const fractureLocation = document.data.side
  const originFileToken = document.data.originFileToken
  const fileToken = document.data.fileToken
  const segToken = document.data.segToken
  let isoToken

  if (document.data.segToken !== document.data.latest_version.segToken) {
    isoToken = ''
  } else {
    isoToken = document.data.isoToken
  }

  await delay(1000)

  if (isoToken === '' && segToken != '') {
    if (process.env.REMOTE_SERVER == false) {
      await sdk.data.documents.transition(backInfo['schemaID'], document.id, {
        id: schema.findTransitionIdByName('isolation'),
        data: {
          filename: fileName,
          user: userInfo['id'],
          broken_location: fractureLocation,
          resultFolderToken: resultFolderToken,
          originFileToken: originFileToken,
          fileToken: fileToken,
          segToken: segToken
        }
      })
    } else {
      // load second backend
      const backendInfo = {
        schemaID: schema.id,
        documentID: document.id,
        indication: document.data.indication
      }

      if (document.data.indication == 'Wrist' || document.data.indication == 'Clavicle') {
        if (
          document.data.status === 'segmented_file' ||
          (document.data.status !== 'segmented_file' &&
            document.data.status !== 'iso_preprocessed' &&
            document.data.status !== 'isolated' &&
            document.data.status !== 'iso_postprocessed' &&
            document.data.status !== 'isolated_file')
        ) {
          let startTimestamp = performance.now()

          fetch('/isolation', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(backendInfo)
          })
            .then((response) => {
              let endTimestamp = performance.now()
              let timeElapsed = endTimestamp - startTimestamp

              // console.log(`Fetch request took ${timeElapsed} milliseconds.`)

              if (timeElapsed > 10000 && response.status === 504) {
                console.log('Ignoring gateway timeout after 10 seconds.')
              } else {
                if (!response.ok) {
                  throw new Error(`Error ${response.status}: ${response.statusText}`)
                }
              }
            })
            .catch((error) => {
              console.error('Fetch error: ', error.message)

              setLoadingValue(-1)
              window.alert(error.message)
            })
        }
      } else {
        let startTimestamp = performance.now()

        fetch('/isolation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(backendInfo)
        })
          .then((response) => {
            let endTimestamp = performance.now()
            let timeElapsed = endTimestamp - startTimestamp

            // console.log(`Fetch request took ${timeElapsed} milliseconds.`)

            if (timeElapsed > 10000 && response.status === 504) {
              console.log('Ignoring gateway timeout after 10 seconds.')
            } else {
              if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`)
              }
            }
          })
          .catch((error) => {
            console.error('Fetch error: ', error.message)

            setLoadingValue(-1)
            window.alert(error.message)
          })
      }
    }

    setBackInfo({
      ...backInfo,
      documentID: document.id
    })

    // loop until resultDocument.data.test is not null
    while (true) {
      resultDocument = await sdk.data.documents.findById(backInfo['schemaID'], document.id)

      if (resultDocument.data.status === 'stop') {
        setLoadingValue(0)
        return 'stop'
      }

      if (document.data.indication == 'Wrist' || document.data.indication == 'Clavicle') {
        if (resultDocument.data.status === 'iso_preprocessed') {
          setLoadingValue(20)
        }

        if (resultDocument.data.status === 'isolated') {
          setLoadingValue(40)
        }

        if (resultDocument.data.status === 'iso_postprocessed') {
          setLoadingValue(60)
        }

        if (resultDocument.data.status === 'isolated_file') {
          setLoadingValue(80)
        }
        if (resultDocument.data.isoToken !== '') {
          break
        }
      } else {
        if (resultDocument.data.isoToken !== '') {
          break
        }
      }

      // wait for a short period of time before trying again
      await delay(1000)
    }

    isoToken = resultDocument.data.isoToken
  }

  return `${
    process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_HOST_NAME : ''
  }/files/v1/${isoToken}/file`
}
