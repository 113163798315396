/* eslint-disable react/prop-types */
import { getSchema } from '../../maintool/functions/others/GetSchema'

// function to handle when user click one of active case cards
export const activeCaseCardDeleteHandler = async (sdk, cases) => {
  const schema = await getSchema(sdk)
  await sdk.data.documents.remove(schema.id, cases.id)

  // Refresh the window to renew active case card list
  window.location.reload()
}
